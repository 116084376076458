export function HandleErrors(err) {
  console.log("Error", err);
  if (err.response === undefined) {
    return `Erro desconhecido`;
  } else if (err.response.status === 500) {
    return `Erro interno de servidor`;
  } else if (err.response.status === 400) {
    return `Ocorreu o seguinte erro : ${err.response.data}`;
  } else if (err.response.status === 401) {
    return `Acesso não autorizado`;
  } else if (err.response.status === 403) {
    return `Acesso proibido`;
  } else if (err.response.status === 404) {
    if (
      err.response.data != undefined &&
      err.response.data != null &&
      err.response.data != ""
    ) {
      return `Não encontrado`;
      return err.response.data;
    }
    return `Elemento não existe`;
  } else if (err.response.status === 408) {
    return `Excedido tempo limite, verifique o status da sua conexão`;
  } else if (err.response.status === 409) {
    return `Já existe um usuário cadastrado com esse email`;
  } else if (err.response.status === 413 || err.response.status === 414) {
    return `Arquivo grande demais`;
  } else if (err.response.status === 415) {
    return `Formato de arquivo não suportado`;
  } else if (err.response.status === 204) {
    return "-";
  } else {
    return "Ocorreu um erro desconhecido";
  }
}

export function HandleAuthErrors(err) {
  if (err.response === undefined) {
    return `Erro desconhecido`;
  } else if (err.status === 500 || err.response.status === 500) {
    return `Erro interno de servidor, tente novamente mais tarde ou entre em contato com suporte.`;
  } else if (err.response.status === 400) {
    let message = "";
    for (let s in err.response.data) {
      message += " " + err.response.data[s];
    }
    return message;
  } else if (err.response.status === 401) {
    return `O usuário ou senha estão incorretos`;
  } else if (err.response.status === 403) {
    return `Acesso proibido`;
  } else if (err.response.status === 404) {
    return `A conta que esta tentando acessar não existe`;
  } else if (err.response.status === 409) {
    return `Já existe um usuário cadastrado com esse email`;
  } else if (err.response.status === 408) {
    return `Excedido tempo limite de acesso, verifique o status da sua conexão`;
  } else {
    return "Ocorreu um erro desconhecido";
  }
}

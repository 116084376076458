import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  ExigencyTemplateInsert,
  ExigencyTemplateList,
  ExigencyTemplateUpdate,
} from "@/app/core/base/controllers/ExigencyTemplateController";
import { ProcessDates } from "@/app/core/util/DateTranslater";

function ExigencyFormComponent({ show, onHide, submit, isLoading, data }) {
  const [template, setTemplate] = useState({});
  const [query, setQuery] = useState("");

  const [exigencies, setExigencies] = useState([]);

  useEffect(() => {
    if (data !== null || data.id !== 0) {
      setTemplate(data);
    } else {
      setTemplate(data);
    }
    getModels();
  }, [data]);

  function getModels() {
    ExigencyTemplateList((res) => {
      setExigencies(ProcessDates(res));
    });
  }

  function save() {
    if (template.description === null || template.code === null) {
      toast.error("Preencha todos os dados para salvar");
      return;
    } else {
      if (template.id === undefined) {
        saveModel();
      } else {
        updateModel();
      }
    }
  }

  function saveModel() {
    ExigencyTemplateInsert(template, (res) => {
      onHide();
      toast.success("Salvo com sucesso");
    });
  }

  function updateModel() {
    ExigencyTemplateUpdate(template, (res) => {
      onHide();
      toast.success("Salvo com sucesso");
    });
  }

  function handleChange(evt) {
    let old = template;
    let newData = {
      [evt.target.name]:
        evt.target.name === "code" ||
        evt.target.name === "relativeCode" ||
        evt.target.name === "action"
          ? parseInt(evt.target.value)
          : evt.target.value,
    };

    setTemplate({ ...old, ...newData });
  }

  return (
    <Modal
      title="Modelo de exigencia"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={save}
      onSubmit={() => save()}
    >
      <form onSubmit={save}>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="example-masked-time">Nome</label>
              <input
                type="text"
                class="form-control"
                name="description"
                defaultValue={template && template.description}
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div class="form-group">
              <label for="example-masked-time">Codigo</label>
              <input
                type="text"
                class="form-control"
                name="code"
                defaultValue={template && template.code}
                onChange={(evt) => handleChange(evt)}
                placeholder=""
              />
            </div>
            <div className="form-group">
              <span>Ação esperada?</span>
              <select
                name="action"
                className="form-control"
                onChange={(evt) => handleChange(evt)}
              >
                <option
                  value={1}
                  selected={template.action === 1 ? true : false}
                >
                  Upload de documento
                </option>
                <option
                  value={4}
                  selected={template.action === 4 ? true : false}
                >
                  Enviar documento físico
                </option>
                <option
                  value={3}
                  selected={template.action === 3 ? true : false}
                >
                  Taxa
                </option>
                <option
                  value={2}
                  selected={template.action === 2 ? true : false}
                >
                  Resposta
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="example-masked-time">Exigência complementar</label>
              <Typeahead
                options={exigencies}
                labelKey={(option) => `${option.code} - ${option.description}`}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                placeholder="Selecionar Exigência"
                onChange={(selected) => {
                  if (selected[0] === undefined) {
                  } else {
                    handleChange({
                      target: { value: selected[0].code, name: "relativeCode" },
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
export default ExigencyFormComponent;

import OptionalOrganListController from "@/app/core/base/controllers/OptionalOrganListController";
import OptionalServiceController from "@/app/core/base/controllers/OptionalServiceController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AddOrganButton from "../organs/AddOrganButton";

export default function OptionalOrganListComponent({
  refresh,
  service,
  organs,
  disabled,
}) {
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState([]);

  const organCtrl = OptionalOrganListController();
  const optionalService = OptionalServiceController();
  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    if (organs && organs.length > 0) {
      let newList = organs.sort(function (a, b) {
        return a.position - b.position;
      });
      setOrganList(newList);
    } else {
      setOrganList([]);
    }
  }, [organs]);

  async function loadOrgans() {
    await organCtrl.Get(service.id, (res) => setOrganList(res));
  }

  async function deleteOrgan(organ) {
    if (window.confirm(Message.DeleteConfirm)) {
      await organCtrl.Delete(organ.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadOrgans();
      });
    }
  }

  async function addOrgan(organ) {
    await organCtrl.Insert(
      {
        ...organ,
        organName: organ.name,
        organId: organ.id,
        optionalServiceId: service.id,
        position: organList.length + 1,
      },
      (res) => {
        toast.success(Message.DeleteSuccess);
        loadOrgans();
      },
    );
  }

  const drop = async (e) => {
    if (disabled == true) {
      return;
    }
    let copyListItems = [...organList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    for (let s in copyListItems) {
      copyListItems[s].position = parseInt(s) + 1;
    }

    setOrganList(copyListItems);
    await organCtrl.UpdateList(copyListItems, (res) => {});
  };

  function dragStart(e, index) {
    dragItem.current = index;
  }
  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  return (
    <div className={`block block-rounded block-bordered mb-2`} key={data.id}>
      <div className="block-header">
        <div className="block-title">Orgãos</div>
        <div className="block-tools">
          <AddOrganButton
            selectedOrgan={(item) => addOrgan(item)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="block-content">
        {organList &&
          organList.map((item, index) => (
            <div
              className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              key={`optiona-organ-${data.id}-${index}`}
              onDragEnd={drop}
              draggable
            >
              <table className="table table-borderless table-vcenter mb-0">
                <tbody>
                  <tr>
                    <td className="js-task-content font-w600">
                      {item && item.position}º {item && item.organName}
                    </td>
                    <td className="text-right" style={{ width: 100 }}>
                      <button
                        onClick={() => deleteOrgan(item)}
                        disabled={disabled}
                        className="js-task-remove btn btn-sm btn-link text-danger"
                      >
                        <i className="fa fa-times fa-fw"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </div>
  );
}

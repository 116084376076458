import DiscountController from "@/app/core/base/controllers/DiscountController";
import OptionalOrganListController from "@/app/core/base/controllers/OptionalOrganListController";
import OptionalServiceController from "@/app/core/base/controllers/OptionalServiceController";
import {
  DateTranslateSimple,
  OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";
import { DiscountType } from "@/app/core/util/helpers/BudgetHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddOrganButton from "../organs/AddOrganButton";
import DiscountComponent from "./DiscountComponent";

export default function DiscountListComponent({
  refresh,
  service,
  processId,
  optionalServiceId,
  selectedServiceId,
  disabled,
}) {
  const [data, setData] = useState([]);
  const discountCtrl = DiscountController();

  useEffect(() => {
    if (service && service.id !== undefined) {
      setData(service.discounts);
    } else {
      setData([]);
    }
  }, [service]);

  async function deleteDiscount(discount) {
    if (window.confirm(Message.DeleteConfirm)) {
      await discountCtrl.Delete(discount.id, (res) => {
        toast.success(Message.DeleteSuccess);
        refresh();
      });
    }
  }

  return (
    <div className={`block block-rounded block-bordered mb-2`}>
      <div className="block-header">
        <div className="block-title">Descontos</div>
        <div className="block-tools">
          <DiscountComponent
            refresh={() => refresh()}
            discounts={service && service.discounts}
            service={service}
            processId={processId}
            optionalServiceId={optionalServiceId}
            selectedServiceId={selectedServiceId}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="ml-1 mr-1 mb-1">
        {data &&
          data.map((item) => (
            <div
              className="js-task block block-rounded block-fx-pop block-bordered border-success block-fx-pop mb-2 animated fadeIn"
              key={data.id}
            >
              <table className="table table-borderless table-vcenter mb-0">
                <tbody>
                  <tr>
                    <td className="js-task-content font-w600">
                      <i className="far fa-money-bill-alt mr-1" />
                      {item.type === DiscountType.Value
                        ? `R$${MoneyMask(item.value, 2)}`
                        : `${item.value}%`}
                    </td>
                    {item.hasValidity === true ? (
                      <td className="">
                        <i className="si si-calendar mr-1" /> Válido até{" "}
                        {OnlyDateTranslate(item.dueDate)}
                      </td>
                    ) : (
                      ""
                    )}
                    <td className="text-right" style={{ width: 20 }}>
                      <button
                        onClick={() => deleteDiscount(item)}
                        disabled={disabled}
                        className="js-task-remove btn btn-sm btn-link text-danger"
                      >
                        <i className="fa fa-times fa-fw"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </div>
  );
}

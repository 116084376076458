import React from "react";
import "../assets/loader.css";
import translate from "@/i18n";

export default function Loading({ loading }) {
  if (loading) {
    return (
      <div style={styleSheet.pageLoaderWrapper}>
        <div style={styleSheet.loader}>
          <div className="m-t-30">
            <img
              src={process.env.PUBLIC_URL + "/assets/media/logo.png"}
              alt="admin"
            />
          </div>

          <p style={styleSheet.p}>
            {`${translate("please wait")}...`}
            <br />
            <i className="fa fa-2x fa-circle-notch fa-spin text-warning mt-5 mb-3" />
          </p>
        </div>
      </div>
    );
  }
  return <></>;
}

const styleSheet = {
  pageLoaderWrapper: {
    zIndex: 99999999,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    background: "rgba(51, 51, 51, 0.8)",
    overflow: "hidden",
    textAlign: "center",
  },
  p: {
    fontSize: "18px",
    marginTop: "10px",
    paddingTop: "30px",
    fontWeight: "bold",
    color: "#fff",
  },
  loader: {
    position: "relative",
    top: "calc(50% - 30px)",
  },
};

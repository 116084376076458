import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  StatusExigency,
  StatusExigencyButton,
} from "@/app/core/util/StatusTranslater";
import { VerifyExigencyState } from "@/app/core/util/StatusStates";
import { NotifyNew } from "@/app/core/util/helpers/NotificationHelper";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import Storage from "@/app/core/util/Storage";

export default function ExigencyTimelineItem({ refresh, data }) {
  const userData = Storage.getUserData();
  useEffect(() => {}, [data]);

  function showNotification(text) {
    toast.info(text);
  }

  return (
    <div
      className={`js-task block block-rounded block-fx-pop border-left ${VerifyExigencyState(
        data.status,
      )} mb-2 border-5x`}
    >
      <div className="block-header">
        {userData != null && userData.level != 5 ? (
          <div className="block-title">
            {data.status === 3 ? (
              <i className="si si-check text-success"></i>
            ) : (
              <i className="si si-close text-danger"></i>
            )}{" "}
            {data.user !== undefined
              ? `Avaliador : ${data.user.firstName} - ${DepartmentTranslate(
                  data.user.level,
                )}`
              : ""}
          </div>
        ) : (
          ""
        )}
        <div className="block-tools">{data.updatedDate}</div>
      </div>
      <table className="table table-borderless table-vcenter mb-0">
        <tbody>
          <tr>
            <td className="js-task-content p-1 ml-3">{data.description}</td>
          </tr>
        </tbody>
      </table>
      <div className="block-content">
        <div className="row">
          <div className="col-md-6">
            Status : {StatusExigencyButton(data.status)}
            {NotifyNew(data.updated)}
          </div>
          <div className="col-md-6 text-right">
            {data.processFileId !== 0 && data.processFileId !== undefined ? (
              <a
                className="btn btn-sm btn-outline-success py-2"
                target="_blank"
                title={data.processFile.name}
                href={data.processFile.url}
                rel="noreferrer"
              >
                {" "}
                <i className="far fa-arrow-alt-circle-down"></i> Baixar Anexo
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function GeneralCounters() {
  const [data, setData] = useState({
    ongoing: 0,
    deferred: 0,
    exigencies: 0,
    processDone: 0,
  });
  const report = ReportController();

  useEffect(() => {
    loadResume();
  }, []);

  function loadResume() {
    report.Resume((res) => setData(res));
  }

  return (
    <div className="row">
      <div className="col-md-3">
        <a class="block block-rounded block-link-pop" href="javascript:void(0)">
          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
            <div>
              <i class="si fa-2x si-users text-primary"></i>
            </div>
            <div class="ml-3 text-right">
              <p class="font-size-h3 font-w300 mb-0">{data.ongoing}</p>
              <p class="text-muted mb-0">Em andamento</p>
            </div>
          </div>
        </a>
      </div>
      <div className="col-md-3">
        <a class="block block-rounded block-link-pop" href="javascript:void(0)">
          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
            <div>
              <i class="si fa-2x si-briefcase text-primary"></i>
            </div>
            <div class="ml-3 text-right">
              <p class="font-size-h3 font-w300 mb-0">{data.deferred}</p>
              <p class="text-muted mb-0">Deferidos</p>
            </div>
          </div>
        </a>
      </div>
      <div className="col-md-3">
        <a class="block block-rounded block-link-pop" href="javascript:void(0)">
          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
            <div>
              <i class="si fa-2x si-book-open text-primary"></i>
            </div>
            <div class="ml-3 text-right">
              <p class="font-size-h3 font-w300 mb-0">{data.exigencies}</p>
              <p class="text-muted mb-0">Retor. com exigência</p>
            </div>
          </div>
        </a>
      </div>
      <div className="col-md-3">
        <a class="block block-rounded block-link-pop" href="javascript:void(0)">
          <div class="block-content block-content-full d-flex align-items-center justify-content-between">
            <div>
              <i class="si fa-2x si-bag text-primary"></i>
            </div>
            <div class="ml-3 text-right">
              <p class="font-size-h3 font-w300 mb-0">{data.processDone}</p>
              <p class="text-muted mb-0">Processos concluídos</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

import React from "react";
import Storage from "@/app/core/util/Storage";
import "../assets/footer.css";
import GetBaseUrl from "@/app/core/base/BaseAddress";

export default function Footer() {
  const userData = Storage.getUserData();
  return (
    <footer
      id="page-footer"
      className={`${Storage.getTheme()} text-body-${Storage.getTheme()}`}
    >
      <div className="content py-0">
        <div className="row font-size-sm">
          <div className="col-sm-4 order-sm-1 text-center text-sm-left">
            &copy; Hasa{" "}
            <span data-toggle="year-copy">{new Date().getFullYear()}</span>
          </div>
          <div className="col-sm-4 order-sm-1 text-center">
            {userData && userData.level == 5 ? (
              <button
                className="btn btn-info"
                onClick={() => (window.location = GetBaseUrl())}
              >
                <i className="si si-home" /> - Atualizar
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col-sm-4 order-sm-1 text-center text-sm-right">
            versão 0.80.2
          </div>
        </div>
      </div>
    </footer>
  );
}

import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListHelper, {
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import React, { useEffect, useState } from "react";

export default function OrganListHistoric({ data, organList }) {
  const [open, setOpen] = useState(false);
  const organHelper = OrganListHelper();

  function toggleCard() {
    setOpen(!open);
  }

  useEffect(() => {
    if (
      data.department == organList.stage &&
      organList.status != OrganStatus.Deferred
    ) {
      setOpen(true);
    }
  }, [data]);

  function processIcon() {
    if (data.department == 1) {
      return <i className="far fa-handshake" />;
    } else if (data.department == 2) {
      return <i className="fa fa-search" />;
    } else if (data.department == 3) {
      return <i className="si si-hourglass" />;
    } else if (data.department == 4) {
      return <i className="si si-clock" />;
    }
  }

  return (
    <ul class="timeline timeline-alt mt-0 mb-0" style={{ marginTop: -35 }}>
      {/* <li class="timeline-event">
                <div class="timeline-event-block block block-rounded block-fx-pop js-appear-enabled animated fadeIn">
                    <div class="block-header block-header-default">
                        <h3 class="block-title">{data.name}</h3>
                        <div class="block-options">
                            <div class="timeline-event-time block-options-item font-size-sm font-w600">
                                <button
                                className="btn btn-sm btn-dual"
                                onClick={() => toggleCard()}
                                >
                                    <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li> */}
      <li className="timeline-event mt-0 mb-0">
        <div class="media">
          <div className="mr-5 overlay-container overlay-left">
            <span className="overlay-item item  item-circle border border-2x border-white timeline-event-icon bg-success">
              {processIcon()}
            </span>
          </div>
          <div className="media-body ml-2 mb-3 row">
            <span className="col-6">{data.name}</span>
            <span className="col-6 text-right">
              <button
                className="btn btn-sm btn-dual"
                onClick={() => toggleCard()}
              >
                <i
                  className={`si si-arrow-${open === true ? "up" : "down"}`}
                ></i>
              </button>
            </span>
          </div>
        </div>
      </li>
      {open &&
        open == true &&
        data &&
        data.data &&
        data.data.map((item) => (
          <li className="timeline-event mt-2 mb-2">
            <div class="media">
              <div className="mr-3 ml-3 overlay-container overlay-left">
                <span className="overlay-item item item-tiny item-circle border border-2x border-white bg-success"></span>
              </div>
              <div className="media-body ml-2">
                {item.description} <br />
                <small>{DateTranslate(item.createdDate)}</small>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );
}

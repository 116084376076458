import React from "react";

export const ExigencyAction = [
  { name: "Upload", action: 1 },
  { name: "Resposta", action: 2 },
  { name: "Taxa", action: 3 },
  { name: "Enviar documento", action: 4 },
];

export const ExigencyType = [
  { name: "Resposta", value: 2 },
  { name: "Upload de documento", value: 1 },
  { name: "Enviar documento físico", value: 4 },
  { name: "Taxa", value: 3 },
  { name: "Assinatura", value: 5 },
];

export const ExigencyTypeObj = {
  Answer: 2,
  Upload: 1,
  SendPhisicDoc: 4,
  Tax: 3,
  Signature: 5,
};

export function ExigencyActionTranlate(i) {
  const response = ExigencyAction.filter((x) => x.action === i);

  if (response.length > 0) {
    return response[0].name;
  } else {
    return "S.I.";
  }
}

export function ProcessLinkText(text) {
  // Split the content on space characters
  if (text === undefined) {
    return text;
  }
  var words = text.split(/\s/);

  // Loop through the words
  var contents = words.map(function (word, i) {
    // Space if the word isn't the very last in the set, thus not requiring a space after it
    var separator = i < words.length - 1 ? " " : "";

    // The word is a URL, return the URL wrapped in a custom <Link> component
    if (word.match(/^https?\:\//)) {
      return (
        <a key={i} href={word} target="_blank" rel="noreferrer">
          {word}
          {separator}
        </a>
      );
      // The word is not a URL, return the word as-is
    } else {
      return word + separator;
    }
  });
  // The nested content was something else than a plain string
  // Return the original content wrapped in a <Text> component

  // Return the modified content wrapped in a <Text> component
  return <p>{contents}</p>;
}

import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main";

const ProjectShell = (props) => (
  <div {...props}>{renderRoutes(props.route.routes, props)}</div>
);

const Redirecter = () => <Redirect to="/error/404" />;

const ReportRouter = {
  path: "/report",
  component: ProjectShell,
  routes: [
    {
      path: "/report",
      exact: false,
      component: MainComponent,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};

export default ReportRouter;

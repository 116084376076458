import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import {
  SendEmailOfCompliment,
  SendEmailOfCritic,
  SendEmailSupport,
} from "@/app/core/base/controllers/ContactController";

export default function ComplimentModal({
  show,
  onHide,
  submit,
  isLoading,
  report,
}) {
  const [data, setData] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {
    if (show) {
      loadData();
    }
  }, [show]);

  async function loadData() {
    setData({
      name: `${userData !== null ? userData.firstName : ""} ${
        userData !== null ? userData.lastName : ""
      }`,
      email: userData !== null ? userData.email : "",
      phone: userData !== null ? userData.phone : "",
      customerId: userData.customerId,
    });
  }

  async function save() {
    if (
      data.name !== undefined &&
      data.name !== "" &&
      data.email !== undefined &&
      data.email !== "" &&
      data.phone !== undefined &&
      data.phone !== "" &&
      data.description !== undefined &&
      data.description !== ""
    ) {
      SendEmailOfCompliment(data);
      if (report !== undefined) {
        report(
          `Responsável : ${data.name} Email : ${data.email} Telefone : ${data.phone} Descrição do elogio : ${data.description}`,
        );
      }
      setTimeout(() => {
        toast.success("Seu elogio foi enviado com sucesso, Obrigado!");
        onHide();
      }, 500);
    } else {
      toast.error(Message.PendingData);
    }
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  return (
    <Modal
      title="Registrar elogio"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div className="mb-3 mt-3 text-center">
        <p className="font-w700  text-muted mr-3 ml-3">
          Elogios ajudam na nossa autoestima e comprovam que estamos no caminho
          certo.
          <br />
          <br />
          Bonificamos os colaboradores que de alguma forma impactaram
          positivamente nossos parceiros. Este espaço é para registrar seu
          elogio ao nosso time.
        </p>
      </div>
      <div className="form-group">
        <span>Nome</span>
        <input
          className="form-control"
          name="name"
          value={data.name}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>E-mail</span>
        <input
          className="form-control"
          name="email"
          value={data.email}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Cód. de cliente</span>
        <input
          className="form-control"
          name="customerId"
          value={data.customerId}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Telefone para contato </span>
        <input
          className="form-control"
          name="phone"
          value={data.phone}
          onChange={(evt) => handleChange(evt)}
        />
      </div>

      <div className="form-group">
        <span>O que você mais gostou ao fazer este trabalho com a Hasa?</span>
        <select
          className="form-control"
          name="category"
          onChange={(evt) => handleChange(evt)}
        >
          <option>Selecione</option>
          <option>Gostei do conhecimento técnico do time Hasa </option>
          <option>Gostei do atendimento e atenção dos colaboradores</option>
          <option>Gostei da velocidade de entrega </option>
          <option>Gostei da plataforma e de suas funcionalidades</option>
          <option>O motivo do elogio é outro...</option>
        </select>
      </div>

      <div className="form-group">
        <span>
          Descreva o motivo do seu elogio e lembre-se... Caso cite algum
          colaborador que lhe atendeu com amor e paixão ele ou ela será premiado
          (a).  Nada mais justo!{" "}
        </span>
        <textarea
          className="form-control"
          name="description"
          value={data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}

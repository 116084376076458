import OrganListController from "@/app/core/base/controllers/OrganListController";
import React from "react";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { SaveTimelineUpdate } from "@/app/core/util/services/TimelineService";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";

export default function ConfirmReview({ organList, reload }) {
  const organCtrl = OrganListController();
  const userData = Storage.getUserData();

  function processButton() {
    let message = "Revisei o ";
    if (organList.internalFeedback === true) {
      message += " Feedback Interno";
    }

    if (
      organList.internalFeedback === true &&
      organList.customerReturn === true
    ) {
      message += " e o ";
    }

    if (organList.customerReturn === true) {
      message += " Retorno do cliente";
    }

    if (
      organList.internalFeedback === true ||
      organList.customerReturn === true
    ) {
      return (
        <button
          className="btn btn-outline btn-info btn-square"
          onClick={() => updateResponse()}
        >
          {message}
        </button>
      );
    }
    if (organList.reavaliation < userData.level && organList.reavaliation > 0) {
      return (
        <button
          className="btn btn-outline btn-warning btn-square"
          onClick={() => updateReavaliation()}
        >
          Confirmado Reavaliação
        </button>
      );
    }
  }

  async function updateResponse() {
    if (window.confirm("Deseja continuar?")) {
      await organCtrl.CustomerReturn(organList.id, false, async (res) => {
        await organCtrl.InternalFeedback(organList.id, false, (res) => {
          toast.info("Salvo com sucesso");
          if (reload !== undefined) {
            reload();
          }
        });
      });
    }
  }

  async function updateReavaliation() {
    if (window.confirm("Deseja continuar?")) {
      await organCtrl.Update({ ...organList, reavaliation: 0 }, async (res) => {
        toast.info("Salvo com sucesso");
        await NotifyUpdate(
          organList.processId,
          "Confirmou a reavaliação",
          false,
          TimelineChannelSistema,
          organList.id,
        );
        if (reload !== undefined) {
          reload();
        }
      });
    }
  }

  return (
    <div>{organList && organList.id !== undefined ? processButton() : ""}</div>
  );
}

import React from "react";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import {
  CnpjMask,
  CpfMask,
  PhoneMask,
  TelphoneMask,
} from "@/app/core/util/helpers/ContactHelper";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function SearchCustomerItem({ data, open, reload }) {
  function goTo() {
    window.open(`${GetBaseUrl()}#/customer-profile/${data.id}`, "_blank");
  }

  return (
    <div class="col-md-6">
      <div class="block block-bordered block-rounded block-fx-pop">
        <div className="block-header">
          <div className="block-title">Cliente : {data && data.name}</div>
          <div className="block-tools">
            <div class="font-size-sm text-success mb-1 text-right">
              {data && CustomerHelper().ShowLevel(data.level, "fa-2x")}
              <br />
              Cód : {data && data.customerCode}
            </div>
          </div>
        </div>
        <div className="block-content">
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{data && data.email}</td>
                </tr>
                <tr>
                  <td>Telefone comercial</td>
                  <td>{data && TelphoneMask(data.phone)}</td>
                </tr>
                <tr>
                  <td>Celular</td>
                  <td>{data && PhoneMask(data.whatsapp)}</td>
                </tr>
                {data && data.type !== 1 ? (
                  <tr>
                    <td>CNPJ</td>
                    <td>{data && CnpjMask(data.cnpj)}</td>
                  </tr>
                ) : (
                  ""
                )}

                <tr>
                  <td>CPF</td>
                  <td>{data && CpfMask(data.cpf)}</td>
                </tr>
                <tr>
                  <td>Tipo de conta</td>
                  <td>
                    {data && data.type === 1
                      ? "Pessoa Física"
                      : "Pessoa Jurídica"}
                  </td>
                </tr>
                <tr>
                  <td>CRC / OAB</td>
                  <td>{data && data.crc}</td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StatusExigencyButton } from "@/app/core/util/StatusTranslater";
import { VerifyExigencyState } from "@/app/core/util/StatusStates";
import { DateTranslateSimple } from "@/app/core/util/DateTranslater";
import { ExigencyUpdate } from "@/app/core/base/controllers/ExigenciesController";
import { NotifyNew } from "@/app/core/util/helpers/NotificationHelper";
import { ProcessLinkText } from "@/app/core/util/helpers/ExigencyActionHelper";

export default function ExigencyItem({
  refresh,
  data,
  showItem,
  deleteExigency,
  response,
  disable,
}) {
  const [description, setDescription] = useState("");
  const [action, setAction] = useState(2);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setDescription(data.description);
      setAction(data.action);
    }
  }, [data]);

  function check(res, id) {
    response(res, id);
  }

  function showNotification(text) {
    toast.info(text);
  }

  function saveExigency() {
    setDisabled(true);
    ExigencyUpdate(
      {
        ...data,
        description: description,
        action: action,
      },
      (res) => {
        toast.info("Salvo com sucesso");
        refresh();
      },
    );
  }

  return (
    <div
      className={`js-task block block-rounded block-fx-pop block-bordered ${VerifyExigencyState(
        data.status,
      )} mb-2 border-3x`}
      key={`card-exigency-${data.id}`}
    >
      <div className="block-header">
        <div className="block-title">
          {DateTranslateSimple(data.updatedDate)}
        </div>
        <div className="block-tools">
          <button
            type="button"
            className="js-task-star btn btn-sm btn-link text-primary"
            onClick={() => setDisabled(!disabled)}
            disabled={disable}
            title="Editar"
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            type="button"
            className="js-task-star btn btn-sm btn-link text-primary"
            onClick={() => showItem(data)}
            title="Visualizar"
          >
            <i className="fa fa-eye"></i>
          </button>
          <button
            type="button"
            className="js-task-remove btn btn-sm btn-link text-danger"
            onClick={() => deleteExigency(data)}
            disabled={disable}
            title="Remover"
          >
            <i className="fa fa-times fa-fw"></i>
          </button>
        </div>
      </div>
      <table className="table table-borderless table-vcenter mb-0">
        <tbody>
          <tr>
            <td className="text-center pr-0" style={{ width: 35 }}>
              <div className="js-task-status custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-primary custom-control-lg">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={data.id}
                  name={data.id}
                  disabled={disable}
                  defaultChecked={data.status === 3 ? true : false}
                  onChange={(evt) => check(evt.target.checked, data.id)}
                  key={`check-${data.id}`}
                />
                <label className="custom-control-label" for={data.id}></label>
              </div>
            </td>
            {disabled === false ? (
              <td className="js-task-content p-1">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={description}
                    onChange={(evt) => setDescription(evt.target.value)}
                  />
                </div>
                <div className="form-group">
                  <span>Ação esperada</span>
                  <select
                    className="form-control"
                    onChange={(evt) => setAction(evt.target.value)}
                  >
                    <option
                      value={2}
                      selected={data.action === 2 ? true : false}
                    >
                      Resposta
                    </option>
                    <option
                      value={1}
                      selected={data.action === 1 ? true : false}
                    >
                      Upload de documento
                    </option>
                    <option
                      value={4}
                      selected={data.action === 4 ? true : false}
                    >
                      Enviar documento físico
                    </option>
                    <option
                      value={3}
                      selected={data.action === 3 ? true : false}
                    >
                      Taxa
                    </option>
                    <option
                      value={5}
                      selected={data.action === 5 ? true : false}
                    >
                      Assinatura
                    </option>
                  </select>
                </div>
                <button
                  className="btn btn-sm btn-square btn-success ml-4"
                  onClick={() => saveExigency()}
                >
                  <i className="si si-check"></i> Salvar
                </button>
              </td>
            ) : (
              <td className="js-task-content p-1">
                {ProcessLinkText(data.description)}
              </td>
            )}
          </tr>
        </tbody>
      </table>
      <div className="block-content">
        <div className="row">
          <div className="col-md-6">
            Status :{" "}
            {/* {data.status === 4 ? (
              <i className="fa fa-exclamation-circle text-warning" />

            ) : (
              ""
            )}{" "} */}
            {StatusExigencyButton(data.status)}
            {NotifyNew(data.updated)}
          </div>
          <div className="col-md-6">
            {data.sendedId !== 0 && data.sendedId !== undefined ? (
              <button
                className="btn btn-sm btn-dual"
                title={`remetente : ${
                  data.user && data.user.firstName
                }; Departamento : ${
                  data.user && data.user.department
                }; Anexo : ${data.sended && data.sended.name}`}
                onClick={() => showNotification(data.sended.name)}
              >
                <i className="fa fa-paperclip text-info" />{" "}
              </button>
            ) : (
              ""
            )}
            {data.processFileId !== 0 && data.processFileId !== undefined ? (
              <button
                className="btn btn-sm btn-dual"
                title={`Retorno do cliente : ${
                  data.processFile && data.processFile.name
                }`}
                onClick={() => showNotification(data.processFile.name)}
              >
                <i className="fa fa-paperclip text-success" />
              </button>
            ) : (
              ""
            )}
            {data.notes !== "" && data.notes !== undefined ? (
              <button
                className="btn btn-sm btn-dual"
                title={`Retorno do operador : ${data.notes}`}
                onClick={() =>
                  showNotification(
                    `O operador enviou a seguinte resposta: ${data.notes}`,
                  )
                }
              >
                <i className="far fa-comments text-dark" />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import ServiceGroupController from "@/app/core/base/controllers/ServiceGroupController";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ServiceGroupTypeList } from "@/app/core/util/helpers/SelectedServiceHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import DropdownElement from "../elements/DropdownElement";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ServiceGroupsComponent({
  processId,
  refresh,
  disabled,
  selectedServices,
}) {
  const [newGroup, setNewGroup] = useState({});

  const serviceGroup = ServiceGroupController();

  useEffect(() => {
    loadInfos();
  }, [processId]);

  function loadInfos() {}

  async function reload() {
    loadInfos();
    if (refresh !== undefined) {
      refresh();
    }
  }

  async function addGroupService() {
    if (newGroup.type === undefined) {
      toast.error("Selecione ao menos o tipo de grupo");
    } else {
      let position = selectedServices.length + 1;
      await serviceGroup.Insert(
        { ...newGroup, processId: parseInt(processId), position },
        (res) => {
          setNewGroup({ description: "" });
          reload();
          loadInfos();
        },
      );
    }
  }

  function handleChange(evt, type) {
    setNewGroup(OnDataChange(newGroup, evt, type));
  }

  return (
    <DropdownElement
      submit={() => addGroupService()}
      style={{ width: 450 }}
      icon={"si si-plus"}
      title="Adicionar grupo"
    >
      <div className="form-group">
        <span>Descrição</span>
        <textarea
          name="description"
          value={newGroup.description}
          className="form-control"
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Tipo</span>
        <select
          className="form-control"
          name="type"
          onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
        >
          <option value={null}>Selecione</option>
          {ServiceGroupTypeList.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </select>
      </div>
    </DropdownElement>
  );
}

import Layout from "@/app/core/layouts/main";
import Storage from "@/app/core/util/Storage";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ProcessCard from "@/app/core/layouts/main/elements/ProcessCard";
import ProcessController from "@/app/core/base/controllers/ProcessController";

function SharedFile(props, presentation) {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const enviroment = process.env.REACT_APP_ENV;

  const userData = Storage.getUserData();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    console.log("Resultados", props.match);
    ProcessController().TheProcess(props.match.params.key, null, (res) => {
      checkCustomer(res);
      setData(res);
    });
  }

  function checkCustomer(process) {
    if (presentation == true) {
      return;
    }
    if (show != true) {
      if (userData !== null || enviroment == "local") {
        setShow(true);
        return;
      }
      let customerCode = window.prompt(
        "Para acessar essa tela, confirme seu código de cliente",
      );
      if (customerCode === process.customer.customerCode) {
        setShow(true);
      } else {
        let customerCode2 = window.prompt(
          "Código de cliente inválido, tente novamente : ",
        );
        if (customerCode2 === process.customer.customerCode) {
          setShow(true);
        } else {
          if (customerCode === process.customer.customerCode) {
            setShow(true);
          } else {
            let customerCode2 = window.prompt(
              "Código de cliente inválido, tente novamente : ",
            );
            if (customerCode2 === process.customer.customerCode) {
              setShow(true);
            } else {
              if (customerCode === process.customer.customerCode) {
                setShow(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === process.customer.customerCode) {
                  setShow(true);
                } else {
                  let customerCode2 = window.prompt(
                    "Código de cliente inválido, tente novamente : ",
                  );
                  if (customerCode2 === process.customer.customerCode) {
                    setShow(true);
                  } else {
                    toast.info(
                      "Você errou o código muitas vezes e será redirecionado ao site",
                    );
                    setTimeout(() => {
                      window.location = "https://hasa.com.br";
                    }, 3900);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div className="py-5">
      <div className="container mb-0">
        {show === true ? (
          <div>
            <div className="block block-fx-shadow">
              <ProcessCard
                preLoad={data}
                organListId={props.match.params.key}
                refresh={() => loadData()}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <ToastContainer className="toastr" />
    </div>
  );
}

export default SharedFile;

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OperationalListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import {
  DailyActivityInsert,
  DailyActivityUpdate,
} from "@/app/core/base/controllers/DailyActivitiesControlle";
import { toast } from "react-toastify";

export default function DailyRatesModal({ values, show, onHide, isLoading }) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (values) {
      setData(values);
    } else {
      setData({});
    }
  }, [values]);

  function handleSubmit() {
    if (data.id !== undefined) {
      DailyActivityUpdate(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    } else {
      DailyActivityInsert(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    }
  }

  function handleChange(evt) {
    let old = data;
    let newData = { [evt.target.name]: parseInt(evt.target.value) };

    setData({ ...old, ...newData });
  }

  return (
    <Modal
      title="Qtd de tarefas diárias"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={handleSubmit}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label for="example-masked-time">Departamento</label>
              <select
                className="form-control"
                onChange={(evt) => handleChange(evt)}
                name="level"
                value={data && data.level}
              >
                <option value={null}>Selecionar Departamento</option>
                {OperationalListHelper &&
                  OperationalListHelper.map((department) => (
                    <option value={department.value}>{department.name}</option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label for="example-masked-time">
                Quantas preparações o operador fará por dia?
              </label>
              <input
                type="text"
                className="form-control"
                name="tasks"
                value={data && data.tasks}
                onChange={(evt) => handleChange(evt)}
              />
              {/* {errors.dailyTasks && touched.dailyTasks && (
                <div style={{ color: "red" }}>{errors.dailyTasks}</div>
              )} */}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

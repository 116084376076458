import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";

export default function SampleTable({
  cols,
  title,
  data,
  remove,
  open,
  newItem,
  refresh,
}) {
  const [elements, setElements] = useState([]);

  useEffect(() => {
    setElements(data);
  }, data);

  function processFilter(param) {
    let itens = [];
    if (param.direction === "ASC") {
      itens = data.sort(function (a, b) {
        if (a[param.orderBy] < b[param.orderBy]) {
          return -1;
        }
        if (a[param.orderBy] > b[param.orderBy]) {
          return 1;
        }
        return 0;
      });
    }
    if (param.direction === "DESC") {
      itens = data.sort(function (a, b) {
        if (a[param.orderBy] > b[param.orderBy]) {
          return -1;
        }
        if (a[param.orderBy] < b[param.orderBy]) {
          return 1;
        }
        return 0;
      });
    }

    setElements(itens);
  }

  return (
    <Table
      title={title}
      loading={false}
      data={elements}
      columns={cols}
      refreshData={() => refresh()}
      insertAction={() => newItem()}
      deleteAction={(info) => remove(info)}
      setFilter={(filter) => processFilter(filter)}
      viewAction={(selected) => open(selected)}
    />
  );
}

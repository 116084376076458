import React, { useEffect, useState } from "react";
import CardItem from "@/app/core/layouts/main-components/CounterCardItem";
import Storage from "@/app/core/util/Storage";
import { CustomerGeralReports } from "@/app/core/base/controllers/GlobalController";
import GeneralExigencyModal from "@/app/modules/exigencies/GeneralExigencyModal";
import ProcessDoneModal from "@/app/core/layouts/main-components/process/ProcessDoneModal";

export default function CustomerReports({ openProcess }) {
  const userData = Storage.getUserData();
  const [exigencies, setExigencies] = useState(0);
  const [budgets, setBudgets] = useState(0);
  const [process, setProcess] = useState(0);
  const [finance, setFinance] = useState(0);
  const [processDone, setProcessDone] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    CustomerGeralReports(userData.customerId, (res) => {
      setExigencies(res.exigencies);
      setBudgets(res.budgets);
      setFinance(res.payments);
      setProcess(res.onGoingProcess);
      setProcessDone(res.processDone);
    });
  }

  return (
    <div className="content row  col-md-12 ">
      <div className="col-md-4">
        <CardItem
          name={"Orçamentos em aberto"}
          value={budgets}
          text={"text-white-75"}
          icon={"far fa-2x fa-check-circle text-primary-lighter"}
          aparence={"block block-rounded block-link-shadow bg-primary"}
        />
      </div>
      <div className="col-md-4">
        <CardItem
          name={"Exigências em aberto"}
          click={() => setShowModal(true)}
          value={exigencies}
          text={"text-white-75"}
          icon={"far fa-2x fa-list-alt text-white"}
          aparence={"block block-rounded block-link-shadow bg-warning"}
        />
      </div>
      <div class="col-md-4">
        <CardItem
          name={"Processos em andamento"}
          value={process}
          text={"text-white-75"}
          icon={"far fa-2x fa-folder-open text-success-light"}
          aparence={"block block-rounded block-link-shadow bg-success"}
        />
      </div>
      <div className="col-md-6">
        <CardItem
          name={"Financeiro"}
          value={finance}
          text={"text-dark-75"}
          icon={"fa fa-2x fa-money-check-alt text-black-50"}
          aparence={"block block-rounded block-link-shadow bg-dual"}
        />
      </div>
      <div className="col-md-6">
        <CardItem
          name={"Processos finalizados"}
          value={processDone}
          text={"text-dark-75"}
          click={() => setShowDoneModal(true)}
          icon={"fa fa-2x fa-tasks text-black-50"}
          aparence={"block block-rounded block-link-shadow bg-dual"}
        />
      </div>
      <GeneralExigencyModal
        show={showModal}
        open={(item) => openProcess(item)}
        onHide={() => setShowModal(false)}
      />
      <ProcessDoneModal
        customerId={userData && userData.customerId}
        show={showDoneModal}
        open={(item) => openProcess(item)}
        onHide={() => setShowDoneModal(false)}
      />
    </div>
  );
}

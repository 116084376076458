import { FieldTypeObj } from "../helpers/FieldTypeHelper";

export function OnDataChange(oldData, evt, type) {
  let info = {};
  if (type === undefined) {
    info = { [evt.target.name]: evt.target.value };
  } else {
    if (type === FieldTypeObj.Currency || type === FieldTypeObj.Decimal) {
      info = { [evt.target.name]: parseFloat(evt.target.value) };
    } else if (type === FieldTypeObj.Area || type === FieldTypeObj.Text) {
      info = { [evt.target.name]: evt.target.value };
    } else if (type === FieldTypeObj.Number) {
      info = { [evt.target.name]: parseInt(evt.target.value) };
    } else if (type === FieldTypeObj.Files) {
      info = { [evt.target.name]: parseInt(evt.target.files) };
    } else if (type === FieldTypeObj.Bool) {
      info = {
        [evt.target.name]:
          evt.target.value === "true" || evt.target.value === true
            ? true
            : false,
      };
    } else {
      info = { [evt.target.name]: evt.target.value };
    }
  }
  return { ...oldData, ...info };
}

export function SetBudgetData(obj) {
  localStorage.setItem("budget", JSON.stringify(obj));
}

export function GetBudgetData() {
  return JSON.parse(localStorage.getItem("budget"));
}

export function RemoveBudgetData() {
  localStorage.removeItem("budget");
}

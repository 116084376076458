import ReportController from "@/app/core/base/controllers/ReportController";
import React, { useEffect, useState } from "react";
import DevolutionReportModal from "./Modals/DevolutionReportModal";
import TeamsModal from "@/app/core/layouts/main-components/teams/TeamsModal";
import Storage from "@/app/core/util/Storage";

export default function DevolutionCounter({ startDate, endDate }) {
  const report = ReportController();
  const [showModal, setShowModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);

  const [data, setData] = useState({
    notStarted: 0,
    filed: 0,
    devolution: 0,
    exigency: 0,
    deferred: 0,
    done: 0,
    accuracity: 0,
    notStartedPercentage: 0,
    filedPercentage: 0,
    devolutionPercentage: 0,
    exigencyPercentage: 0,
    deferredPercentage: 0,
    donePercentage: 0,
  });

  useEffect(() => {
    loadCounters();
  }, [startDate, endDate]);

  function loadCounters() {
    const oldResult = Storage.getDevolutionCounter();
    if (
      oldResult == null ||
      oldResult.startDate != startDate ||
      oldResult.endDate != endDate
    ) {
      report.DevolutionResume(startDate, endDate, (res) => {
        let result = res;
        // const base = res.total;
        const base =
          res.notStarted +
          res.filed +
          res.devolution +
          res.exigency +
          res.deferred;

        result.notStartedPercentage = parseInt((res.notStarted / base) * 100);
        result.filedPercentage = parseInt((res.filed / base) * 100);
        result.devolutionPercentage = parseInt((res.devolution / base) * 100);
        result.exigencyPercentage = parseInt((res.exigency / base) * 100);
        result.deferredPercentage = parseInt((res.deferred / base) * 100);
        result.donePercentage = parseInt((res.done / base) * 100);

        result.startDate = startDate;
        result.endDate = endDate;

        Storage.setDevolutionCounter(result);
        setData(result);
      });
    } else {
      setData(oldResult);
    }
  }

  return (
    <div>
      <div className="block block-rounded bg-xwork">
        <div className="block-header bg-xwork-dark">
          <div className="blick-title text-white text-uppercase font-size-sm font-w700">
            Devolução
          </div>
          <div className="block-tools">
            <button
              className="btn bg-xwork-dark btn-sm text-white"
              onClick={() => setShowModal(true)}
            >
              <i className="far fa-eye" />
            </button>
            <button
              className="btn bg-xwork-dark btn-sm text-white"
              onClick={() => setShowTeamModal(true)}
            >
              <i className="far fa-chart-bar" />
            </button>
          </div>
        </div>
        <div className="block-content block-content-full">
          <div className="row text-center">
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.notStarted}{" "}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.notStartedPercentage}%
                </p>
                <p className="text-white-75 mb-0">A Protocolar</p>
              </div>
            </div>
            <div className="col-2">
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.filed}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.filedPercentage}%
                </p>
                <p className="text-white-75 mb-0">Protocolado</p>
              </div>
            </div>
            <div className="col-2">
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.devolution}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.devolutionPercentage}%
                </p>
                <p className="text-white-75 mb-0">Aguardando Devolução</p>
              </div>
            </div>
            <div className="col-2">
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.exigency}{" "}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.exigencyPercentage}%
                </p>
                <p className="text-white-75 mb-0">Retornado com Exigência</p>
              </div>
            </div>
            <div className="col-3 border-black-op">
              <div className="py-3">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.deferred}{" "}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.deferredPercentage}%
                </p>
                <p className="text-white-75 mb-0">Deferidos</p>
              </div>
            </div>
            {/* <div className="col-2">
              <div className="py-3">
                <div className="text-white item item-circle bg-black-25 mx-auto">
                  {data && data.done}{" "}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.donePercentage}%
                </p>
                <p className="text-white-75 mb-0">Concluídos</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <DevolutionReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        startDate={startDate}
        endDate={endDate}
      />
      <TeamsModal
        show={showTeamModal}
        onHide={() => setShowTeamModal(false)}
        level={4}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import QuizController from "@/app/core/base/controllers/QuizController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Card from "@/app/core/components/CardModel";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import FeedbackStructureModal from "./FeedbackStructureModal";
import {
  FeedbackStructureDelete,
  FeedbackStructureByQuizId,
} from "@/app/core/base/controllers/FeedbackController";
import { GetAddress } from "@/app/core/base/controllers/GlobalController";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import FeedbackReport from "./FeedbackReport";

function QuizModal(props) {
  const { data, show, onHide, submit, isLoading } = props;

  const [values, setValues] = useState({});
  const [structures, setStructures] = useState([]);
  const quizCtrl = QuizController();
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState(null);

  const cols = [
    { header: "Id", isSortable: true, name: "id" },
    { header: "Pergunta", isSortable: true, name: "description" },
    {
      header: "Tipo de campo",
      isSortable: true,
      name: "fieldType",
      switch: {
        1: "Email",
        2: "Quadro de texto",
        3: "Texto",
        4: "Select",
        5: "TypeAhead",
        6: "Data",
        7: "Moeda",
        9: "Number",
      },
    },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    if (data !== null && data.id !== undefined) {
      setValues(data);

      getService();
    } else {
      setValues({});
    }
  }, [data]);

  function saveService() {
    if (values.name === undefined) {
      toast.error("Preencha os dados para poder salvar");
      return;
    } else {
      if (values.id === undefined) {
        quizCtrl.Insert(values, (res) => {
          toast.success(Message.Saved);
          onHide();
        });
      } else {
        quizCtrl.Update(values, (res) => {
          toast.success(Message.Saved);
          onHide();
        });
      }
    }
  }

  function handleChange(evt, type) {
    setValues(OnDataChange(values, evt, type));
  }

  function getService() {
    if (props.data && props.data.id === undefined) {
      return;
    }
    FeedbackStructureByQuizId(props.data.id, (res) => {
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
      }
      console.log("res", res);
      setStructures(res);
    });
  }

  function disableServices(data) {
    if (window.confirm(`Tem certeza que quer excluir esse item?`)) {
      FeedbackStructureDelete(data.id, (res) => {
        toast.info("Item removido com Sucesso");
        getService();
      });
    }
  }

  function openService(item) {
    setService(item.data);
    setShowModal(true);
  }

  function sendEmails() {
    quizCtrl.Send(values, (res) => toast.success("Enviado com sucesso"));
  }

  return (
    <Modal
      title="Formulário"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveService()}
    >
      <Tabs>
        {values && values.id !== undefined ? (
          <div>
            <Tab>
              <button className="btn btn-square btn-white">
                Informações gerais
              </button>
            </Tab>
            <Tab>
              <button className="btn btn-square btn-white">Relatórios</button>
            </Tab>
          </div>
        ) : (
          ""
        )}
        <Panel>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="example-masked-time">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  value={values && values.name}
                  onChange={(evt) => handleChange(evt)}
                />
              </div>
              <div class="form-group">
                <label for="example-masked-time">
                  Disponível no painel do cliente?
                </label>
                <select
                  class="form-control"
                  name="available"
                  value={values && values.available}
                  onChange={(evt) => handleChange(evt, FieldTypeObj.Bool)}
                >
                  <option>Selecione</option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </div>
            </div>
            {values && values.id !== undefined ? (
              <div class="col-sm-12">
                <div className="block block-fx-pop">
                  <div className="block-header">
                    <div className="block-title">Enviar</div>
                  </div>
                  <div className="block-content">
                    <div class="form-group">
                      <label for="example-masked-time">
                        Enviar para todos?
                      </label>
                      <select
                        class="form-control"
                        name="sendAll"
                        value={values && values.sendAll}
                        onChange={(evt) => handleChange(evt, FieldTypeObj.Bool)}
                      >
                        <option>Selecione</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                      </select>
                    </div>
                    {values && values.id && values.sendAll === false ? (
                      <div class="form-group">
                        <label for="example-masked-time">
                          Filtar por status de cliente
                        </label>
                        <select
                          class="form-control"
                          name="customerStatus"
                          value={values && values.customerStatus}
                          onChange={(evt) =>
                            handleChange(evt, FieldTypeObj.Number)
                          }
                        >
                          <option value={0}>Todos</option>
                          <option value={1}>Pendente</option>
                          <option value={2}>Ativo</option>
                          <option value={3}>Bloqueado</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    {values && values.id && values.sendAll === false ? (
                      <div class="form-group">
                        <label for="example-masked-time">
                          Periodo de atividade
                        </label>
                        <select
                          class="form-control"
                          name="activityDays"
                          value={values && values.activityDays}
                          onChange={(evt) =>
                            handleChange(evt, FieldTypeObj.Number)
                          }
                        >
                          <option value={0}>Todos</option>
                          <option value={1}>30 dias</option>
                          <option value={2}>60 dias</option>
                          <option value={90}>90 dias</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    {values && values.id && values.sendAll === false ? (
                      <div class="text-center">
                        <button
                          className="btn btn-success btn-square"
                          onClick={() => sendEmails()}
                        >
                          Enviar
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {values && values.id !== undefined ? (
              <div class="col-sm-12">
                <div className="py-3 text-center">
                  Link público : {`${GetBaseUrl()}#/feedback/${values.id}`}
                </div>
                <Card
                  title="Perguntas desse formulário"
                  action={() => setShowModal(!showModal)}
                  icon="plus"
                >
                  <SampleTable
                    title="Perguntas desse formulário"
                    cols={cols}
                    data={structures}
                    remove={(task) => disableServices(task.data)}
                    refresh={() => getService()}
                    newItem={() => setShowModal(true)}
                    open={(item) => openService(item)}
                  />
                </Card>
              </div>
            ) : (
              ""
            )}
          </div>
        </Panel>
        <Panel>
          <FeedbackReport feedback={values} structures={structures} />
        </Panel>
      </Tabs>
      <FeedbackStructureModal
        data={service}
        quizId={values && values.id}
        show={showModal}
        onHide={() =>
          setShowModal(!showModal) | getService() | setService(null)
        }
      />
    </Modal>
  );
}

export default QuizModal;

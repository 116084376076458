import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { ResendInsert } from "@/app/core/base/controllers/SendController";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import ObjectiveController from "@/app/core/base/controllers/ObjectiveController";

export default function ObjectiveModal({ values, show, onHide, isLoading }) {
  const [data, setData] = useState({});
  const objectiveCtrl = ObjectiveController();

  useEffect(() => {
    if (values) {
      setData(values);
    } else {
      setData({});
    }
  }, [values]);

  function handleSubmit() {
    if (data.id != undefined) {
      objectiveCtrl.Update(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    } else {
      objectiveCtrl.Insert(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  return (
    <Modal
      title="Envio automático"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={handleSubmit}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label for="example-masked-time">Vendas</label>
              <input
                type="number"
                className="form-control"
                name="sales"
                value={data && data.sales}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
              />
            </div>
            <div className="form-group">
              <label for="example-masked-time">Atendimento</label>
              <input
                type="number"
                className="form-control"
                name="service"
                value={data && data.service}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
              />
            </div>
            {/* <div className="form-group">
              <label for="example-masked-time">
                Recorrência
              </label>
              <input
                type="number"
                className="form-control"
                name="recurrence"
                value={data && data.recurrence}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
              />
            </div> */}
            <div className="form-group">
              <label for="example-masked-time">BHAG</label>
              <input
                type="number"
                className="form-control"
                name="bhag"
                value={data && data.bhag}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

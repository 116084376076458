import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function PreparationStepsController() {
  async function Get(userId, response) {
    await BaseService(
      {
        method: "GET",
        url: `preparationStep/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  }

  async function Insert(data, response) {
    BaseService(
      {
        method: "POST",
        url: "preparationstep/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  }

  async function Delete(id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `preparationstep/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  }

  return {
    Get,
    Insert,
    Delete,
  };
}

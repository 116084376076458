import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ReminderFileController = {
  Upload: async function (formData, reminderId, response) {
    await BaseService(
      {
        method: "POST",
        url: `reminderFile/upload?reminderId=${reminderId}`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Get: async function (reminderId, response) {
    await BaseService(
      {
        method: "GET",
        url: `reminderFile/${reminderId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Delete: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `reminderFile/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
};

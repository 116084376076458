import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import CurrencyFormat from "react-currency-format";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function PaymentModal({ show, onHide, selected }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    if (selected !== undefined && selected.id !== undefined) {
      setData(selected);
    } else {
      setData({});
    }
  }, [selected]);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function save() {
    setLoading(true);
    if (data.id === undefined) {
      await cashFlow.Insert(data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    } else {
      await cashFlow.Update(data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  return (
    <Modal
      title="Cobrança"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Valor</span>
        <CurrencyFormat
          className={`form-control`}
          thousandSeparator={"."}
          decimalSeparator={","}
          allowNegative={false}
          prefix={"R$"}
          value={data.value}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange(
              {
                target: {
                  name: "value",
                  value: value,
                },
              },
              FieldTypeObj.Currency,
            );
          }}
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <span>Data de vencimento</span>
            <input
              type={"date"}
              className="form-control"
              name="dueDate"
              value={data.dueDate}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <span>Cliente</span>
            <input
              className="form-control"
              name="customerId"
              value={data.customerId}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Descrição</span>
            <input
              className="form-control"
              value={data.description}
              name="description"
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Tipo de operação</span>
            <select
              className="form-control"
              name="type"
              value={data.type}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.OperationTypeList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Tipo de pagamento</span>
            <select
              className="form-control"
              name="paymentType"
              value={data.paymentType}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.PaymentTypeList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <span>Status</span>
            <select
              className="form-control"
              name="status"
              value={data.status}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
            >
              <option>Selecione</option>
              {financialHelper.PaymentStatusList.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ForgotPassword } from "@/app/core/base/controllers/UsersController";

export default function SignIn(props) {
  const [email, setEmail] = useState(null);
  const [type, setType] = useState(1);
  const [loading, setLoading] = useState(false);

  function recover() {
    if (email === null || email === "") {
      toast.error("Escreva o seu email para recuperar a senha");
      return;
    }

    setLoading(true);
    ForgotPassword(email, type, (res) => {
      if (type == 1) {
        toast.info("Foi enviado um email com o link para alterar a senha");
      } else {
        toast.info(
          "Foi enviado uma mensagem pelo WhatsApp com o link para alterar a senha",
        );
      }
      setLoading(false);
      setTimeout(() => {
        goAuth();
      }, 8500);
    });
  }

  function goAuth() {
    window.location = "#/";
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="index.html"
                  >
                    <img src={Logo} style={{ maxWidth: "150px" }} />
                  </a>
                </div>
                <p className="text-center text-uppercase font-w700 font-size-sm text-muted">
                  Redefinir a senha
                </p>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    <div className="js-validation-signin">
                      <div className="py-3">
                        <div className="form-group">
                          <span>E-mail</span>
                          <input
                            type="email"
                            className="form-control form-control-lg form-control-alt"
                            id="email"
                            name="email"
                            onChange={(evt) => setEmail(evt.target.value)}
                            placeholder="E-mail"
                          />
                        </div>
                        <div className="form-group">
                          <label className="d-block">
                            Preferência de contato:
                          </label>
                          <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="Email"
                              name="example-rd-custom-inline-lg"
                              onChange={(evt) =>
                                setType(evt.target.checked == true ? 1 : 2)
                              }
                              checked={type == 1}
                            />
                            <label className="custom-control-label" for="Email">
                              Email
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline custom-control-lg">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="WhatsApp"
                              name="example-rd-custom-inline-lg"
                              onChange={(evt) =>
                                setType(evt.target.checked == true ? 2 : 1)
                              }
                              checked={type == 2}
                            />
                            <label
                              className="custom-control-label"
                              for="WhatsApp"
                            >
                              WhatsApp
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={() => recover()}
                          className="btn btn-block btn-hero-lg btn-hero-primary"
                        >
                          <i
                            className={
                              loading === true
                                ? "fa fa-circle-notch fa-spin"
                                : "fa fa-fw fa-arrow-right mr-1"
                            }
                          />{" "}
                          Redefinir
                        </button>
                        <p className="mt-3 mb-0 text-center">
                          <a
                            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                            onClick={() => goAuth()}
                          >
                            <i className="fa fa-arrow-left text-muted mr-1" />{" "}
                            Voltar para login
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Sua experiência está sendo atualizada!
                  <br />
                  Para sempre ;)
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Hasa - Documentos &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toastr" />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ExigencyShortCutModal from "./ExigencyShortCutModal";

export default function ExigencyCardReport({ exigencies, reload }) {
  const [showModal, setShowModal] = useState(false);

  function openExigencies() {
    setShowModal(true);
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openExigencies()}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="fa fa-tasks fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question"
              title="Responda todas as exigências dos seus processos de forma prática e em um único lugar"
            />
          </div>
        </div>
        <div className="block-content">
          <h2 className="mt-3">{exigencies && exigencies.length} Exigências</h2>
          Pendentes
        </div>
      </div>
      <ExigencyShortCutModal
        process={exigencies}
        show={showModal}
        onHide={() => setShowModal(false) | reload()}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import FileViewer from "../FileViewer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default function MegaViewer({ doc, size }) {
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);

  useEffect(() => {
    openFile();
  }, [doc]);

  function openFile() {
    setFile(doc);
  }

  function reOpenFile() {
    setFile("");
    setTimeout(() => {
      setFile(doc);
    }, 450);
  }

  function openLightBox(item) {
    setImagesUrl([item]);
    setIsOpen(true);
  }

  function openTab() {
    let url =
      "https://drive.google.com/viewerng/viewer?url=" +
      doc +
      "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

    if (doc.includes("pdf") || doc.includes("PDF")) {
      window.open(doc, "_blank");
    } else {
      window.open(url, "_blank");
    }
  }

  return (
    <div className="block block-rounded">
      {isOpen && (
        <Lightbox
          reactModalStyle={{ zIndex: 999999999999999999999 }}
          enableZoom={true}
          mainSrc={imagesUrl[photoIndex]}
          nextSrc={imagesUrl[(photoIndex + 1) % imagesUrl.length]}
          prevSrc={
            imagesUrl[(photoIndex + imagesUrl.length - 1) % imagesUrl.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesUrl.length - 1) % imagesUrl.length,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesUrl.length)
          }
        >
          <button className="btn btn-square">has</button>
        </Lightbox>
      )}
      <div className="block-header">
        <div className="block-title"></div>
        <div className="block-tools">
          <button className="btn btn-white" onClick={() => openTab()}>
            <i className="si si-share-alt"></i> Abrir em outra guia
          </button>
          <button className="btn btn-white" onClick={() => reOpenFile()}>
            <i className="si si-refresh"></i> Atualizar
          </button>
        </div>
      </div>
      <div className="block-tools">
        <FileViewer
          file={file}
          height={size === undefined ? "100%" : size}
          openImage={(item) => openLightBox(item)}
        />
      </div>
    </div>
  );
}

export default function TranslateDeliveryType(i) {
  switch (i) {
    case 0:
      return "Não definido";
    case 1:
      return "Enviado a pergunta";
    case 2:
      return "Sedex";
    case 3:
      return "Motoboy";
    case 4:
      return "Retirada na recepção";
    case 5:
      return "Digital";
    case 6:
      return "Carta Simples";

    default:
      return "Não enviado";
      break;
  }
}

export const DeliveryType = {
  NotDefined: 0,
  Questioned: 1,
  Sedex: 2,
  Motoboy: 3,
  Retirada: 4,
  Digital: 5,
};

export const DocumentTypeList = [
  { name: "Físico", value: 0 },
  { name: "Digital", value: 1 },
];

export const DocumentType = {
  Physical: 0,
  Digital: 1,
};

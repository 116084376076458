import React, { useState, useEffect } from "react";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";
import Grid from "./Grid";

export default function MainComponent(props) {
  const { key } = props.match.params;
  const [data, setData] = useState({});
  const [kickOut, setKickOut] = useState(false);

  useEffect(() => {
    BaseService(
      {
        method: "POST",
        url: "budget-info",
        data: {
          id: key,
        },
      },
      (res) => {
        setData(res);
      },
      (err) => {
        setKickOut(true);
      },
    );
  }, []);

  if (kickOut) return <Redirect to="error/404" />;

  return (
    <div className="content">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-8">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <h4>Processo #{key}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <Grid data={data.timeline} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-4">
          <div className="block block-rounded text-center">
            <div className="block-content block-content-full bg-image">
              <img
                className="img-avatar img-avatar-thumb"
                src={`${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`}
                id="currentUserHome"
              />
            </div>
            <div className="block-content block-content-full block-content-sm bg-body-light">
              <div className="font-size-sm text-muted">
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-md-4">
                    Nome do Cliente
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    {data.budget && data.budget.clientId}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-md-4">
                    Código do Cliente
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    {data.budget && data.budget.clientId}
                  </div>
                </div>
                <div className="row mt-4 d-flex justify-content-around">
                  <button className="btn btn-warning">Email</button>
                  <button className="btn btn-warning">Whatsapp</button>
                  <button className="btn btn-warning">Chat</button>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-sm-2 col-md-2">Telefone</div>
                  <div className="col-lg-10 col-sm-10 col-md-10"></div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-sm-2 col-md-2">E-mail</div>
                  <div className="col-lg-10 col-sm-10 col-md-10"></div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-12 col-sm-12 col-md-12">
                    Responsável pelo processo
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4 col-sm-4 col-md-4">
                    <img
                      className="img-avatar img-avatar-thumb"
                      src={`${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`}
                      id="currentUserHome"
                      width="40"
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8">
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-2">Nome</div>
                      <div className="col-lg-10 col-md-10 col-sm-10">
                        {data.budgetResponsable && data.budgetResponsable.name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-2">
                        Departamento
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import CustomerEdit from "../../customer/main/CustomerEditModal";
import ServiceShowCase from "@/app/core/layouts/main-components/services/ServiceShowCase";
import CustomerContract from "@/app/core/layouts/main-components/documents/contracts/CustomerContract";
import TermsComponent from "@/app/core/layouts/main-components/documents/terms/TermsComponent";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [data, setData] = useState({});
  const userData = Storage.getUserData();
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <Loading loading={loading} />
        <Tabs>
          <div className="bg-white">
            <div className="content">
              <div className="push">
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="si si-book-open mr-1" /> Serviços oferecidos
                  </button>
                </Tab>
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    disabled={true}
                    className="btn btn-sm btn-light"
                  >
                    <i className="si si-users mr-1" /> Políticas
                  </button>
                </Tab>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-md-12">
              <Panel>
                <ServiceShowCase />
              </Panel>
              <Panel>
                <CustomerContract customerId={userData.customerId} />
                <TermsComponent />
              </Panel>
            </div>
          </div>
        </Tabs>
        <CustomerEdit
          data={data}
          show={showCustomerModal}
          onHide={() => setShowCustomerModal(false)}
        />
      </div>
    </>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function NotificationController() {
  async function ByUser(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `notification/user/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function AllUserNotifications(userId, days, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `notification/alluser/${userId}?days=${days}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function AllProcessNotifications(processId, days, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `notification/process/${processId}?days=${days}&`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function MarkAllAsRead(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `notification/read/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "notification/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "notification/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    ByUser,
    AllUserNotifications,
    AllProcessNotifications,
    MarkAllAsRead,
    Update,
    Insert,
  };
}

import React, { useState } from "react";
import { TypeFields } from "./InputHelper";
import InputMask from "react-input-mask";
import { Typeahead } from "react-bootstrap-typeahead";
import CurrencyFormat from "react-currency-format";

export default function DynamicField({ item, data, handleChange }) {
  const [query, setQuery] = useState("");

  return (
    <div className="form-group">
      <span>{item.description}</span>
      {item.fieldType === TypeFields.Mask ? (
        <textarea
          className="form-control"
          name={item.name}
          defaulvalue={data !== undefined ? data : ""}
          onChange={(evt) =>
            handleChange({
              target: {
                value: evt.target.value,
                name: evt.target.name,
              },
            })
          }
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.TypeAhead ? (
        <Typeahead
          options={item.options}
          labelKey={item.query}
          onSearch={(q) => setQuery(q)}
          id="advanced-filter-state"
          name="actingArea"
          defaulvalue={data !== undefined ? data : ""}
          placeholder="Selecionar Cliente"
          onChange={(selected) => {
            handleChange({
              target: {
                value: selected[0] ? selected[0][item.query] : "",
                name: item.name,
              },
            });
          }}
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.Currency ? (
        <CurrencyFormat
          className={`form-control`}
          thousandSeparator={true}
          thousandSeparator={"."}
          decimalSeparator={","}
          allowNegative={false}
          defaulvalue={data !== undefined ? data : ""}
          prefix={"R$"}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange({
              target: {
                name: item.name,
                value: value,
              },
            });
          }}
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.Text ||
      item.fieldType === TypeFields.Email ? (
        <input
          type="text"
          className="form-control"
          name={item.name}
          defaulvalue={data !== undefined ? data : ""}
          onChange={(evt) => handleChange(evt)}
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.File ? (
        <input
          type="file"
          className="form-control"
          name={item.name}
          onChange={(evt) => handleChange(evt)}
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.Date ? (
        <input
          type="date"
          className="form-control"
          name={item.name}
          defaulvalue={data !== undefined ? data : ""}
          onChange={(evt) => handleChange(evt)}
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.Number ? (
        <input
          type="number"
          className="form-control"
          name={item.name}
          defaulvalue={data !== undefined ? data : ""}
          onChange={(evt) =>
            handleChange({
              target: {
                value: parseInt(evt.target.value),
                name: evt.target.name,
              },
            })
          }
        />
      ) : (
        ""
      )}
      {item.fieldType === TypeFields.Select ? (
        <select
          className="form-control"
          defaulvalue={data !== undefined ? data : ""}
          name={item.name}
          onChange={(evt) => handleChange(evt)}
        >
          <option defaulvalue={null}>Selecione</option>
          {item.feedbackValues &&
            item.feedbackValues.map((element) => (
              <option
                defaulvalue={element[item.value]}
                selected={element[item.value] === data ? true : false}
              >
                {element.value}
              </option>
            ))}
        </select>
      ) : (
        ""
      )}
    </div>
  );
}

import React, { useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";

export default function CustomContact({ send }) {
  const [email, setEmail] = useState("");

  function processSend() {
    if (email.length === 0) {
      toast.error("Preencha para poder enviar");
    } else {
      send(email);
    }
  }

  return (
    <DropdownElement
      submit={() => processSend()}
      icon="si si-envelope-letter"
      style={{ width: "100%", height: 145 }}
      title={""}
    >
      <div className="form-group">
        <span>E-mail</span>
        <input
          type={"email"}
          className="form-control"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </div>
    </DropdownElement>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import {
  OperationalListHelper,
  DepartmentTranslate,
} from "@/app/core/util/helpers/DepartmentHelper";
import {
  TeamInsert,
  TeamSelect,
  TeamUpdate,
} from "@/app/core/base/controllers/TeamsController";
import {
  CompanyUsers,
  UserUpdate,
} from "@/app/core/base/controllers/UsersController";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";

export default function TeamModal({ infos, show, onHide }, props) {
  const [data, setData] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState("");

  const { submit, isLoading } = props;

  useEffect(() => {
    setData(infos);
    loadUsers();
  }, [infos]);

  function loadUsers() {
    CompanyUsers((res) => {
      setUsers(res);
    });
  }

  function handleChange(evt) {
    let content = data;

    let info = {
      [evt.target.name]:
        evt.target.name === "department"
          ? parseInt(evt.target.value)
          : evt.target.value,
    };

    setData({ ...content, ...info });
  }

  function save() {
    if (data.department === undefined) {
      toast.error(`Preencha o departamento para poder salvar`);
      return;
    }

    if (data.id !== undefined) {
      TeamUpdate(data, (res) => {
        onHide();
        toast.success(`Salvo com sucesso`);
      });
    } else {
      TeamInsert(data, (res) => {
        onHide();
        toast.success(`Salvo com sucesso`);
      });
    }
  }

  function loadTeam() {
    TeamSelect(data.id, (res) => {
      setData(res);
    });
  }

  function removeUser(user) {
    if (window.confirm(DeleteConfirm)) {
      UserUpdate({ ...user, teamId: null }, (res) => {
        loadTeam();
      });
    }
  }

  function addUser() {
    if (selectedUser.id === undefined) {
      toast.error("Selecione um usuário");
    } else {
      UserUpdate({ ...selectedUser, teamId: data.id }, (res) => {
        loadTeam();
        setSelectedUser({});
      });
    }
  }

  return (
    <Modal
      title="Equipe"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="example-masked-time">Departamento</label>
            <select
              class="form-control"
              name="department"
              onChange={handleChange}
            >
              <option value={null}>Selecione</option>
              {OperationalListHelper.map((item) => (
                <option
                  value={item.value}
                  selected={data.department === item.value ? true : false}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {data && data.id !== undefined ? (
          <div class="col-sm-12">
            <div className="block-header">
              <h2 className="content-heading">
                <i className="fa fa-angle-right text-muted mr-1"></i> Membros
                equipe : {data.id}
              </h2>
              <div className="tools">
                <DropdownElement
                  icon={"si si-plus"}
                  title={"Adicionar membro"}
                  button={"Adicionar"}
                  style={{ width: 350 }}
                  submit={() => addUser()}
                >
                  <div className="form-group">
                    <span>Operador</span>
                    <Typeahead
                      options={users}
                      labelKey="firstName"
                      onSearch={(q) => setQuery(q)}
                      id="advanced-filter-state"
                      placeholder="Selecionar operador"
                      labelKey={(option) =>
                        `${option.firstName} ${
                          option.lastName
                        } - Departamento : ${DepartmentTranslate(
                          option.level,
                        )} - Email : ${option.email} - Cod. ${option.id}`
                      }
                      onChange={(selected) => {
                        setSelectedUser(selected[0]);
                      }}
                    />
                  </div>
                </DropdownElement>
              </div>
            </div>
            <div className="block-content">
              {data &&
                data.users &&
                data.users.map((user) => (
                  <div className="block block-bordered">
                    <div className="block-content block-content-sm block-content-full bg-body-light">
                      <div className="media py-3">
                        <div className="mr-3 ml-2 overlay-container overlay-right">
                          <img
                            className="img-avatar img-avatar48"
                            src={
                              user.avatarImage !== undefined
                                ? user.avatarImage.url
                                : "assets/media/avatars/avatar4.jpg"
                            }
                          />
                        </div>
                        <div className="media-body">
                          <div className="row">
                            <div className="col-sm-7">
                              <a className="font-w600 link-fx">
                                {user && user.firstName} {user && user.lastName}
                              </a>
                              <div className="font-size-sm text-muted">
                                <a
                                  href={`https://wa.me/${user && user.phone}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {user && user.phone}
                                </a>
                                <br />
                                <a
                                  href={`mailto:${user && user.email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {user && user.email}
                                </a>
                              </div>
                            </div>
                            <div className="col-sm-5 d-sm-flex align-items-sm-center">
                              <div className="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                                <p className="mb-0">
                                  {user && DepartmentTranslate(user.level)}
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-link"
                                    onClick={() => {
                                      removeUser(user);
                                    }}
                                  >
                                    <i className="si si-close text-danger"></i>
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}

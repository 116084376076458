import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import DocInput from "../DocInput";
import { Dropdown } from "react-bootstrap";
import { DeleteConfirm, Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import {
  TermsList,
  TermsDelete,
  UploadTerms,
} from "@/app/core/base/controllers/TermsController";
import TermItem from "./TermItem";
import { OnDataChange } from "@/app/core/util/services/DataService";

export default function TermsComponent() {
  const userData = Storage.getUserData();
  const [data, setData] = useState([]);
  const [term, setTerm] = useState({});

  useEffect(() => {
    loadTerms();
  }, []);

  function uploadFile() {
    if (
      term.name === undefined ||
      term.name === "" ||
      term.files === undefined
    ) {
      toast.error(Message.PendingData);
      return;
    }
    var formData = new FormData();
    formData.append("file", term.files[0]);

    UploadTerms(formData, term.name, (res) => {
      setTerm({});
      loadTerms();
    });
  }

  function loadTerms() {
    TermsList((res) => {
      setData(res);
    });
  }

  function handleChange(evt) {
    setTerm(OnDataChange(term, evt));
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Termos & políticas</h3>
          <div className="block-tools">
            {userData && userData.level !== 5 ? (
              <Dropdown drop="left">
                <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                <Dropdown.Menu style={{ width: 310 }}>
                  <div className="form-group">
                    <span>Nome</span>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={term && term.name}
                      onChange={(evt) => handleChange(evt)}
                    />
                  </div>
                  <div className="form-group">
                    <span>
                      {term && term.files !== undefined
                        ? `Arquivo : ${term.files[0].name}`
                        : ""}
                    </span>
                    <DocInput
                      multiple={false}
                      uploadFile={(itens) => setTerm({ ...term, files: itens })}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-square btn-success"
                      onClick={() => uploadFile()}
                    >
                      Salvar
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="block-content">
          {data &&
            data.map((item, index) => (
              <TermItem data={item} loadTerms={() => loadTerms()} />
            ))}
        </div>
      </div>
    </>
  );
}

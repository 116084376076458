import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function StateInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: "state/insert",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function StateUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: "state/update",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function StateDelete(id, response) {
  BaseService(
    {
      method: "GET",
      url: `state/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function StateList(response) {
  BaseService(
    {
      method: "GET",
      url: `state`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import React, { useEffect, useState } from "react";
import Nps from "../../dashboard/components/panels/administratorElements/Nps";
import ExigencyCounter from "../../dashboard/components/panels/administratorElements/ExigencyCounter";
export default function MainComponent(props) {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-6">
          <div className="content-heading">
            <h2>Exigências</h2>
          </div>
        </div>
        <div className="col-md-12">
          <ExigencyCounter />
        </div>
      </div>
    </div>
  );
}

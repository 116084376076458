import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function TemplateUpload(formData, folderId, response) {
  BaseService(
    {
      method: "POST",
      url: `template?folderId=${folderId}`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleUploadErrors(err));
    },
  );
}

export function TemplateDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `template/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function TemplateList(folderId, response) {
  BaseService(
    {
      method: "GET",
      url: `template/${folderId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import CustomerDocController from "@/app/core/base/controllers/CustomerDocController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import DocInput from "./DocInput";
import ModalDoc from "./ModalDoc";

export default function CustomerDocCard({ customerId }) {
  const [docs, setDocs] = useState([]);
  const [data, setData] = useState({});
  const [selectedDoc, setSelectedDoc] = useState("");
  const [showModal, setShowModal] = useState(false);
  const docsController = CustomerDocController();
  const userData = Storage.getUserData();

  useEffect(() => {
    if (customerId !== undefined) {
      loadDocs();
    }
  }, [customerId]);

  async function loadDocs() {
    await docsController.List(customerId, (res) => {
      setDocs(res);
    });
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  async function saveDoc() {
    if (data.docName === "" || data.docName === undefined) {
      toast.error(Message.PendingData);
    } else if (data.files === "" || data.files === undefined) {
      toast.error(Message.PendingData);
    } else {
      var formData = new FormData();
      formData.append("file", data.files[0]);
      await docsController.Upload(customerId, data.docName, formData, (res) => {
        toast.success(Message.Saved);
        loadDocs();
      });
    }
  }

  async function removeDoc(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await docsController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadDocs();
      });
    }
  }

  function openDoc(item) {
    setSelectedDoc(item.url);
    setShowModal(true);
  }

  return (
    <div className="block block-bordered block-rounded">
      <div className="block-header">
        <div className="block-title">Documentos</div>
        <div className="block-tools">
          <Dropdown drop="left">
            <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
            <Dropdown.Menu style={{ width: 310 }}>
              <div className="form-group">
                <span>Nome</span>
                <input
                  type="text"
                  className="form-control"
                  name="docName"
                  value={data && data.docName}
                  onChange={(evt) => handleChange(evt)}
                />
              </div>
              <div className="form-group">
                <span>
                  {data && data.files !== undefined
                    ? `Arquivo : ${data.files[0].name}`
                    : ""}
                </span>
                <DocInput
                  multiple={false}
                  uploadFile={(itens) => setData({ ...data, files: itens })}
                />
              </div>
              <div className="text-center">
                <button
                  className="btn btn-square btn-success"
                  onClick={() => saveDoc()}
                >
                  Salvar
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="block-content">
        {docs &&
          docs.map((item) => (
            <div className="block-header block-header-default block-bordered m-1">
              <small>{item.docName}</small>
              <div className="block-tools">
                {DateTranslate(item.updatedDate)} {" - "}
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => openDoc(item)}
                >
                  <i className="fa fa-eye"></i>
                </button>
                {userData && userData.role > 0 ? (
                  <button
                    className="btn btn-sm btn-light"
                    onClick={() => removeDoc(item)}
                  >
                    <i className="si si-close text-danger" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </div>
      <ModalDoc
        show={showModal}
        onHide={() => setShowModal(false) | setSelectedDoc("")}
        doc={selectedDoc}
      />
    </div>
  );
}

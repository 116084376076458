import RemunerationController from "@/app/core/base/controllers/RemunerationController";
import Functions from "@/app/core/util/Functions";
import RemunerationHelper from "@/app/core/util/helpers/RemunerationHelper";
import React, { useEffect, useState } from "react";
import RemunerationModal from "./RemunerationModal";
import Table from "@/app/core/components/table/";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { OrganStatusTranslate } from "@/app/core/util/helpers/OrganListHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function RemunerationCard({ serviceOrganId }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const remunerationCtrl = RemunerationController();
  const remunerationHelper = RemunerationHelper();

  useEffect(() => {
    loadRemunerations();
  }, [serviceOrganId]);

  async function loadRemunerations() {
    setLoading(true);
    await remunerationCtrl.List(serviceOrganId, (res) => {
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].levelName = DepartmentTranslate(res[s].level);
        if (res[s].level === 4) {
          res[s].levelName += ` ${OrganStatusTranslate(
            res[s].devolutionStatus,
          )}`;
        }
      }
      setData(res);
    });
    setLoading(false);
  }

  function viewAction(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  async function removeRemuneration(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await remunerationCtrl.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadRemunerations();
      });
    }
  }

  return (
    <div className="block block-rounded block-bordered">
      <div className="block-header">
        <div className="block-title">Remuneração</div>
        <div className="block-tools">
          <button
            className="btn btn-dual btn-sm"
            onClick={() => setShowModal(true)}
          >
            Adicionar
          </button>
        </div>
      </div>
      <div className="block-content">
        <Table
          title={" Não iniciados"}
          loading={loading}
          data={data}
          columns={remunerationHelper.remunerationCols}
          refreshData={() => loadRemunerations()}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          viewAction={(data) => viewAction(data)}
          deleteAction={(item) => removeRemuneration(item.data)}
          hideHeader={true}
        />
      </div>
      <RemunerationModal
        infos={selected}
        serviceOrganId={serviceOrganId}
        show={showModal}
        onHide={() =>
          setShowModal(false) | setSelected({}) | loadRemunerations()
        }
      />
    </div>
  );
}

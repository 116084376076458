import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "@/assets/img/logo.jpg";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderFileController } from "@/app/core/base/controllers/ReminderFileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import CurrencyFormat from "react-currency-format";
import {
  CPFValidator,
  CivilState,
  CivilStateObj,
  CivilStateTranslate,
  Communion,
  CommunionObj,
  CommunionTranslate,
} from "@/app/core/util/helpers/UserHelper";
import InputMask from "react-input-mask";
import { CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { StateList } from "@/app/core/base/controllers/StateController";

export default function DrafterComponent(props) {
  const [step, setStep] = useState(1);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({});
  const [partner, setPartner] = useState({});
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPartner, setNewPartner] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());
  const [states, setStates] = useState([]);
  const inputFile = useRef(null);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {}, [step]);

  useEffect(() => {
    setLogo(Logo);
    loadStates();
  }, []);

  function loadStates() {
    StateList((res) => {
      setStates(res);
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function loadReminder() {}

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function handleAdmChange(evt, type) {
    console.log("Dados", evt, OnDataChange(partner, evt, type));
    setPartner(OnDataChange(partner, evt, type));
  }

  function toSecond() {
    let error = false;
    let msg = "";
    if (!data.name) {
      error = true;
      msg += "Nome da empresa; ";
    }

    if (!data.street) {
      error = true;
      msg += "Logradouro; ";
    }

    if (!data.neighborhood) {
      error = true;
      msg += "Bairro; ";
    }

    if (!data.postalCode) {
      error = true;
      msg += "CEP; ";
    }

    if (!data.city) {
      error = true;
      msg += "Cidade; ";
    }

    if (!data.state) {
      error = true;
      msg += "Estado; ";
    }

    if (!data.activity) {
      error = true;
      msg += "Atividade; ";
    }
    if (!data.capital) {
      error = true;
      msg += "Capital social; ";
    }

    if (error) {
      toast.error(`Faltou preenche o ${msg}`);
    } else {
      setStep(step + 1);
    }
  }

  function addPartner() {
    let error = false;
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (partner.civilState == undefined) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.street) {
      error = true;
      msg += "Logradouro; ";
    }

    if (!partner.neighborhood) {
      error = true;
      msg += "Bairro; ";
    }

    if (!partner.postalCode) {
      error = true;
      msg += "CEP; ";
    }

    if (!partner.city) {
      error = true;
      msg += "Cidade; ";
    }

    if (!partner.state) {
      error = true;
      msg += "Estado; ";
    }

    if (!partner.shares) {
      error = true;
      msg += "Participação; ";
    }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (error) {
      toast.error(msg);
    } else {
      setPartners([...partners, { ...partner, id: partners.length + 1 }]);
      setPartner({});
      setNewPartner(false);
    }
  }

  function partnerForm(index) {
    return (
      <div className="m-2 mt-3 row">
        <div className="form-group col-md-6">
          <span>Nome:</span>
          <input
            className="form-control"
            value={partner && partner.name}
            key="namePartner"
            name="name"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Nacionalidade:</span>
          <input
            className="form-control"
            value={partner && partner.nationality}
            key="nationalityPartner"
            name="nationality"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>
            Estado Civil: (se casado informar qual a comunhão de bens)
          </span>
          <select
            className="form-control"
            value={partner && partner.civilState}
            key="civilStatePartner"
            name="civilState"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          >
            <option value={null}>Selecione</option>
            {CivilState.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
        {partner && partner.civilState == CivilStateObj.Married ? (
          <div className="form-group col-md-6">
            <span>Tipo de comunhão</span>
            <select
              className="form-control"
              value={partner && partner.communion}
              key="communionPartner"
              name="communion"
              onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
            >
              <option value={null}>Selecione</option>
              {Communion.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
        <div className="form-group col-md-6">
          <span>Profissão:</span>
          <input
            className="form-control"
            value={partner && partner.jobTitle}
            key="jobTitlePartner"
            name="jobTitle"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>RG:</span>
          <input
            className="form-control"
            value={partner && partner.doc}
            key="docPartner"
            name="doc"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>CPF:</span>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            name="cpf"
            placeholder="999.999.999-99"
            value={partner && partner.stateId}
            onChange={(event) => {
              handleAdmChange({
                target: {
                  name: "stateId",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Logradouro:</span>
          <input
            className="form-control"
            value={partner && partner.street}
            key="streetPartner"
            name="street"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6 row">
          <span className="col-md-6">Numero:</span>
          <div className="col-md-6 text-right">
            <button
              className="btn btn-sm btn-dual"
              onClick={() =>
                handleAdmChange(
                  {
                    target: {
                      value:
                        partner.noNumber == undefined
                          ? true
                          : !partner.noNumber,
                      name: "noNumber",
                    },
                  },
                  FieldTypeObj.Bool,
                )
              }
            >
              <i className="si si-check mr-1" />
              Sem Numero
            </button>
          </div>
          {partner &&
          (partner.noNumber == undefined || partner.noNumber == false) ? (
            <input
              className="form-control"
              value={partner && partner.number}
              key="numberPartner"
              name="number"
              onChange={(evt) => handleAdmChange(evt)}
            />
          ) : (
            <span className="form-control">S/N</span>
          )}
        </div>

        <div className="form-group col-md-6">
          <span>Bairro:</span>
          <input
            className="form-control"
            value={partner && partner.neighborhood}
            key="neighborhoodPartner"
            name="neighborhood"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Complemento:</span>
          <input
            className="form-control"
            value={partner && partner.complement}
            key="complementPartner"
            name="complement"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>CEP:</span>
          <input
            className="form-control"
            value={partner && partner.postalCode}
            key="postalCodePartner"
            name="postalCode"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Município:</span>
          <input
            className="form-control"
            value={partner && partner.city}
            key="cityPartner"
            name="city"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Estado:</span>
          <select
            className="form-control"
            value={partner && partner.state}
            key="statePartner"
            name="state"
            onChange={(evt) => handleAdmChange(evt)}
          >
            <option value={null}>Selecione</option>
            {states &&
              states.map((item) => (
                <option value={item.longName}>{item.longName}</option>
              ))}
          </select>
        </div>

        <div className="form-group col-md-6">
          <span>Porcentagem do capital</span>
          <input
            className="form-control"
            type="number"
            value={partner && partner.shares}
            key="sharesPartner"
            name="shares"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          />
        </div>
        {/* Se as cotas não corresponderem a 100% da empresa, devemos evitar a pessoa de avançar */}

        {index != undefined ? (
          ""
        ) : (
          <div className="text-center">
            <button
              className="btn btn-warning btn-square mt-4 mb-4"
              onClick={() => addPartner()}
            >
              Salvar sócio
            </button>
          </div>
        )}

        {/* Depois de salvar apresentar como conteiner e colocar o botão Adicionar Sócio */}

        {loading === true ? (
          <div className="text-center">
            <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  function editItem(index) {
    let old = partners;
    old[index].edit =
      old[index].edit == undefined || old[index].edit == false ? true : false;
    setPartners(old);
    setPartner(old[index]);
    notifyChange();
  }

  function saveItem(index) {
    let error = false;
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (!partner.civilState) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.address) {
      error = true;
      msg += "Endereço; ";
    }
    if (!partner.shares) {
      error = true;
      msg += "Participação; ";
    }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (error) {
      toast.error(msg);
    } else {
      let old = partners;
      old[index] = partner;
      old[index].edit = false;
      setPartners(old);
      console.log("Parceiros", old);
      setPartner({});
      notifyChange();
    }
  }

  function removeItem(index) {
    if (window.confirm(Message.DeleteConfirm)) {
      let old = partners;
      delete old[index];
      setPartners(old);
      notifyChange();
    }
  }

  function goToLast() {
    if (partners.length == 0) {
      toast.error("Adicione ao menos 1 socio");
    } else {
      let total = 0;
      for (let s in partners) {
        total += partners[s].shares;
      }

      console.log("total", total);

      if (total < 100) {
        toast.error(
          "A quantidade de quotas está abaixo de 100% do capital social",
        );
      } else if (total > 100) {
        toast.error(
          "A quantidade de quotas está acima de 100% do capital social",
        );
      } else {
        setStep(step + 1);
      }
    }
  }

  function checkAdministrator(index) {
    let old = partners;
    old[index].adm = !old[index].adm;
    setPartners(old);
    notifyChange();
  }

  function checkWage(index) {
    let old = partners;
    old[index].wage = !old[index].wage;
    setPartners(old);
    notifyChange();
  }

  function changeStep() {
    switch (step) {
      case 1:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3">
              <div className="form-group">
                <span>Precisamos saber qual o nome desta empresa?</span>
                <input
                  className="form-control"
                  value={data && data.name}
                  name="name"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>Logradouro:</span>
                <input
                  className="form-control"
                  value={data && data.street}
                  key="streetData"
                  name="street"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span className="row">
                  <span className="col-md-6">Numero:</span>
                  <div className="col-md-6 text-right">
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() =>
                        handleChange(
                          {
                            target: {
                              value:
                                data.noNumber == undefined
                                  ? true
                                  : !data.noNumber,
                              name: "noNumber",
                            },
                          },
                          FieldTypeObj.Bool,
                        )
                      }
                    >
                      <i className="si si-check mr-1" />
                      Sem Numero
                    </button>
                  </div>
                </span>
                {data &&
                (data.noNumber == undefined || data.noNumber == false) ? (
                  <input
                    className="form-control"
                    value={data && data.number}
                    key="numberData"
                    name="number"
                    onChange={(evt) => handleChange(evt)}
                  />
                ) : (
                  <span className="form-control">S/N</span>
                )}
              </div>

              <div className="form-group">
                <span>Bairro:</span>
                <input
                  className="form-control"
                  value={data && data.neighborhood}
                  key="neighborhoodData"
                  name="neighborhood"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>Complemento:</span>
                <input
                  className="form-control"
                  value={data && data.complement}
                  key="complementData"
                  name="complement"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>CEP:</span>
                <input
                  className="form-control"
                  value={data && data.postalCode}
                  key="postalCodeData"
                  name="postalCode"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>Município:</span>
                <input
                  className="form-control"
                  value={data && data.city}
                  key="cityData"
                  name="city"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>Estado:</span>
                <select
                  className="form-control"
                  value={data && data.state}
                  key="stateData"
                  name="state"
                  onChange={(evt) => handleChange(evt)}
                >
                  <option value={null}>Selecione</option>
                  {states &&
                    states.map((item) => (
                      <option value={item.longName}>{item.longName}</option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <span>
                  Vamos falar sobre a atividade dessa empresa agora, me informe
                  detalhadamente a atividade que ela vai realizar
                </span>
                <input
                  className="form-control"
                  value={data && data.activity}
                  name="activity"
                  onChange={(evt) => handleChange(evt)}
                />
              </div>

              <div className="form-group">
                <span>Qual será o capital da empresa</span>
                <CurrencyFormat
                  className={`form-control`}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  name="capital"
                  value={data && data.capital}
                  prefix={"R$"}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    handleChange(
                      {
                        target: {
                          name: "capital",
                          value:
                            value != null && value != "" && value != NaN
                              ? value
                              : 0,
                        },
                      },
                      FieldTypeObj.Currency,
                    );
                  }}
                />
              </div>

              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-right">
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => toSecond()}
              >
                Próximo
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="block-content">
            {change && partners && partners.length == 0 ? (
              partnerForm()
            ) : (
              <p>Sócios</p>
            )}
            {change &&
              partners &&
              partners.map((item, index) => (
                <div>
                  <div
                    className="block block-bordered border-2x border-warning"
                    key={`partiner-${index}`}
                  >
                    <div className="block-header">
                      <div className="block-title">Nome do sócio</div>
                      <div className="block-tools">
                        {change && item && item.edit != true ? (
                          <button
                            className="btn btn-dual"
                            title="Editar Sócio"
                            onClick={() => editItem(index)}
                          >
                            <i className="far fa-edit" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-success"
                            title="Salvar Sócio"
                            onClick={() => saveItem(index)}
                          >
                            <i className="si si-success" /> Salvar
                          </button>
                        )}
                        <button
                          className="btn btn-dual"
                          onClick={() => removeItem(index)}
                        >
                          <i className="si si-close text-danger" />
                        </button>
                      </div>
                    </div>
                    {item && item.edit != true ? (
                      <div className="block-content row">
                        <div className="col-md-6">
                          <b className="font-w700">Nome</b>
                          <br />
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Nacionalidade</b>
                          <br />
                          <p>{item.nationality}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Estado civil</b>
                          <br />
                          <p>{CivilStateTranslate(item.civilState)}</p>
                        </div>
                        {item.civilState == CivilStateObj.Married ? (
                          <div className="col-md-6">
                            <b className="font-w700">Tipo de comunhão</b>
                            <br />
                            <p>{CommunionTranslate(item.communion)}</p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-6">
                          <b className="font-w700">Profissão</b>
                          <br />
                          <p>{item.jobTitle}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">RG</b>
                          <br />
                          <p>{item.doc}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">CPF</b>
                          <br />
                          <p>{CpfMask(item.stateId)}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Logradouro</b>
                          <br />
                          <p>{item.street}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Numero</b>
                          <br />
                          <p>{item.number ? item.number : "S/N"}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Bairro</b>
                          <br />
                          <p>{item.neighborhood}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Complemento</b>
                          <br />
                          <p>{item.complement}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">CEP</b>
                          <br />
                          <p>{item.postalCode}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Cidade</b>
                          <br />
                          <p>{item.city}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Estado</b>
                          <br />
                          <p>{item.state}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Porcentagem do capital</b>
                          <br />
                          <p>{item.shares}</p>
                        </div>
                      </div>
                    ) : (
                      partnerForm(index)
                    )}
                  </div>
                  <hr />
                </div>
              ))}

            {newPartner == false && partners.length > 0 ? (
              <div className="text-center">
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  key={"newPartnet"}
                  onClick={() => setNewPartner(!newPartner)}
                >
                  Adicionar Sócio
                </button>
              </div>
            ) : newPartner == true && partners.length > 0 ? (
              partnerForm()
            ) : (
              "c"
            )}

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step - 1)}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => goToLast()}
              >
                Próximo
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="content">
            <div className="form-group mb-4">
              <label className="d-block">
                Como a administração da empresa será exercida?
              </label>
              <div className="custom-control custom-radio custom-control-inline custom-control-warning">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="example-radio-custom-inline1"
                  name="example-radio-custom-inline"
                />
                <label
                  className="custom-control-label"
                  for="example-radio-custom-inline1"
                >
                  Isoladamente
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline custom-control-warning">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="example-radio-custom-inline2"
                  name="example-radio-custom-inline"
                />
                <label
                  className="custom-control-label"
                  for="example-radio-custom-inline2"
                >
                  Em conjunto
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline custom-control-warning">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="example-radio-custom-inline3"
                  name="example-radio-custom-inline"
                />
                <label
                  className="custom-control-label"
                  for="example-radio-custom-inline3"
                >
                  Ambos
                </label>
              </div>
            </div>

            <div className="form-group">
              <span>Quem fará parte da administração empresa?</span>
              <div className="row">
                {change &&
                  partners.map((item, index) => (
                    <div
                      className={`col-6 block-content block block-bordered border-2x ${item.adm == true ? "border-warning" : ""} block-content-full d-flex align-items-center justify-content-between`}
                    >
                      <div className="custom-control custom-checkbox custom-control-warning mb-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={item.id}
                          name={item.id}
                          checked={item.adm}
                          onChange={(evt) => checkAdministrator(index)}
                        />
                        <label className="custom-control-label" for={item.id}>
                          Selecionar
                        </label>
                      </div>
                      <div className="ml-3 text-right">
                        <p className="font-w600 mb-0">{item.name}</p>
                        <p className="font-size-sm font-italic text-muted mb-0">
                          {item.shares}% da empresa
                        </p>
                      </div>
                    </div>
                  ))}

                <div className="col-12 text-center">
                  <button
                    className="btn btn-sm btn-outline-warning btn-square mt-4 mb-4"
                    onClick={() => setStep(step + 1)}
                  >
                    Adicionar administrador não sócio
                  </button>
                </div>
              </div>
              <div className="form-group">
                <span>Algum deles terá a retirada de prólabore?</span>
                <div className="row">
                  {change &&
                    partners.map((item, index) => (
                      <div
                        className={`col-6 block-content block block-bordered border-2x ${item.wage == true ? "border-warning" : ""} block-content-full d-flex align-items-center justify-content-between`}
                      >
                        <div className="custom-control custom-checkbox custom-control-warning mb-1">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`wage-${item.id}`}
                            name={`wage-${item.id}`}
                            checked={item.wage}
                            onChange={(evt) => checkWage(index)}
                          />
                          <label
                            className="custom-control-label"
                            for={`wage-${item.id}`}
                          >
                            Selecionar
                          </label>
                        </div>
                        <div className="ml-3 text-right">
                          <p className="font-w600 mb-0">{item.name}</p>
                          <p className="font-size-sm font-italic text-muted mb-0">
                            {item.shares}% da empresa
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step - 1)}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="content">
            <div className="block block-fx-pop block-bordered border-success mt-5">
              <div className="block-header">
                <div className="block-title">
                  <i className="far fa-check-circle text-success mr-2" />{" "}
                  Agradecemos o preenchimento das informações!
                </div>
                <div className="block-tools"></div>
              </div>
            </div>
          </div>
        );
    }
  }

  function sendBack() {
    ReminderController.Response(data.id, (res) => {
      toast.success(Message.Saved);
      loadReminder();
    });
  }

  async function uploadFile(fl) {
    setLoading(true);
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }

    await ReminderFileController.Upload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      loadReminder();
    });
  }

  return (
    <div className="">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
          <div className="block block-rounded block-fx-pop w-100 mb-0 overflow-hidden">
            <div className="block-content">
              {data &&
              data.status === ReminderHelper.Status.Done &&
              userData == null ? (
                <div className="block block-fx-pop block-bordered border-success mt-5">
                  <div className="block-header">
                    <div className="block-title">
                      <i className="far fa-check-circle text-success mr-2" />{" "}
                      Você já respondeu esse lembrete
                    </div>
                    <div className="block-tools"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                <img src={logo} width="150px" />
                <br />
                {step && step <= 3 ? (
                  <h3 className="text-muted mt-3 mb-1">Contrato de abertura</h3>
                ) : (
                  ""
                )}
                {step && step <= 3 ? (
                  <small className="text-muted mt-0 mb-5">
                    Preencha os dados para gerar o documento
                  </small>
                ) : (
                  ""
                )}
              </div>
              {step && step <= 3 ? (
                <div className="d-flex align-items-center justify-content-between ml-5 mr-5 mt-4">
                  <div className="text-center">
                    <button
                      type="button"
                      className={
                        step &&
                        `btn btn-rounded ${step == 1 ? "btn-warning" : "btn-outline-warning"}`
                      }
                    >
                      1
                    </button>
                    <br />
                    <span>Dados da empresa</span>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className={
                        step &&
                        `btn btn-rounded ${step == 2 ? "btn-warning" : "btn-outline-warning"}`
                      }
                    >
                      2
                    </button>
                    <br />
                    <span>Dados dos sócios</span>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className={
                        step &&
                        `btn btn-rounded ${step == 3 ? "btn-warning" : "btn-outline-warning"}`
                      }
                    >
                      3
                    </button>
                    <br />
                    <span>Detalhes</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {step && changeStep()}
            </div>
          </div>
        </div>
        <ToastContainer className="toastr" />
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import {
  FolderInsert,
  FolderUpdate,
} from "@/app/core/base/controllers/FoldersController";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import {
  TemplateDelete,
  TemplateList,
  TemplateUpload,
} from "@/app/core/base/controllers/TemplatesController";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";
import { ProcessDates } from "@/app/core/util/DateTranslater";

export default function FolderModal({ show, onHide, values }) {
  const [data, setData] = useState({});
  const [templates, setTemplates] = useState([]);
  const inputFile = useRef(null);

  useEffect(() => {
    if (values) {
      setData(values);
      if (values.id !== undefined) {
        loadTemplates();
      }
    } else {
      setData({});
    }
  }, [values]);

  function handleSubmit() {
    if (data.id !== undefined) {
      FolderUpdate(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    } else {
      FolderInsert(data, (res) => {
        toast.success("Salvo com sucesso");
        onHide();
      });
    }
  }

  function loadTemplates() {
    TemplateList(values.id, (res) => setTemplates(ProcessDates(res)));
  }

  function handleChange(evt) {
    let old = data;
    let newData = { [evt.target.name]: evt.target.value };

    setData({ ...old, ...newData });
  }

  function downloadTemplate(item) {
    window.open(item.url, "_blank");
  }

  function removeTemplate(item) {
    if (window.confirm(DeleteConfirm)) {
      TemplateDelete(item.id, (res) => {
        toast.success("Removido com sucesso");
        loadTemplates();
      });
    }
  }

  function uploadTemplates(files) {
    var formData = new FormData();
    for (let s in files) {
      formData.append("file", files[s]);
    }

    TemplateUpload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      loadTemplates();
    });
  }

  function onUpload() {
    inputFile.current.click();
  }

  return (
    <Modal
      title="Pasta"
      show={show}
      onHide={onHide}
      onSave={handleSubmit}
      onSubmit={() => handleSubmit()}
    >
      <Tabs>
        <div class="row">
          {data && data.id !== undefined ? (
            <div class="col-sm-12">
              <Tab>
                <button className="btn btn-dual m-3">Pasta</button>
              </Tab>
              <Tab>
                <button className="btn btn-dual m-3">Templates</button>
              </Tab>
            </div>
          ) : (
            ""
          )}
          <Panel>
            <div class="col-sm-12">
              <div class="form-group">
                <label for="example-masked-time">Nome da pasta</label>
                <input
                  type="text"
                  class="form-control"
                  value={data && data.name}
                  name="name"
                  defaultValue={data && data.name}
                  onChange={handleChange}
                />
              </div>
              <div class="form-group">
                <label for="example-masked-time">Descrição</label>
                <input
                  type="text"
                  class="form-control"
                  name="description"
                  defaultValue={data && data.description}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Panel>
          <Panel>
            <div className="col-md-12">
              <div className="block block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Templates</div>
                  <div className="block-tolls">
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() => onUpload()}
                    >
                      <i className="si si-cloud-upload" /> Adicionar
                    </button>
                  </div>
                </div>

                <div className="block-content">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Id</td>
                        <td>Nome</td>
                        <td>Ações</td>
                      </tr>
                    </thead>
                    <tbody>
                      {templates &&
                        templates.map((item) => (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>
                              <button
                                className="btn btn-sm btn-dual"
                                onClick={() => downloadTemplate(item)}
                              >
                                <i className="si si-cloud-download" />
                              </button>
                              <button
                                className="btn btn-sm btn-dual"
                                onClick={() => removeTemplate(item)}
                              >
                                <i className="si si-close text-danger" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </Tabs>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFile}
        onChange={(event) => {
          uploadTemplates(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
    </Modal>
  );
}

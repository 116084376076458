import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ComunicationController = {
  BudgetWhats: async function (processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `comunication/budget-whatsapp?processId=${processId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  ExigenciesWhats: async function (organListId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `comunication/exigencies-whatsapp?organListId=${organListId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  DeferredWhats: async function (organListId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `comunication/deferred-whatsapp?organListId=${organListId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
};

export const TypeFields = {
  Email: 1,
  Mask: 2,
  Text: 3,
  Select: 4,
  TypeAhead: 5,
  Date: 6,
  Currency: 7,
  Number: 9,
};

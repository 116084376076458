import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  DateTranslate,
  DateTranslateSimple,
  OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";
import React, { useEffect, useState } from "react";
import DeferredItem from "./elements/DeferredItem";
import OrganDevolutionItem from "./elements/OrganDevolutionItem";
import OrganExigencyItem from "./elements/OrganExigencyItem";
import ProtocolsItem from "./elements/ProtocolsItem";
import Storage from "@/app/core/util/Storage";
import { DevolutionStepUser } from "@/app/core/base/controllers/DevolutionStepController";
import ProcessDeferredModal from "@/app/core/layouts/main-components/process/ProcessDeferredModal";
import { SendDeferredToCustomer } from "@/app/core/base/controllers/ContactController";
import { processObservations } from "@/app/core/util/helpers/TasksHelper";
import JobsController from "@/app/core/base/controllers/JobsController";
import { toast } from "react-toastify";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";

export default function DevolutionPanel({ data, reload, setView, loading }) {
  const [tasks, setTasks] = useState([]);
  const [disclaimer, setDisclaimer] = useState(null);
  const [devolutionNotStarted, setDevolutionNotStarted] = useState(false);
  const [devolutionFiled, setDevolutionFiled] = useState(false);
  const [devolutionDevolution, setDevolutionDevolution] = useState(false);
  const [devolutionExigency, setDevolutionExigency] = useState(false);
  const [devolutionDeferred, setDevolutionDeferred] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [query, setQuery] = useState("");
  const [done, setDone] = useState(false);
  const [doneList, setDoneList] = useState([]);
  const [doneRaw, setDoneRaw] = useState([]);

  const [openNotStarted, setOpenNotStarted] = useState(true);
  const [openFiled, setOpenFiled] = useState(true);
  const [openDevolution, setOpenDevolution] = useState(true);
  const [openDeferreds, setOpenDeferreds] = useState(true);
  const [openExigency, setOpenExigency] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [organ, setOrgan] = useState({});

  const userData = Storage.getUserData();
  const token = Storage.getUserToken();
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    console.log("infos", data);
    if (data) {
      setTasks(data);
      let dones = [];

      for (let s in data.done) {
        let item = data.done[s];
        // item.processName = item.process.processName;
        // item.serviceName = item.selectedService.serviceName;
        // item.organName = item.organ.name;
        item.endDevolutionDate = DateTranslate(item.endDevolutionDate);
        dones.push(item);
      }
      setDoneList(dones);
      setDoneRaw(dones);
    }

    if (userData !== null) {
      verifyUserState();
    }
  }, [data]);

  function verifyUserState() {
    if (userData.devolutionSteps === undefined) {
      DevolutionStepUser(userData.id, (res) => {
        console.log("Resposta", res);
        if (res.length === 0) {
          setDisclaimer(
            "Não foi atribuído um papel para o seu usuário, entre em contato com seu supervisor.",
          );
        }
      });
    } else {
      processSteps(userData);
    }
  }

  function processSteps(item) {
    const devolutionSteps = item.devolutionSteps;
    let rawNotStarted = devolutionSteps.filter((x) => x.step === 0);
    let rawFiled = devolutionSteps.filter((x) => x.step === 1);
    let rawDevolution = devolutionSteps.filter((x) => x.step === 2);
    let rawExigency = devolutionSteps.filter((x) => x.step === 3);
    let rawDeferred = devolutionSteps.filter((x) => x.step === 4);
    let rawDone = devolutionSteps.filter((x) => x.step === 6);

    if (rawNotStarted.length > 0) {
      setDevolutionNotStarted(true);
    }
    if (rawFiled.length > 0) {
      setDevolutionFiled(true);
    }
    if (rawDevolution.length > 0) {
      setDevolutionDevolution(true);
    }
    if (rawExigency.length > 0) {
      setDevolutionExigency(true);
    }
    if (rawDeferred.length > 0) {
      setDevolutionDeferred(true);
    }
    if (rawDone.length > 0) {
      setDone(true);
    }
  }

  async function filed(item) {
    if (window.confirm("Deseja continuar?")) {
      await jobsDone.Insert(item.processId, item.id, (res) => {});
      await organCtrl.Update({ ...item, status: 1 }, async (res) => {
        await NotifyUpdate(
          item.processId,
          `Enviado ao Órgão –  Em breve o número de protocolo estará disponível`,
          true,
          TimelineChannelSistema,
          item.id,
        );
        reload();
      });
    }
  }

  async function ReSendEmail(item) {
    if (window.confirm("Tem certeza de que deseja reenviar esse item?")) {
      await SendDeferredToCustomer(
        { email: item.responsible, firstName: item.responsibleName },
        item.processName,
        item.organName,
        item.id,
      );
    }
  }

  function openDeferred(organId) {
    setShowModal(true);
    setOrgan(organId);
  }

  function handleChange(value) {
    setQuery(value);
    let organs_to_show = [];

    if (value.length > 0) {
      setDoneList([]);
      organs_to_show = autocomplete(value);
      setDoneList(organs_to_show);
    } else {
      organs_to_show = [];
      setDoneList([]);
    }
    if (value.length === 0) {
      setDoneList(doneRaw);
    }
  }

  function autocomplete(value) {
    const inputValue = value.trim().toLowerCase();
    let itens = doneRaw;
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const processName =
        item.processName !== undefined ? item.processName.toLowerCase() : "";
      const serviceName =
        item.serviceName !== undefined ? item.serviceName.toLowerCase() : "";
      const organName =
        item.organName !== undefined ? item.organName.toLowerCase() : "";
      const createdDate =
        item.createdDate !== undefined ? item.createdDate.toLowerCase() : "";
      const updatedDate =
        item.updatedDate !== undefined ? item.updatedDate.toLowerCase() : "";
      const endDevolutionDate =
        item.endDevolutionDate !== undefined
          ? item.endDevolutionDate.toLowerCase()
          : "";
      if (
        processName.includes(inputValue) ||
        serviceName.includes(inputValue) ||
        organName.includes(inputValue) ||
        updatedDate.includes(inputValue) ||
        endDevolutionDate.includes(inputValue) ||
        createdDate.includes(inputValue)
      ) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  function refresh() {
    toast.info("Carregando...");
    reload();
  }

  return (
    <div>
      {disclaimer !== null ? (
        <div className="text-center">{disclaimer}</div>
      ) : (
        ""
      )}
      {devolutionNotStarted === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.ongoing && data.ongoing.length} A protocolar
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenNotStarted(!openNotStarted)}
              >
                <i
                  className={`si si-arrow-${
                    openNotStarted === true ? "up" : "down"
                  }`}
                ></i>
              </button>
            </div>
          </div>
          {openNotStarted && openNotStarted === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <table className="table table-borderless table-striped table-hover table-vcenter">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      <td>Serviço</td>
                      <td>Órgão</td>
                      <td>Obs</td>
                      <td>Data de início</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.ongoing !== undefined
                      ? data.ongoing.map((item) => (
                          <tr key={`ongoing-${item.id}`}>
                            <td>{item.id}</td>
                            <td>{item.processName}</td>
                            <td>{item.serviceName}</td>
                            <td>{item.organName}</td>
                            <td>{item && processObservations(item)}</td>
                            <td>
                              {item && DateTranslate(item.endPreparationDate)}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-dual"
                                onClick={() => filed(item)}
                              >
                                Protocolado
                              </button>
                              <button
                                className="btn btn-sm btn-dual"
                                onClick={() =>
                                  setView({
                                    data: {
                                      ...item.process,
                                      id: item.processId,
                                      organListId: item.id,
                                    },
                                  }) | setOrgan(item.id)
                                }
                              >
                                <i className="far fa-eye" />
                              </button>
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {devolutionFiled === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.filed && data.filed.length} Protocolados
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenFiled(!openFiled)}
              >
                <i
                  className={`si si-arrow-${
                    openFiled === true ? "up" : "down"
                  }`}
                ></i>
              </button>
            </div>
          </div>
          {openFiled && openFiled === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <table className="table table-borderless table-striped table-hover table-vcenter">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      <td>Serviço</td>
                      <td>Órgão</td>
                      <td>Nº de protocolo</td>
                      <td>Obs</td>
                      <td>Data fin. Preparação</td>
                      <td>Data de início</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.filed !== undefined
                      ? data.filed.map((item) => {
                          console.log("files", item);
                          return (
                            <ProtocolsItem
                              data={item}
                              key={item.id}
                              open={(i) => setView(i)}
                              reload={() => reload()}
                            />
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {devolutionDevolution === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.devolution && data.devolution.length} Aguardando Devolução
              do Órgão
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenDevolution(!openDevolution)}
              >
                <i
                  className={`si si-arrow-${
                    openDevolution === true ? "up" : "down"
                  }`}
                ></i>
              </button>
            </div>
          </div>
          {openDevolution && openDevolution === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <table className="table table-borderless table-striped table-hover table-vcenter">
                  <thead>
                    <tr>
                      {/* <td>Id</td> */}
                      <td>Processo</td>
                      <td>Serviço</td>
                      <td>Órgão</td>
                      <td>Protocolo</td>
                      <td>Obs</td>
                      <td>Data do protocolo</td>
                      <td>Data de início</td>
                      <td>Ações</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.devolution !== undefined
                      ? data.devolution.map((item) => (
                          <OrganDevolutionItem
                            data={item}
                            reload={() => reload()}
                            open={(i) =>
                              setView({
                                data: {
                                  id: item.processId,
                                  organListId: item.id,
                                },
                              })
                            }
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {devolutionDeferred === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.deferred && data.deferred.length} Deferidos
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenDeferreds(!openDeferreds)}
              >
                <i
                  className={`si si-arrow-${
                    openDeferreds === true ? "up" : "down"
                  }`}
                ></i>
              </button>
            </div>
          </div>
          {openDeferreds && openDeferreds === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <table className="table table-borderless table-striped table-hover table-vcenter">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      <td>Serviço</td>
                      <td>Órgão</td>
                      <td>Protocolo</td>
                      <td>Obs</td>
                      <td>Data do protocolo</td>
                      <td>Data de início</td>
                      <td>Status E-mail</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.deferred !== undefined
                      ? data.deferred.map((item) => (
                          <DeferredItem
                            data={item}
                            open={(i) => openDeferred(item)}
                            reload={() => reload()}
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {devolutionExigency === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.exigency && data.exigency.length} Exigências
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenExigency(!openExigency)}
              >
                <i
                  className={`si si-arrow-${
                    openExigency === true ? "up" : "down"
                  }`}
                ></i>
              </button>
            </div>
          </div>
          {openExigency && openExigency === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <table className="table table-borderless table-striped table-hover table-vcenter">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      <td>Serviço</td>
                      <td>Órgão</td>
                      <td>Status</td>
                      <td>Obs</td>
                      <td>Data do protocolo</td>
                      <td>Data de início</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.exigency !== undefined
                      ? data.exigency.map((item) => (
                          <OrganExigencyItem
                            data={item}
                            open={(i) => setView(i)}
                            reload={() => reload()}
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {done === true ? (
        <div className="block block-fx-pop">
          <div className="block-header">
            <div className="block-title">
              {data.done && data.done.length} Concluídos
            </div>
            <div className="block-tools">
              <button className="btn btn-sm btn-dual" onClick={() => refresh()}>
                <i className={`si si-refresh`}></i>
              </button>
              <button
                className="btn btn-sm btn-dual"
                onClick={() => setOpenDone(!openDone)}
              >
                <i
                  className={`si si-arrow-${openDone === true ? "up" : "down"}`}
                ></i>
              </button>
            </div>
          </div>
          {openDone === true ? (
            <div className="block-content">
              {loading === true ? (
                <div className="text-center">
                  <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                </div>
              ) : (
                <div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control form-control-alt"
                      placeholder="Pesquisar..."
                      onChange={(evt) => handleChange(evt.target.value)}
                    />
                  </div>
                  {/* <Table
                    title="Concluídos"
                    loading={loading}
                    hideHeader={true}
                    data={doneList}
                    columns={OrganListDoneCols}
                    refreshData={() => reload()}
                    insertAction={() => null}
                    hide={false}
                    setFilter={(baseFilter) =>
                      Functions.ProcessFilter(baseFilter, doneList)
                    }
                    filter={tableFilter}
                    viewAction={(selected) =>
                      setView({ data: selected.data.process })
                    }
                  /> */}

                  <table className="table table-borderless table-striped table-hover table-vcenter">
                    <thead>
                      <tr>
                        <td>Id</td>
                        <td>Processo</td>
                        <td>Serviço</td>
                        <td>Órgão</td>
                        <td>Protocolo</td>
                        <td>Data de conclusão</td>
                        <td>Data de início</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.done !== undefined
                        ? data.done.map((item) => (
                            <tr key={`done-${item.id}`}>
                              <td>{item.id}</td>
                              <td>{item && item.processName}</td>
                              <td>{item && item.serviceName}</td>
                              <td>{item && item.organName}</td>
                              <td>{item && item.protocol}</td>
                              <td>{item && item.endDevolutionDate}</td>
                              <td>
                                {item && DateTranslate(item.endPreparationDate)}
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-dual"
                                  onClick={() =>
                                    setView({
                                      data: {
                                        id: item.processId,
                                        organListId: item.id,
                                      },
                                    })
                                  }
                                >
                                  <i className="far fa-eye" />
                                </button>
                                <button
                                  className="btn btn-sm btn-dual"
                                  onClick={() => ReSendEmail(item)}
                                >
                                  <i className="si si-envelope-letter" />{" "}
                                  Reenviar email
                                </button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <ProcessDeferredModal
        show={showModal}
        onHide={() => setShowModal(false) | reload()}
        organList={organ}
      />
    </div>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function EvaluationInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: `evaluation/insert`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function EvaluationUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: `evaluation/update`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function EvaluationDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `evaluation/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function EvaluationList(processId, response) {
  BaseService(
    {
      method: "GET",
      url: `evaluation/process/${processId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function EvaluationListByOrgan(organListId, response) {
  BaseService(
    {
      method: "GET",
      url: `evaluation/organlist/${organListId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function EvaluationListByDate(startDate, endDate, response) {
  BaseService(
    {
      method: "GET",
      url: `evaluation/?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

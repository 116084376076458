import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";

export default function AttachmentController() {
  async function Upload(formData, timelineId, processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `attachment/upload?timelineId=${timelineId}&processId=${processId}`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function Update(infos, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "attachment/update",
        data: infos,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function List(organListId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `attachment/organListId/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function Delete(docId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `attachment/delete/${docId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  return {
    Upload,
    Update,
    List,
    Delete,
  };
}

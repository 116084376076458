export default function RemunerationHelper() {
  const remunerationCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Departamento", isSortable: true, name: "levelName" },
    { header: "Valor base", isSortable: true, name: "basePrice" },
    { header: "Valor por precisão", isSortable: true, name: "priceRight" },
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  return {
    remunerationCols,
  };
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function OrganListController() {
  async function OrganInsert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organ/insert`,
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function OrganUpdate(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organ/update`,
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function StartOrStop(organId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/playstop/${organId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DistributeOperators(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/distribute-operators`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ReturnProcess(organListId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/return/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function OrganDelete(id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `organ/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error("Erro ao deletar o item: " + err);
      },
    );
  }

  async function OrgansSelect(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organ`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Insert(organ, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "organlist/insert",
        data: organ,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function InsertList(organ, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "organlist/register",
        data: organ,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(organ, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/update`,
        data: organ,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delivered(organListId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/delivered/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateList(organs, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/update/order`,
        data: organs,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reprocess(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/reprocess`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ReprocessOne(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/reprocess-one/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reavaliation(
    organListId,
    description,
    department,
    status,
    type,
    preparationStage,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/reavaliation/${organListId}?description=${description}&department=${department}&status=${status}&type=${type}&preparationStage=${preparationStage}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `organlist/delete/${id}`,
      },
      (res) => {
        toast.info("Removido com sucesso");
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SelectByService(selectedServiceId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/selectedservice/${selectedServiceId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SelectById(organListId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SelectByCustomer(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/customer/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListByStage(stage, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/department/${stage}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListByStatus(status, customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/status/${status}?customerId=${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListByProcess(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/process/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListStatusTab(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/statustab/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function EndOrgan(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/endorgan/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendPreparation(organListId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/analysis/end/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendPreparationReview(organListId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/preparation/send-review/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendDevolution(organList, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/preparation/end/`,
        data: organList,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ReturnExigency(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/exigency/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendDeferredEmail(organListId, sendEmail, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/deferred/email/${organListId}?sendEmail=${sendEmail}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        if (error === undefined) {
          toast.error(HandleErrors(err));
        } else {
          error(err);
        }
      },
    );
  }

  async function InternalFeedback(
    organListId,
    internalFeedback,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/internal/feedback/${organListId}/?internalFeedback=${internalFeedback}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Urgent(processId, isUrgent, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/urgent/${processId}/?urgent=${isUrgent}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerReturn(organListId, customerReturn, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/customer/return/${organListId}/?customerReturn=${customerReturn}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reavaliations(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/reavaliations/user/${userId}/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DoneByDate(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `organlist/done/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SendDeferredCustomEmail(
    organListId,
    sendEmail,
    mail,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/deferred/email/${organListId}?sendEmail=${sendEmail}&email=${mail}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Redirect(organListId, userId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `organlist/redirect/user/${userId}/${organListId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Redirect,
    OrganInsert,
    OrganUpdate,
    OrganDelete,
    OrgansSelect,
    Reavaliations,
    UpdateList,

    Insert,
    InsertList,
    SendPreparation,
    SendDevolution,
    Update,
    Delete,
    SelectByService,
    SelectById,
    SelectByCustomer,
    ListByStage,
    ListByStatus,
    ListByProcess,
    EndOrgan,
    ReturnExigency,
    SendDeferredEmail,
    SendDeferredCustomEmail,
    Reavaliation,
    StartOrStop,
    InternalFeedback,
    CustomerReturn,
    Urgent,
    DistributeOperators,
    SendPreparationReview,
    Reprocess,
    DoneByDate,
    Delivered,
    ListStatusTab,
    ReturnProcess,
    ReprocessOne,
  };
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function SaveProcessReturn(infos, response) {
  BaseService(
    {
      method: "POST",
      url: `processreturn/insert`,
      data: infos,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function UpdateProcessReturn(infos, response) {
  BaseService(
    {
      method: "POST",
      url: `processreturn/update`,
      data: infos,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function RemoveProcessReturn(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `processreturn/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function GetProcessReturnByProcessId(id, response) {
  BaseService(
    {
      method: "GET",
      url: `processreturn/process/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function GetProcessReturnByServiceId(id, response) {
  BaseService(
    {
      method: "GET",
      url: `processreturn/selectedService/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export function GetProcessReturnByOrganListId(id, response) {
  BaseService(
    {
      method: "GET",
      url: `processreturn/organList/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

export async function ReturnsByUser(userId, startDate, endDate, response) {
  BaseService(
    {
      method: "GET",
      url: `processreturn/user/${userId}?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.info(HandleErrors(err));
    },
  );
}

import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import MegaViewer from "./MegaViewer";

export default function ModalDoc({ show, onHide, isLoading, doc }) {
  useEffect(() => {}, [show, doc]);

  return (
    <CustomModal
      title="Visualizador"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
    >
      <MegaViewer doc={doc} size={640} />
    </CustomModal>
  );
}

import DocsController from "@/app/core/base/controllers/DocumentsController";
import { DocHelper } from "@/app/core/util/helpers/DocsHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function AddExistingDoc({ process, organListId, reload }) {
  const [docs, setDocs] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (process && process.docs) {
      setDocs(
        process.docs.filter((x) => x.status == DocHelper.Status.MasterDoc),
      );
    }
  }, [process, organListId]);

  function saveNewFile() {
    if (!selected) {
      toast.error(Message.PendingData);
    } else {
      DocsController().AddAsMaster(organListId, [selected], (res) => {
        toast.success(Message.Saved);
        reload();
      });
    }
  }

  return (
    <div className="form-group d-flex">
      <select
        className="form-control form-control-sm"
        onChange={(evt) => setSelected(parseInt(evt.target.value))}
      >
        <option value={null}>Selecionar documento</option>
        {docs &&
          docs.map((item) => <option value={item.id}>{item.name}</option>)}
      </select>
      <button
        className="btn btn-sm ml-2  btn-info"
        onClick={() => saveNewFile()}
      >
        Adicionar
      </button>
    </div>
  );
}

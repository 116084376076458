import React from "react";

export default function DocButton({
  style,
  action,
  data,
  icon,
  title,
  disabled,
}) {
  return (
    <button
      className={`btn btn-sm btn-square btn-dual ${style}`}
      type="button"
      title={title}
      onClick={() => action(data)}
      disabled={disabled}
    >
      <i className={icon}></i> {title}
    </button>
  );
}

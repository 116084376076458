import { XAxis, AreaChart, Area, Tooltip, ResponsiveContainer } from "recharts";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import React, { useEffect, useState } from "react";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function ServicesReport({ startDate, endDate }) {
  const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
  const [services, setServices] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [hidenServices, setHidenServices] = useState([]);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [invoicing, setInvoicing] = useState([]);
  const [volume, setVolume] = useState([]);
  const [openHiden, setOpenHiden] = useState(false);
  const cashFlow = CashFlowController();
  const report = ReportController();
  const reportHelper = ReportHelper();

  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    loadServices();
  }, [startDate, endDate]);

  useEffect(() => {
    notifyChange();
  }, [invoicing, ticket]);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadServices() {
    cashFlow.ServiceReport(startDate, endDate, (res) => {
      let total = 0,
        ticket = 0,
        count = 0;
      for (let s in res) {
        total = total + res[s].value;
        ticket = ticket + res[s].value / res[s].selectedServices.length;
        count = count + res[s].selectedServices.length;
      }

      for (let s in res) {
        res[s].percentage = (res[s].value / total) * 100;
      }

      res.sort(function (a, b) {
        return b.percentage - a.percentage;
      });

      setData({
        total,
        ticket: ticket / res.length,
        count,
      });
      setServices(res.slice(0, 10));
      setHidenServices(res.slice(10, res.length - 1));

      processData(res);
    });
  }

  function processInvoicing(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].faturamento = MoneyMask(res[i].result, 2);
        }
      }
    }

    setInvoicing(responses);
  }

  function processVolumes(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].volume = res[i].result;
        }
      }
    }

    setVolume(responses);
  }

  function processTickets(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].ticket = MoneyMask(res[i].result, 2);
        }
      }
    }

    setTicket(responses);
  }

  function processAccounts(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].contas = res[i].result;
        }
      }
    }

    setActiveAccounts(responses);
  }

  function processData(res) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 30) {
      processMonthlyData(res);
    } else {
      processDailyData(res);
    }
  }

  function processMonthlyData(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        responses[s][res[i].name] = 0;
      }
    }

    for (let s in res) {
      const itemBase = res[s];
      const price =
        itemBase.value /
        itemBase.selectedServices.filter((x) => x.price !== 0).length;

      for (let x in res[s].selectedServices) {
        const item = res[s].selectedServices[x];
        if (item.price !== 0) {
          const date = new Date(item.process.endBudgetDate);
          const name = reportHelper.monthNames[date.getMonth()];
          const results = responses.filter((x) => x.name === name);
          if (results.length > 0) {
            for (let i in responses) {
              if (responses[i].name === name) {
                responses[i][itemBase.name] =
                  responses[i][itemBase.name] + price;
              }
            }
          }
        }
      }
    }
    setEarnings(responses);
  }

  function processDailyData(res) {
    let responses = [];

    for (let s in res) {
      for (let x in res[s].selectedServices) {
        const item = res[s].selectedServices[x];
        const date = new Date(item.createdDate);
        const name = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;

        let exist = responses.filter((x) => x.name === name);
        if (exist.length === 0) {
          responses.push({ name });
        }
      }
    }

    responses.sort(function (a, b) {
      return a.name - b.name;
    });

    for (let s in responses) {
      for (let i in res) {
        responses[s][res[i].name] = 0;
      }
    }

    for (let s in res) {
      const itemBase = res[s];
      const price = itemBase.value / itemBase.selectedServices.length;

      for (let x in res[s].selectedServices) {
        const item = res[s].selectedServices[x];
        const date = new Date(item.createdDate);
        const name = `${date.getDate()}/${
          date.getMonth() + 1 < 10
            ? date.getMonth() + 1
            : `0${date.getMonth() + 1}`
        }/${date.getFullYear()}`;

        const results = responses.filter((x) => x.name === name);
        if (results.length > 0) {
          for (let i in responses) {
            if (responses[i].name === name) {
              responses[i][itemBase.name] = responses[i][itemBase.name] + price;
            }
          }
        }
      }
    }

    setEarnings(responses);
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="block block-fx-pop" style={{ width: 350 }}>
          <div className="block-content">
            <div className="row">
              {payload.map((item) => (
                <p className="col-md-12">
                  <i
                    class="fa fa-circle mr-2"
                    style={{
                      color: item.color,
                    }}
                  ></i>
                  {`${item.name} : ${item.value}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="block block-tranparent">
          <div className="block-header">
            <div className="block-title">Faturamento por serviço</div>
          </div>
          <div className="block-content">
            <div className="row">
              {services &&
                services.map((item) => (
                  <div className="col-md-3" key={item.id + "-service"}>
                    <div class="mb-0">
                      <div class="progress mb-1" style={{ height: 6 }}>
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{ width: `${parseInt(item.percentage)}%` }}
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="font-size-sm font-w600 mb-3 row">
                        <span class="font-w700 col-md-6">
                          {item.name} - {parseInt(item.percentage)}% | total:{" "}
                          {item.selectedServices &&
                            item.selectedServices.length}
                        </span>
                        <span class="font-w700  col-md-6 text-right">
                          R${MoneyMask(item.value, 2)}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              {hidenServices &&
                hidenServices.map((item) => (
                  <div className="col-md-3">
                    <div class="mb-0">
                      <div class="progress mb-1" style={{ height: 6 }}>
                        <div
                          class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                          role="progressbar"
                          style={{ width: `${parseInt(item.percentage)}%` }}
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p class="font-size-sm font-w600 mb-3 row">
                        <span class="font-w700 col-md-6">
                          {item.name} - {parseInt(item.percentage)}% | total:{" "}
                          {item.selectedServices &&
                            item.selectedServices.length}
                        </span>
                        <span class="font-w700  col-md-6 text-right">
                          R${MoneyMask(item.value, 2)}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main";
import Storage from "@/app/core/util/Storage";

const EmptyRouter = (props) =>
  Storage.isAuthenticated() ? (
    <Redirect to="/app" />
  ) : (
    <div {...props}>{renderRoutes(props.route.routes, props)}</div>
  );

const RegisterRouter = () => ({
  path: "/register",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "/register",
      component: MainComponent,
    },
  ],
});

export default RegisterRouter();

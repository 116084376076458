import React from "react";

const cols = [
  { header: "#ID", isSortable: true, name: "id" },
  { header: "Processo", isSortable: true, name: "processName" },
  { header: "Cliente", isSortable: true, name: "customerName" },
  {
    header: "Status",
    isSortable: true,
    name: "budgetStatus",
    switch: {
      0: <span class="badge badge-info">Não iniciado</span>,
      1: <span class="badge badge-primary">Aguardando orçamento</span>,
      2: <span class="badge badge-success">Confirmado</span>,
      3: <span class="badge badge-danger">Recusado</span>,
      4: <span class="badge badge-warning">Retornar ao cliente</span>,
      5: <span class="badge badge-info">Aguardando Aprovação</span>,
      6: <span class="badge badge-danger">Reavaliação</span>,
      9: <span class="badge badge-dark">Removido</span>,
    },
    default: <span class="badge badge-primary">Aguardando orçamento</span>,
  },
  {
    header: "Obs",
    isSortable: false,
    name: "observations",
  },
  { header: "Criado em", isSortable: true, name: "createdDate" },
];

const customerBudgetCols = [
  { header: "#ID", isSortable: true, name: "id" },
  { header: "Processo", isSortable: true, name: "processName" },
  {
    header: "Status",
    isSortable: true,
    name: "budgetStatus",
    switch: {
      0: <span class="badge badge-info">Não iniciado</span>,
      1: <span class="badge badge-primary">Aguardando orçamento</span>,
      2: <span class="badge badge-success">Confirmado</span>,
      3: <span class="badge badge-danger">Recusado</span>,
      4: <span class="badge badge-warning">Retornar ao cliente</span>,
      5: <span class="badge badge-info">Aguardando Aprovação</span>,
      6: <span class="badge badge-info">Aguar. Conclusão de processo </span>,
      9: <span class="badge badge-dark">Removido</span>,
    },
  },
  { header: "Solicitado", isSortable: true, name: "createdDate" },
];

const userBudgetCols = [
  { header: "#ID", isSortable: true, name: "id" },
  { header: "Processo", isSortable: true, name: "processName" },
  {
    header: "Status",
    isSortable: true,
    name: "budgetStatusName",
    switch: {
      "Aguardando Orçamento": (
        <span class="badge badge-primary">Aguardando orçamento</span>
      ),
      Recusado: <span class="badge badge-danger">Recusado</span>,
      "Aguardando aprovação": (
        <span class="badge badge-warning">Aguardando Aprovação</span>
      ),
    },
  },
  { header: "Solicitado", isSortable: true, name: "createdDate" },
];

const customerProcessCols = [
  { header: "#ID", isSortable: true, name: "id" },
  { header: "Processo", isSortable: true, name: "processName" },
  {
    header: "Status",
    isSortable: true,
    name: "status",
    switch: {
      0: <span class="badge badge-info">Não iniciado</span>,
      1: <span class="badge badge-primary">Em andamento</span>,
      2: <span class="badge badge-success">Concluído</span>,
      3: <span class="badge badge-danger">Atrasado</span>,
      4: <span class="badge badge-warning">Aguardando Exigências</span>,
    },
  },
  { header: "Solicitado", isSortable: true, name: "createdDate" },
];

export { cols, customerBudgetCols, customerProcessCols, userBudgetCols };

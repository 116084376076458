import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function JobsController() {
  async function Insert(processId, organListId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `jobs/register/?processId=${processId}&organListId=${organListId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function WrongReport(
    processId,
    organListId,
    level,
    devolutionStep,
    track,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `jobs/done/wrong/?processId=${processId}&organListId=${organListId}&level=${level}&devolutionStep=${devolutionStep}&track=${track}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `jobs/user/${userId}/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function Report(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `jobs/report/user/${userId}/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Productivity(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `jobs/productivity/?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    List,
    WrongReport,
    Report,
    Productivity,
  };
}

import React, { useState, useEffect } from "react";
import Table from "@/app/core/components/table/";
import { FolderList } from "@/app/core/base/controllers/FoldersController";
import { TemplateList } from "@/app/core/base/controllers/TemplatesController";

export default function FolderTemplatesComponent(props) {
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState(null);
  const [folder, setFolder] = useState({});
  const [folderList, setFolderList] = useState(null);
  const cols = [
    { header: "Nome", name: "name" },
    { header: "Descrição", name: "description" },
  ];

  useEffect(() => {
    if (folderList === null) {
      getFolders();
    } else {
      listDocuments();
    }
  }, [folder]);

  function getFolders() {
    FolderList((res) => {
      console.log("Folder", res);
      let newRes = res.sort(function (a, b) {
        var x = a.name.toLowerCase();
        var y = b.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });
      console.log("Folder", newRes, res);
      setFolderList(newRes);
    });
  }

  function listDocuments() {
    TemplateList(folder, (res) => {
      setData(res);
    });
  }

  function seeFile(item) {
    props.setView(item.url);
  }

  function download(item) {
    window.open(item.url, "_blank");
  }

  return (
    <div className="content">
      <div className="form-group">
        <span>Selecione a pasta</span>
        <select
          className="form-control"
          onChange={(evt) => setFolder(evt.target.value)}
        >
          <option value={null}>Selecione uma pasta</option>
          {folderList &&
            folderList.map((item) => (
              <option value={item.id}>{item.name}</option>
            ))}
        </select>
      </div>
      {data &&
        data.map((item) => (
          <div className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn">
            <table className="table table-borderless table-vcenter mb-0">
              <tbody>
                <tr>
                  <td className="js-task-content font-w600">{item.name}</td>
                  <td className="js-task-content font-w600">
                    {item.description}
                  </td>
                  <td className="text-right" style={{ width: 100 }}>
                    <button
                      onClick={() => seeFile(item)}
                      className="btn btn-sm btn-link text-primary"
                    >
                      <i className="fa fa-eye fa-fw"></i>
                    </button>
                    <button
                      onClick={() => download(item)}
                      className="btn btn-sm btn-link text-primary"
                    >
                      <i className="si si-cloud-download"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
}

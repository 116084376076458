import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Storage from "@/app/core/util/Storage";
import ServiceShowCase from "@/app/core/layouts/main-components/services/ServiceShowCase";

export default function MainCustomer(props) {
  const [loading] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <Loading loading={loading} />
        <div className="row py-3">
          <div className="col-lg-12 col-md-12 col-md-12">
            <ServiceShowCase />
          </div>
        </div>
      </div>
    </>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function AutomationsController() {
  async function DistributeProcess(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/distribute-process`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function CheckContracts(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/consult-contracts`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function CashFlow(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/cashflow`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function EndNotFullfilment(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/automatic-end-exigencies`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function ServicesWithout(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/services-without`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function EndProcess(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/endprocess`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function DevolutionResend(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/devolution-resend`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function AdjustOrgans(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/adjustorgans`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function RepairService(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/repair-service`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function DistributeService(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/distribute-service`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function DistributeOrgans(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/distribute-organs`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function AutomaticResend(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/automatic-resend`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Budgets(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/budgets`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomersLevel(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/customers`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reminders(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `automations/reminder`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    DistributeProcess,
    RepairService,
    DistributeService,
    DistributeOrgans,
    AutomaticResend,
    CheckContracts,
    EndProcess,
    AdjustOrgans,
    CashFlow,
    Budgets,
    CustomersLevel,
    DevolutionResend,
    EndNotFullfilment,
    ServicesWithout,
    Reminders,
  };
}

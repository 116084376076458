import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import React from "react";

export async function GetProcessRaw(id) {
  let response;
  await BaseService(
    {
      method: "GET",
      url: `process?processId=${id}&`,
    },
    (res) => {
      response = res;
    },
    (err) => {
      response = err;
      toast.error("Não foi possivel carregar o processo");
    },
  );
  return response;
}

export async function GetProcessReturn(id) {
  let response;
  await BaseService(
    {
      method: "GET",
      url: `process-return?processId=${id}&`,
    },
    (res) => {
      response = res;
    },
    (err) => {
      toast.error("Não foi possivel carregar o processo");
    },
  );
  return response;
}

export default function ProcessHelper() {
  const Status = {
    NotStarted: 0,
    Ongoing: 1,
    Done: 2,
    Late: 3,
    Deleted: 9,
  };

  const StatusList = [
    { label: "NotStarted", value: 0, name: "Não iniciado" },
    { label: "Ongoing", value: 1, name: "Em andamento" },
    { label: "Done", value: 2, name: "Concluído" },
    { label: "Late", value: 3, name: "Atrasado" },
    { label: "Deleted", value: 9, name: "Removido" },
  ];

  function StatusTranslate(sts) {
    const result = StatusList.filter((x) => x.value === sts);

    if (result.length > 0) {
      return result[0];
    } else {
      return null;
    }
  }

  function GetCurrentOrgan(orgaId, process) {
    for (let s in process.selectedServices) {
      const service = process.selectedServices[s];

      const organ = service.organList.filter((x) => x.id == parseInt(orgaId));
      if (organ.length > 0) {
        return organ[0];
      }
    }
  }

  const ContactPreference = {
    Both: 0,
    Email: 1,
    WhatsApp: 2,
  };

  const ReimbursementType = {
    None: 0,
    Erro: 1,
    Desistencia: 2,
    Alteracao: 3,
  };

  const ContactList = [
    { name: "Email & WhatsApp", value: 0 },
    { name: "Email", value: 1 },
    { name: "WhatsApp", value: 2 },
  ];

  function StatusTicker(currentProcess) {
    if (
      currentProcess &&
      currentProcess.timeline &&
      currentProcess.timeline.length > 0
    ) {
      return <i className="fa fa-exclamation-circle text-danger ml-2" />;
    } else {
      return "";
    }
  }

  return {
    Status,
    StatusList,
    StatusTranslate,
    GetCurrentOrgan,
    ContactPreference,
    ContactList,
    ReimbursementType,
    StatusTicker,
  };
}

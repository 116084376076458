import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import {
  CustomerSelect,
  CustomerTimeline,
  InsertCustomerTimeline,
  UpdateCustomerStage,
} from "@/app/core/base/controllers/CustomerController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { Autocomplete } from "@/app/core/util/helpers/AutocompleteHelper";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import DropdownElement from "../elements/DropdownElement";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ChannelTranslate } from "@/app/core/util/StatusStates";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";

export default function CustomerTimelineModal({
  customerId,
  show,
  onHide,
  submit,
  isLoading,
}) {
  const [entry, setEntry] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [customer, setCustomer] = useState({});
  const [question, setQuestion] = useState("");

  useEffect(() => {
    if (show && customerId !== undefined && customerId !== null) {
      loadTimeline();
      loadCustomer();
    }
  }, [customerId, show]);

  function loadCustomer() {
    CustomerSelect(customerId, (res) => setCustomer(res));
  }

  function handleChange(evt, type) {
    setEntry(OnDataChange(entry, evt, type));
  }

  async function loadTimeline() {
    CustomerTimeline(customerId, (res) => {
      for (let s in res) {
        res[s].time = new Date(res[s].createdDate).getTime();
      }

      res.sort(function (a, b) {
        return b.time - a.time;
      });
      setData(res);
    });
  }

  function handleFilterChange(value) {
    setQuestion(value);
    let timeline_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      timeline_to_show = Autocomplete(value, data, "description");
      setFilter(timeline_to_show);
    } else {
      timeline_to_show = [];
      setFilter([]);
    }
  }

  function saveUpdate() {
    InsertCustomerTimeline(
      { ...entry, customerId: parseInt(customerId) },
      (res) => {
        loadTimeline();
      },
    );
  }

  function updateStage(customerId, stage) {
    UpdateCustomerStage(customerId, stage, (res) => {
      toast.success(Message.Saved);
      loadTimeline();
      loadCustomer();
    });
  }

  return (
    <Modal
      title="Timeline do cliente"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => null}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="form-group py-3">
            <div className="input-group">
              <input
                type="text"
                placeholder="Filtrar"
                className="form-control"
                id="search-filter"
                value={question}
                onChange={(evt) => handleFilterChange(evt.target.value)}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  onClick={() => setQuestion("") | setFilter([])}
                  title="Limpar campo"
                >
                  <i className="si si-close"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div className="text-center">
            <div
              className="btn-group mr-2 mb-2"
              role="group"
              aria-label="Outline Primary First group"
            >
              <button
                type="button"
                onClick={() =>
                  updateStage(customerId, CustomerHelper().Stage.Lead)
                }
                className={
                  customer.stage == CustomerHelper().Stage.Lead
                    ? "btn btn-primary"
                    : "btn btn-outline-primary"
                }
              >
                Lead
              </button>
              <button
                type="button"
                onClick={() =>
                  updateStage(customerId, CustomerHelper().Stage.Qualificado)
                }
                className={
                  customer.stage == CustomerHelper().Stage.Qualificado
                    ? "btn btn-info"
                    : "btn btn-outline-primary"
                }
              >
                Qualificado
              </button>
              <button
                type="button"
                onClick={() =>
                  updateStage(customerId, CustomerHelper().Stage.Convertido)
                }
                className={
                  customer.stage == CustomerHelper().Stage.Convertido
                    ? "btn btn-success"
                    : "btn btn-outline-primary"
                }
              >
                Convertido
              </button>
              <button
                type="button"
                onClick={() =>
                  updateStage(customerId, CustomerHelper().Stage.Perdido)
                }
                className={
                  customer.stage == CustomerHelper().Stage.Perdido
                    ? "btn btn-danger"
                    : "btn btn-outline-primary"
                }
              >
                Perdido
              </button>
            </div>
          </div>
          <DropdownElement
            submit={() => saveUpdate()}
            icon=""
            className="btn btn-dual mr-2"
            style={{ width: "100%", height: 350 }}
            title={"Adicionar informacao"}
          >
            <div className="form-group">
              <span>Qual o canal de contato?</span>
              <select
                name="channel"
                className="form-control"
                value={entry && entry.channel}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
              >
                <option value={1}>Email</option>
                <option value={2}>Whatsapp</option>
                <option value={3}>Chat</option>
                <option value={4}>Telefone</option>
                <option value={5}>Recepção</option>
              </select>
            </div>
            <div className="form-group">
              <span>O que foi tratado?</span>
              <textarea
                type="text"
                className="form-control"
                name="description"
                value={entry && entry.description}
                onChange={(evt) => handleChange(evt)}
              />
            </div>
          </DropdownElement>
        </div>
        <div className="col-md-12">
          {filter.length > 0 ? (
            <div className="col-md-12 py-3">
              {filter &&
                filter.map((item) => (
                  <a
                    className={
                      "block block-rounded block-link-shadow border-left border-5x js-appear-enabled animated fadeIn"
                    }
                    key={`filtered-${item.id}`}
                    data-toggle="appear"
                    href="javascript:void(0)"
                  >
                    <div className="block-header">
                      <div className="block-title">
                        {" "}
                        {item && item.user !== null && item.user !== undefined
                          ? `${item.user.firstName} | ${DepartmentTranslate(
                              item.user.level,
                            )} - `
                          : ""}
                        {item && ChannelTranslate(item.channel)}
                      </div>
                    </div>
                    <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                      <div>
                        <p className="text-muted mb-0">{item.description}</p>
                      </div>
                    </div>
                    <div className="block-content block-content-full block-content-sm bg-body-light">
                      <span className="font-size-sm text-muted">
                        <strong>{DateTranslate(item.createdDate)}</strong>
                      </span>
                    </div>
                  </a>
                ))}
            </div>
          ) : (
            <div className="col-md-12 py-3">
              {data &&
                data.map((item) => (
                  <a
                    className={
                      "block block-rounded block-link-shadow border border-5x js-appear-enabled animated fadeIn"
                    }
                    data-toggle="appear"
                    href="javascript:void(0)"
                  >
                    <div className="block-header">
                      <div className="block-title">
                        {" "}
                        {item.user !== null && item.user !== undefined
                          ? `${item.user.firstName} | ${DepartmentTranslate(
                              item.user.level,
                            )} - `
                          : ""}
                        {ChannelTranslate(item.channel)}
                      </div>
                    </div>
                    <div className="block-content block-content-full d-flex align-items-center justify-content-between">
                      <div>
                        <p className="text-muted mb-0">{item.description}</p>
                      </div>
                    </div>
                    <div className="block-content block-content-full block-content-sm bg-body-light">
                      <span className="font-size-sm text-muted">
                        <strong>{DateTranslate(item.createdDate)}</strong>
                      </span>
                    </div>
                  </a>
                ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

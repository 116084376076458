import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import ProcessExigency from "./ProcessExigency";

export default function GeneralExigencyModal({ show, onHide, open }) {
  const [process, setProcess] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = Storage.getUserData();
  const processCtrl = ProcessController();

  useEffect(() => {
    if (show) {
      loadProcessExigencies();
    }
  }, [show]);

  async function loadProcessExigencies() {
    if (userData === null) {
      return;
    }
    processCtrl.CustomerProcessExigencies(userData.customerId, (res) => {
      console.log("processes", res);
      setProcess(ProcessDates(res));
    });
  }

  return (
    <Modal
      title="Resolver Exigências"
      show={show}
      isLoading={loading}
      onHide={onHide}
    >
      {process.map((item) => (
        <ProcessExigency
          processId={item.id}
          process={item}
          openProcess={() =>
            open({ data: { ...item, processId: item.processId } })
          }
          reload={() => loadProcessExigencies()}
        />
      ))}
    </Modal>
  );
}

import { ServiceList } from "@/app/core/base/controllers/ServicesController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import React, { useEffect, useState } from "react";
import CustomerNewBudget from "../budget/CustomerNewBudget";

export default function ServiceShowCase() {
  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [showBudgetModal, setShowBudgetModal] = useState(false);

  useEffect(() => {
    loadServices();
  }, []);

  function loadServices() {
    ServiceList((res) => {
      const result = res.filter((x) => x.visible === true);
      setData(result);
    });
  }

  function openNewBudget(item) {
    setSelectedService(item);
    setShowBudgetModal(true);
  }

  return (
    <div className="row py-3">
      {data &&
        data.map((item) => (
          <div className="col-md-3" onClick={() => openNewBudget(item)}>
            <a
              class="block block-link-pop block-rounded block-bordered text-center"
              href="javascript:void(0)"
            >
              <div class="block-header">
                <h3 class="block-title">{item.name}</h3>
              </div>
              <div class="block-content bg-body-light">
                <div class="py-2">
                  <p class="h1 font-w700 mb-2">R${MoneyMask(item.value, 2)}</p>
                </div>
              </div>
              <div class="block-content">
                <div class="py-2">
                  <p style={{ height: 150 }}>{item.observation}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      <CustomerNewBudget
        show={showBudgetModal}
        selectedService={selectedService}
        onHide={() => setShowBudgetModal(false)}
      />
    </div>
  );
}

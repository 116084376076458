import FollowController from "@/app/core/base/controllers/FollowController";
import AccountController from "@/app/core/base/controllers/UsersController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import OrganListHelper, {
  GetCurrentOperatoData,
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import React, { useEffect, useState, useRef } from "react";
import OperationProcessModal from "../OperationProcessModal";
import AddTimelineComponent from "../timeline/AddTimelineComponent";

export default function CustomerListFollow() {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const userData = Storage.getUserData();
  const followCtrl = FollowController();
  const accountCtrl = AccountController();
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    loadFollow();
  }, []);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadFollow() {
    await followCtrl.FollowingCustomerByUser(userData.id, (res) => {
      let itens = res.sort(function (a, b) {
        return a.position - b.position;
      });

      for (let s in itens) {
        itens[s].open = false;
      }

      setData(itens);
      notifyChange();
    });
  }

  function processBody(process) {
    let ongoing = [];

    if (process.organList) {
      ongoing = process.organList.filter(
        (x) =>
          x.stage !== 1 && x.stage !== 10 && x.status !== OrganStatus.Deferred,
      );
      return (
        <div className="block-rounded">
          {process &&
            process.organList &&
            process.organList.map((item) => {
              if (
                item.stage > 1 &&
                item.status !== OrganStatus.Done &&
                item.status !== OrganStatus.Deferred
              ) {
                return (
                  <div className="row">
                    <div className="col-8">
                      {DepartmentTranslate(item.stage)} -{" "}
                      {OrganListHelper().GetCurrentStatus(item)}
                      <br />
                      <i className="si si-clock mr-1" />{" "}
                      {DateTranslate(item.updatedDate)}
                    </div>
                    <div className="col-4 py-3">
                      <button
                        onClick={() =>
                          setSelectedData({
                            ...process,
                            organListId: item.id,
                          }) | setShowProcessModal(true)
                        }
                        className="btn btn-sm btn-square btn-dual ml-1"
                      >
                        <i className={`far fa-eye`}></i>
                      </button>
                    </div>
                  </div>
                );
              }
            })}
          {(process && process.organList == undefined) ||
          process.organList == null ||
          process.organList.length == 0 ? (
            <button
              onClick={() =>
                setSelectedData(process) | setShowProcessModal(true)
              }
              className="btn btn-sm btn-square btn-dual ml-1"
            >
              <i className={`far fa-eye`}></i>
            </button>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  const drop = async (e) => {
    if (userData && userData.level == 1 && userData.level == 5) {
      return;
    }

    let copyListItems = [...data];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    for (let s in copyListItems) {
      copyListItems[s].position = parseInt(s) + 1;
    }

    setData(copyListItems);
    for (let s in copyListItems) {
      await followCtrl.UpdateCustomer(
        copyListItems[s],
        (res) => {},
        (error) => {},
      );
    }
  };

  function dragStart(e, index) {
    dragItem.current = index;
  }
  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  function openItem(index) {
    let old = data;
    old[index].open = !old[index].open;
    setData(old);
    notifyChange();
  }

  return (
    <div>
      {change &&
        data &&
        data.map((item, index) => (
          <div
            className="block block-rounded block-fx-pop block-bordered"
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            key={`organlist-${data.id}-${index}`}
            onDragEnd={drop}
            draggable
          >
            <div className="block-header">
              <div className="block-title">
                {item.customer && item.customer.name}
              </div>
              <div className="block-tools">
                <button
                  className="btn btn-sm btn-square btn-dual ml-1"
                  onClick={() => openItem(index)}
                >
                  <i
                    className={`si si-arrow-${"open" === true ? "up" : "down"} mr-1`}
                  ></i>
                </button>
                <a
                  className="btn btn-sm btn-square btn-dual ml-1"
                  href={`#/customer-profile/${item.customerId}`}
                >
                  <i className={`far fa-eye`}></i>
                </a>
              </div>
            </div>
            {item.open ? (
              <div className="block-content">
                Processos
                {item.process &&
                  item.process.map((process) => (
                    <table className="table table-hover align-items-center justify-content-between">
                      <tbody>
                        <tr className="row">
                          <td className="col-5 text-sm">
                            {process.processName}
                            <AddTimelineComponent
                              processId={process.id}
                              search={true}
                            />
                          </td>
                          <td className="col-7 text-sm align-items-center">
                            {processBody(process)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      <OperationProcessModal
        data={selectedData}
        show={showProcessModal}
        onHide={() => setShowProcessModal(false) | setSelectedData({})}
      />
    </div>
  );
}

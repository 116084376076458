import React, { useEffect, useState } from "react";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import CustomerDocCard from "../documents/CustomerDocCard";
import {
  CepMask,
  CnpjMask,
  CpfMask,
  PhoneMask,
  TelphoneMask,
} from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function CustomerCard({ customerId, preLoad }) {
  const [customer, setCustomer] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (preLoad != undefined && preLoad.id != undefined) {
      setCustomer(preLoad);
    } else if (customerId) {
      loadCustomer();
    }
  }, [customerId, preLoad]);

  function loadCustomer() {
    CustomerSelect(customerId, (res) => {
      setCustomer(res);
    });
  }

  function toggleCard() {
    setOpen(!open);
  }

  function openCustomer() {
    window.open(`#/customer-profile/${customer.id}`, "_blank");
  }

  return (
    <div className="block block-bordered border-2x border-secondary-light">
      <div className="block-header">
        <div className="block-title inline-block">
          Cliente -{customer && CustomerHelper().ShowStatus(customer.status)}
          {customer && CustomerHelper().ShowLevel(customer.level)}
        </div>
        <div className="block-tools">
          <button
            className="btn btn-sm btn-square btn-outline-dark js-click-ripple-enabled mr-1"
            onClick={() => openCustomer()}
          >
            <i className={`si si-user`}></i> Perfil do cliente
          </button>
          <button
            className="btn btn-sm btn-square btn-outline-dark js-click-ripple-enabled"
            onClick={() => toggleCard()}
          >
            <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
          </button>
        </div>
      </div>
      {open === true ? (
        <div className="block-content">
          <table className="table">
            <tr>
              <td>Nome</td>
              <td>{customer && customer.name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{customer && customer.email}</td>
            </tr>
            <tr>
              <td>Telefone comercial</td>
              <td>
                {customer && customer.phone && TelphoneMask(customer.phone)}
              </td>
            </tr>
            <tr>
              <td>Telefone celular</td>
              <td>
                {customer && customer.whatsapp && PhoneMask(customer.whatsapp)}
              </td>
            </tr>
            <tr>
              <td>CNPJ</td>
              <td>{customer && customer.cnpj && CnpjMask(customer.cnpj)}</td>
            </tr>
            <tr>
              <td>Cód. de cliente</td>
              <td>{customer && customer.customerCode}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{customer && customer.cpf && CpfMask(customer.cpf)}</td>
            </tr>
            <tr>
              <td>CRC / OAB</td>
              <td>{customer && customer.crc}</td>
            </tr>
            <tr>
              <td>Logradouro</td>
              <td>{customer.street}</td>
            </tr>
            <tr>
              <td>Número</td>
              <td>{customer.number}</td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>{customer.neighborhood}</td>
            </tr>
            <tr>
              <td>Complemento</td>
              <td>{customer.complement}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>{customer.zipcode && CepMask(customer.zipcode)}</td>
            </tr>
            <tr>
              <td>Local</td>
              <td>
                {customer && customer.city} / {customer && customer.uf}
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                {customer && CustomerHelper().ShowStatus(customer.status)}
                {customer && CustomerHelper().ShowLevel(customer.level)}
              </td>
            </tr>
            <tr>
              <td>Observações</td>
              <td>{customer && customer.observation}</td>
            </tr>
          </table>
          <CustomerDocCard customerId={customer && customer.id} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

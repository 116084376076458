import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import ShortcutAddModal from "./ShortcutAddModal";
import {
  ShortcutInsert,
  ShortcutUpdate,
  ShortcutDelete,
  ShortcutList,
} from "@/app/core/base/controllers/ShortcutsController";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import Functions from "@/app/core/util/Functions";

export default function ShortcutComponent() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState({});

  const cols = [
    { header: "Nome", isSortable: true, name: "name" },
    { header: "Url", isSortable: true, name: "url" },
    {
      header: "Departamento",
      isSortable: true,
      name: "level",
      switch: {
        1: "Orçamento",
        2: "Análise",
        3: "Preparação",
        4: "Devolução Órgão",
        5: "Cliente",
      },
    },
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    getShortcuts();
  }, []);

  function getShortcuts() {
    ShortcutList((res) => {
      setData(ProcessDates(res));
    });
  }

  function showEditItem(item) {
    setItemData(item.data);
    setShowModal(true);
  }

  function postShortcut(item) {
    if (item.id === 0 || item.id === undefined) {
      ShortcutInsert({ ...item, level: parseInt(item.level) }, (res) => {
        getShortcuts();
        setShowModal(false);
      });
    } else {
      ShortcutUpdate({ ...item, level: parseInt(item.level) }, (res) => {
        getShortcuts();
        setShowModal(false);
      });
    }
  }

  function deleteItem(item) {
    if (window.confirm("Deseja realmente deletar esse item?")) {
      ShortcutDelete(item.data.id, (res) => {
        getShortcuts();
      });
    }
  }

  return (
    <>
      <Card title="Atalhos" action={() => setShowModal(!showModal)} icon="plus">
        <SampleTable
          title="Atalhos"
          cols={cols}
          data={data}
          newItem={() => setShowModal(true)}
          remove={(task) => deleteItem(task.data)}
          refresh={() => getShortcuts()}
          open={(item) => showEditItem(item)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
        />
      </Card>
      <ShortcutAddModal
        show={showModal}
        data={itemData}
        onHide={() => setShowModal(!showModal)}
        submit={(service) => postShortcut(service)}
        title="Adicionar Serviço"
      />
    </>
  );
}

import OrganListController from "@/app/core/base/controllers/OrganListController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import {
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import { processObservations } from "@/app/core/util/helpers/TasksHelper";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import JobsController from "@/app/core/base/controllers/JobsController";

export default function ProtocolsItem({ data, reload, open }) {
  const [edit, setEdit] = useState(false);
  const [protocol, setProtocol] = useState("");
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    if (data && data.protocol) {
      setProtocol(data.protocol);
    }
  }, [data]);

  function saveOrEdit() {
    if (edit === false) {
      setEdit(true);
    } else {
      saveProtocol();
      setEdit(false);
    }
  }

  async function saveProtocol() {
    if (protocol.length > 0) {
      await jobsDone.Insert(data.processId, data.id, (res) => {});
      await organCtrl.Update(
        { ...data, protocol: protocol, status: OrganStatus.Devolution },
        async (res) => {
          await NotifyUpdate(
            data.processId,
            `Protocolado - ${protocol}`,
            true,
            TimelineChannelSistema,
            data.id,
          );
          await NotifyUpdate(
            data.processId,
            `Aguardando dev. Do órgão ${data.organName} Protocolo Nº${protocol}`,
            false,
            TimelineChannelSistema,
          );

          reload();
        },
      );
    } else {
      toast.error("Você deve preencher o Nº de Protocolo");
    }
  }

  return (
    <tr id={`protocol-${data && data.id}`} key={data.id}>
      <td>{data && data.id}</td>
      <td>{data && data.processName}</td>
      <td>{data && data.serviceName}</td>
      <td>{data && data.organName}</td>
      <td>
        <div className="form-group">
          <div className="input-group">
            <input
              type="email"
              className="form-control"
              defaultValue={data && data.protocol}
              disabled={!edit}
              id={`protocol-field-${data && data.id}`}
              name={`protocol-field-${data && data.id}`}
              onChange={(evt) => setProtocol(evt.target.value)}
            />
            <div className="input-group-append">
              <button
                type="button"
                className={`btn btn-${edit === false ? "dark" : "success"}`}
                title="Editar"
                onClick={() => saveOrEdit()}
              >
                <i
                  className={`far fa-${
                    edit === false ? "edit" : "check-circle"
                  }`}
                />
              </button>
              <CopyToClipboard
                text={protocol}
                onCopy={() =>
                  toast.info("Copiado para a área de transferência")
                }
              >
                <button type="button" className="btn btn-dark" title="copiar">
                  <i className="far fa-copy" />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </td>
      <td>{data && processObservations(data)}</td>
      <td>{data && DateTranslate(data.endPreparationDate)}</td>
      <td>{data && DateTranslate(data.updatedDate)}</td>
      <td>
        <button
          className="btn btn-sm btn-dual"
          onClick={() =>
            open({ data: { id: data.processId, organListId: data.id } })
          }
        >
          <i className="fa fa-eye" />
        </button>
      </td>
    </tr>
  );
}

import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main";
import Storage from "@/app/core/util/Storage";
import Layout from "@/app/core/layouts/main";

const ProjectShell = (props) =>
  Storage.isAuthenticated() ? (
    <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
  ) : (
    <Redirect to="/" />
  );

const Redirecter = () => <Redirect to="/error/404" />;

const ResendDevolutionRouter = {
  path: "/resendDevolution",
  component: ProjectShell,
  routes: [
    {
      path: "/resendDevolution",
      exact: false,
      component: MainComponent,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};

export default ResendDevolutionRouter;

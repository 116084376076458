import React, { useEffect, useRef, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  BlobProvider,
  Svg,
  Line,
} from "@react-pdf/renderer";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import {
  GetCurrentOperatoData,
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import { ExigencyStatus } from "@/app/core/util/constants/ExigenciesConstants";

export default function ProcessMirror({ process }) {
  const [myDoc, setMyDoc] = useState(null);
  const date = new Date();

  // Font.register({
  //   family: "Oswald",
  //   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  // });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 5,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      // fontFamily: "Times-Roman",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginTop: 10,
    },
    subtitle: {
      fontSize: 18,
      margin: 6,
      // fontFamily: "Times-Roman",
    },
    text: {
      margin: 8,
      fontSize: 12,
      textAlign: "justify",
      // fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 5,
      marginHorizontal: 50,
      width: 80,
    },
    header: {
      fontSize: 12,
      marginBottom: 10,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  useEffect(() => {
    if (process && process.id) {
      createContract();
    }
  }, [process]);

  function createContract() {
    setMyDoc(
      <Document>
        <Page style={styles.body} orientation="landscape">
          <Image style={styles.image} src={Logo} />
          <Text style={styles.title}>
            {process.customer.customerCode} -{" "}
            {process.customer && process.customer.name}
          </Text>
          <Text style={styles.title}>DOCUMENTO : {process.processName}</Text>
          <Svg height="10" width="800">
            <Line
              x1="0"
              y1="0"
              x2="850"
              y2="0"
              strokeWidth={4}
              stroke="rgb(0,0,0)"
            />
          </Svg>

          <Text style={styles.author}>Dados do serviço</Text>
          <View style={styles.section}>
            <Text style={styles.text}>
              Serviço :{" "}
              {process &&
                process.selectedServices &&
                process.selectedServices.map((item) => {
                  return `${item.serviceName}; `;
                })}
            </Text>
            <Text style={styles.text}>
              Orgãos inclusos :{" "}
              {process &&
                process.organList &&
                process.organList.map((organ) => {
                  return `${organ.organ.name}; `;
                })}
            </Text>
            <Text style={styles.text}>
              Data de início : {process && DateTranslate(process.endBudgetDate)}
            </Text>
            <Text style={styles.text}>
              Estágio atual :{" "}
              {process &&
                process.organList &&
                process.organList.map((item) => {
                  if (
                    item.stage > 1 &&
                    item.status !== OrganStatus.Deferred &&
                    item.status !== OrganStatus.Done
                  ) {
                    let operator = GetCurrentOperatoData(item);
                    if (operator !== undefined) {
                      return `${DepartmentTranslate(item.stage)}`;
                    }
                  }
                })}
            </Text>
            <Text style={styles.text}>
              Responsável atual :{" "}
              {process &&
                process.organList &&
                process.organList.map((item) => {
                  if (
                    item.stage > 1 &&
                    item.status !== OrganStatus.Deferred &&
                    item.status !== OrganStatus.Done
                  ) {
                    let operator = GetCurrentOperatoData(item);
                    if (operator !== undefined) {
                      return `${operator.firstName} - Equipe : ${
                        operator.teamId
                      } | Data de impressão ${DateTranslate(date)}`;
                    }
                  }
                })}
            </Text>
          </View>
          <Svg height="10" width="800">
            <Line
              x1="0"
              y1="0"
              x2="850"
              y2="0"
              strokeWidth={4}
              stroke="rgb(0,0,0)"
            />
          </Svg>

          <Text style={styles.author}>Contatos do cliente</Text>
          <View style={styles.section}>
            <Text style={styles.text}>
              Nome : {process && process.customer && process.customer.name}
            </Text>
            <Text style={styles.text}>
              Email : {process && process.customer && process.customer.email}
            </Text>
            <Text style={styles.text}>
              Telefone : {process && process.customer && process.customer.phone}
            </Text>
            <Text style={styles.text}>
              Celular :{" "}
              {process && process.customer && process.customer.whatsapp}
            </Text>
          </View>
          <Svg height="10" width="800">
            <Line
              x1="0"
              y1="0"
              x2="850"
              y2="0"
              strokeWidth={4}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <Text style={styles.author}>Exigências</Text>
          {process &&
            process.exigencies &&
            process.exigencies.map((item, index) => {
              if (item.status !== ExigencyStatus.done) {
                return (
                  <Text style={styles.text} key={`exigency-${index}`}>
                    {index + 1}. {item.description}
                  </Text>
                );
              }
            })}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>,
    );
  }

  return (
    <span>
      {process && myDoc && (
        <BlobProvider document={myDoc}>
          {({ blob, url, loading, error }) => {
            return (
              <a
                className="btn btn-sm btn-white"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="si si-printer"></i>
              </a>
            );
          }}
        </BlobProvider>
      )}
    </span>
  );
}

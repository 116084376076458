import AttachmentController from "@/app/core/base/controllers/AttachmentController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import Storage from "@/app/core/util/Storage";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";
import DocInput from "../documents/DocInput";

export default function AddTimelineComponent({
  organListId,
  processId,
  loadTimeline,
  search,
}) {
  const [data, setData] = useState({});
  const timelineCtrl = TimelineController();
  const organCtrl = OrganListController();
  const processCtrl = ProcessController();
  const attachmentCtrl = AttachmentController();
  const userData = Storage.getUserData();

  function clearFields() {
    setData({});
  }

  async function saveUpdate() {
    let timeline = {
      processId: processId,
      organListId,
      level: userData.level,
      userId: userData.id,
      status: 1,
      ...data,
    };

    await timelineCtrl.Insert(timeline, async (res) => {
      toast.info("Salvo com sucesso");
      if (timeline.files !== undefined && timeline.files.length > 0) {
        var formData = new FormData();
        for (let s in timeline.files) {
          formData.append("file", timeline.files[s]);
        }
        await attachmentCtrl.Upload(formData, res.id, processId, (res) => {});
      }
      clearFields();
      if (loadTimeline) {
        loadTimeline();
      }
    });

    if (organListId !== undefined) {
      if (userData.level !== 6 && userData.role > 0) {
        await organCtrl.InternalFeedback(organListId, true, (res) => {});
      } else if (userData.level === 6 || search === true) {
        await organCtrl.CustomerReturn(organListId, true, (res) => {});
      }
    } else {
      if (userData.level !== 6 && userData.role > 0) {
        await processCtrl.InternalFeedback(processId, true, (res) => {});
      } else if (userData.level === 6 || search === true) {
        await processCtrl.CustomerReturn(processId, true, (res) => {});
      }
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  return (
    <DropdownElement
      submit={() => saveUpdate()}
      icon="si si-plus"
      style={{ width: "100%", height: 350 }}
      title={"Timeline"}
    >
      <div className="form-group">
        <span>Qual o canal de contato?</span>
        <select
          name="channel"
          className="form-control"
          onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
        >
          <option value={1}>Email</option>
          <option value={2}>Whatsapp</option>
          <option value={3}>Chat</option>
          <option value={4}>Telefone</option>
          <option value={5}>Recepção</option>
        </select>
      </div>
      <div className="form-group">
        <span>O que foi tratado?</span>
        <textarea
          type="text"
          className="form-control"
          name="observation"
          value={data && data.observation}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <DocInput
          disable={false}
          multiple={true}
          uploadFile={(files) => setData({ ...data, files })}
        />
      </div>
    </DropdownElement>
  );
}

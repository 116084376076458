import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import {
  ExigenciesList,
  ExigencyUpdate,
  InsertExigency,
} from "../../base/controllers/ExigenciesController";
import DocumentsController from "../../base/controllers/DocumentsController";
import DocButton from "./documents/DocButton";
import { DepartmentTranslate } from "../../util/helpers/DepartmentHelper";
import DropdownElement from "./elements/DropdownElement";
import { NotifyNew } from "../../util/helpers/NotificationHelper";
import { DateTranslateSimple } from "../../util/DateTranslater";
import { Message } from "../../util/messages/GeralMessages";
import ExigencyFulfillmentComponent from "./ExigencyFulfillmentModal";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { DocHelper } from "../../util/helpers/DocsHelper";

export default function DocItemComponent({
  setView,
  setRightView,
  data,
  updateList,
  disabled,
  organListId,
  viewers,
  exigencies,
  reportChecked,
}) {
  const userData = Storage.getUserData();
  const [fileName, setFilename] = useState(data.fileName);
  const [exigency, setExigency] = useState(null);
  const [disable, setDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exigencyData, setExigencyData] = useState(null);
  const [results, setResults] = useState([]);
  const [content, setContent] = useState(null);
  const inputFile = useRef(null);
  const docCtrl = DocumentsController();

  useEffect(() => {
    if (disabled === undefined || disabled === null) {
      setDisable(false);
    } else {
      setDisable(disabled);
    }
  }, []);

  function getSignature(file) {
    if (exigency === null || exigency === "") {
      toast.error(`Descreva o que o cliente precisa fazer`);
    } else {
      let itens = {
        processId: data.processId,
        userId: userData.id,
        description: exigency,
        action: 5,
        fulfill: false,
        status: 1,
        sendedId: file.id,
        organListId,
      };

      InsertExigency(itens, (res) => {
        toast.info("Enviado com sucesso");
      });
    }
  }

  function statusEnum(sts) {
    switch (sts) {
      case 1:
        return "Mestre";
        break;
      case 2:
        return "Complementar";
        break;
      case 3:
        return "Consultas e fichas";
        break;
      case 4:
        return "Inutilizado";
        break;

      default:
        return "Excluído";
        break;
    }
  }

  function updateDoc(file) {
    inputFile.current.click();
  }

  async function uploadFile(itens) {
    let file = itens[0];
    var formData = new FormData();
    formData.append("file", file);
    await docCtrl.ChangeDoc(data.id, formData, userData.id, (res) => {
      toast.info(Message.Saved);
      if (res.exigency !== undefined && res.exigency.length > 0) {
        for (let s in res.exigency) {
          ExigencyUpdate(
            { ...res.exigency[s], sendedId: res.result.id },
            (result) => {},
          );
        }
        toast.info(
          "Atenção - Você substituiu um documento anexado a uma exigência. Confira esta exigência clicando aqui",
          {
            position: "top-right",
            autoClose: 50000,
            hideProgressBar: true,
            closeOnClick: false,
            onClick: () => openExigency(res.exigency, res.result),
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      }
      updateList();
    });
  }

  function openExigency(item, sended) {
    setExigencyData({ ...item[0], sended: sended, sendedId: sended.id });
    setShowModal(true);
  }

  async function removeDocument(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await docCtrl.Update(
        { ...item, status: 5, eraserId: userData.id },
        (res) => {
          toast.info(Message.DeleteSuccess);
          updateList();
        },
      );
    }
  }

  async function changeVisibility(item) {
    item.visible = !item.visible;
    await docCtrl.Update(item, (res) => {
      updateList();
    });
  }

  function downloadDoc(item) {
    if (item.url.includes(".pfx") || item.url.includes(".PFX")) {
      NotifyUpdate(
        item.processId,
        `Baixou o arquivo .pfx: ${item.name}`,
        false,
      );
    }
    window.open(item.url, "_blank");
  }

  function handleExigencyChange(value) {
    let result = [];

    if (value.length > 0) {
      setResults([]);
      result = Autocomplete(value, exigencies, "name");
      setResults(result);
    } else {
      result = [];
      setResults([]);
    }
  }

  function getDocExigency() {
    return (
      <DropdownElement
        submit={() => getSignature(data)}
        // orientation="left"
        icon="fa fa-fw fa-file-signature"
        style={{ width: 270 }}
        title={"Enviar exigências ao cliente com anexo"}
      >
        <p>Solicitar esse doc assinado?</p>
        <div className="form-group">
          <span>Descrição</span>
          <textarea
            className="form-control"
            onChange={(evt) =>
              setExigency(evt.target.value) |
              handleExigencyChange(evt.target.value)
            }
            value={exigency}
          />
          <ul
            style={{
              borderRadius: "3px",
              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
              background: "rgba(255, 255, 255, 0.9)",
              padding: "2px 0",
              fontSize: "90%",
              position: "fixed",
              overflow: "auto",
              marginTop: 20,
              width: "120%",
              maxHeight: "300px", // TODO: don't cheat, let it flow to the bottom
            }}
          >
            {results &&
              results.map((item) => {
                return (
                  <li
                    className="m-3"
                    onClick={() =>
                      setExigency(item.description) | setResults([])
                    }
                  >
                    {item.name}
                  </li>
                );
              })}
          </ul>
        </div>
      </DropdownElement>
    );
  }

  function userLevelButtons(item) {
    if (userData === null || userData === undefined) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <DocButton
            data={item}
            title="Download"
            style={"text-dark mb-1"}
            action={() => downloadDoc(item)}
            icon={"fa fa-fw fa-file-download"}
          />
          <DocButton
            data={item}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setView(item)}
            icon={"fa fa-fw fa-eye"}
          />
          <br />
        </div>
      );
    } else if (item.status === 5) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <DocButton
            data={item}
            title="Download"
            style={"text-dark mb-1"}
            action={() => downloadDoc(item)}
            icon={"fa fa-fw fa-file-download"}
          />
          {/* <br /> */}
          <DocButton
            data={item}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setView(item)}
            icon={"fa fa-fw fa-eye"}
          />
          <br />
        </div>
      );
    } else if (userData.level === 5) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <DocButton
            data={item}
            title="Download"
            style={"text-dark mb-1"}
            action={() => downloadDoc(item)}
            icon={"fa fa-fw fa-file-download"}
          />
          {/* <br /> */}
          <DocButton
            data={item}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setView(item)}
            icon={"fa fa-fw fa-eye"}
          />
          {/* <br /> */}
        </div>
      );
    } else if (userData.level === 3) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <div className="row">
            <div className="col-md-4">
              <DocButton
                data={item}
                title=""
                style={"text-dark mb-1"}
                action={() => setView(item)}
                icon={"fa fa-fw fa-eye"}
              />
            </div>
            <div className="col-md-4">
              <DocButton
                data={item}
                title=""
                style={"text-dark mb-1"}
                action={() => downloadDoc(item)}
                icon={"fa fa-fw fa-file-download"}
              />
            </div>
            <div className="col-md-4">
              <Dropdown>
                <Dropdown.Toggle
                  className="btn btn-sm btn-dual"
                  variant="light"
                  disabled={disable}
                >
                  <i class="fa fa-ellipsis-v"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DocButton
                    data={item}
                    title="Substituir"
                    style={"text-dark mb-1"}
                    action={() => updateDoc(item)}
                    disabled={disable}
                    icon={"fa fa-fw fa-file-upload"}
                  />
                  <br />
                  <DocButton
                    data={item}
                    style={"text-dark mb-1"}
                    title="Tornar visível/invisível"
                    action={() => changeVisibility(item)}
                    disabled={disable}
                    icon={
                      item && item.visible === true
                        ? "far fa-fw fa-eye"
                        : "far fa-fw fa-eye-slash"
                    }
                  />
                  <br />
                  <DocButton
                    data={item}
                    style={"text-dark mb-1 text-danger"}
                    title="Excluir"
                    action={() => removeDocument(item)}
                    disabled={disable}
                    icon={"fa fa-times fa-fw"}
                  />
                  <br />
                  {getDocExigency()}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      );
    } else if (userData.level === 4) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <div className="row">
            {viewers !== 2 ? (
              <div className="col-md-4">
                <DocButton
                  data={item}
                  title=""
                  style={"text-dark mb-1"}
                  action={() => setView(item)}
                  icon={"fa fa-fw fa-eye"}
                />
              </div>
            ) : (
              ""
            )}
            {viewers === 2 ? (
              <div className="col-md-4">
                <DocButton
                  data={item}
                  title=""
                  style={"text-dark mb-1"}
                  action={() => setView(item)}
                  icon={"fa fa-fw fa-arrow-alt-circle-left"}
                />
              </div>
            ) : (
              ""
            )}
            {viewers === 2 ? (
              <div className="col-md-4">
                <DocButton
                  data={item}
                  style={"text-dark mb-1"}
                  title=""
                  action={() => setRightView(item)}
                  icon={"fa fa-fw fa-arrow-alt-circle-right"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-4">
              <DocButton
                data={item}
                title=""
                style={"text-dark mb-1"}
                action={() => downloadDoc(item)}
                icon={"fa fa-fw fa-file-download"}
              />
            </div>
            <div className="col-md-4">
              <Dropdown>
                <Dropdown.Toggle
                  className="btn btn-sm btn-dual"
                  variant="light"
                  disabled={disable}
                >
                  <i class="fa fa-ellipsis-v"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DocButton
                    data={item}
                    title="Substituir"
                    style={"text-dark mb-1"}
                    action={() => updateDoc(item)}
                    disabled={disable}
                    icon={"fa fa-fw fa-file-upload"}
                  />
                  <br />
                  <DocButton
                    data={item}
                    style={"text-dark mb-1"}
                    title="Tornar visível/invisível"
                    action={() => changeVisibility(item)}
                    disabled={disable}
                    icon={
                      item && item.visible === true
                        ? "far fa-fw fa-eye"
                        : "far fa-fw fa-eye-slash"
                    }
                  />
                  <br />
                  <DocButton
                    data={item}
                    style={"text-dark mb-1 text-danger"}
                    title="Excluir"
                    action={() => removeDocument(item)}
                    disabled={disable}
                    icon={"fa fa-times fa-fw"}
                  />
                  <br />
                  {getDocExigency()}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      );
    } else if (userData.level === 2) {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          {viewers === 2 ? (
            <DocButton
              data={item}
              title=""
              style={"text-dark mb-1"}
              action={() => setView(item)}
              icon={"fa fa-fw fa-arrow-alt-circle-left"}
            />
          ) : (
            ""
          )}
          {viewers === 2 ? (
            <DocButton
              data={item}
              style={"text-dark mb-1"}
              title=""
              action={() => setRightView(item)}
              icon={"fa fa-fw fa-arrow-alt-circle-right"}
            />
          ) : (
            <DocButton
              data={item}
              title=""
              style={"text-dark mb-1"}
              action={() => setView(item)}
              icon={"fa fa-fw fa-eye"}
            />
          )}
          <Dropdown>
            <Dropdown.Toggle className="btn btn-sm btn-dual" variant="light">
              <i class="fa fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DocButton
                data={item}
                title="Substituir"
                style={"text-dark mb-1"}
                action={() => updateDoc(item)}
                disabled={disable}
                icon={"fa fa-fw fa-file-upload"}
              />
              <DocButton
                data={item}
                title="Download"
                style={"text-dark mb-1"}
                action={() => downloadDoc(item)}
                icon={"fa fa-fw fa-file-download"}
              />
              <DocButton
                data={item}
                style={"text-dark mb-1"}
                title="Tornar visível/invisível"
                action={() => changeVisibility(item)}
                disabled={disable}
                icon={
                  item && item.visible === true
                    ? "far fa-fw fa-eye"
                    : "far fa-fw fa-eye-slash"
                }
              />
              <DocButton
                data={item}
                style={"text-dark mb-1 text-danger"}
                title="Excluir"
                disabled={disable}
                action={() => removeDocument(item)}
                icon={"fa fa-times fa-fw"}
              />
              {getDocExigency()}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="block-tools d-flex align-items-center justify-content-between">
          <DocButton
            data={item}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setView(item)}
            icon={"fa fa-fw fa-eye"}
          />
          <DocButton
            data={item}
            title="Download"
            style={"text-dark mb-1"}
            action={() => downloadDoc(item)}
            icon={"fa fa-fw fa-file-download"}
          />
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              className="btn btn-sm btn-dual"
              disabled={disable}
            >
              <i class="fa fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DocButton
                data={item}
                style={"text-dark mb-1"}
                title="Tornar visível/invisível"
                action={() => changeVisibility(item)}
                disabled={disable}
                icon={
                  item && item.visible === true
                    ? "far fa-fw fa-eye"
                    : "far fa-fw fa-eye-slash"
                }
              />
              <br />
              <DocButton
                data={item}
                style={"text-dark mb-1 text-danger"}
                title="Excluir"
                action={() => removeDocument(item)}
                icon={"fa fa-times fa-fw"}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }
  }

  function verifyState(item) {
    if (userData === null) {
      return "";
    } else if (userData.level === 5) {
      return "";
    } else if (item.status === 1) {
      return "border-info";
    } else if (item.status === 2) {
      return "border-secondary";
    } else if (item.status === 3) {
      return "border-warning";
    } else {
      return "border-danger";
    }
  }

  async function updateDocStatus(item, status) {
    let baseData = { ...item, status: status };
    if (baseData.status == 1) {
      baseData.position = 0;
    }
    if (baseData.status == 3) {
      baseData.position = 10;
    }
    await docCtrl.Update(baseData, (res) => {
      updateList();
    });
  }

  function legibleName(name) {
    if (name.length > 27) {
      let newName = name.substr(0, 27) + "...";
      return newName;
    } else {
      return name;
    }
  }

  async function updateFilename() {
    await docCtrl.Update({ ...data, name: fileName }, (res) => {
      toast.info("Salvo com sucesso");
      updateList();
    });
  }

  function customStatusButtons(item) {
    if (userData === null || userData === undefined) {
      return "";
    } else if (userData.level !== 1 && userData.level !== 5) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-sm btn-dual"
            variant={Storage.getTheme()}
            disabled={disable}
          >
            {statusEnum(item.status)}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ zIndex: 99999999999 }}>
            <button
              className="btn btn-square btn-outline-info mb-1 mt-1"
              style={{ width: "100%", zIndex: 99999999999 }}
              onClick={() => updateDocStatus(item, 1)}
            >
              Mestre
            </button>
            <br />
            <button
              className="btn btn-square btn-outline-secondary mb-1 mt-1"
              style={{ width: "100%", zIndex: 99999999999 }}
              onClick={() => updateDocStatus(item, 2)}
            >
              Complementar
            </button>
            <br />
            <button
              className="btn btn-square btn-outline-warning mb-1 mt-1"
              style={{ width: "100%", zIndex: 99999999999 }}
              onClick={() => updateDocStatus(item, 3)}
            >
              Consultas e fichas
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (userData.level === 1) {
      if (data.status === 5) {
        return (
          <button
            className="btn btn-square btn-dual mb-1 mt-1"
            style={{ width: "100%", zIndex: 99999999999 }}
            onClick={() => updateDocStatus(item, 2)}
          >
            Recuperar
          </button>
        );
      } else {
        return statusEnum(item.status);
      }
    } else {
      return "";
    }
  }

  function handleChecked(evt) {
    let old = data;
    old.checked = evt.target.checked;
    if (reportChecked != undefined) {
      reportChecked(old);
    }
  }

  return (
    <div
      className={`block block-rounded border-left ${verifyState(
        data,
      )} border-5x block-fx-pop mb-2`}
      key={data.id}
    >
      <div className="block-header mb-0 pb-0">
        <div className="block-title">
          {userData === null || userData.level === 5 ? (
            <div>{data.name}</div>
          ) : (
            <span className="d-flex">
              {data && data.status == DocHelper.Status.Trash ? (
                ""
              ) : (
                <div class="custom-control custom-checkbox custom-control-success mb-1">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id={`check-doc-${data.id}`}
                    name={`check-doc-${data.id}`}
                    checked={data.checked}
                    onChange={(evt) => handleChecked(evt)}
                  />
                  <label
                    class="custom-control-label mt-1"
                    for={`check-doc-${data.id}`}
                  ></label>
                </div>
              )}
              <DropdownElement
                submit={() => updateFilename()}
                className="btn btn-sm btn-dual"
                disabled={disable}
                icon=""
                style={{ minWidth: 240, maxWidth: "100%" }}
                title={legibleName(data.name)}
              >
                <input
                  type="text"
                  placeholder="Nome do arquivo"
                  defaultValue={data.name}
                  onChange={(evt) => setFilename(evt.target.value)}
                  className="form-control"
                />
              </DropdownElement>
            </span>
          )}
        </div>
        {userLevelButtons(data)}
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10, width: 10 }}
        />
      </div>
      <table className="table table-borderless table-vcenter mb-0">
        <tbody>
          <tr style={{ whiteSpace: "nowrap" }}>
            <td className="text-left" style={{ width: 147 }}>
              {customStatusButtons(data)}
            </td>
            <td className="text-left">
              {userData !== null ? (
                <div>
                  {" "}
                  {data && data.sender !== undefined && userData.level === 5 ? (
                    <span title="Remetente">
                      <i className="far fa-user-circle mr-1" />
                      {DepartmentTranslate(data.sender.level)}
                    </span>
                  ) : (
                    ""
                  )}
                  {data && data.user !== undefined && userData.level !== 5 ? (
                    <span title="Remetente">
                      <i className="far fa-user-circle mr-1" />
                      {data.user.firstName}{" "}
                      {DepartmentTranslate(data.user.level)}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </td>

            {userData !== null ? (
              <td className="text-right">
                {userData.level !== 5 ? NotifyNew(data.createdDate) : ""}
                {userData !== null ? (
                  <span className="ml-1">
                    {DateTranslateSimple(data.createdDate)}
                  </span>
                ) : (
                  ""
                )}
              </td>
            ) : (
              ""
            )}
          </tr>
        </tbody>
      </table>
      <ExigencyFulfillmentComponent
        data={exigencyData}
        show={showModal}
        onHide={() => setShowModal(!showModal) | updateList()}
        submit={(service) => updateList() | setShowModal(!showModal)}
      />
    </div>
  );
}

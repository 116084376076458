import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

export default function DropdownElement(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", verifyKey);
  });

  function verifyKey(evt) {
    if (evt.keyCode === 27) {
      setShow(false);
    }
  }

  function onToggle(info) {
    setShow(info);
  }

  return (
    <Dropdown
      show={show}
      onToggle={(info) => onToggle(info)}
      style={{ zIndex: 999999999999999999999999999999999 }}
      drop={props.orientation}
    >
      <Dropdown.Toggle
        variant="light"
        className={props.className}
        onClick={() => setShow(!show)}
        disabled={props.disabled}
      >
        <i className={props.icon} /> {props.title}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ ...props.style }}>
        {props.children}
        <button
          type="submit"
          className="btn btn-info form-control mb-1 mt-1"
          onClick={() => setShow(false) | props.submit()}
        >
          {props.button === undefined ? "Enviar" : props.button}
        </button>
      </Dropdown.Menu>
    </Dropdown>
  );
}

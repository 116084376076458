import React from "react";

export default function FinancialHelper() {
  const OperationTypeList = [
    { name: "Entrada", value: 0, color: "success" },
    { name: "Saída", value: 1, color: "danger" },
    { name: "Desconto", value: 2, color: "info" },
    { name: "Reembolso", value: 3, color: "warning" },
  ];

  const OperationTypeBaseList = [
    { name: "Crédito", value: 0, color: "success" },
    { name: "Débito", value: 1, color: "danger" },
    { name: "Saque", value: 9, color: "warning" },
  ];

  const OperationType = {
    Income: 0,
    Outcome: 1,
    Discount: 2,
    Reimbursement: 3,
  };

  const PaymentTypeList = [
    { name: "Dinheiro", value: 0, color: "success" },
    { name: "Cheque", value: 1, color: "danger" },
    { name: "Transferência bancária", value: 2, color: "info" },
    { name: "Boleto", value: 3, color: "warning" },
  ];

  const PaymentType = {
    Money: 0,
    BillOfExchange: 1, // cheque
    BankTransfer: 2,
    BankSlip: 3, // boleto
  };

  const PaymentStatusList = [
    { name: "Pendente", value: 0, color: "info" },
    { name: "Em análise", value: 1, color: "warning" },
    { name: "Pago", value: 2, color: "success" },
    { name: "Recusado", value: 3, color: "danger" },
  ];

  const PaymentStatus = {
    Pending: 0,
    InAnalysis: 1,
    Paid: 2,
    Refused: 3,
  };

  function PaymentStatusTranslate(status) {
    let res = PaymentStatusList.filter((x) => x.value === status);
    if (res.length > 0) {
      return res[0];
    } else {
      return {};
    }
  }

  function PaymentStatusTranslateBadge(status) {
    let res = PaymentStatusList.filter((x) => x.value === status);
    if (res.length > 0) {
      return <span class={"badge badge-" + res[0].color}>{res[0].name}</span>;
    } else {
      return {};
    }
  }

  function OperationTypeTranslateBadge(status) {
    let res = OperationTypeList.filter((x) => x.value === status);
    if (res.length > 0) {
      return <span class={"badge badge-" + res[0].color}>{res[0].name}</span>;
    } else {
      return {};
    }
  }

  const ChargeResumeCols = [
    { header: "Id", isSortable: true, name: "id" },
    { header: "Customer", isSortable: true, name: "customerName" },
    {
      header: "Status",
      isSortable: true,
      name: "status",
      switch: {
        0: <span class="badge badge-info">Pendente</span>,
        1: <span class="badge badge-warning">Em análise</span>,
        2: <span class="badge badge-success">Pago</span>,
        3: <span class="badge badge-danger">Recusado</span>,
      },
    },
    { header: "Últ. atualização", isSortable: true, name: "updatedDate" },
  ];

  return {
    OperationTypeList,
    OperationTypeBaseList,
    OperationType,
    PaymentTypeList,
    PaymentType,
    ChargeResumeCols,
    PaymentStatusList,
    PaymentStatus,
    PaymentStatusTranslate,
    PaymentStatusTranslateBadge,
    OperationTypeTranslateBadge,
  };
}

export function OpenOnGoogleDocs(address) {
  let url =
    "https://drive.google.com/viewerng/viewer?url=" +
    address +
    "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

  window.open(url, "_blank");
}

export function HandleUploadErrors(err) {
  if (err.response.status === 500) {
    return `Erro interno de servidor, tente novamente mais tarde.`;
  } else if (err.response.status === 400) {
    return `Ocorreu um erro ao fazer o upload`;
  } else if (err.response.status === 401) {
    return `Acesso não autorizado, tente fazer login`;
  } else if (err.response.status === 403) {
    return `Acesso proibido. Entre em contato com seu supervisor`;
  } else if (err.response.status === 404) {
    return `O processo que esta tentando atualizar não existe`;
  } else if (err.response.status === 408) {
    return `Excedido tempo limite para o upload, verifique o status da sua conexão`;
  } else if (err.response.status === 413 || err.response.status === 414) {
    return `Limite de tamanho do arquivo ou remessa excedido. Tente enviar em partes`;
  } else if (err.response.status === 415) {
    return `Formato de arquivo não suportado`;
  } else {
    return "Ocorreu um erro ao subir o arquivo";
  }
}

export const DocHelper = {
  Status: {
    MasterDoc: 1,
    Complement: 2,
    Consults: 3,
    Leftovers: 4,
    Trash: 5,
  },
};

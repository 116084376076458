import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function FolderInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: `folder/insert`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FolderUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: `folder/update`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FolderDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `folder/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FolderList(response) {
  BaseService(
    {
      method: "GET",
      url: `folder`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import ReportController from "@/app/core/base/controllers/ReportController";
import React, { useEffect, useState } from "react";
import PreparationReportModal from "./Modals/PreparationReportModal";
import TeamsModal from "@/app/core/layouts/main-components/teams/TeamsModal";
import Storage from "@/app/core/util/Storage";

export default function PreparationCounter({ startDate, endDate }) {
  const report = ReportController();
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    notStarted: 0,
    ongoing: 0,
    done: 0,
    notStartedPercentage: 0,
    ongoingPercentage: 0,
    donePercentage: 0,
    waitingFulfillment: 0,
    waitingFulfillmentPercentage: 0,
    waitingConfirmation: 0,
    waitingConfirmationPercentage: 0,
    time: 0,
  });

  useEffect(() => {
    setData({
      notStarted: 0,
      ongoing: 0,
      done: 0,
      waitingFulfillment: 0,
      waitingFulfillmentPercentage: 0,
      waitingConfirmation: 0,
      waitingConfirmationPercentage: 0,
      time: 0,
    });
    loadCounters();
  }, [startDate, endDate, showModal]);

  async function loadCounters() {
    const oldResult = Storage.getPreparationCounter();
    if (
      oldResult == null ||
      oldResult.startDate != startDate ||
      oldResult.endDate != endDate
    ) {
      await report.PreparationResume(startDate, endDate, (res) => {
        console.log("Resultados", res);
        const base =
          res.notStarted + res.waitingFulfillment + res.waitingConfirmation;
        let result = res;
        result.notStartedPercentage = parseInt((res.notStarted / base) * 100);
        result.ongoingPercentage = parseInt((res.ongoing / base) * 100);
        result.donePercentage = parseInt((res.done / base) * 100);

        result.waitingFulfillment = res.waitingFulfillment;
        result.waitingFulfillmentPercentage = parseInt(
          (res.waitingFulfillment / base) * 100,
        );
        result.waitingConfirmation = res.waitingConfirmation;
        result.hours = res.hours;
        result.waitingConfirmationPercentage = parseInt(
          (res.waitingConfirmation / base) * 100,
        );

        result.startDate = startDate;
        result.endDate = endDate;

        Storage.setPreparationCounter(result);
        setData(result);
      });
    } else {
      setData(oldResult);
    }
  }

  return (
    <div>
      <div className="block block-rounded bg-xmodern">
        <div className="block-header bg-xmodern-dark">
          <div className="blick-title text-white text-uppercase font-size-sm font-w700">
            Preparação
          </div>
          <div className="block-tools">
            <button
              className="btn bg-xmodern-dark btn-sm text-white"
              onClick={() => setShowModal(true)}
            >
              <i className="far fa-eye" />
            </button>
            <button
              className="btn bg-xmodern-dark btn-sm text-white"
              onClick={() => setShowTeamModal(true)}
            >
              <i className="far fa-chart-bar" />
            </button>
          </div>
        </div>
        <div className="block-content block-content-full">
          <div className="row text-center">
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 text-white mx-auto">
                  {data && data.notStarted}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.notStartedPercentage}%
                </p>
                <p className="text-white-75 mb-0">Não iniciados</p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 text-white mx-auto">
                  {data && data.waitingFulfillment}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.waitingFulfillmentPercentage}%
                </p>
                <p className="text-white-75 mb-0">
                  Aguardando cump. de exigências
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 text-white mx-auto">
                  {data && data.waitingConfirmation}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.waitingConfirmationPercentage}%
                </p>
                <p className="text-white-75 mb-0">
                  Aguardando conf. do operador
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-black-op">
                <div className="item item-circle bg-black-25 text-white mx-auto">
                  {data && parseInt(data.hours)}h
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0"></p>
                <p className="text-white-75 mb-0">Tempo médio de ex.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreparationReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        startDate={startDate}
        endDate={endDate}
      />
      <TeamsModal
        show={showTeamModal}
        onHide={() => setShowTeamModal(false)}
        level={3}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Logo from "@/assets/img/logo.jpg";
import ResearchForm from "./Research";
import "react-toastify/dist/ReactToastify.css";
import { NotificationTypeContactRequired } from "@/app/core/util/helpers/NotificationHelper";
import { DateTimeNow } from "@/app/core/util/DateTranslater";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { SelectedServices } from "@/app/core/base/controllers/ServicesController";
import ServiceItem from "./components/ServiceItem";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import {
  ProcessStatus,
  ServiceGroupType,
} from "@/app/core/util/helpers/SelectedServiceHelper";
import { EvaluationInsert } from "@/app/core/base/controllers/EvaluationController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import Storage from "@/app/core/util/Storage";
import CompleteAccountModal from "./components/CompleteAccountModal";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import ServiceGroupController from "@/app/core/base/controllers/ServiceGroupController";
import ServiceGroupItem from "@/app/core/layouts/main-components/budget/ServiceGroupItem";
import CustomerBusinessModal from "@/app/core/layouts/main-components/budget/CustomerBusinessModal";
import {
  BudgetStatus,
  ProcessTotal,
} from "@/app/core/util/helpers/BudgetHelper";

function ConfirmService(props, { end }) {
  const [data, setData] = useState({});
  const [services, setServices] = useState([]);
  const [serviceGroups, setServiceGroups] = useState([]);
  const [total, setTotal] = useState(0);
  const [userData, setUserData] = useState(Storage.getUserData());

  const [client, setClient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledDoubts, setDisabledDoubts] = useState(false);
  const [disabledRefuse, setDisabledRefuse] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [info, setInfo] = useState(null);
  const processController = ProcessController();
  const notificationCtrl = NotificationController();
  const serviceGroupController = ServiceGroupController();
  const [show, setShow] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [logo, setLogo] = useState("");
  const enviroment = process.env.REACT_APP_ENV;
  const status = [
    "",
    "Solicitar Contato",
    "Aceitar",
    "Recusar",
    "Solicitar contato",
  ];

  useEffect(() => {
    loadPresentation();
  }, []);

  function loadPresentation() {
    let returnBool = false;
    if (props.location && props.location.search) {
      let param = props.location.search;
      if (param !== undefined) {
        let item = param.split("?")[1];
        if (item !== undefined) {
          if (item.includes("customer")) {
            returnBool = true;
          }
        }
      }
    }

    loadBudget(returnBool);
  }

  async function loadBudget(customerPresentation) {
    await processController.Select(props.match.params.key, async (res) => {
      setData(res);

      if (customerPresentation === true) {
        if (
          res.customer !== null &&
          res.customer.avatarImages !== undefined &&
          res.customer.avatarImages.length > 0
        ) {
          setLogo(res.customer.avatarImages[0].url);
          setInfo(<h4>{res.customer.name}</h4>);
        } else {
          setLogo(`${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`);
          setInfo(<h4>{res.customer.name}</h4>);
        }
      } else {
        setLogo(Logo);
      }

      if (res.status !== 1 && res.status !== 0) {
        setDisabledDoubts(true);
        setDisabledRefuse(true);
        setDisabled(true);
      }
      if (res && res.budgetStatus !== 2 && res.budgetStatus !== 3) {
        if (res.budgetStatus === 4) {
          setDisabledDoubts(true);
          setDisabledRefuse(true);
          setDisabled(true);
        } else if (res.budgetStatus === 1 || res.budgetStatus === 0) {
          setInfo(
            <p>
              <i className="fa fa-exclamation"></i> - Este orçamento esta sendo
              preparado - Por favor aguarde
            </p>,
          );
          setDisabled(true);
          setDisabledDoubts(true);
          setDisabledRefuse(true);
        } else {
          setDisabled(false);
          setDisabledDoubts(false);
          setDisabledRefuse(false);
        }
      } else {
        setDisabled(true);
        setDisabledDoubts(true);
        setDisabledRefuse(true);
      }

      loadStatus(res);
      checkCustomer(res, customerPresentation);
    });
    await serviceGroupController.Get(props.match.params.key, (res) => {
      setServiceGroups(res);
      loadServices(res, customerPresentation);
    });
  }

  function checkCustomer(process, customerPresentation) {
    if (
      userData !== null ||
      (process.customer &&
        process.customer.cpf === undefined &&
        process.customer.cnpj === undefined) ||
      customerPresentation === true ||
      client === true ||
      show === true
    ) {
      setClient(customerPresentation);
      setShow(true);

      if (userData !== null && customerPresentation == true) {
        setDisabled(true);
      }
      return;
    } else {
      setClient(false);
    }
    if (enviroment == "local") {
      setShow(true);
      return;
    }
    let customerCode = window.prompt(
      "Para acessar essa tela, confirme seu código de cliente",
    );
    if (customerCode === process.customer.customerCode) {
      setShow(true);
    } else {
      let customerCode2 = window.prompt(
        "Código de cliente inválido, tente novamente : ",
      );
      if (customerCode2 === process.customer.customerCode) {
        setShow(true);
      } else {
        if (customerCode === process.customer.customerCode) {
          setShow(true);
        } else {
          let customerCode2 = window.prompt(
            "Código de cliente inválido, tente novamente : ",
          );
          if (customerCode2 === process.customer.customerCode) {
            setShow(true);
          } else {
            if (customerCode === process.customer.customerCode) {
              setShow(true);
            } else {
              let customerCode2 = window.prompt(
                "Código de cliente inválido, tente novamente : ",
              );
              if (customerCode2 === process.customer.customerCode) {
                setShow(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === process.customer.customerCode) {
                  setShow(true);
                } else {
                  toast.info(
                    "Você errou o código muitas vezes e será redirecionado ao site",
                  );
                  setTimeout(() => {
                    window.location = "https://hasa.com.br";
                  }, 3900);
                }
              }
            }
          }
        }
      }
    }
  }

  function loadServices(optionals, isBusinessMan) {
    SelectedServices(props.match.params.key, (res) => {
      let investiment = 0;
      if (isBusinessMan === undefined) {
        isBusinessMan = client;
      }
      for (let s in res) {
        investiment += res[s].priceEdited;
        res[s].selected = true;
      }

      let serviceList = [];

      if (optionals !== null && optionals.length > 0) {
        for (let y in optionals) {
          const base = optionals[y];
          for (let s in base.optionalServices) {
            const item = base.optionalServices[s];
            if (item.selected === true) {
              if (isBusinessMan === true) {
                investiment += item.priceEdited;
              } else {
                investiment += item.price;
                serviceList.push(item);
              }
            }
          }
        }
      }

      if (isBusinessMan === false) {
        for (let s in res) {
          serviceList.push(res[s]);
        }
        setTotal(ProcessTotal(serviceList));
      } else {
        setTotal(investiment);
      }
      let allServices = [];
      for (let s in res) {
        let iten = { ...res[s], isGroup: false };
        allServices.push(iten);
      }
      for (let s in optionals) {
        let iten = { ...optionals[s], isGroup: true };
        allServices.push(iten);
      }

      const itens = allServices.sort(function (a, b) {
        return a.position - b.position;
      });

      setServices(itens);
    });
  }

  function postSearch(data) {
    const currentUrl = window.location.href;
    if (
      data.values.observation === undefined ||
      data.values.observation.length == 0
    ) {
      toast.error("Preencha a descrição, por favor");
      return;
    }
    EvaluationInsert(
      {
        ...data.values,
        answer: parseInt(data.values.answer),
        processId: parseInt(props.match.params.key),
      },
      (res) => {
        toast.info("Salvo com sucesso: ");
        let done = false;
        for (let s in services) {
          if (
            services[s].budgetStatus === 2 ||
            services[s].budgetStatus === 3
          ) {
            done = true;
          } else {
            done = false;
          }
        }
        if (currentUrl.includes("confirm")) {
          toast.success(Message.Saved);
          setTimeout(() => {
            window.location = "https://hasa.com.br";
          }, 1200);
        } else {
          toast.success(
            "O serviço será iniciado imediatamente! Agradecemos sua parceria",
          );
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      },
    );
  }

  async function updateTimeline(message, historic) {
    await NotifyUpdate(
      parseInt(props.match.params.key),
      message,
      historic,
      TimelineChannelSistema,
    );
  }

  function showDoc(item) {
    let url =
      "https://drive.google.com/viewerng/viewer?url=" +
      item +
      "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

    window.open(url, "_blank");
  }

  function loadStatus(svc) {
    if (svc.budgetStatus === 2) {
      setInfo(
        <p>
          <i className="fa fa-exclamation"></i> - Este orçamento já foi aceito.
          {/* acompanhe seus pedidos na{" "}
          <a href="https://hasa.com.br/backoffice">área do cliente</a> */}
        </p>,
      );
    } else if (svc.budgetStatus === 3) {
      setInfo(
        <p>
          <i className="fa fa-exclamation"></i> - Este orçamento foi recusado
          anteriormente, para solicitar um novo{" "}
          <a href="javascript:void()" onClick={() => openLink()}>
            clique aqui
          </a>
        </p>,
      );
    }
  }

  function openLink() {
    if (userData !== null) {
      setShowBudgetModal(true);
    } else {
      window.location = "https://hasa.com.br/solicite-nossos-servicos/";
    }
  }

  function checkLocation() {
    const currentUrl = window.location.href;
    if (currentUrl.includes("confirm")) {
      toast.success(Message.Saved);
      setTimeout(() => {
        window.location = "https://hasa.com.br";
      }, 1200);
    } else {
      toast.success(
        "O serviço será iniciado imediatamente! Agradecemos sua parceria",
      );
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    }
  }

  async function onTapButton(recvStatus) {
    if (
      window.confirm(
        `Tem certeza que deseja ${status[recvStatus]} este pedido?`,
      )
    ) {
      let reason = "";
      setDisabled(true);

      if (recvStatus === 4) {
        reason = window.prompt(
          "Gostaria de adiantar a sua dúvida por aqui? (Opcional)",
        );

        setDisabledDoubts(true);
        setDisabledRefuse(true);
        if (reason !== undefined && reason !== "") {
          customerDoubts(reason);
        }
      }
      if (recvStatus === 3) {
        await processController.BudgetDeclined(
          props.match.params.key,
          (res) => {
            toast.success(Message.Refused);
            setShowModal(true);
          },
        );
      } else if (recvStatus === 2) {
        if (
          data.customer.cpf === undefined &&
          data.customer.cnpj === undefined
        ) {
          setShowAccountModal(true);
        } else {
          if (serviceGroups.length > 0) {
            if (serviceGroups !== null && serviceGroups.length > 0) {
              for (let y in serviceGroups) {
                const base = serviceGroups[y];
                if (base.type === ServiceGroupType.SingleChoice) {
                  let selected = false;
                  for (let s in base.optionalServices) {
                    const item = base.optionalServices[s];
                    if (item.selected === true) {
                      selected = true;
                    }
                  }

                  if (selected === false) {
                    toast.error(
                      "Você deve selecionar ao menos uma opção de serviço",
                    );
                    return;
                  }
                }
              }
            }
          }

          if (total === 0) {
            toast.error("Você deve selecionar ao menos 1 serviço");
            return;
          }

          await processController.BudgetAccepted(
            props.match.params.key,
            client,
            (res) => {
              // setTimeout(() => {
              if (client === false) {
                checkLocation();
              } else {
                if (
                  data.customer &&
                  data.customer.website !== undefined &&
                  data.customer.website !== null
                ) {
                  window.location = data.customer.website;
                } else {
                  toast.info(Message.CanClose);
                }
              }
              // }, 1650);
            },
          );
        }
      } else {
        let processInfos = {
          ...data,
          budgetStatus: recvStatus,
          status: ProcessStatus.Ongoing,
        };

        if (recvStatus === 2 || recvStatus === 3) {
          // processInfos.endBudgetDate = DateTimeNow();
        }

        updateProcess(processInfos);

        const response = `${
          status[recvStatus] === "Aceitar" || status[recvStatus] === "Recusar"
            ? `Orçamento ${
                status[recvStatus] === "Aceitar" ? " Aprovado" : " Recusado"
              }`
            : `O cliente ${tranlateStatus(status[recvStatus])}`
        }`;

        if (response.includes("solicitou contato")) {
          updateTimeline(
            `${response} ${reason.length > 0 ? `e perguntou: ${reason}` : ""}`,
            true,
          );
        } else {
          if (!response.includes("Orçamento Aprovado")) {
            updateTimeline(response, false);
          }
        }
      }
    } else {
      setDisabled(false);
      toast.info("Cancelado");
    }
  }

  async function acceptBudget() {
    setShowAccountModal(false);
    await processController.BudgetAccepted(
      props.match.params.key,
      client,
      (res) => {
        toast.success(Message.Saved);
        setTimeout(() => {
          window.location = GetBaseUrl();
        }, 1650);
      },
    );
  }

  function tranlateStatus(v) {
    if (v === "Aceitar") {
      return "aceitou";
    } else if (v === "Recusar") {
      return "recusou";
    } else if (v === "Solicitar contato") {
      return "solicitou contato";
    } else {
      return v;
    }
  }

  async function updateProcess(info) {
    await processController.Update(info, (res) => {
      loadPresentation();
    });
  }

  async function customerDoubts(item) {
    await notificationCtrl.Insert(
      {
        message: item,
        processId: parseInt(props.match.params.key),
        view: false,
        type: NotificationTypeContactRequired,
        userId: data.sellerId,
      },
      (res) => {
        toast.success("Salvo com sucesso. Um representante entrará em contato");
      },
    );
  }

  return (
    <div>
      <div
        className="container mb-3 "
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={logo} width="150px" />
            <br />
            <br />
            {info}
          </div>
        </div>
        {show === true ? (
          <div className="mb-5 pb-5">
            <div className="block block-fx-shadow">
              <div className="text-center py-3">
                <h3>Orçamento</h3>
              </div>
              <div className="block-content">
                <table className="table table-bordered table-hover">
                  <tbody>
                    <tr>
                      <td>Responsável pelo Processo</td>
                      <td>
                        {data.responsible &&
                          `${data.responsible.firstName} ${
                            data.responsible.lastName !== undefined
                              ? data.responsible.lastName
                              : ""
                          }`}
                      </td>
                    </tr>
                    <tr>
                      <td>Nome do Processo</td>
                      <td>{data && data.processName}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="">
                  <div className="text-center">
                    <div className="">Observações</div>
                  </div>
                  <div
                    className="block-content mb-3"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {services && client === false
                      ? data && data.observation
                      : data && data.observationEdited}
                  </div>
                </div>
              </div>
            </div>
            {services &&
              services.map((item, index) => {
                if (item.isGroup == false) {
                  return (
                    <ServiceItem
                      service={item}
                      reload={() => loadPresentation()}
                      isClient={client}
                      clientView={true}
                    />
                  );
                } else {
                  return (
                    <ServiceGroupItem
                      group={item}
                      refresh={() => loadPresentation()}
                      clientView={true}
                      preview={client}
                      services={[]}
                      position={index + 1}
                      disabled={
                        (data && data.budgetStatus === BudgetStatus.Acepted) ||
                        (data && data.budgetStatus === BudgetStatus.Refused)
                          ? true
                          : false
                      }
                    />
                  );
                }
              })}

            <div className="block block-fx-pop">
              <div className="py-3 text-center">
                Total : R${total && MoneyMask(total, 2)}
              </div>
            </div>

            <div className="d-flex justify-content-around mb-3">
              {client === false ? (
                <button
                  disabled={disabledDoubts}
                  onClick={() => onTapButton(4)}
                  className="btn btn-hero-primary btn-block"
                >
                  Solicitar Contato
                </button>
              ) : (
                ""
              )}
              {client === false ? (
                <button
                  disabled={disabledRefuse}
                  onClick={() => onTapButton(3)}
                  className="btn btn-hero-danger btn-block"
                >
                  Recusar
                </button>
              ) : (
                ""
              )}
              <button
                disabled={disabled}
                onClick={() => onTapButton(2)}
                className="btn btn-hero-success btn-block"
              >
                Aceitar
              </button>
            </div>

            {userData &&
            userData.level === 5 &&
            client === false &&
            data &&
            data.budgetStatus !== BudgetStatus.Acepted &&
            data.budgetStatus !== BudgetStatus.Refused &&
            (enviroment == "local" || enviroment == "staging") ? (
              <div className="block block-fx-pop block-bordered border-info mt-5">
                <div className="block-header">
                  <div className="block-title">
                    <i className="far fa-check-circle text-info mr-2" /> Agora
                    você pode editar sua margem e enviar esse orçamento direto
                    ao seu cliente!
                    <br />
                    <small>Fique tranquilo, o nome da Hasa não aparece</small>
                  </div>
                  <div className="block-tools">
                    <button
                      disabled={disabled}
                      onClick={() => setShowBusinessModal(true)}
                      className="btn btn-hero-info btn-sm"
                    >
                      Clique aqui
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {services && client === false ? (
              <DocsComponent
                processId={props.match.params.key}
                disabled={data && data.budgetStatus === 3 ? true : false}
                setView={(item) => showDoc(item)}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <ToastContainer className="toastr" />
        <ResearchForm
          show={showModal}
          onHide={() => setShowModal(!showModal)}
          submit={(data) => postSearch(data)}
        />
      </div>

      <CustomerNewBudget
        show={showBudgetModal}
        onHide={() => setShowBudgetModal(false)}
      />

      <CompleteAccountModal
        show={showAccountModal}
        customer={data && data.customer}
        onHide={() => setShowAccountModal(false)}
        confirmSend={() => acceptBudget()}
      />
      {userData &&
      userData.level === 5 &&
      (enviroment == "local" || enviroment == "staging") ? (
        <CustomerBusinessModal
          show={showBusinessModal}
          processId={props.match.params.key}
          onHide={() => setShowBusinessModal(false) | loadPresentation()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ConfirmService;

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function FollowController() {
  async function FollowProcess(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `follow/insert/process`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function FollowCustomer(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `follow/insert/customer`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function FollowingCustomer(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `follow/bycustomer/${customerId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateProcess(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `follow/update/process`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function FollowingCustomerByUser(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `follow/customer/${userId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateProcess(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `follow/update/process`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateCustomer(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `follow/update/customer`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DeleteProcess(id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `follow/delete/process/${id}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DeleteCustomer(id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `follow/delete/customer/${id}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListProcess(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `follow/process/${userId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ListCustomer(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `follow/customer/${userId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    FollowProcess,
    FollowCustomer,
    FollowingCustomer,
    FollowingCustomerByUser,
    UpdateProcess,
    UpdateCustomer,
    DeleteProcess,
    DeleteCustomer,
    ListProcess,
    ListCustomer,
  };
}

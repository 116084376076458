import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { cols } from "../../../main/ColumnHelper";
import { toast } from "react-toastify";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";
// import ReactToExcel from "react-html-table-to-excel";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function AnalysisReportModal({
  showModal,
  onHide,
  infos,
  startDate,
  endDate,
}) {
  const [data, setData] = useState({ notStarted: [], ongoing: [], done: [] });
  const [loading, setLoading] = useState(false);
  const organHelper = OrganListHelper();
  const report = ReportController();

  useEffect(() => {
    if (showModal) {
      loadInfos();
    }
  }, [showModal]);

  async function loadInfos() {
    setLoading(true);
    await report.AnalysisCounters(startDate, endDate, (res) => {
      let infos = res.serviceOrders;
      if (infos !== null && infos !== undefined && infos.length > 0) {
        for (let s in infos) {
          infos[s].serviceName = `${
            infos[s].selectedService !== undefined
              ? infos[s].selectedService.serviceName
              : "S.I."
          }`;
          infos[s].organName = `${
            infos[s].organ !== undefined ? infos[s].organ.name : "S.I."
          }`;
          infos[s].processName = `${
            infos[s].process !== undefined
              ? infos[s].process.processName
              : "S.I."
          }`;
          infos[s].createdDate = DateTranslate(infos[s].createdDate);
          infos[s].updatedDate = DateTranslate(infos[s].updatedDate);
        }
        let notStarted = infos.filter(
          (x) => x.statusAnalysis === organHelper.AnalysisStatus.NotStarted,
        );
        let ongoing = infos.filter(
          (x) =>
            x.statusAnalysis !== organHelper.AnalysisStatus.NotStarted &&
            x.statusAnalysis !== organHelper.AnalysisStatus.Done,
        );
        let done = infos.filter(
          (x) => x.statusAnalysis === organHelper.AnalysisStatus.Done,
        );

        setData({
          notStarted,
          ongoing,
          done,
        });
      } else {
        setData({
          notStarted: [],
          ongoing: [],
          done: [],
        });
      }
      setLoading(false);
    });
  }

  return (
    <Modal title="Análise" show={showModal} onHide={onHide} isLoading={loading}>
      <div className="block-header">
        <div className="block-title">
          {data.notStarted && data.notStarted.length + " Não iniciados"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="analiseNotStarted"
            filename={`Relatorio - Análise 'Não iniciados' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={data.notStarted && data.notStarted.length + " Não iniciados"}
        data={data.notStarted}
        columns={organHelper.AnalysisCols}
        tableId="analiseNotStarted"
        hideHeader={true}
        hide={false}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.notStarted)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.ongoing && data.ongoing.length + " Em andamento"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="analiseOngoing"
            filename={`Relatorio - Análise 'Em andamento' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>
      <Table
        title={data.ongoing && data.ongoing.length + " andamento"}
        tableId="analiseOngoing"
        data={data.ongoing}
        hideHeader={true}
        columns={organHelper.AnalysisCols}
        hide={false}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.ongoing)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.done && data.done.length + " Concluídos"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="done"
            filename={`Relatorio - Análise 'Concluídos' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={data.done && data.done.length + " Concluídos"}
        tableId="done"
        data={data.done}
        hideHeader={true}
        columns={organHelper.AnalysisCols}
        hide={false}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.done)}
      />
    </Modal>
  );
}

import GetBaseUrl from "@/app/core/base/BaseAddress";
import CustomerExigencies from "@/app/core/layouts/main-components/exigencies/CustomerExigencies";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ProcessExigency({
  processId,
  process,
  reload,
  closeOthers,
  openProcess,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (process && process.close == true) {
      setOpen(false);
    }
  }, [process]);

  function toggleCard() {
    setOpen(!open);
    closeOthers(process);
  }

  function notify(message) {
    toast.info(message);
  }

  function openNotification() {
    window.open(`${GetBaseUrl()}#/exigencies/${processId}`, "_blank");
  }

  return (
    <div
      className="block block-fx-shadow bg-black-50"
      // style={{ backgroundImage: "url('assets/media/photos/photo9.jpg')" }}
    >
      <div></div>
      <div className="row bg-black-50 p-1 mt-1 mb-1">
        <div className="col-4 text-white">
          Processo : {process && process.processName}{" "}
        </div>
        <div className="col-8 text-right">
          <button
            className={
              process.waitingExigencies > 0
                ? "btn btn-sm btn-square btn-danger"
                : "btn btn-sm btn-square btn-dark"
            }
            onClick={() =>
              notify(`${process.waitingExigencies} exigências pendentes`)
            }
          >
            <i className="si si-clock"></i>{" "}
            {process && process.waitingExigencies}
          </button>

          <button
            className="btn btn-sm btn-square btn-dark"
            onClick={() => openProcess()}
          >
            <i className="fa fa-eye"></i>
          </button>
          <button
            className="btn btn-sm btn-square btn-dark"
            onClick={() => toggleCard()}
          >
            <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>{" "}
            Resolver exigências
          </button>
        </div>
      </div>
      {open === true ? (
        <div className="ml-1 mr-1 mt-1 mb-1">
          <CustomerExigencies
            processId={processId}
            organListId={process && process.organListId}
            isInternal={true}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

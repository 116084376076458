export function MoneyMask(v, n) {
  let i, f;
  i = Math.floor(v);
  f = v - i;
  if (n !== 0) {
    if (i.toLocaleString() == NaN || i.toLocaleString() == "NaN") {
      return "0,0";
    }
    return `${i.toLocaleString()},${f.toFixed(n).substr(2)}`;
  } else {
    return `${i.toLocaleString()}`;
  }
}

export function ToFixed(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

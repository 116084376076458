import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { SenderController } from "@/app/core/base/controllers/SenderController";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { EmailHelper } from "@/app/core/util/helpers/EmailHelper";

export default function SenderModal({ values, show, onHide, isLoading }) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (values) {
      setData(values);
    } else {
      setData({});
    }
  }, [values]);

  function handleSubmit() {
    SenderController.Insert(data, (res) => {
      toast.success("Salvo com sucesso");
      onHide();
    });
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  return (
    <Modal
      title="Remetente"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={handleSubmit}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label for="example-masked-time">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={data && data.email}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
        <div className="form-group">
          <label for="example-masked-time">Tipo</label>
          <select
            className="form-control"
            name="type"
            value={data && data.type}
            onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
          >
            <option>Selecione</option>
            {EmailHelper.TypeList.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
      </form>
    </Modal>
  );
}

import HistoricController from "../HistoricController";

export function AnalysisHistory(processId, organListId) {
  HistoricController().Insert(
    {
      processId,
      description: "Em Análise (Confirmando informações do pedido)",
      level: 2,
      organListId,
      important: true,
    },
    (res) => {},
  );
}

export function PreparationHistory(processId, organListId) {
  HistoricController().Insert(
    {
      processId,
      description: "Em Preparação (Seu processo esta sendo preparado)",
      level: 3,
      important: true,
      organListId: organListId,
    },
    (res) => {},
  );
}

export function DevolutionHistory(processId) {
  HistoricController().Insert(
    { processId, description: "Aguardando Devolução do órgão", level: 4 },
    (res) => {},
  );
}

export function DevolutionExigencyHistory(processId) {
  HistoricController().Insert(
    { processId, description: "Em devolução. Voltou com exigência", level: 4 },
    (res) => {},
  );
}

export function DevolutionDeferredHistory(processId) {
  HistoricController().Insert(
    { processId, description: "Em devolução. Deferido", level: 4 },
    (res) => {},
  );
}

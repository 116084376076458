import React, { useState } from "react";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import AccountController, {
  UserUpdate,
} from "@/app/core/base/controllers/UsersController";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { PasswordIsStrong } from "@/app/core/util/helpers/UserHelper";

export default function ProfileComponent() {
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState({});
  const [data, setData] = useState(Storage.getUserData());
  const userData = Storage.getUserData();
  const account = AccountController();

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  function handlePasswordChange(evt) {
    setReset(OnDataChange(reset, evt));
  }

  function saveUser() {
    if (window.confirm("Deseja realmente atualizar esses dados?")) {
      UserUpdate(data, (res) => {
        let oldData = Storage.getUserToken();
        oldData.user = res;
        Storage.setUserData(oldData);
        toast.success("Salvo com sucesso");
        setDisable(true);
      });
    }
  }

  async function saveNewPassword() {
    if (reset.currentPassword === reset.newPassword) {
      toast.error("Você não pode usar a senha antiga");
    } else {
      if (!PasswordIsStrong(reset.newPassword)) {
        toast.error(
          "A senha é muito fraca, ela precisa ter ao menos 1 caracter especial e letras maiúsculas",
        );
      } else {
        await account.RestartPassword(
          userData.id,
          { ...reset, email: userData.email },
          (res) => {
            setOpen(!open);
            toast.success("Salvo com sucesso");
          },
        );
      }
    }
  }

  return (
    <div className="block block-rounded">
      <div className="block-header">
        <div className="block-title">Perfil</div>
        <div className="block-tools">
          <button
            className="btn btn-sm btn-outline-info"
            onClick={() => setDisable(!disable)}
          >
            {disable === true ? "Habilitar" : "Desabilitar"}
          </button>
        </div>
      </div>
      <div className="block-content">
        <div className="form-group">
          <span>Nome</span>
          <input
            className="form-control"
            value={data.firstName}
            disabled={disable}
            onChange={(evt) => handleChange(evt)}
            name="firstName"
          />
        </div>
        <div className="form-group">
          <span>Sobrenome</span>
          <input
            className="form-control"
            disabled={disable}
            value={data.lastName}
            onChange={(evt) => handleChange(evt)}
            name="lastName"
          />
        </div>
        <div className="form-group">
          <span>CPF</span>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            disabled={
              data.id && data.cpf !== undefined && data.cpf.length >= 11
                ? true
                : disable
            }
            name="cpf"
            placeholder="999.999.999-99"
            value={data && data.cpf}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "cpf",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>
        <div className="form-group">
          <span>Celular</span>
          <input
            className="form-control"
            disabled={disable}
            value={data.phone}
            onChange={(evt) => handleChange(evt)}
            name="phone"
          />
        </div>

        <button
          className="btn btn-dual mt-3 mb-2"
          onClick={() => setOpen(!open)}
        >
          Alterar a senha
        </button>
        {open === true ? (
          <div>
            <div className="form-group">
              <span>Senha atual</span>
              <input
                className="form-control"
                value={reset.currentPassword}
                onChange={(evt) => handlePasswordChange(evt)}
                name="currentPassword"
              />
            </div>
            <div className="form-group">
              <span>Senha nova</span>
              <input
                className="form-control"
                value={reset.newPassword}
                onChange={(evt) => handlePasswordChange(evt)}
                name="newPassword"
              />
            </div>
            <div className="col-sm-12 mb-3 py-3">
              <small className="">
                * A senha precisa de ao menos 1 caracter especial, letra
                maiúscula, minúscula e número
              </small>
              <br />
            </div>
            <button
              className="btn btn-square btn-info mb-2 mt-2"
              onClick={() => saveNewPassword()}
            >
              Redefinir senha
            </button>
          </div>
        ) : (
          ""
        )}

        <br />
        <button
          className="btn btn-square btn-success mb-2 mt-2"
          onClick={() => saveUser()}
        >
          Salvar
        </button>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { cols } from "../../../main/ColumnHelper";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function BudgetReportModal({
  showModal,
  onHide,
  startDate,
  endDate,
}) {
  const [data, setData] = useState({ open: [], accepted: [], refused: [] });
  const [loading, setLoading] = useState(false);
  const report = ReportController();

  useEffect(() => {
    if (showModal) {
      loadReport();
    }
  }, [showModal]);

  function loadReport() {
    setLoading(true);
    report.BudgetCounters(startDate, endDate, (infos) => {
      if (infos !== null && infos !== undefined && infos.length > 0) {
        for (let s in infos) {
          infos[s].customerName =
            `${infos[s].customer !== undefined ? infos[s].customer.name : "S.I."}`;
          infos[s].createdDate = DateTranslate(infos[s].createdDate);
        }
        let open = infos.filter(
          (x) =>
            x.budgetStatus !== BudgetStatus.Acepted &&
            x.budgetStatus !== BudgetStatus.Refused,
        );
        let accepted = infos.filter(
          (x) => x.budgetStatus === BudgetStatus.Acepted,
        );
        let refused = infos.filter(
          (x) => x.budgetStatus === BudgetStatus.Refused,
        );

        for (let s in refused) {
          if (refused[s].evaluations.length > 0) {
            refused[s].observations =
              `${refused[s].evaluations[0].observation}`;
          }
        }

        setData({
          open,
          accepted,
          refused,
        });
      } else {
        setData({
          open: [],
          accepted: [],
          refused: [],
        });
      }

      setLoading(false);
    });
  }

  return (
    <Modal
      title="Orçamentos"
      show={showModal}
      onHide={onHide}
      isLoading={loading}
    >
      <div className="block-header">
        <div className="block-title">
          {data.open && data.open.length + " Em aberto"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
                        className="btn btn-info mt-3"
                        table="orcamentosAbertos"
                        filename={`Relatorio - Orçamentos Em aberto periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar" /> */}
        </div>
      </div>

      <Table
        title={data.open && data.open.length + " Em aberto"}
        data={data.open}
        columns={cols}
        tableId="orcamentosAbertos"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.open)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.refused && data.refused.length + " Recusados"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
                        className="btn btn-info mt-3"
                        table="orcamentosRecusados"
                        filename={`Relatorio - Orçamentos Recusados periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar" /> */}
        </div>
      </div>

      <Table
        title={data.refused && data.refused.length + " Recusados"}
        tableId="orcamentosRecusados"
        data={data.refused}
        hide={false}
        hideHeader={true}
        columns={cols}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.refused)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.accepted && data.accepted.length + " Aceitos"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
                        className="btn btn-info mt-3"
                        table="orcamentosAceitos"
                        filename={`Relatorio - Orçamentos Aceitos periodo: ${startDate} - ${endDate}`}
                        sheet="sheet 1"
                        buttonText="Exportar" /> */}
        </div>
      </div>
      <Table
        title={data.accepted && data.accepted.length + " Aceitos"}
        tableId="orcamentosAceitos"
        data={data.accepted}
        hide={false}
        hideHeader={true}
        columns={cols}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.accepted)}
      />
    </Modal>
  );
}

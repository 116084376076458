import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { Message } from "@/app/core/util/messages/GeralMessages";
import PreparationStepsController from "@/app/core/base/controllers/PreparationStepsController";
import {
  PreparationStepList,
  PreparationStepTranslate,
} from "@/app/core/util/helpers/UserHelper";

export default function PreparationStepCard({ userId }) {
  const [data, setData] = useState([]);
  const [step, setStep] = useState(0);
  const preparationSteps = PreparationStepsController();

  useEffect(() => {
    loadSteps();
  }, [userId]);

  async function loadSteps() {
    preparationSteps.Get(userId, (res) => setData(res));
  }

  async function addStep() {
    if (step == null || step == undefined) {
      toast.error(Message.PendingData);
    } else {
      await preparationSteps.Insert({ userId, step }, (res) => loadSteps());
    }
  }

  async function removeStep(id) {
    if (window.confirm(Message.DeleteConfirm)) {
      await preparationSteps.Delete(id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadSteps();
      });
    }
  }

  return (
    <div className="block block-fx-pop">
      <div className="block-header">
        <div className="block-title">Atribuições preparação</div>
        <div className="block-tools">
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <i className="si si-plus"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="form-group">
                <span>Selecione o passo</span>
                <select
                  className="form-control"
                  onChange={(evt) => setStep(parseInt(evt.target.value))}
                >
                  <option>Selecione</option>
                  {PreparationStepList.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-square btn-success"
                  onClick={() => addStep()}
                >
                  Salvar
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="block-content">
        <table className="table table-hover">
          <thead>
            <tr>
              <td>Id</td>
              <td>Passo</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{PreparationStepTranslate(item.step)}</td>
                  <td>
                    <button
                      className="btn btn-dual"
                      onClick={() => removeStep(item.id)}
                    >
                      <i className="si si-close text-danger"></i>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import InputMask from "react-input-mask";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { CnpjMask } from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";

export default function SituationOfCompanyModal(
  { show, onHide, infos },
  props,
) {
  const userData = Storage.getUserData();
  const [data, setData] = useState({});
  const [organs, setOrgans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);

  useEffect(() => {
    setData(infos);
    if (infos.organs) {
      setOrgans(
        infos.organs
          .filter(
            (item) =>
              item.status != OrganHelper.CompanyOrganStatus.NotApply &&
              item.status != OrganHelper.CompanyOrganStatus.Pending,
          )
          .sort(function (a, b) {
            return a.position - b.position;
          }),
      );
    }
  }, [show, infos]);

  function save() {}

  function newService(item) {
    let invalids = item.organs.filter(
      (x) => x.status == OrganHelper.CompanyOrganStatus.Irregular,
    );
    if (invalids.length > 0) {
      item.observation = "Regularizar: ";
      for (let s in invalids) {
        item.observation += `${invalids[s].name}; `;
      }
    }
    setSelected(item);
    setShowNewBudgetModal(true);
  }

  function hide() {
    setSelected({});
    onHide();
  }

  return (
    <Modal
      show={show}
      onHide={() => hide()}
      title="Empresa"
      onSave={() => save()}
      onSubmit={() => save()}
      isLoading={loading}
    >
      <div
        className="block block-rounded block-fx-pop mb-2 js-appear-enabled animated fadeIn"
        data-toggle="appear"
      >
        <div className="block-content block-content-full border-left border-3x border-success">
          <div className="d-md-flex justify-content-md-between align-items-md-center">
            <div className="p-1 p-md-3">
              <h3 className="h4 font-w700 mb-1">Nome: {data && data.name}</h3>
              <p className="font-size-sm mb-2">
                <a className="mr-1 mb-1" href="javascript:void(0)">
                  CNPJ: {data && CnpjMask(data.doc)}
                </a>
              </p>
              <p className="font-size-sm text-muted mb-0">
                Dados do responsável:
                <br />
                Nome: {data && data.responsible}
                <br />
                E-mail: {data && data.email}
                <br />
                Celular: {data && data.phone}
                <br />
                Data da última consulta:{" "}
                {data && DateTranslate(data.updatedDate)}
              </p>
            </div>
            <div className="p-1 p-md-3">
              {data.status == CustomerHelper().CompanyStatus.NotConsulted ? (
                ""
              ) : data.status == CustomerHelper().CompanyStatus.Consulting ? (
                ""
              ) : data.isRegular == true ? (
                ""
              ) : (
                <button
                  type="button"
                  class="btn btn-hero-sm btn-hero-success"
                  onClick={() => newService(infos)}
                >
                  Solicitar regularização
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <ul class="timeline timeline-alt mt-0 mb-0" style={{ marginTop: -35 }}>
        {data &&
          organs &&
          organs.map((item) => (
            <li className="timeline-event mt-0 mb-0">
              <div class="media">
                <div className="mr-5 overlay-container overlay-left">
                  <span
                    className={`overlay-item item  item-circle border border-2x border-white timeline-event-icon bg-${item.status == OrganHelper.CompanyOrganStatus.Irregular ? "danger" : "success"}`}
                  >
                    {item.status == OrganHelper.CompanyOrganStatus.Pending ? (
                      <i className="si si-clock" />
                    ) : item.status ==
                      OrganHelper.CompanyOrganStatus.Irregular ? (
                      <i className="fa fa-exclamation-circle " />
                    ) : item.status ==
                      OrganHelper.CompanyOrganStatus.Regular ? (
                      <i className="si si-check " />
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="media-body block block-bordered ml-2 mb-3 row mr-3 p-3">
                  <span className="col-12">
                    {item && item.name}
                    <br />
                    Status:{" "}
                    {item.status == OrganHelper.CompanyOrganStatus.Regular ? (
                      <span className="badge badge-success">Regular</span>
                    ) : item.status ==
                      OrganHelper.CompanyOrganStatus.Pending ? (
                      <span className="badge badge-warning">Consultando</span>
                    ) : (
                      <span className="badge badge-danger">Irregular</span>
                    )}
                    {item && item.docs && item.docs.length > 0 ? (
                      <div>
                        <span>Arquivos</span>
                        {item.docs.map((doc) => {
                          if (doc.visible) {
                            return (
                              <div className="block block-bordered border-2x">
                                <div className="block-header">
                                  <div className="block-title">{doc.name}</div>
                                  <div className="block-tools">
                                    <button
                                      className="btn btn-sm btn-dual"
                                      onClick={() =>
                                        window.open(doc.url, "_blank")
                                      }
                                    >
                                      <i className="far fa-eye"></i> Visualizar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </li>
          ))}
      </ul>

      {data && data.status == CustomerHelper().CompanyStatus.Consulting ? (
        <div className="text-center mt-5">
          <i className="si si-settings text-warning fa-2x"></i>
          <br />
          <h4 className="text-muted">
            Agradecemos a confiança na Hasa Documentos,
            <br /> nossa equipe está consultando a regularidade dessa empresa.
            <br />
            Por favor aguarde - Você será notificado
          </h4>
        </div>
      ) : (
        ""
      )}
      <CustomerNewBudget
        show={showNewBudgetModal}
        infos={selected}
        customerId={infos && infos.customerId}
        onHide={() => setShowNewBudgetModal(false)}
      />
    </Modal>
  );
}

import Storage from "@/app/core/util/Storage";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import React, { useEffect, useState } from "react";
import RefusedBudgetsCard from "../RefusedBudgetsCard";
import RemovedProcessCard from "../RemovedProcessCard";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import CustomerPayments from "@/app/core/layouts/main-components/financial/CustomerPayments";
import CustomerContract from "@/app/core/layouts/main-components/documents/contracts/CustomerContract";
import DeferredCard from "../DeferredCard";
import ExigenciesCard from "../ExigenciesCard";
import OngoingCard from "../OngoingCard";
import BudgetsCard from "../BudgetsCard";
import UsersCardComponent from "@/app/core/layouts/main-components/user/UsersCard";
import CustomerDocCard from "@/app/core/layouts/main-components/documents/CustomerDocCard";
import { ContractList } from "@/app/core/base/controllers/ContractController";
import { CustomerTerms } from "@/app/core/base/controllers/TermsController";
import {
  CepMask,
  CnpjMask,
  CpfMask,
  PhoneMask,
  TelphoneMask,
} from "@/app/core/util/helpers/ContactHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import CustomerTimelineModal from "@/app/core/layouts/main-components/customer/CustomerTimelineModal";
import CompaniesComponent from "@/app/modules/companies/main";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";

export default function TaskCard({ customerId, data, exigencies }) {
  const userData = Storage.getUserData();
  const [budgets, setBudgets] = useState([]);
  const [refusedBudgets, setRefused] = useState([]);
  const [deferred, setDeferred] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [finished, setFinished] = useState([]);
  const [returned, setReturned] = useState([]);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    checkIfItsOK();
    getCustomerSO();
  }, [data]);

  async function checkIfItsOK() {
    CustomerTerms(customerId, (res) => {
      for (let s in res) {
        if (res[s].response === false && userData.level == 5) {
          window.location = "#/verification";
        }
      }
    });

    ContractList(customerId, (res) => {
      let doneContracts = res.filter((x) => x.status !== 0);
      if (
        doneContracts.length === 0 &&
        enviroment !== "local" &&
        userData.level == 5
      ) {
        window.location = "#/verification";
      }
    });
  }

  function getCustomerSO() {
    ProcessController().CustomerList(customerId, (res) => {
      let open = [];

      for (let s in res) {
        res[s].statusName = OrganListHelper().GetCurrentStatus(res[s], true);
        res[s].processName = res[s].process.processName;
        res[s].serviceName = res[s].selectedService.serviceName;
        const item = res[s];
        if (item.status != ProcessStatus.Returned) {
          let exist = open.filter((x) => x.processId === item.processId);

          if (exist.length === 0) {
            let itens = res.filter((x) => x.processId === item.processId);

            let theOne = {};

            itens.sort(function (a, b) {
              if (a.position < b.position) {
                return -1;
              }
              if (a.position > b.position) {
                return 1;
              }
              return 0;
            });

            for (let x in itens) {
              if (itens[x].stage !== 1 && itens[x].stage !== 10) {
                theOne = itens[x];
              }
            }

            item.updatedDate = DateTranslate(item.updatedDate);
            item.createdDate = DateTranslate(item.createdDate);
            open.push(item);
          }
        }
      }

      setServiceOrders(open);
    });

    ProcessController().CustomerDone(customerId, (res) => {
      let done = [];
      for (let s in res) {
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].createdDate = DateTranslate(res[s].createdDate);
        done.push(res[s]);
      }

      setFinished(done);
    });

    ProcessController().CustomerBudgets(customerId, (res) => {
      let budget = [];
      let refuseds = [];
      for (let s in res) {
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].createdDate = DateTranslate(res[s].createdDate);
        if (
          res[s].budgetStatus === 0 ||
          res[s].budgetStatus === 1 ||
          res[s].budgetStatus === 4 ||
          res[s].budgetStatus === 5
        ) {
          res[s].budgetStatusName =
            res[s].budgetStatus === 5
              ? "Aguardando aprovação"
              : "Aguardando Orçamento";
          budget.push(res[s]);
        } else if (res[s].budgetStatus === BudgetStatus.Refused) {
          res[s].budgetStatusName = "Recusado";
          refuseds.push(res[s]);
        }
      }
      setBudgets(budget);
      setRefused(refuseds);
    });
  }

  function loadExigencies() {}

  return (
    <div>
      <Tabs>
        <div className="block block-rounded block-bordered">
          <div className="" style={{ padding: 10 }}>
            {userData && userData.level !== 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="si si-book-open mr-1" /> Informações
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level !== 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="si si-users mr-1" /> Usuários da plataforma
                </button>
              </Tab>
            ) : (
              ""
            )}

            <Tab>
              <button style={{ margin: 10 }} className="btn  btn-light">
                <i className="si si-diamond mr-1" /> Orçamentos em aberto (
                {budgets.length})
              </button>
            </Tab>

            <Tab>
              <button style={{ margin: 10 }} className="btn  btn-light">
                <i className="far fa-clock mr-1" /> Serviços em Andamento
              </button>
            </Tab>
            <Tab>
              <button style={{ margin: 10 }} className="btn  btn-light">
                <i className="fa fa-tasks mr-1" /> Exigências em aberto (
                {exigencies && exigencies.length})
              </button>
            </Tab>
            <Tab disabled={userData && userData.level === 5 ? true : false}>
              <button style={{ margin: 10 }} className="btn  btn-light">
                <i className="far fa-check-circle mr-1" /> Concluídos
              </button>
            </Tab>

            {userData && userData.level !== 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="fa fa-file-signature mr-1" /> Contrato
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level !== 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="si si-credit-card mr-1" /> Cobranças
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level > 6 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="far fa-money-bill-alt mr-1" /> Conta corrente
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level != 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="far fa-window-close mr-1" /> Processos excluídos
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level != 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="si si-bag mr-1" /> Orçamentos recusados
                </button>
              </Tab>
            ) : (
              ""
            )}
            {userData && userData.level != 5 ? (
              <Tab>
                <button style={{ margin: 10 }} className="btn  btn-light">
                  <i className="far fa-building mr-1" /> Empresas
                </button>
              </Tab>
            ) : (
              ""
            )}
          </div>
        </div>
        {userData && userData.level !== 5 ? (
          <Panel>
            <div className="block block-rounded">
              <div className="block-header">
                <div className="title">Informações</div>
                <div className="tools">
                  <button
                    className="btn  btn-outline-info"
                    onClick={() => setShowCustomerModal(true)}
                  >
                    Timeline
                  </button>
                </div>
              </div>
              <div className="block-content">
                <table className="table">
                  <tr>
                    <td>Nome</td>
                    <td>{data && data.name}</td>
                  </tr>
                  <tr>
                    <td> Código de Cliente</td>
                    <td>{data && data.customerCode}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{data && data.email}</td>
                  </tr>
                  <tr>
                    <td>Telefone comercial</td>
                    <td>{data && data.phone && TelphoneMask(data.phone)}</td>
                  </tr>
                  <tr>
                    <td>Telefone celular</td>
                    <td>{data && data.whatsapp && PhoneMask(data.whatsapp)}</td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>{data && data.website && data.website}</td>
                  </tr>
                  <tr>
                    <td>CNPJ</td>
                    <td>{data && data.cnpj && CnpjMask(data.cnpj)}</td>
                  </tr>
                  <tr>
                    <td>CPF</td>
                    <td>{data && data.cpf && CpfMask(data.cpf)}</td>
                  </tr>
                  <tr>
                    <td>Logradouro</td>
                    <td>{data.street}</td>
                  </tr>
                  <tr>
                    <td>Número</td>
                    <td>{data.number}</td>
                  </tr>
                  <tr>
                    <td>Bairro</td>
                    <td>{data.neighborhood}</td>
                  </tr>
                  <tr>
                    <td>Complemento</td>
                    <td>{data.complement}</td>
                  </tr>
                  <tr>
                    <td>CEP</td>
                    <td>{data.zipcode && CepMask(data.zipcode)}</td>
                  </tr>
                  <tr>
                    <td>Local</td>
                    <td>
                      {data && data.city} / {data && data.uf}
                    </td>
                  </tr>
                  <tr>
                    <td>Tipo de conta</td>
                    <td>
                      {data && data.type === 1
                        ? "Pessoa Física"
                        : "Pessoa Jurídica"}
                    </td>
                  </tr>
                  <tr>
                    <td>Observações</td>
                    <td>{data && data.observation}</td>
                  </tr>
                </table>
                <CustomerDocCard customerId={parseInt(customerId)} />
              </div>
            </div>
          </Panel>
        ) : (
          ""
        )}
        {userData && userData.level !== 5 ? (
          <Panel>
            <UsersCardComponent customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        <Panel>
          <BudgetsCard
            getCustomerSO={() => window.location.reload()}
            budgets={budgets}
            refusedBudgets={refusedBudgets}
            customerId={customerId}
          />
        </Panel>
        <Panel>
          <OngoingCard
            getCustomerSO={() => getCustomerSO()}
            deferredData={deferred}
            data={serviceOrders}
          />
        </Panel>

        <Panel>
          <ExigenciesCard data={exigencies} reload={() => loadExigencies()} />
        </Panel>
        <Panel>
          <DeferredCard
            finishedData={finished}
            returnedData={returned}
            customerId={customerId}
            getCustomerSO={() => getCustomerSO()}
          />
        </Panel>

        {userData && userData.level !== 5 ? (
          <Panel>
            <CustomerContract customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        {userData && userData.level !== 5 ? (
          <Panel>
            <CustomerPayments customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        {userData && (userData.level === 7 || userData.level === 10) ? (
          <Panel>
            <FinancialEntries customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        {userData && userData.level != 5 ? (
          <Panel>
            <RemovedProcessCard customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        {userData && userData.level != 5 ? (
          <Panel>
            <RefusedBudgetsCard customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
        {userData && userData.level != 5 ? (
          <Panel>
            <CompaniesComponent customerId={customerId} />
          </Panel>
        ) : (
          ""
        )}
      </Tabs>

      <CustomerTimelineModal
        customerId={data && data.id}
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false)}
      />
    </div>
  );
}

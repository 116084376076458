import FollowController from "@/app/core/base/controllers/FollowController";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";

export default function FollowingProcessButton({ processId }) {
  const [data, setData] = useState([]);
  const userData = Storage.getUserData();
  const followCtrl = FollowController();

  useEffect(() => {
    loadFollow();
  }, [processId]);

  async function loadFollow() {
    await followCtrl.ListProcess(userData.id, (res) => {
      let current = res.filter((x) => x.processId == processId);
      setData(current);
    });
  }

  async function unfollow() {
    await followCtrl.DeleteProcess(data[0].id, (res) => {
      loadFollow();
    });
  }

  async function follow() {
    await followCtrl.FollowProcess(
      { processId: parseInt(processId), userId: userData.id },
      (res) => {
        loadFollow();
      },
    );
  }

  return (
    <span>
      {data && data.length > 0 ? (
        <button className="btn btn-sm btn-warning" onClick={() => unfollow()}>
          <i className="si si-close mr-2" />{" "}
          {userData && userData.level != 5
            ? "Deixar de seguir"
            : "Parar notificações"}
        </button>
      ) : (
        <button className="btn btn-sm btn-info" onClick={() => follow()}>
          <i className="si si-check mr-2" />{" "}
          {userData && userData.level != 5
            ? "Seguir processo"
            : "Receber notificações"}
        </button>
      )}
    </span>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Storage from "@/app/core/util/Storage";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import BaseService from "@/app/core/base/BaseService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditUserComponent from "./EditProfileModal";
import { UserPictureUpload } from "@/app/core/base/controllers/UsersController";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";

export default function ProfileMainComponent(props) {
  const userData = Storage.getUserData();
  const tokenData = Storage.getUserToken();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [user, setUser] = useState({});
  const inputFile = useRef(null);

  function toggleModal() {
    setShowModal(!showModal);
  }

  useEffect(() => {
    setUser(userData);
  }, [refresh]);

  function newPic() {
    inputFile.current.click();
  }

  function uploadNewPic(file) {
    console.log("Files", file);
    var formData = new FormData();
    formData.append("file", file);
    UserPictureUpload(formData, userData.id, (res) => {
      let userNew = { ...userData, avatarImage: res };

      Storage.setUserData({
        token: tokenData.token,
        user: userNew,
      });

      console.log("Novaço", userNew);

      setUser(userNew);
      toast.info("Upload concluído");
    });
  }
  return (
    <div>
      <div className={Storage.getTheme()}>
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <div className="block block-content">
              <div className="block block-rounded text-center">
                <div className="block-content block-content-full bg-image">
                  <input
                    type="file"
                    id="profilePic"
                    ref={inputFile}
                    onChange={(event) => {
                      uploadNewPic(event.currentTarget.files[0]);
                    }}
                    style={{ visibility: "hidden" }}
                  />
                  <img
                    className="img-avatar img-avatar-thumb"
                    src={
                      user.avatarImage === undefined
                        ? `${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`
                        : user.avatarImage.url
                    }
                    alt="UserPicture"
                    onClick={() => newPic()}
                  />
                  <p>
                    <b>
                      {user && user.firstName} {user && user.lastName}
                    </b>
                    <br />
                    ID #{user && user.id}
                  </p>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Horizontal Success"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={() => {
                        toggleModal();
                      }}
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </div>
              <table className="table">
                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>Telefone</td>
                  <td>{user.phone}</td>
                </tr>
                <tr>
                  <td>Departamento</td>
                  <td>{DepartmentTranslate(user.level)}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <EditUserComponent
        show={showModal}
        onHide={() => setShowModal(false) | setRefresh(Date.now())}
      />
      <ToastContainer className="toastr" />
    </div>
  );
}

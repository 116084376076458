import OrganListController from "@/app/core/base/controllers/OrganListController";
import Storage from "@/app/core/util/Storage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AddOrganButton({ selectedOrgan, disabled }) {
  const [organs, setOrgans] = useState(Storage.getOrgans());
  const [organId, setOrganId] = useState(0);
  const organCtrl = OrganListController();

  useEffect(() => {
    loadOrgans();
  }, []);

  async function loadOrgans() {
    if (!organs || organs.length == 0) {
      await organCtrl.OrgansSelect((res) => {
        setOrgans(res);
      });
    }
  }

  function addNewOrgan() {
    if (organId !== undefined && organId !== 0) {
      let item = organs.filter((x) => x.id === organId);
      selectedOrgan(item[0]);
    } else {
      toast.error("Selecione o órgão para prosseguir");
    }
  }

  return (
    <Dropdown disabled={disabled}>
      <Dropdown.Toggle
        variant={"light"}
        className="btn btn-sm btn-dual"
        disabled={disabled}
      >
        <i className="si si-list" /> Adicionar órgão
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="form-group">
          <span>Órgão</span>
          <select
            className="form-control"
            name="organId"
            valie={organId}
            onChange={(evt) => setOrganId(parseInt(evt.target.value))}
          >
            <option value={null}>Selecione</option>
            {organs.map((organ) => (
              <option value={organ.id}>{organ.name}</option>
            ))}
          </select>
        </div>
        <div className="text-center">
          <button
            className="btn btn-sm btn-square btn-sucess"
            onClick={() => addNewOrgan()}
            disabled={disabled}
          >
            Salvar
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

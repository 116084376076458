import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import BaseService from "@/app/core/base/BaseService";
import { cols } from "./ColumnHelper";
import Storage from "@/app/core/util/Storage";
import { Redirect } from "react-router-dom";

export default function SearchComponent(props) {
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const features = Storage.getFeatures();

  function editAction(data) {}

  function viewAction(recv) {}

  return (
    <div className="content">
      <div className="row">
        <Table
          title="Linha do Tempo"
          loading={tableLoading || false}
          data={props.data}
          columns={cols}
          refreshData={() => setRefreshData(!refreshData)}
          viewAction={(data) => viewAction(data)}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import Logo from "@/assets/img/logo.jpg";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginAccount } from "@/app/core/base/controllers/UsersController";

export default function SignIn(props) {
  const [authenticate, setAuthenticate] = useState(false);

  function onSubmit(form) {
    LoginAccount(form.values, (res) => {
      Storage.setUserData(res);
      setAuthenticate(true);
      toast.info("Bem vindo...");
    });
  }

  if (authenticate) return <Redirect to="/verification" />;
  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="index.html"
                  >
                    <img src={Logo} style={{ maxWidth: "90px" }} />
                  </a>
                  <p className="text-uppercase font-w700 font-size-sm text-muted">
                    Entrar
                  </p>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-10 col-xl-10">
                    <RegisterForm submit={(form) => onSubmit(form)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Sua experiência está sendo atualizada!
                  <br />
                  Para sempre ;)
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright <b>Hasa</b> &copy;{" "}
                  {/* <span className="js-year-copy">
                    {new Date().getFullYear()}- Desenvolvido pela{" "}
                    <a
                      className="text-white"
                      href="https://navigate.one"
                      target="_blank"
                      onClick={() => {
                        window.location = "/";
                      }}
                    >
                      Navigate
                    </a>
                  </span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}

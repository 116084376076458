import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import ProcessExigency from "../../exigencies/ProcessExigency";

export default function ExigenciesCard({ reload, data }) {
  const userData = Storage.getUserData();
  const [query, setQuery] = useState("");
  const [serviceOrders, setServiceOrders] = useState([]);
  const [header, setHeader] = useState({
    id: "DESC",
    processName: "DESC",
    serviceName: "DESC",
    statusName: "DESC",
    createdDate: "DESC",
    updatedDate: "DESC",
  });
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [operationData, setOperationData] = useState({});
  const organHelper = OrganListHelper();
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    console.log("ProcessExigencies", data);
    setServiceOrders(data);
  }, [data]);

  function handleChange(value) {
    setQuery(value);
    let sv = [];

    if (value.length > 0) {
      setServiceOrders([]);
      sv = autocomplete(value, data);
      setServiceOrders(sv);
    } else {
      sv = [];
      setServiceOrders(data);
    }
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function autocomplete(value, data) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.processName.toLowerCase();
      if (name.includes(inputValue)) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  function closeOthers(item, index) {
    let old = serviceOrders;

    for (let s in old) {
      if (s != index) {
        old[s].close = true;
      } else {
        old[s].close = false;
      }
    }

    setServiceOrders(old);
    notifyChange();
    console.log("Fechou?", old);
  }

  return (
    <div className="block block-rounded">
      <div className="block-header">
        <div className="block-title">Exigências</div>
        <div className="block-tools">
          <button className="btn btn-dual btn-sm" onClick={() => reload()}>
            <i className="si si-refresh"></i>
          </button>
        </div>
      </div>
      <div className="block-content">
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={query}
              onChange={(evt) => handleChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuery("") | setServiceOrders(data)}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
        {change &&
          serviceOrders.map((item, index) => (
            <ProcessExigency
              processId={item.processId}
              organListId={item.organListId}
              process={item}
              closeOthers={() => closeOthers(item, index)}
              openProcess={() =>
                setOperationData({
                  ...item,
                  processId: item.processId,
                }) | setShowOperationModal(true)
              }
              reload={() => reload()}
            />
          ))}
      </div>
      <OperationProcessModal
        data={operationData}
        show={showOperationModal}
        submit={(updateBudget) => null}
        onHide={() =>
          setOperationData(null) | reload() | setShowOperationModal(false)
        }
      />
    </div>
  );
}

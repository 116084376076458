import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import DocInput from "../DocInput";
import {
  ContractDelete,
  ContractList,
  UploadContract,
} from "@/app/core/base/controllers/ContractController";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import ContractItem from "./ContractItem";
import { Dropdown } from "react-bootstrap";

export default function CustomerContract({ customerId }) {
  const userData = Storage.getUserData();
  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState({});
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    loadContracts();
    loadCustomer();
  }, []);

  function loadCustomer() {
    CustomerSelect(customerId, (res) => {
      setCustomer(res);
    });
  }

  function uploadFile() {
    var formData = new FormData();
    for (let s in files) {
      formData.append("file", files[s]);
    }

    UploadContract(customerId, formData, status, (res) => {
      loadContracts();
      setFiles([]);
    });
  }

  function loadContracts() {
    ContractList(customerId, (res) => {
      setData(res);
    });
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Contrato</h3>
          <div className="block-tools">
            {userData && userData.level !== 5 ? (
              <Dropdown drop="left">
                <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                <Dropdown.Menu style={{ width: 310 }}>
                  <div className="form-group">
                    <span>Status do contrato</span>
                    <select
                      className="form-control"
                      onChange={(evt) => setStatus(parseInt(evt.target.value))}
                    >
                      <option value={0}>Não assinado</option>
                      <option value={1}>Assinado pelo cliente</option>
                      <option value={2}>Assinado pela Hasa</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <span>
                      {files && files.length > 0
                        ? `Arquivo : ${files[0].name}`
                        : ""}
                    </span>
                    <DocInput
                      multiple={false}
                      uploadFile={(itens) => setFiles(itens)}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-square btn-success"
                      onClick={() => uploadFile()}
                    >
                      Salvar
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="block-content">
          {data &&
            data.map((item) => (
              <ContractItem item={item} refresh={() => loadContracts()} />
            ))}
        </div>
      </div>
    </>
  );
}

import { MoneyMask } from "./MoneyHelper";
import React from "react";
import {
  DateTranslate,
  DateTranslateToTimestamp,
  OnlyDateTranslate,
} from "../DateTranslater";

export const BudgetStatusList = [
  { name: "Não iniciado", value: 0 },
  { name: "Aguardando orçamento", value: 1 },
  { name: "Confirmado", value: 2 },
  { name: "Recusado", value: 3 },
  { name: "Retornar ao cliente", value: 4 },
  { name: "Aguardando Aprovação", value: 5 },
  { name: "Reavaliação", value: 6 },
  { name: "Removido", value: 9 },
];

export const BudgetStatus = {
  NotStarted: 0,
  WaitingBudget: 1,
  Acepted: 2,
  Refused: 3,
  ReturnToCustomer: 4,
  WaitingApproval: 5,
  Reavaliation: 6,
  Removed: 9,
};

export const DiscountType = {
  Value: 0,
  Percentage: 1,
};

export const DiscountTypeList = [
  { name: "Valor", value: 0 },
  { name: "Porcentagem", value: 1 },
];

export function DiscountTranslate(i) {
  const res = DiscountTypeList.filter((x) => x.value === i);
  if (res.length > 0) {
    return res.name;
  } else {
    return "S.I.";
  }
}

export function ProcessPrice(service) {
  let basePrice = service.price;
  let date = new Date();
  const discounts = service.discounts;

  if (discounts !== undefined && discounts.length > 0) {
    let disclaimers = [];
    for (let s in discounts) {
      const base = discounts[s];
      if (
        base.hasValidity !== true ||
        (base.hasValidity === true &&
          new Date(base.dueDate).getTime() >= date.getTime())
      ) {
        if (base.type === DiscountType.Value) {
          basePrice -= base.value;
        } else if (base.type === DiscountType.Percentage) {
          basePrice -= (base.value * service.price) / 100;
        }
        disclaimers.push(
          <span className="badge badge-warning ml-2">
            {base.type === DiscountType.Value
              ? `R$${MoneyMask(base.value, 2)}`
              : `${base.value}%`}{" "}
            de desconto{" "}
            {base.hasValidity === true ? (
              <td className="">
                <i className="si si-calendar mr-1" /> Válido até{" "}
                {OnlyDateTranslate(base.dueDate)}
              </td>
            ) : (
              ""
            )}
          </span>,
        );
      }
    }
    return (
      <div>
        De <del>R${MoneyMask(service.price, 2)}</del> por apenas{" "}
        <span className="text-success">R${MoneyMask(basePrice, 2)}</span>
        {disclaimers && disclaimers.map((item) => item)}
      </div>
    );
  } else {
    return <p className="text-success">R${MoneyMask(basePrice, 2)}</p>;
  }
}

export function ProcessTotal(services) {
  let price = 0;
  let date = new Date();

  for (let s in services) {
    const service = services[s];
    const discounts = service.discounts;
    let basePrice = service.price;
    if (service.selected === true || service.selected === undefined) {
      if (discounts !== undefined && discounts.length > 0) {
        for (let s in discounts) {
          const base = discounts[s];
          if (
            base.hasValidity !== true ||
            (base.hasValidity === true &&
              new Date(base.dueDate).getTime() >= date.getTime())
          ) {
            if (base.type === DiscountType.Value) {
              basePrice -= base.value;
            } else if (base.type === DiscountType.Percentage) {
              basePrice -= (base.value * service.price) / 100;
            }
          }
        }
      }

      price += basePrice;
    }
  }

  return price;
}

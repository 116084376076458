import BaseService from "@/app/core/base/BaseService";
import React from "react";

export function SendNotification(item) {
  BaseService(
    {
      method: "POST",
      url: "send-notification",
      data: item,
    },
    (res) => {},
    (err) => {},
  );
}

export function NotifyNew(updated) {
  let date = new Date(updated);
  const now = Date.now();
  const createdAt = date;
  const oneDay = 24 * 60 * 60 * 1000;
  const isMoreThanADay = now - createdAt < oneDay;

  if (isMoreThanADay) {
    return <span className="text-warning">Novo</span>;
  }
}

export function NotifyIfIsNew(updated) {
  let date = new Date(updated);
  const now = Date.now();
  const createdAt = date;
  const oneDay = 24 * 60 * 60 * 1000;
  const isMoreThanADay = now - createdAt < oneDay;

  if (isMoreThanADay) {
    return true;
  } else {
    return false;
  }
}

export const NotificationTypeContactRequired = 1;
export const NotificationTypeBudgetDeclined = 2;
export const NotificationTypeBudgetAccepted = 3;
export const NotificationTypeExigencyFulfillment = 4;
export const NotificationTypeFileUpload = 5;
export const NotificationTypeGeneral = 6;

export function VerifyExigencyState(sts) {
  if (sts === 3) {
    return "border-success";
  } else if (sts === 1) {
    return "border-warning";
  } else if (sts === 2) {
    return "border-info";
  } else {
    return "border-danger";
  }
}

export function ChannelTranslate(item) {
  switch (item) {
    case 1:
      return "Email";
      break;
    case 2:
      return "Whatsapp";
      break;
    case 3:
      return "Chat";
      break;
    case 4:
      return "Telefone";
      break;
    case 5:
      return "Recepção";
      break;
    default:
      return "Sistema";
      break;
  }
}

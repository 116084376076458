import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import BaseService from "@/app/core/base/BaseService";
import MotivationalForm from "./MotivationalForm";
import {
  MotivationalDelete,
  MotivationalInsert,
  MotivationalList,
} from "@/app/core/base/controllers/MotivationalController";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";
import { DateTranslate } from "@/app/core/util/DateTranslater";

export default function MotivationalComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    getMotivational();
  }, []);

  function getMotivational() {
    MotivationalList((res) => {
      setData(res);
      setTableLoading(false);
    });
  }

  function postMotivational(data) {
    setModalLoading(true);

    var formData = new FormData();
    for (let s in data) {
      formData.append("file", data[s]);
    }

    MotivationalInsert(formData, (res) => {
      setModalLoading(false);
      setShowModal(false);
      getMotivational();
      props.toast.info("Serviço Cadastrado com Sucesso");
    });
  }

  function removeMotivational(item) {
    if (window.confirm(DeleteConfirm)) {
      MotivationalDelete(item.id, (res) => {
        getMotivational();
        props.toast.info("Removido com sucesso");
      });
    }
  }

  return (
    <>
      <Card
        action={() => setShowModal(!showModal)}
        icon="plus"
        title="Mensagens"
      >
        <table className="table">
          {data &&
            data.map((item) => (
              <tr>
                <td>
                  <img
                    className="img-avatar img-avatar-thumb"
                    src={item.url && item.url}
                    alt="inspirationImage"
                  />
                </td>
                <td>{item.level === 5 ? "Cliente" : "Equipe interna"}</td>
                <td>{DateTranslate(item.createdDate)}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-dual"
                    data-toggle="layout"
                    data-action="side_overlay_toggle"
                    onClick={() => {
                      removeMotivational(item);
                    }}
                  >
                    <i class="far fa-fw fa-times-circle"></i>
                  </button>
                </td>
              </tr>
            ))}
        </table>
      </Card>
      <MotivationalForm
        show={showModal}
        isLoading={modalLoading}
        onHide={() => setShowModal(!showModal) | getMotivational()}
        submit={(service) => postMotivational(service)}
        title="Mensagens Home"
      />
    </>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";

export default function CustomerDocController() {
  async function Upload(customerId, name, formData, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `customerdoc/upload?folder=${customerId}&name=${name}`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function List(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `customerdoc/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function Delete(docId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `customerdoc/${docId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  return {
    Upload,
    List,
    Delete,
  };
}

import React, { useEffect } from "react";
import Logo from "@/assets/img/logo.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerClosure from "@/app/core/layouts/main-components/documents/CustomerClosure";

export default function ClosureComponent(props) {
  useEffect(() => {}, []);

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={Logo} width="150px" />
          </div>
          <div className="col-md-12">
            <CustomerClosure processId={props.match.params.key} />
          </div>
        </div>
        <ToastContainer className="toastr" />
      </div>
    </div>
  );
}

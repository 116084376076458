import {
  SendTermToAll,
  TermsDelete,
} from "@/app/core/base/controllers/TermsController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import React, { useState } from "react";
import FileViewer from "../../FileViewer";
import DocButton from "../DocButton";
import Storage from "@/app/core/util/Storage";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";

export default function TermItem({ data, loadTerms }) {
  const [open, setOpen] = useState(false);
  const userData = Storage.getUserData();

  function removeContract(item) {
    if (window.confirm(DeleteConfirm))
      TermsDelete(item.id, (res) => {
        loadTerms();
      });
  }

  function downloadDoc(item) {
    window.open(item.url, "_blank");
  }

  function sendToAll() {
    if (
      window.confirm(
        "Tem certeza de que deseja enviar a todos os clientes que não aceitaram esse termo ainda?",
      )
    ) {
      SendTermToAll(data.id, (res) => {
        toast.success(res.message);
      });
    }
  }

  return (
    <div
      className={`block block-rounded border-left border-5x block-fx-pop mb-2`}
      key={data.id}
    >
      <div className="block-header">
        <div className="block-title">{data.name}</div>
        <div className="block-tools">
          {userData && userData.level !== 5 ? (
            <DocButton
              data={data}
              title="Enviar para todos que não aceitaram"
              style={"text-dark mb-1"}
              action={() => sendToAll()}
              icon={"si si-envelope-letter"}
            />
          ) : (
            ""
          )}
          {userData && userData.level !== 5 ? (
            <DocButton
              data={data}
              title="Download"
              style={"text-dark mb-1"}
              action={() => downloadDoc(data)}
              icon={"fa fa-fw fa-file-download"}
            />
          ) : (
            ""
          )}
          <DocButton
            data={data}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setOpen(!open)}
            icon={"fa fa-fw fa-eye"}
          />
          {userData && userData.level !== 5 ? (
            <DocButton
              data={data}
              title="Remover"
              style={"text-dark mb-1"}
              action={() => removeContract(data)}
              icon={"si si-close"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <table className="table table-borderless table-vcenter mb-0">
        <tbody>
          <tr>
            {userData && userData.level !== 5 ? (
              <td className="text-left">
                Aceito por {data.accepteds} de {data.open}
              </td>
            ) : (
              ""
            )}
            {userData && userData.level !== 5 ? (
              <td className="text-right">{DateTranslate(data.updatedDate)}</td>
            ) : (
              ""
            )}
          </tr>
        </tbody>
      </table>
      {open === true ? (
        <div className="block-content text-center">
          <FileViewer file={data.url} height={450} openImage={(data) => null} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

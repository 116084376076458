import ReportController from "@/app/core/base/controllers/ReportController";
import React, { useEffect, useState } from "react";
import DeferredReportModal from "./Modals/DeferredReportModal";
import Storage from "@/app/core/util/Storage";

export default function DeferredCounter({ startDate, endDate }) {
  const [showModal, setShowModal] = useState(false);
  const report = ReportController();
  const [data, setData] = useState({
    total: 0,
    first: 0,
    second: 0,
    third: 0,
    firstPercentage: 0,
    secondPercentage: 0,
    thirdPercentage: 0,
  });

  useEffect(() => {
    loadCounters();
  }, [startDate, endDate, showModal]);

  function loadCounters() {
    // const oldResult = Storage.getDeferredCounter();
    // if (oldResult == null || (oldResult.startDate != startDate || oldResult.endDate != endDate)) {
    report.DeferredCounters(startDate, endDate, (res) => {
      res.firstPercentage = parseInt((res.first / res.total) * 100);
      res.secondPercentage = parseInt((res.second / res.total) * 100);
      res.thirdPercentage = parseInt((res.third / res.total) * 100);

      res.startDate = startDate;
      res.endDate = endDate;

      // Storage.setDeferredCounter(res);

      setData(res);
    });
    // } else {
    // setData(oldResult);
    // }
  }

  return (
    <div>
      <a
        class="block block-rounded bg-info"
        href="javascript:void(0)"
        onClick={() => setShowModal(true)}
      >
        <div class="block-content bg-info-dark">
          <p class="text-white text-uppercase font-size-sm font-w700">
            Deferidos
          </p>
        </div>
        <div class="block-content block-content-full">
          <div class="row text-center">
            <div class="col-3">
              <div class="py-3 border-right border-black-op">
                <div class="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.total}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">100%</p>
                <p class="text-white-75 mb-0">Total</p>
              </div>
            </div>
            <div class="col-3">
              <div class="py-3 border-right border-black-op">
                <div class="item item-circle text-white bg-black-25 mx-auto">
                  {data && data.first}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.firstPercentage}%
                </p>
                <p class="text-white-75 mb-0">Deferidos de 1ª</p>
              </div>
            </div>
            <div class="col-3">
              <div class="py-3 border-black-op">
                <div class="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.second}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.secondPercentage}%
                </p>
                <p class="text-white-75 mb-0">Deferidos de 2ª</p>
              </div>
            </div>
            <div class="col-3">
              <div class="py-3 border-black-op">
                <div class="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.third}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.thirdPercentage}%
                </p>
                <p class="text-white-75 mb-0">Deferidos de 3ª</p>
              </div>
            </div>
          </div>
        </div>
      </a>
      <DeferredReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        infos={showModal && data}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}

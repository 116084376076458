import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function RemunerationController() {
  async function Insert(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `remuneration/insert`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `remuneration/update`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(remunerationId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `remuneration/delete/${remunerationId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(serviceorganId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `remuneration/serviceorgan/${serviceorganId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    List,
    Update,
    Delete,
  };
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ProcessController() {
  async function BudgetAccepted(processId, client, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/accept/${processId}?client=${client}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ProspectInsert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/prospect/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function BudgetDeclined(processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/decline/${processId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function InternalFeedback(processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/internalfeedback/${processId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerReturn(processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/customerReturn/${processId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(id, motive, reimbursementType, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `process/delete/${id}?motive=${motive}&reimbursementType=${reimbursementType}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Recover(id, motive, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/recover/${id}?motive=${motive}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Every(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/every`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Returned(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/returned/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function TheProcess(organListId, processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/operation?organListId=${organListId != null ? organListId : 0}&processId=${processId != null ? processId : 0}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        error === undefined ? toast.error(HandleErrors(err)) : error(err);
      },
    );
  }

  async function Tasks(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/user/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function OperatorTasks(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/operator/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ByDate(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/dates/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ConsultReport(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/consult/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Select(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "process/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "process/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function End(processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `process/end/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerList(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerBudgets(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/budgets/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerDone(id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/done/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerDeleted(
    customerId,
    startDate,
    endDate,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/deleted/${customerId}?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerRefused(
    customerId,
    startDate,
    endDate,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/refuseds/${customerId}?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerProcessByDays(id, days, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/customer/days/${id}/?days=${days}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerProcessExigencies(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/exigency/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UserReport(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `global/operator/reports/${userId}?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ReactivateBudget(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `process/reactivate-budget/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    Delete,
    Recover,
    Select,
    ConsultReport,
    BudgetAccepted,
    BudgetDeclined,
    Tasks,
    OperatorTasks,
    UserReport,
    End,
    CustomerList,
    CustomerBudgets,
    CustomerDone,
    CustomerProcessByDays,
    CustomerProcessExigencies,
    ByDate,
    ProspectInsert,
    Every,
    InternalFeedback,
    CustomerReturn,
    CustomerDeleted,
    CustomerRefused,
    TheProcess,
    Returned,
    ReactivateBudget,
  };
}

import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

/* ---------- Feedback ---------- */
export async function FeedbackInsert(dataList, response) {
  await BaseService(
    {
      method: "POST",
      url: `feedback/insert`,
      data: dataList,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `feedback/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackGet(id, startDate, endDate, response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/quiz/${id}?startDate=${startDate}&endDate=${endDate}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

/* ---------- End Feedback ---------- */

/* ---------- FeedbackStructure ---------- */
export function FeedbackStructureInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/structure/insert`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackStructureUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/structure/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackStructureDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `feedback/structure/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (
        err.response &&
        err.response.status > 0 &&
        err.response.status < 399
      ) {
        response(err.response.data);
      } else {
        toast.error(HandleErrors(err));
      }
    },
  );
}

export function FeedbackStructureList(response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/structure`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackStructureByQuizId(quizId, response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/structure/quiz/${quizId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

/* ---------- End FeedbackStructure ---------- */

/* ---------- FeedbackValue ---------- */
export function FeedbackValueInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/feedbackvalues/insert`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackValueUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/feedbackvalue/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackValueDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `feedback/feedbackvalue/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackValueList(response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/feedbackvalue`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FeedbackValueByStructure(structureId, response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/feedbackvalue/${structureId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

/* ---------- End FeedbackValue ---------- */

/* ---------- Form ---------- */

export function FormUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `feedback/forms/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FormDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `feedback/form/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FormList(response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/forms`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function FormCustomerList(customerId, response) {
  BaseService(
    {
      method: "GET",
      url: `feedback/forms/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

/* ---------- End Form ---------- */

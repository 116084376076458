import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ObjectiveController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `objective/insert`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `objective/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function List(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `objective/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function Get(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `objective/list`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }
  async function Report(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `objective/report/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(docId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `objective/delete/${docId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    Delete,
    List,
    Get,
  };
}

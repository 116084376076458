import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ServiceStepController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `servicesteps/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(stepId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `servicesteps/delete/${stepId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(serviceId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `servicesteps/service/${serviceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Delete,
    List,
  };
}

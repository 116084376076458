import React, { useState, useEffect } from "react";
import OrgansModal from "../Modals/OrgansModal";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";

export default function OrgansCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const userData = Storage.getUserData();
  useEffect(() => {
    getModels();
  }, []);

  async function getModels() {
    await organCtrl.OrgansSelect((res) => {
      let results = [];
      for (let s in res) {
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].description =
          res[s].description === null ? "" : res[s].description;
        results.push(res[s]);
      }
      setData(results);
    });
  }

  function showEditItem(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  async function deleteItem(item) {
    if (userData.level !== 10) {
      toast.info(
        "Seu nível de acesso não permite a exclusão de um cliente, solicite ao administrador",
      );
    } else {
      if (window.confirm(Message.DeleteConfirm)) {
        await organCtrl.OrganDelete(item.data.id, (res) => {
          getModels();
        });
      }
    }
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header bg-xwork">
          <h3 className="block-title">Órgãos</h3>
          <div className="block-options">
            <button
              type="button"
              className="btn-block-option"
              onClick={() => setShowModal(true)}
            >
              <i className={`si si-plus`}></i>
            </button>
          </div>
        </div>
        <div className="block-content">
          <SampleTable
            title="Órgãos"
            cols={organHelper.OrganCols}
            data={data}
            remove={(task) => deleteItem(task)}
            refresh={() => getModels()}
            newItem={() => setShowModal(true)}
            open={(item) => showEditItem(item)}
          />
        </div>
      </div>
      <OrgansModal
        show={showModal}
        infos={selected}
        onHide={() => setShowModal(!showModal) | getModels() | setSelected({})}
      />
    </>
  );
}

import React from "react";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";

export default function ConfirmProcessReview({ process, reload }) {
  const processCtrl = ProcessController();
  const userData = Storage.getUserData();

  function processButton() {
    let message = "Revisei o ";
    if (process.internalFeedback === true) {
      message += " Feedback Interno";
    }

    if (process.internalFeedback === true && process.customerReturn === true) {
      message += " e o ";
    }

    if (process.customerReturn === true) {
      message += " Retorno do cliente";
    }

    if (process.internalFeedback === true || process.customerReturn === true) {
      return (
        <button
          className="btn btn-outline btn-info btn-square"
          onClick={() => updateResponse()}
        >
          {message}
        </button>
      );
    }
    if (process.reavaliation < userData.level && process.reavaliation > 0) {
      return (
        <button
          className="btn btn-outline btn-warning btn-square"
          onClick={() => updateReavaliation()}
        >
          Confirmado Reavaliação
        </button>
      );
    }
  }

  async function updateResponse() {
    if (window.confirm("Deseja continuar?")) {
      await processCtrl.CustomerReturn(process.id, false, async (res) => {
        await processCtrl.InternalFeedback(process.id, false, (res) => {
          toast.info("Salvo com sucesso");
          if (reload !== undefined) {
            reload();
          }
        });
      });
    }
  }

  async function updateReavaliation() {
    if (window.confirm("Deseja continuar?")) {
      await processCtrl.Update({ ...process, reavaliation: 0 }, async (res) => {
        toast.info("Salvo com sucesso");
        await NotifyUpdate(
          process.processId,
          "Confirmou a reavaliação",
          false,
          TimelineChannelSistema,
          process.id,
        );
        if (reload !== undefined) {
          reload();
        }
      });
    }
  }

  return (
    <div>{process && process.id !== undefined ? processButton() : ""}</div>
  );
}

import { DateTranslate } from "../DateTranslater";
import React from "react";
import { EmailHelper } from "./EmailHelper";

export function organizeTasks(infos, level) {
  if (level === 6) {
    for (let s in infos) {
      if (infos[s].customer !== null) {
        infos[s].customerName = infos[s].customer.name;
      }
      infos[s].time = new Date(infos[s].createdDate).getTime();
      infos[s].updatedDateString = DateTranslate(infos[s].updatedDate);
      infos[s].createdDateString = DateTranslate(infos[s].createdDate);
    }

    infos = organize(infos);

    return infos;
  } else if (level === 1) {
    for (let s in infos.closed) {
      if (infos.closed[s].customer !== null) {
        infos.closed[s].customerName = infos.closed[s].customer.name;
      }
      infos.closed[s].time = new Date(infos.closed[s].updatedDate).getTime();
      infos.closed[s].updatedDate = DateTranslate(infos.closed[s].updatedDate);
      infos.closed[s].createdDate = DateTranslate(infos.closed[s].createdDate);
    }

    for (let s in infos.ongoing) {
      if (infos.ongoing[s].customer !== null) {
        infos.ongoing[s].customerName = infos.ongoing[s].customer.name;
      }

      infos.ongoing[s].time = new Date(infos.ongoing[s].updatedDate).getTime();
      infos.ongoing[s].updatedDate = DateTranslate(
        infos.ongoing[s].updatedDate,
      );
      infos.ongoing[s].createdDate = DateTranslate(
        infos.ongoing[s].createdDate,
      );

      infos.ongoing[s].observations = processObservations(
        infos.ongoing[s],
        level,
      );
    }

    for (let s in infos.open) {
      if (infos.open[s].customer !== null) {
        infos.open[s].customerName = infos.open[s].customer.name;
      }
      infos.open[s].time = new Date(infos.open[s].updatedDate).getTime();
      infos.open[s].updatedDate = DateTranslate(infos.open[s].updatedDate);
      infos.open[s].createdDate = DateTranslate(infos.open[s].createdDate);
      infos.open[s].observations = processObservations(infos.open[s], level);
    }

    infos.open = organize(infos.open);
    infos.ongoing = organize(infos.ongoing);
    infos.closed = organize(infos.closed);

    return infos;
  } else if (level === 2 || level === 3) {
    for (let s in infos.open) {
      infos.open[s].time = new Date(infos.open[s].updatedDate).getTime();
      // infos.open[s].updatedDate = DateTranslate(infos.open[s].updatedDate);
      // infos.open[s].processName = infos.open[s].process.processName;
      // infos.open[s].organName = infos.open[s].organ.name;
      // infos.open[s].serviceName =
      //   infos.open[s].selectedService &&
      //   infos.open[s].selectedService.serviceName;
      infos.open[s].createdDate = DateTranslate(infos.open[s].createdDate);

      infos.open[s].updatedDate =
        level === 2
          ? DateTranslate(infos.open[s].endBudgetDate)
          : DateTranslate(infos.open[s].endAnalysisDate);
      infos.open[s].observations = processObservations(infos.open[s], level);
    }

    for (let s in infos.ongoing) {
      infos.ongoing[s].time = new Date(infos.ongoing[s].updatedDate).getTime();

      infos.ongoing[s].updatedDate =
        level === 2
          ? DateTranslate(infos.ongoing[s].endBudgetDate)
          : DateTranslate(infos.ongoing[s].endAnalysisDate);

      // infos.ongoing[s].processName = infos.ongoing[s].process.processName;
      // infos.ongoing[s].organName = infos.ongoing[s].organ.name;
      // infos.ongoing[s].returned = infos.ongoing[s].selectedService.returned;
      // infos.ongoing[s].serviceName =
      //   infos.ongoing[s].selectedService &&
      //   infos.ongoing[s].selectedService.serviceName;
      infos.ongoing[s].createdDate = DateTranslate(
        infos.ongoing[s].createdDate,
      );
      infos.ongoing[s].observations = processObservations(
        infos.ongoing[s],
        level,
      );
    }

    for (let s in infos.review) {
      infos.review[s].time = new Date(infos.review[s].updatedDate).getTime();

      infos.review[s].updatedDate =
        level === 2
          ? DateTranslate(infos.review[s].endBudgetDate)
          : DateTranslate(infos.review[s].endAnalysisDate);

      // infos.review[s].processName = infos.review[s].process.processName;
      // infos.review[s].organName = infos.review[s].organ.name;
      // infos.review[s].serviceName = infos.review[s].selectedService.serviceName;
      infos.review[s].createdDate = DateTranslate(infos.review[s].createdDate);
      infos.review[s].observations = processObservations(
        infos.review[s],
        level,
      );
    }

    for (let s in infos.closed) {
      infos.closed[s].time = new Date(infos.closed[s].updatedDate).getTime();
      // infos.closed[s].processName = infos.closed[s].process.processName;
      // infos.closed[s].organName = infos.closed[s].organ.name;
      // infos.closed[s].serviceName =
      //   infos.closed[s].selectedService &&
      //   infos.closed[s].selectedService.serviceName;
      infos.closed[s].createdDate = DateTranslate(infos.closed[s].createdDate);

      infos.closed[s].updatedDate =
        level === 2
          ? DateTranslate(infos.closed[s].endBudgetDate)
          : DateTranslate(infos.closed[s].endAnalysisDate);
      infos.closed[s].observations = processObservations(
        infos.closed[s],
        level,
      );
    }

    infos.open = organize(infos.open);
    infos.ongoing = organize(infos.ongoing);
    infos.closed = organize(infos.closed);
    infos.review = organize(infos.review);

    return infos;
  } else if (level === 4) {
    if (infos.ongoing !== undefined && infos.ongoing.length > 0) {
      for (let s in infos.ongoing) {
        infos.ongoing[s].time = new Date(
          infos.ongoing[s].endPreparationDate,
        ).getTime();
      }

      infos.ongoing = infos.ongoing.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    if (infos.filed !== undefined && infos.filed.length > 0) {
      for (let s in infos.filed) {
        infos.filed[s].time = new Date(
          infos.filed[s].endPreparationDate,
        ).getTime();
      }

      infos.filed = infos.filed.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    if (infos.devolution !== undefined && infos.devolution.length > 0) {
      for (let s in infos.devolution) {
        infos.devolution[s].time = new Date(
          infos.devolution[s].endPreparationDate,
        ).getTime();
      }

      infos.devolution = infos.devolution.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    if (infos.deferred !== undefined && infos.deferred.length > 0) {
      for (let s in infos.deferred) {
        infos.deferred[s].time = new Date(
          infos.deferred[s].endDevolutionDate,
        ).getTime();
      }

      infos.deferred = infos.deferred.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    if (infos.exigency !== undefined && infos.exigency.length > 0) {
      for (let s in infos.exigency) {
        infos.exigency[s].time = new Date(
          infos.exigency[s].endPreparationDate,
        ).getTime();
      }

      infos.exigency = infos.exigency.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    if (
      infos.done !== undefined &&
      infos.done.length > 0 &&
      infos.stage !== undefined
    ) {
      for (let s in infos.done) {
        infos.done[s].time = new Date(
          infos.done[s].endDevolutionDate,
        ).getTime();
      }

      infos.done = infos.done.sort(function (a, b) {
        return a.time - b.time;
      });
    }
    return infos;
  }
}

export function processObservations(organList, level) {
  return (
    <div>
      {organList.returned === true ? (
        <div>
          <span className="badge badge-danger">Retornado com exigência</span>
        </div>
      ) : (
        ""
      )}
      {organList.internalFeedback === true ? (
        <div>
          <span className="badge badge-info">Feedback interno</span>
        </div>
      ) : (
        ""
      )}
      {organList.customerReturn === true ? (
        <div>
          <span className="badge badge-warning">Ret. do cliente</span>
        </div>
      ) : (
        ""
      )}
      {organList.urgent === true ? (
        <div>
          <span className="badge badge-danger">
            <i className="fa fa-exclamation-circle" /> Urgente
          </span>
        </div>
      ) : (
        ""
      )}
      {organList.type == 1 ? (
        <div>
          <i className="si si-rocket text-info" title="Primeiro Serviço" />
        </div>
      ) : (
        ""
      )}
      {organList.type == 2 ? (
        <div>
          <i
            className="fa fa-exclamation-triangle text-danger"
            title="Última compra + de 60 dias"
          />
        </div>
      ) : (
        ""
      )}
      {organList.consulting === true ? (
        <div>
          <span className="badge badge-secondary"> Consultando</span>
        </div>
      ) : (
        ""
      )}
      {organList.reavaliation > 0 && organList.reavaliation < level ? (
        <div>
          <span className="badge badge-dark"> Reavaliado</span>
        </div>
      ) : (
        ""
      )}
      {checkEmail(organList)}
    </div>
  );
}

function checkEmail(organList) {
  let sentEmails = organList.sentEmails;
  if (sentEmails != undefined && sentEmails.length > 0) {
    if (sentEmails[0].status == EmailHelper.Status.Bounce) {
      return (
        <div
          title={`Email enviado em: ${DateTranslate(sentEmails[0].createdDate)}`}
        >
          <span className="badge badge-danger">
            {" "}
            Email (bloqueado ou inexistente)
          </span>
        </div>
      );
    } else if (sentEmails[0].status == EmailHelper.Status.Droped) {
      return (
        <div
          title={`Email enviado em: ${DateTranslate(sentEmails[0].createdDate)}`}
        >
          <span className="badge badge-danger"> Email bloqueado</span>
        </div>
      );
    }
  }
}

function organize(res) {
  if (res !== undefined && res.length > 0) {
    res.sort(function (a, b) {
      return b.time - a.time;
    });

    return res;
  } else {
    return res;
  }
}

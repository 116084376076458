import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import GeneralCounters from "./GeneralCounters";
import Storage from "@/app/core/util/Storage";
import { Dropdown } from "react-bootstrap";
import {
  AnalysisStatusTranslate,
  BudgetStatusTranslate,
} from "@/app/core/util/StatusTranslater";
import { DateTranslateSimple } from "@/app/core/util/DateTranslater";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  GetCurrentOperatorName,
  OrganStatus,
  OrganStatusTranslate,
  OrganStatusList,
} from "@/app/core/util/helpers/OrganListHelper";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import AccountController from "@/app/core/base/controllers/UsersController";
import { AdministratorTeamTasks } from "@/app/core/base/controllers/TeamsController";

export default function MainComponent() {
  const [processes, setProcesses] = useState([]);
  const [members, setMembers] = useState([]);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const [status, setStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [filter, setFilter] = useState({});
  const [filtered, setFiltered] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(0);
  const userData = Storage.getUserData();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [newUser, setNewUser] = useState({});
  const [newDevolutionUser, setNewDevolutionUser] = useState({});
  const [header, setHeader] = useState({
    id: "DESC",
    processName: "DESC",
    organ: "DESC",
    department: "DESC",
    responsible: "DESC",
    status: "DESC",
    updatedDate: "DESC",
    createdDate: "DESC",
  });
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const account = AccountController();

  useEffect(() => {
    processData();
  }, [members]);

  useEffect(() => {
    handleFilters();
  }, [filter, refreshData]);

  function notyChange() {
    setChange(date.getTime());
  }

  function processData() {
    account.CompanyUsers((res) => {
      let userList = [];
      for (let s in res.users) {
        let organAssignments = res.assignments.filter(
          (x) => x.userId === res.users[s].id,
        );
        let devolutionSteps = res.devolutionSteps.filter(
          (x) => x.userId === res.users[s].id,
        );
        userList.push({
          ...res.users[s],
          checked: true,
          organAssignments,
          devolutionSteps,
        });
      }
      setUsers(userList);
      setSelectedUsers(userList.length);
    });

    AdministratorTeamTasks((res) => {
      setProcesses([...res.organList, ...res.process]);
      setFiltered([...res.organList, ...res.process]);
    });
  }

  function openSelectedService(item) {
    if (userData.level === 2) {
      window.location = `#/analysis/${item.id}`;
    } else if (userData.level === 3) {
      window.location = `#/preparation/${item.id}`;
    } else {
      setSelectedData({
        processId: item.processId === undefined ? item.id : item.processId,
        id: item.processId === undefined ? item.id : item.processId,
      });
      setShowProcessModal(true);
    }
  }

  function handleChange(evt) {
    let olds = filter;
    let newItens = {};
    if (evt.target.name === "status") {
      newItens = { [evt.target.name]: parseInt(evt.target.value) };
    } else {
      newItens = { [evt.target.name]: evt.target.value };
    }

    setFilter({ ...olds, ...newItens });
  }

  function handleFilters() {
    let infos = processes;
    let params = filter;
    let results = [];

    let acceptUsers = users.filter((x) => x.checked === true);

    for (let s in infos) {
      let item = infos[s];
      let itsOK = false;
      item.time = new Date(item.updatedDate).getTime();
      let accetable = acceptUsers.filter(
        (x) =>
          x.id === item.sellerId ||
          x.id === item.analystId ||
          x.id === item.preparerId ||
          x.id === item.devolutionNotStartedId ||
          x.id === item.devolutionFiledId ||
          x.id === item.devolutionDevolutionId ||
          x.id === item.devolutionExigencyId ||
          x.id === item.devolutionDeferredId,
      );

      if (params.status !== undefined) {
        if (userData.level === 1 && params.status === item.budgetStatus) {
          itsOK = true;
        }
        if (userData.level === 2 && params.status === item.statusAnalysis) {
          itsOK = true;
        }
        if (userData.level === 3 && params.status === item.statusPreparation) {
          itsOK = true;
        }
        if (userData.level === 4 && params.status === item.statusDevolution) {
          itsOK = true;
        }
      } else {
        itsOK = true;
      }

      if (accetable.length > 0 && itsOK === true) {
        results.push(item);
      } else {
        if (acceptUsers.length === 0 && itsOK === true) {
          results.push(item);
        }
      }
    }

    if (params.order !== undefined) {
      if (params.order === "ASC") {
        results.sort(function (a, b) {
          return a.time - b.time;
        });
      }
      if (params.order === "DESC") {
        results.sort(function (a, b) {
          return b.time - a.time;
        });
      }
    }

    setFiltered(results);
  }

  function handleSelectedUsers(evt, index) {
    let olds = users;
    olds[index].checked = evt.target.checked;

    const count = olds.filter((x) => x.checked === true);

    setSelectedUsers(count.length);
    setUsers(olds);
    setRefreshData(!refreshData);
    notyChange();
  }

  function selectAll() {
    let list = users;
    for (let s in list) {
      list[s].checked = true;
    }

    const count = list.filter((x) => x.checked === true);
    setSelectedUsers(count.length);
    setUsers(list);
    notyChange();
  }

  function deselectAll() {
    let list = users;
    for (let s in list) {
      list[s].checked = false;
    }

    const count = list.filter((x) => x.checked === true);
    setSelectedUsers(count.length);
    setUsers(list);
    notyChange();
  }

  function electiveUsers(item) {
    let responses = [];
    members &&
      members.map((user) => {
        let assignments = user.assignments;
        let results = assignments.filter((x) => x.organId === item.organId);
        if (results.length > 0) {
          if (user.level === item.stage) {
            responses.push(user);
          }
        } else {
          if (item.budgetStatus !== undefined && user.level === 1) {
            responses.push(user);
          }
        }
      });
  }

  function handleLine(item) {
    if (item && item.sellerId !== undefined) {
      return (
        <tr>
          <td>{item.id}</td>
          <td>{item.processName}</td>
          <td></td>
          <td>{item.seller !== undefined ? item.seller.firstName : "S.I."}</td>
          <td>{BudgetStatusTranslate(item.budgetStatus)}</td>
          <td>{DateTranslateSimple(item.updatedDate)}</td>
          <td>{DateTranslateSimple(item.createdDate)}</td>
          <td>
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService(item)}
            >
              <i className="fa fa-eye" />
            </button>
            {electiveUsers(item)}
          </td>
        </tr>
      );
    } else if (item && item.stage === 2) {
      return (
        <tr>
          <td>{item.id}</td>
          <td>{item.process && item.process.processName}</td>
          <td>{item.organ && item.organ.name}</td>
          <td>
            {item &&
              item.selectedService &&
              item.selectedService.service &&
              item.selectedService.serviceName}
          </td>
          <td>
            {item.analyst !== undefined ? item.analyst.firstName : "S.I."}
          </td>
          <td>{AnalysisStatusTranslate(item.statusAnalysis)}</td>
          <td>{DateTranslateSimple(item.updatedDate)}</td>
          <td>{DateTranslateSimple(item.createdDate)}</td>
          <td>
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService(item)}
            >
              <i className="fa fa-eye" />
            </button>

            {electiveUsers(item)}
          </td>
        </tr>
      );
    } else if (item && item.stage === 3) {
      return (
        <tr>
          <td>{item.id}</td>
          <td>{item.process && item.process.processName}</td>
          <td>{item.organ && item.organ.name}</td>
          <td>
            {item &&
              item.selectedService &&
              item.selectedService.service &&
              item.selectedService.serviceName}
          </td>
          <td>
            {item.preparer !== undefined ? item.preparer.firstName : "S.I."}
          </td>
          <td>{AnalysisStatusTranslate(item.statusPreparation)}</td>
          <td>{DateTranslateSimple(item.updatedDate)}</td>
          <td>{DateTranslateSimple(item.createdDate)}</td>
          <td>
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService(item)}
            >
              <i className="fa fa-eye" />
            </button>

            {electiveUsers(item)}
          </td>
        </tr>
      );
    } else if (item && item.stage === 4) {
      return (
        <tr>
          <td>{item.id}</td>
          <td>{item.process && item.process.processName}</td>
          <td>{item.organ && item.organ.name}</td>
          <td>
            {item &&
              item.selectedService &&
              item.selectedService.service &&
              item.selectedService.serviceName}
          </td>
          <td>{legibleName(GetCurrentOperatorName(item))}</td>
          <td>{OrganStatusTranslate(item.status)}</td>
          <td>{DateTranslateSimple(item.updatedDate)}</td>
          <td>{DateTranslateSimple(item.createdDate)}</td>
          <td>
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService(item)}
            >
              <i className="fa fa-eye" />
            </button>
          </td>
        </tr>
      );
    }
  }

  function legibleName(name) {
    if (name.length > 27) {
      let newName = name.substr(0, 27) + "...";
      return newName;
    } else {
      return name;
    }
  }

  useEffect(() => {}, []);

  return (
    <div className="content">
      <GeneralCounters />

      <div className="row">
        <div className="col-12">
          <div className="block block-transparent">
            <div className="block-header">
              <div className="block-title">
                <h2 className="content-heading">
                  <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                  Processos
                </h2>
              </div>
            </div>
            <div>
              <div className="block-header">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <span>Data</span>
                      <select
                        className="form-control"
                        name="order"
                        onChange={(evt) => handleChange(evt)}
                      >
                        <option value={null}>Selecione</option>
                        <option value={"ASC"}>Ordem crescente</option>
                        <option value={"DESC"}>Ordem decrescente</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="block-tools">
                  <button
                    className="btn btn-sm btn-dual"
                    onClick={() => handleFilters()}
                  >
                    <i className="si si-check"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-dual"
                    title={"Limpar filtro"}
                    onClick={() => setFiltered(processes) | setFilter({})}
                  >
                    <i className="si si-close text-danger"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-dual"
                    onClick={() => setRefreshData(!refreshData)}
                  >
                    <i className="si si-refresh"></i>
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <table className="table table-striped table-hover table-vcenter font-size-sm">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      {userData && userData.level !== 1 ? <td>Órgão</td> : ""}
                      <td>Serviço</td>
                      <td>Responsável</td>
                      <td>Status</td>
                      <td>Atualizado em</td>
                      <td>Criado em</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.length > 0 &&
                      filtered.map((item) => handleLine(item))}
                  </tbody>
                </table>
              </div>
            </div>
            <OperationProcessModal
              data={selectedData}
              show={showProcessModal}
              onHide={() => setShowProcessModal(false) | setSelectedData({})}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  customerBudgetCols,
  userBudgetCols,
} from "../../dashboard/components/main/ColumnHelper";
import Storage from "@/app/core/util/Storage";
import BudgetModal from "@/app/core/layouts/main-components/BudgetModal";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import CustomerBudgetModal from "./components/CustomerBudgetModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { toast } from "react-toastify";
import {
  BudgetStatus,
  ProcessTotal,
} from "@/app/core/util/helpers/BudgetHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { ServiceGroupType } from "@/app/core/util/helpers/SelectedServiceHelper";

export default function BudgetsCard({
  getCustomerSO,
  budgets,
  refusedBudgets,
  customerId,
}) {
  const userData = Storage.getUserData();
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [showBudget, setShowBudget] = useState(false);
  const [budget, setBudget] = useState(null);
  const [query, setQuery] = useState("");
  const [budgetsFilter, setBudgetsFilter] = useState([]);
  const [refusedBudgetsFilter, setRefusedBudgetsFilter] = useState([]);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    setBudgetsFilter(budgets);
    setRefusedBudgetsFilter(refusedBudgets);
  }, [budgets, refusedBudgets]);

  function openBudget(item) {
    if (userData.level === 5) {
      // window.open(`#/confirm/${item.id}`, "_blank");
      setProcessId(item.id);
      setShowBudget(true);
    } else {
      setBudget(item);
      setShowBudgetModal(true);
    }
  }

  function handleChange(value) {
    setQuery(value);
    let bgts = [];
    let refusedbgts = [];

    if (value.length > 0) {
      setBudgetsFilter([]);
      bgts = autocomplete(value, budgets);
      refusedbgts = autocomplete(value, refusedBudgets);
      setBudgetsFilter(bgts);
      setRefusedBudgetsFilter(refusedbgts);
    } else {
      bgts = [];
      setBudgetsFilter(budgets);
      setRefusedBudgetsFilter(refusedBudgets);
    }
  }

  function autocomplete(value, data) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.processName.toLowerCase();
      if (name.includes(inputValue)) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  function acceptBudget(item) {
    if (window.confirm(Message.Confirm)) {
      ProcessController().BudgetAccepted(item.id, false, (res) => {
        toast.success(
          "O serviço será iniciado imediatamente! Agradecemos sua parceria",
        );
        setTimeout(() => {
          getCustomerSO();
        }, 5200);
      });
    }
  }

  function processOptionalsPrice(item) {
    let names = "";
    let prices = 0;

    for (let s in item.optionalServices) {
      const service = item.optionalServices[s];
      if (service.type != ServiceGroupType.MultipleChoise) {
        for (let y in service.optionalServices) {
          const theService = service.optionalServices[y];
          if (theService.recomended) {
            names += ` ${theService.serviceName}`;
            prices += theService.price;
          }
        }
      }
    }

    if (prices > 0) {
      return (
        <small>
          Serviços: {names}
          <br />
          Valor: R${MoneyMask(prices, 2)}
        </small>
      );
    } else {
      return "";
    }
  }

  function checkIfDontHaveMultiple(item) {
    for (let s in item.optionalServices) {
      const service = item.optionalServices[s];
      if (service.type == ServiceGroupType.MultipleChoise) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className="block block-rounded">
      <div className="block-content">
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-append">
              <button
                type="button"
                class="btn btn-success"
                onClick={() => setShowNewBudgetModal(true)}
              >
                <i class="si si-plus"></i> Novo Orçamento
              </button>
            </div>
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={query}
              onChange={(evt) => handleChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() =>
                  setQuery("") |
                  setBudgetsFilter(budgets) |
                  setRefusedBudgetsFilter(refusedBudgets)
                }
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <div class="block-title">Orçamentos em aberto</div>
          {enviroment == "staging" || enviroment == "local" ? (
            <div>
              {budgetsFilter &&
                budgetsFilter.map((item) => (
                  <div className="block block-bordered block-rounded border-warning border-2x">
                    <div className="block-header mb-0">
                      <div className="block-title mb-0">{item.processName}</div>
                      <div className="block-tools mb-0">
                        {(item.budgetStatus == BudgetStatus.WaitingApproval ||
                          item.budgetStatus == BudgetStatus.ReturnToCustomer) &&
                        checkIfDontHaveMultiple(item) ? (
                          <button
                            className="btn btn-success"
                            onClick={() => acceptBudget(item)}
                          >
                            <i className="si si-check mr-2" />
                            Aceitar
                          </button>
                        ) : (
                          ""
                        )}

                        <button
                          className="btn btn-dual"
                          onClick={() => openBudget(item)}
                        >
                          <i className="si si-info mr-2" />
                          Detalhes
                        </button>
                      </div>
                    </div>
                    <div className="block-header mt-0 pt-0">
                      {item.budgetStatus == BudgetStatus.WaitingApproval ||
                      item.budgetStatus == BudgetStatus.ReturnToCustomer ? (
                        <div className="block-title mt-0">
                          {item &&
                          item.selectedServices &&
                          item.selectedServices.length > 0 ? (
                            <small>
                              Serviços:{" "}
                              {item.selectedServices.map(
                                (item) => `${item.serviceName} `,
                              )}
                              <br />
                              Valor: R$
                              {item &&
                                item.selectedServices &&
                                MoneyMask(
                                  ProcessTotal(item.selectedServices),
                                  2,
                                )}
                            </small>
                          ) : (
                            <div>{processOptionalsPrice(item)}</div>
                          )}
                        </div>
                      ) : (
                        <span className="badge badge-info">
                          Aguardando Orçamento
                        </span>
                      )}
                      <div className="block-tools"></div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <Table
              title="Orçamentos em aberto"
              data={budgetsFilter}
              columns={
                userData && userData.level !== 5
                  ? customerBudgetCols
                  : userBudgetCols
              }
              setFilter={(filter) =>
                Functions.ProcessFilter(filter, budgetsFilter)
              }
              refreshData={() => getCustomerSO()}
              viewAction={(data) => openBudget(data.data)}
            />
          )}
          {enviroment == "staging" || enviroment == "local" ? (
            ""
          ) : (
            <Table
              title="Orçamentos recusados"
              data={refusedBudgetsFilter}
              columns={
                userData && userData.level !== 5
                  ? customerBudgetCols
                  : userBudgetCols
              }
              setFilter={(filter) =>
                Functions.ProcessFilter(filter, refusedBudgetsFilter)
              }
              refreshData={() => getCustomerSO()}
              viewAction={(data) => openBudget(data.data)}
            />
          )}
        </div>
      </div>

      <BudgetModal
        data={budget}
        info={budget}
        show={showBudgetModal}
        onHide={() =>
          setBudget(null) | setShowBudgetModal(false) | getCustomerSO()
        }
      />

      <CustomerNewBudget
        show={showNewBudgetModal}
        customerId={customerId}
        onHide={() => setShowNewBudgetModal(false) | getCustomerSO()}
      />
      <CustomerBudgetModal
        processId={processId && processId}
        show={showBudget}
        onHide={() =>
          setShowBudget(false) | setProcessId(null) | getCustomerSO()
        }
      />
    </div>
  );
}

import { DateTranslate } from "@/app/core/util/DateTranslater";
import React, { useState } from "react";

export default function ComercialProdutivityReport({ data }) {
  const [sends, setsends] = useState(true);
  const [waiting, setwaiting] = useState(true);
  const [accepted, setaccepted] = useState(true);
  const [refused, setrefused] = useState(true);

  function processTable(itens) {
    return (
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Serviço</th>
            <th>Processo</th>
            <th>Criado em</th>
            <th>Atualizado em</th>
          </tr>
        </thead>
        <tbody>
          {itens &&
            itens.map((item) => (
              <tr>
                <td>{item.serviceName}</td>
                <td>{item.process && item.process.processName}</td>
                <td>{DateTranslate(item.createdDate)}</td>
                <td>
                  {item.acceptedDate != "0001-01-01T00:00:00+00:00"
                    ? DateTranslate(item.acceptedDate)
                    : DateTranslate(item.updatedDate)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <div>
        <div className="block-header">
          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> (
              {data && data.sends && data.sends.length}) Enviados
            </h2>
          </div>
          <div className="block-tools">
            <button className="btn btn-dual" onClick={() => setsends(!sends)}>
              <i
                className={`si si-arrow-${sends === true ? "up" : "down"}`}
              ></i>
            </button>
          </div>
        </div>

        {sends == true ? (
          <div>{data && data.sends ? processTable(data.sends) : ""}</div>
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="block-header">
          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> (
              {data && data.waiting && data.waiting.length}) Aguardando Resposta
            </h2>
          </div>
          <div className="block-tools">
            <button
              className="btn btn-dual"
              onClick={() => setwaiting(!waiting)}
            >
              <i
                className={`si si-arrow-${waiting === true ? "up" : "down"}`}
              ></i>
            </button>
          </div>
        </div>

        {waiting == true ? (
          <div>{data && data.waiting ? processTable(data.waiting) : ""}</div>
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="block-header">
          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> (
              {data && data.accepted && data.accepted.length}) Aceitos
            </h2>
          </div>
          <div className="block-tools">
            <button
              className="btn btn-dual"
              onClick={() => setaccepted(!accepted)}
            >
              <i
                className={`si si-arrow-${accepted === true ? "up" : "down"}`}
              ></i>
            </button>
          </div>
        </div>

        {accepted == true ? (
          <div>{data && data.accepted ? processTable(data.accepted) : ""}</div>
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="block-header">
          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> (
              {data && data.refused && data.refused.length}) Recusados
            </h2>
          </div>
          <div className="block-tools">
            <button
              className="btn btn-dual"
              onClick={() => setrefused(!refused)}
            >
              <i
                className={`si si-arrow-${refused === true ? "up" : "down"}`}
              ></i>
            </button>
          </div>
        </div>

        {refused == true ? (
          <div>{data && data.refused ? processTable(data.refused) : ""}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import QueryString from "query-string";
import { withRouter } from "react-router-dom";
import translate from "@/i18n";
import SearchModal from "../../main-components/SearchModal";

function SearchInput(props) {
  const [search, setSearch] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSearch(QueryString.parse(props.search).q);
  }, [props.search]);

  useEffect(() => {
    setRedirect(false);
  }, []);

  function toggleClass() {
    document.querySelector("#page-header-search").classList.toggle("show");
    document.getElementById("page-header-search-input").focus();
  }

  function tryToToggle(e) {
    if (
      e.keyCode === 27 &&
      document.querySelector("#page-header-search").classList.contains("show")
    ) {
      toggleClass();
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    if (search !== "" && search !== QueryString.parse(props.search).q) {
      setQuery(search ? search.trim() : "");
      setShowModal(true);
    }
  }

  if (redirect) {
    props.history.push(`/app/search?q=${search ? search.trim() : ""}`);
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-dual"
        // onClick={() => toggleClass()}
        onClick={() => setShowModal(true)}
      >
        <i className="fa fa-fw fa-search" />{" "}
        <span className="ml-1 d-none d-sm-inline-block">Pesquisar</span>
      </button>
      <div
        id="page-header-search"
        style={{ zIndex: 999999 }}
        className={
          "overlay-header bg-primary" +
          (search !== null && search !== undefined ? " show" : "")
        }
      >
        <div className="content-header">
          <form className="w-100" onSubmit={(e) => onSubmit(e)}>
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => toggleClass()}
                >
                  <i className="fa fa-fw fa-times-circle" />
                </button>
              </div>
              <input
                type="text"
                value={search || ""}
                onChange={(evt) => setSearch(evt.target.value)}
                className="form-control border-0"
                placeholder={translate("search or hit esc")}
                id="page-header-search-input"
                name="q"
                onKeyUp={(e) => tryToToggle(e)}
              />
            </div>
          </form>
        </div>
      </div>
      <SearchModal
        show={showModal}
        information={query}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default withRouter(SearchInput);

import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import FolderEditModal from "./FolderEditModal";
import { toast } from "react-toastify";
import {
  FolderDelete,
  FolderList,
} from "@/app/core/base/controllers/FoldersController";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";
import Functions from "@/app/core/util/Functions";

export default function TemplateDocsComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);

  const cols = [
    { header: "Id", name: "id", isSortable: true },
    { header: "Nome", name: "name", isSortable: true },
    { header: "Descrição", name: "description", isSortable: true },
    { header: "Data de Atualização", name: "updatedDate", isSortable: true },
    { header: "Data de criação", name: "createdDate", isSortable: true },
  ];

  useEffect(() => {
    getFolders();
  }, []);

  function getFolders() {
    FolderList((res) => {
      setData(ProcessDates(res));
    });
  }

  function callEditFolderModal(item) {
    console.log("passando dados", item);
    setEditData(item.data);
    setShowEditModal(true);
  }

  function deleteFolder(item) {
    if (window.confirm(DeleteConfirm)) {
      FolderDelete(item.data.id, (res) => {
        toast.success("Removido com sucesso");
        getFolders();
      });
    }
  }

  return (
    <>
      <Card
        title="Pastas"
        action={() => setShowEditModal(!showModal)}
        icon="plus"
      >
        <Table
          loading={tableLoading || false}
          data={data}
          columns={cols}
          editAction={(item) => callEditFolderModal(item)}
          deleteAction={(task) => deleteFolder(task)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          hideHeader
        />
      </Card>

      <FolderEditModal
        show={showEditModal}
        values={editData}
        onHide={() =>
          setShowEditModal(!showEditModal) | setEditData({}) | getFolders()
        }
      />
    </>
  );
}

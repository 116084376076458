export function Autocomplete(value, itens, param) {
  let newResults = [];
  for (let s in itens) {
    const item = itens[s];
    const info = item[param].toLowerCase();
    if (info.includes(value.toLowerCase())) {
      newResults.push(item);
    }
  }
  return newResults;
}

import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { DateTranslate } from "@/app/core/util/DateTranslater";
// import ReactToExcel from "react-html-table-to-excel";
import React, { useState, useEffect } from "react";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";

export default function DeferredReportModal({
  showModal,
  onHide,
  infos,
  startDate,
  endDate,
}) {
  const [data, setData] = useState([]);
  const organHelper = OrganListHelper();

  useEffect(() => {
    if (infos !== null && infos !== undefined) {
      let organs = [];
      for (let s in infos.serviceOrders) {
        let item = infos.serviceOrders[s];
        item.serviceName = `${
          item.selectedService !== undefined
            ? item.selectedService.serviceName
            : "S.I."
        }`;
        item.organName = `${
          item.organ !== undefined ? item.organ.name : "S.I."
        }`;
        item.processName = `${
          item.process !== undefined ? item.process.processName : "S.I."
        }`;

        item.createdDate = DateTranslate(item.createdDate);
        item.updatedDate = DateTranslate(item.updatedDate);
        item.analysisDate = DateTranslate(item.process.endBudgetDate);
        item.endDevolutionDate = DateTranslate(item.endDevolutionDate);

        item.sends = 1;
        if (item.returned === true) {
          let returns = infos.returns.filter((x) => x.organListId === item.id);
          item.sends = returns.length + 1;
        }

        organs.push(item);
      }
      setData(organs);
    } else {
      setData([]);
    }
  }, [infos, showModal]);

  return (
    <Modal title="Deferidos" show={showModal} onHide={onHide}>
      <div className="block-header">
        <div className="block-title">{data && data.length + " Deferidos"}</div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="deferreds"
            filename={`Relatorio - Deferidos periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data}
        columns={organHelper.DeferredReturnCols}
        tableId="deferreds"
        hideHeader={true}
        hide={false}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data)}
      />
    </Modal>
  );
}

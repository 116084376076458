import React from "react";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function TeamLine({ team }) {
  return (
    <div className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
      <div className="block-header block-header-default">
        <div className="block-title">Equipe {team && team.id}</div>
        <div className="block-tools">
          <button className="btn btn-sm btn-dual">
            <i className="si si-users" /> {team && team.members}
          </button>

          <button className="btn btn-sm btn-dual">
            {team && MoneyMask(team.open / team.members, 1)}
            <br />
            {team && team.department !== 4 ? "Em aberto" : "A protocolar"}
          </button>
          {team && team.department !== 4 ? (
            <button className="btn btn-sm btn-dual">
              {team && MoneyMask(team.ongoing / team.members, 1)} <br />
              Em andamento
            </button>
          ) : (
            ""
          )}
          {team && team.department === 4 ? (
            <button className="btn btn-sm btn-dual">
              {team && MoneyMask(team.filed / team.members, 1)} <br />
              Protocolado
            </button>
          ) : (
            ""
          )}
          {team && team.department === 4 ? (
            <button className="btn btn-sm btn-dual">
              {team && MoneyMask(team.devolution / team.members, 1)} <br />
              Devolução
            </button>
          ) : (
            ""
          )}
          {team && team.department === 4 ? (
            <button className="btn btn-sm btn-dual">
              {team && MoneyMask(team.exigency / team.members, 1)} <br />
              Exigência
            </button>
          ) : (
            ""
          )}
          {team && team.department === 4 ? (
            <button className="btn btn-sm btn-dual">
              {team && MoneyMask(team.deferred / team.members, 1)} <br />
              Deferido
            </button>
          ) : (
            ""
          )}
          <button className="btn btn-sm btn-dual">
            {team && MoneyMask(team.done / team.members, 1)} <br />
            Concluídos
          </button>
          <button
            className="btn btn-sm btn-dual"
            onClick={() =>
              window.open(
                `${GetBaseUrl()}#/supervisor?teamId=${team.id}&level=${team.department}`,
                "_blank",
              )
            }
          >
            <i className="fa fa-eye" />
          </button>
        </div>
      </div>
    </div>
  );
}

const userLang = navigator.language || navigator.userLanguage;

let translations;
try {
  translations = require(`./${userLang.split("-")[0]}.json`);
} catch (err) {
  translations = require(`./en.json`);
}

export default (key) =>
  key.split(".").reduce((acc, curr) => acc[curr], translations) || key;

import React, { useEffect, useState } from "react";
import Notifications from "../../main/components/Notifications";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import Modal from "@/app/core/layouts/main-components/BudgetModal";

export default function NotificationComponent({ processId, orientation }) {
  const [notification, setNotification] = useState([]);
  const userData = Storage.getUserData();
  const [selectedData, setSelectedData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const notificationCtrl = NotificationController();

  useEffect(() => {
    notificationList();
  }, [processId]);

  function toggleModal(data) {
    setSelectedData({ id: data.processId });
    updateNotificationStatus(data);
    setShowModal(!showModal);
  }

  function onOpenNotification(id) {
    toggleModal(id);
  }

  async function notificationList() {
    if (processId !== null && processId !== undefined) {
      loadProcessNotifications();
    } else {
    }
  }

  async function loadProcessNotifications() {
    await notificationCtrl.AllProcessNotifications(processId, 2, (res) =>
      setNotification(res),
    );
  }

  async function updateNotificationStatus(data) {
    await notificationCtrl.Update(
      {
        ...data,
        view: true,
      },
      (res) => notificationList(),
    );
  }

  async function markAllAsRead(id) {
    await notificationCtrl.MarkAllAsRead(userData.id, (res) =>
      notificationList(),
    );
  }

  return (
    <div>
      {userData && userData.level === 5 ? (
        ""
      ) : (
        <Dropdown drop={orientation}>
          <Dropdown.Toggle
            variant={Storage.getTheme()}
            id="dropdown-basic"
            className="btn-sm"
            disabled={userData && userData.level === 5 ? true : false}
          >
            <i className="fa fa-fw fa-bell" />
            {notification && notification.length > 0 && (
              <span className="badge badge-secondary badge-pill">
                {notification.length}{" "}
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: "320px", overflowY: "scroll" }}>
            <Notifications
              data={notification}
              processId={processId}
              updateNotification={(item) => updateNotificationStatus(item)}
              onReadAll={(item) => markAllAsRead(item)}
              onOpen={(recv) => onOpenNotification(recv)}
            />
          </Dropdown.Menu>

          <Modal
            info={selectedData}
            data={selectedData}
            show={showModal}
            submit={(updateBudget) => notificationList(updateBudget)}
            onHide={() => setShowModal(false)}
          />
        </Dropdown>
      )}
    </div>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ServiceGroupController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `servicegroup/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `servicegroup/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Get(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `servicegroup/process/${processId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function GetById(groupId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `servicegroup/${groupId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(serviceGroupId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `servicegroup/delete/${serviceGroupId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Get,
    Delete,
    Update,
    GetById,
  };
}

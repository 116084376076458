import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function OrganAssignmentInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: `organassignment/insert`,
      data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function OrganAssignmentDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `organassignment/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function OrganAssignmentByUser(userId, response) {
  BaseService(
    {
      method: "GET",
      url: `organassignment/${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { MotivationalInsert } from "@/app/core/base/controllers/MotivationalController";

function MotivationalForm(props) {
  const { handleSubmit, show, onHide, isLoading } = props;
  const [file, setFile] = useState(null);
  const [level, setLevel] = useState(null);

  useEffect(() => {}, []);

  function postMotivational() {
    var formData = new FormData();
    for (let s in file) {
      formData.append("file", file[s]);
    }

    MotivationalInsert(formData, level, (res) => {
      toast.info("Imagem com Sucesso");
      onHide();
    });
  }

  return (
    <Modal
      title="Adicionar Mensagem home"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={postMotivational}
      onSubmit={() => postMotivational()}
    >
      <form onSubmit={handleSubmit}>
        <div class="row">
          <div class="col-sm-12">
            <div className="col-sm-12">
              <label for="uri">Anexar imagem</label>
              <input
                class="form-control"
                type="file"
                onChange={(event) => {
                  setFile(event.currentTarget.files);
                }}
                multiple
                id="uri"
                name="uri"
              />
            </div>
            <div className="col-sm-12">
              <label for="uri">Apresentar para</label>
              <select
                className="form-control"
                onChange={(event) => {
                  setLevel(parseInt(event.target.value));
                }}
              >
                <option value={null}>Selecione</option>
                <option value={1}>Equipe interna</option>
                <option value={5}>Clientes</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

const ExigencyModal = MotivationalForm;

export default ExigencyModal;

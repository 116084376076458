import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function InsertProtocol(data, response) {
  BaseService(
    {
      method: "POST",
      url: "protocol/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProtocolUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: "protocol/update",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProtocolList(processId, response) {
  BaseService(
    {
      method: "GET",
      url: `protocol/process/${processId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProtocolDelete(protocolId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `protocol/delete/${protocolId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function ShortcutInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: "shortcut/insert",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ShortcutUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: "shortcut/update",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ShortcutDelete(id, response) {
  BaseService(
    {
      method: "GET",
      url: `shortcut/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ShortcutList(response) {
  BaseService(
    {
      method: "GET",
      url: `shortcut`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

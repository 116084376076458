import FollowController from "@/app/core/base/controllers/FollowController";
import AccountController from "@/app/core/base/controllers/UsersController";
import Storage from "@/app/core/util/Storage";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import React, { useEffect, useState } from "react";

export default function FollowingCustomerCard({ customerId, presentation }) {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const userData = Storage.getUserData();
  const followCtrl = FollowController();
  const accountCtrl = AccountController();

  useEffect(() => {
    loadUsers();
    loadFollow();
  }, [customerId]);

  async function loadUsers() {
    await accountCtrl.CompanyUsers((res) => {
      let supervisors = res.users.filter((x) => x.role > 0);
      setUsers(supervisors);
    });
  }

  async function loadFollow() {
    await followCtrl.FollowingCustomer(customerId, (res) => {
      setData(res);
    });
  }

  async function unfollow(id) {
    await followCtrl.DeleteCustomer(id, (res) => {
      loadFollow();
    });
  }

  async function follow() {
    await followCtrl.FollowCustomer(
      { customerId: parseInt(customerId), userId: userId },
      (res) => {
        loadFollow();
      },
    );
  }

  return (
    <span>
      {presentation != true ? (
        <div>
          <div className="form-group">
            <div className="input-group">
              <select
                type="email"
                className="form-control"
                onChange={(evt) => setUserId(evt.target.value)}
              >
                <option value={""}>Selecione</option>
                {users &&
                  users.map((item) => (
                    <option value={item.id}>
                      {item && item.firstName} - {item && item.email}
                    </option>
                  ))}
              </select>
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => follow()}
                >
                  Adicionar
                </button>
              </div>
            </div>
          </div>
          {data && data.length > 0 ? (
            <div className="block-title">
              <h2 className="content-heading">
                <i className="fa fa-angle-right text-muted mr-1"></i> Gerente de
                relacionamento
              </h2>
            </div>
          ) : (
            ""
          )}
          {data &&
            data.map((item) => (
              <div class="custom-control custom-block custom-control-primary">
                <label class="custom-control-label">
                  <span class="d-flex align-items-center">
                    <img
                      class="img-avatar img-avatar48"
                      src={
                        item === undefined ||
                        item.user.avatarImages === "" ||
                        item === undefined ||
                        item.user.avatarImages === undefined ||
                        item.user.avatarImages.length === 0
                          ? `assets/media/avatars/avatar8.jpg`
                          : item.user.avatarImages[0].url
                      }
                      alt=""
                    />
                    <span class="ml-2">
                      <span class="font-w700">
                        {item && item.user && item.user.firstName} - Equipe :{" "}
                        {item && item.user && item.user.teamId}
                      </span>
                      <span class="d-block font-size-sm text-muted">
                        {item && DepartmentTranslate(item && item.user.level)}
                      </span>
                    </span>
                    <span class="ml-2">
                      <button
                        type="button"
                        className="btn btn-dual btn-sm"
                        onClick={() => unfollow(item.id)}
                      >
                        <i className="si si-close text-danger" />
                      </button>
                    </span>
                  </span>
                </label>
              </div>
            ))}
        </div>
      ) : (
        <div>
          {data &&
            data.map((item) => (
              <div class="custom-control">
                <label class="">
                  <span class="d-flex align-items-center text-white">
                    <span class="ml-2">
                      <span class="">
                        Gerente de conta:{" "}
                        {item && item.user && item.user.firstName}{" "}
                        {item && item.user && item.user.lastName}
                      </span>
                      <span class="d-block font-size-sm text-muted"></span>
                    </span>
                  </span>
                </label>
              </div>
            ))}
        </div>
      )}
    </span>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import {
  SendEmailOfCritic,
  SendEmailSupport,
} from "@/app/core/base/controllers/ContactController";

export default function CriticModal({
  show,
  onHide,
  submit,
  isLoading,
  report,
}) {
  const [data, setData] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {
    loadData();
  }, [show]);

  async function loadData() {
    setData({
      name: `${userData !== null ? userData.firstName : ""} ${
        userData !== null ? userData.lastName : ""
      }`,
      email: userData !== null ? userData.email : "",
      phone: userData !== null ? userData.phone : "",
      customerId: userData.customerId,
    });
  }

  async function save() {
    if (
      data.name !== undefined &&
      data.name !== "" &&
      data.email !== undefined &&
      data.email !== "" &&
      data.phone !== undefined &&
      data.phone !== "" &&
      data.description !== undefined &&
      data.description !== ""
    ) {
      SendEmailOfCritic(data);
      if (report !== undefined) {
        report(
          `Responsável : ${data.name} Email : ${data.email} Telefone : ${data.phone} Descrição da crítica : ${data.description}`,
        );
      }
      setTimeout(() => {
        toast.success(
          "Sua crítica foi enviada com sucesso, em breve retornaremos",
        );
        onHide();
      }, 500);
    } else {
      toast.error(Message.PendingData);
    }
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  return (
    <Modal
      title="Registrar Crítica"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div className="mb-3 mt-3 text-center">
        <p className="font-w700  text-muted">
          Sabemos o valor de uma crítica construtiva ao nosso time e por este
          motivo agradecemos pelo seu tempo. <br />
          Tenha certeza que sua critica será ouvida e transformada em otimização
          para nossa empresa.
        </p>
      </div>
      <div className="form-group">
        <span>Nome</span>
        <input
          className="form-control"
          name="name"
          value={data.name}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>E-mail</span>
        <input
          className="form-control"
          name="email"
          value={data.email}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Cód. de cliente</span>
        <input
          className="form-control"
          name="customerId"
          value={data.customerId}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Telefone para contato </span>
        <input
          className="form-control"
          name="phone"
          value={data.phone}
          onChange={(evt) => handleChange(evt)}
        />
      </div>

      <div className="form-group">
        <span>Em qual das etapas abaixo sua crítica se encaixa?</span>
        <select
          className="form-control"
          name="category"
          onChange={(evt) => handleChange(evt)}
        >
          <option>Selecione</option>
          <option>Clareza das exigências no processo</option>
          <option>Comunicação com o Time</option>
          <option>Prazo de entrega do serviço</option>
          <option>Plataforma e suas funcionalidades</option>
          <option>Minha critica é sobre outro assunto...</option>
        </select>
      </div>

      <div className="form-group">
        <span>Descreva sua critica e mais uma vez obrigado:</span>
        <textarea
          className="form-control"
          name="description"
          value={data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import translate from "@/i18n";
import {
  DateToPast,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import NotificationsModal from "../../main-components/notifications/NotificationsModal";
import {
  NotificationTypeBudgetAccepted,
  NotificationTypeBudgetDeclined,
  NotificationTypeContactRequired,
  NotificationTypeExigencyFulfillment,
  NotificationTypeFileUpload,
} from "@/app/core/util/helpers/NotificationHelper";

export default function Notifications({
  ref,
  data,
  processId,
  onOpen,
  onReadAll,
  updateNotification,
}) {
  const userData = Storage.getUserData();
  const [showCentralModal, setShowCentralModal] = useState(false);

  function seeNotification(item) {
    updateNotification(item);
    if (processId) return;

    if (userData.level === 1) {
      //Open modal
      onOpen(item);
    } else if (userData.level === 2) {
      //Go somewhere
      window.location = `#/analysis/${item.selectedServiceId}`;
    } else if (userData.level === 3) {
      //Go somewhere
      window.location = `#/preparation/${item.selectedServiceId}`;
    }
  }

  function getIcon(num) {
    switch (num) {
      case NotificationTypeContactRequired:
        return <i className="fa fa-fw fa-phone text-warning" />;
      case NotificationTypeBudgetAccepted:
        return <i className="far fa-fw fa-check-circle text-success" />;
      case NotificationTypeBudgetDeclined:
        return <i className="si si-close text-danger" />;
      case NotificationTypeExigencyFulfillment:
        return <i className="fa fa-tasks text-info" />;
      case NotificationTypeFileUpload:
        return <i className="far fa-file-pdf text-dark" />;
      default:
        return <i className="fa fa-fw fa-exclamation-circle text-warning" />;
        break;
    }
  }

  function markAsRead() {
    onReadAll();
  }

  return (
    <>
      <Dropdown.Item
        eventKey="0"
        className="bg-primary-darker rounded-top font-w600 text-white text-center p-3"
      >
        {userData.level !== 5 ? translate("notifications") : "Em breve"}
      </Dropdown.Item>
      {data &&
        data.map((item, idx) => {
          if (idx > 5) return;
          //if(!item.view) onOpen(item.id);
          // alert(idx);
          return (
            <Dropdown.Item
              eventKey={item.id}
              id={item.id}
              key={item.id}
              onClick={() => {
                seeNotification(item);
              }}
            >
              <div className="text-dark media py-2" href="#">
                <div className="mx-3">{getIcon(item.type)}</div>
                <div className="media-body font-size-sm pr-2">
                  <div className="font-w600">
                    {!processId &&
                      item.process &&
                      `Processo : ${item.process.processName}`}{" "}
                    - {item.message}
                  </div>
                  <div className="text-muted font-italic">
                    {DateTranslateSimple(item.createdDate)} - (
                    {DateToPast(item.createdDate)})
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          );
        })}

      <Dropdown.Item
        className="p-2 border-top"
        onClick={() => {
          if (
            window.confirm("Tem certeza de que deseja marcar todas como lidas?")
          ) {
            markAsRead();
          }
        }}
      >
        <i className="si si-eye mr-1" /> Marcar todas como lidos
      </Dropdown.Item>
      <Dropdown.Item
        className="p-2 border-top"
        onClick={() => setShowCentralModal(true)}
      >
        <i className="si si-list mr-1" /> Central de notificações
      </Dropdown.Item>
      <NotificationsModal
        processId={processId}
        show={showCentralModal}
        open={(item) => seeNotification(item)}
        onHide={() => setShowCentralModal(false)}
      />
    </>
  );
}

import Loading from "@/app/core/layouts/main/components/Loading";
import { OperationalListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import { Dropdown, Toast } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SaveProcessReturn } from "@/app/core/base/controllers/ProcessReturnController";
import { SelectedServiceUpdate } from "@/app/core/base/controllers/ServicesController";
import {
  OrganStatus,
  OrganStatusList,
} from "@/app/core/util/helpers/OrganListHelper";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import Storage from "@/app/core/util/Storage";
import HistoricController from "@/app/core/base/controllers/HistoricController";
import { SelectedServiceAnalysisStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import JobsController from "@/app/core/base/controllers/JobsController";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";

export default function DevolutionExigencyReport({ organListId }) {
  const [loading, setLoading] = useState(false);
  const [internalReason, setInternalReason] = useState(false);
  const [changeProtocol, setChangeProtocol] = useState(false);
  const [department, setDepartment] = useState(null);
  const [track, setTrack] = useState(null);
  const [observations, setObservations] = useState("");
  const [selectedService, setSelectedService] = useState({});
  const [organ, setOrgan] = useState({});
  const [toDepartment, setToDepartment] = useState(null);
  const [organStatus, setOrganStatus] = useState(0);
  const [newProtocol, setNewProtocol] = useState("");
  const userData = Storage.getUserData();
  const timelineCtrl = TimelineController();
  const historicCtrl = HistoricController();
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    loadOrganList();
  }, [organListId]);

  async function loadOrganList() {
    await organCtrl.SelectById(organListId, (res) => {
      setSelectedService(res.selectedService);
      setOrgan(res);
      setNewProtocol(res.protocol);
    });
  }

  async function saveReturn(e) {
    if (e != undefined) {
      e.preventDefault();
    }
    if (window.confirm("Realmente deseja salvar essa informações?")) {
      setLoading(true);
      if (department == 3 && track == null) {
        toast.error("Selecione a esteira responsável da preparação");
        return;
      }

      let infos = {
        processId: selectedService.processId,
        selectedServiceId: selectedService.id,
        organListId: organ.id,
        wasDeferred: false,
        IsInternalReason: internalReason,
        department: department === null ? 0 : parseInt(department),
        observations: observations,
      };

      await jobsDone.Insert(organ.processId, organ.id, (res) => {});

      let oldService = selectedService;
      let organInfos = {
        ...organ,
        returned: true,
        preparationStage: PreparationStep.Track,
      };
      oldService.stage = toDepartment;
      oldService.returned = true;
      if (toDepartment === 2) {
        organInfos.statusAnalysis = SelectedServiceAnalysisStatus.NotStarted;
        organInfos.stage = toDepartment;
      }
      if (toDepartment === 3) {
        organInfos.statusPreparation = SelectedServiceAnalysisStatus.NotStarted;
        organInfos.stage = toDepartment;
      }
      if (toDepartment === 4) {
        organInfos.statusDevolution = parseInt(organStatus);
        infos.DevolutionStep = parseInt(organStatus);
        organInfos.stage = toDepartment;
      }

      SaveProcessReturn(infos, async (response) => {
        if (internalReason === true) {
          await jobsDone.WrongReport(
            organ.processId,
            organ.id,
            department,
            track,
            parseInt(organStatus),
            (res) => {},
          );
        }

        let timelineUpdate = {
          channel: TimelineChannelSistema,
          processId: selectedService.processId,
          level: 4,
          department: 4,
          important: false,
          observation: `Processo retornou com exigência no órgão ${
            organ.organ && organ.organ.name
          }. `,
          userId: userData.id,
          organListId: organ.id,
        };

        oldService.returned = true;
        if (changeProtocol === true) {
          if (newProtocol.length > 0) {
            organInfos.protocol = newProtocol;
            timelineUpdate.observation = `${timelineUpdate.observation}`;
            // O Nº de protocolo mudou de "${organ.protocol}" para "${newProtocol}".
          } else {
            toast.error("Preencha o novo número de protocolo");
            return;
          }
        }

        await timelineCtrl.Insert(timelineUpdate, (res) => {});
        await historicCtrl.Clear(
          organInfos.id,
          toDepartment,
          toDepartment,
          (res) => {},
        );
        await historicCtrl.Insert(
          { ...timelineUpdate, description: timelineUpdate.observation },
          (res) => {},
        );

        if (
          organInfos.status == OrganStatus.Done &&
          selectedService.steps != undefined &&
          selectedService.steps.length > 0
        ) {
          organInfos.stage = 10;
        }

        await SelectedServiceUpdate(oldService, async (res) => {
          if (toDepartment === 4) {
            await organCtrl.Update(
              { ...organInfos, status: parseInt(organStatus) },
              (responses) => {
                toast.info("Salvo com sucesso");
                window.location = "#/app";
              },
            );
          } else {
            await organCtrl.Update(organInfos, (responses) => {
              toast.info("Salvo com sucesso");
              window.location = "#/app";
            });
          }
        });
      });

      setLoading(false);
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant={"light"}
        className="btn btn-hero-sm btn-hero-secondary"
      >
        <i className="si si-list text-warning" /> Encerrar devolução
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: 360 }}>
        <Loading loading={loading} />
        <form onSubmit={(e) => saveReturn(e)}>
          <div className="form-group">
            <label class="d-block">Foi um erro interno?</label>
            <div class="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                class="custom-control-input"
                id="example-radio-custom-inline4"
                name="example-radio-custom-inline2"
                defaultChecked={internalReason === true ? true : false}
                onChange={(evt) =>
                  setInternalReason(evt.target.checked === true ? true : false)
                }
              />
              <label
                class="custom-control-label"
                for="example-radio-custom-inline4"
              >
                Sim
              </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                class="custom-control-input"
                id="example-radio-custom-inline3"
                name="example-radio-custom-inline2"
                defaultChecked={internalReason === false ? true : false}
                onChange={(evt) =>
                  setInternalReason(evt.target.checked === true ? false : true)
                }
              />
              <label
                class="custom-control-label"
                for="example-radio-custom-inline3"
              >
                Não
              </label>
            </div>
          </div>

          {internalReason && internalReason === true ? (
            <div>
              <div className="form-group">
                <span>Selecione a equipe responsável</span>
                <select
                  className="form-control"
                  onChange={(evt) => setDepartment(evt.target.value)}
                >
                  <option value={null}>Selecione</option>
                  {OperationalListHelper &&
                    OperationalListHelper.map((item) => (
                      <option
                        value={item.value}
                        selected={item.value === department}
                      >
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <span>Considerações</span>
                <textarea
                  className="form-control"
                  value={observations}
                  onChange={(evt) => setObservations(evt.target.value)}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {department && department == 3 ? (
            <div className="form-group">
              <span>Qual a esteira responsável?</span>
              <select
                className="form-control"
                onChange={(evt) =>
                  setTrack(
                    evt.target.value !== "null"
                      ? parseInt(evt.target.value)
                      : null,
                  )
                }
              >
                <option value={null}>Selecione</option>
                <option value={0}>Preparação</option>
                <option value={1}>Conferência</option>
              </select>
            </div>
          ) : (
            ""
          )}

          <div className="form-group">
            <label class="d-block">O Protocolo mudou?</label>
            <div class="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                class="custom-control-input"
                id="example-radio-custom-inline5"
                name="example-radio-custom-inline5"
                defaultChecked={changeProtocol === true ? true : false}
                onChange={(evt) =>
                  setChangeProtocol(evt.target.checked === true ? true : false)
                }
              />
              <label
                class="custom-control-label"
                for="example-radio-custom-inline5"
              >
                Sim
              </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                class="custom-control-input"
                id="example-radio-custom-inline6"
                name="example-radio-custom-inline5"
                defaultChecked={changeProtocol === false ? true : false}
                onChange={(evt) =>
                  setChangeProtocol(evt.target.checked === true ? false : true)
                }
              />
              <label
                class="custom-control-label"
                for="example-radio-custom-inline6"
              >
                Não
              </label>
            </div>
          </div>

          {changeProtocol && changeProtocol === true ? (
            <div>
              <div className="form-group">
                <span>Novo Protocolo</span>
                <input
                  className="form-control"
                  type="text"
                  value={newProtocol}
                  onChange={(evt) => setNewProtocol(evt.target.value)}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="form-group">
            <span>Enviar para qual Departamento</span>
            <select
              className="form-control"
              onChange={(evt) =>
                setToDepartment(
                  evt.target.value !== "null"
                    ? parseInt(evt.target.value)
                    : null,
                )
              }
            >
              <option value={null}>Selecione</option>
              {OperationalListHelper &&
                OperationalListHelper.map((item) => (
                  <option
                    value={item.value}
                    selected={item.value === department}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          {toDepartment === 4 ? (
            <div className="form-group">
              <span>Enviar para qual Estágio</span>
              <select
                className="form-control"
                onChange={(evt) => setOrganStatus(evt.target.value)}
              >
                <option value={null}>Selecione</option>
                {OrganStatusList.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="text-center">
            <button type="submit" className="btn btn-square btn-success">
              Salvar
            </button>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
}

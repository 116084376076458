const Storage = {
  isAuthenticated: function (data) {
    const userData = Storage.getUserData();
    if (userData) return true;
    return false;
  },
  setUserData: function (data) {
    localStorage.setItem("user_data", JSON.stringify(data));
  },
  getUserData: function () {
    const user = JSON.parse(localStorage.getItem("user_data"));
    return user !== null ? user.user : null;
  },
  getUserToken: function () {
    return JSON.parse(localStorage.getItem("user_data"));
  },
  getTheme: function () {
    return localStorage.getItem("theme");
  },
  setTheme: function (color) {
    localStorage.setItem("theme", color);
  },
  setTemplates: function (itens) {
    localStorage.setItem("exigencyTemplates", JSON.stringify(itens));
  },
  getTemplates: function () {
    return JSON.parse(localStorage.getItem("exigencyTemplates"));
  },
  setProductivity: function (itens) {
    localStorage.setItem("productivity", JSON.stringify(itens));
  },
  getProductivity: function () {
    return JSON.parse(localStorage.getItem("productivity"));
  },
  setServices: function (itens) {
    localStorage.setItem("services", JSON.stringify(itens));
  },
  getServices: function () {
    return JSON.parse(localStorage.getItem("services"));
  },
  setOrgans: function (itens) {
    localStorage.setItem("organs", JSON.stringify(itens));
  },
  getOrgans: function () {
    return JSON.parse(localStorage.getItem("organs"));
  },
  setFollowing: function (itens) {
    localStorage.setItem("following", JSON.stringify(itens));
  },
  getFollowing: function () {
    return JSON.parse(localStorage.getItem("following"));
  },
  setMotivational: function (itens) {
    localStorage.setItem("motivational", JSON.stringify(itens));
  },
  getMotivational: function () {
    return JSON.parse(localStorage.getItem("motivational"));
  },

  setBudgetCounter: function (itens) {
    localStorage.setItem("budget_counter", JSON.stringify(itens));
  },
  getBudgetCounter: function () {
    return JSON.parse(localStorage.getItem("budget_counter"));
  },

  setAnalysisCounter: function (itens) {
    localStorage.setItem("analysis_counter", JSON.stringify(itens));
  },
  getAnalysisCounter: function () {
    return JSON.parse(localStorage.getItem("analysis_counter"));
  },

  setPreparationCounter: function (itens) {
    localStorage.setItem("preparation_counter", JSON.stringify(itens));
  },
  getPreparationCounter: function () {
    return JSON.parse(localStorage.getItem("preparation_counter"));
  },

  setExigenciesCounter: function (itens) {
    localStorage.setItem("exigencies_counter", JSON.stringify(itens));
  },
  getExigenciesCounter: function () {
    return JSON.parse(localStorage.getItem("exigencies_counter"));
  },

  setDevolutionCounter: function (itens) {
    localStorage.setItem("devolution_counter", JSON.stringify(itens));
  },
  getDevolutionCounter: function () {
    return JSON.parse(localStorage.getItem("devolution_counter"));
  },

  setDeferredCounter: function (itens) {
    localStorage.setItem("deferred_counter", JSON.stringify(itens));
  },
  getDeferredCounter: function () {
    return JSON.parse(localStorage.getItem("deferred_counter"));
  },

  getFeatures: function () {
    const features = JSON.stringify(localStorage.getItem("features"));

    if (typeof features === "object") {
      return features;
    }
    return [];
  },
};

export default Storage;

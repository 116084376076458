import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import SignInComponent from "./sign-in";
import Storage from "@/app/core/util/Storage";

const EmptyRouter = (props) =>
  Storage.isAuthenticated() ? (
    <Redirect to="/app" />
  ) : (
    <div {...props}>{renderRoutes(props.route.routes, props)}</div>
  );

const AuthenticationRouter = () => ({
  path: "/",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "",
      component: SignInComponent,
    },
  ],
});

export default AuthenticationRouter();

import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import { DepartmentsListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { SendNotification } from "../../util/helpers/NotificationHelper";
import {
  InsertProtocol,
  ProtocolDelete,
  ProtocolList,
  ProtocolUpdate,
} from "../../base/controllers/ProtocolController";
import {
  DateTimeNow,
  IsTodayDate,
  ProcessDates,
} from "../../util/DateTranslater";
import { DeleteConfirm } from "../../util/messages/GeralMessages";
import { ShortcutList } from "../../base/controllers/ShortcutsController";
import { SaveProcessReturn } from "../../base/controllers/ProcessReturnController";
import ProcessController from "../../base/controllers/ProcessController";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import DropdownElement from "./elements/DropdownElement";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { TimelineChannelSistema } from "../../util/helpers/TimelineHelper";
import OrganListController from "../../base/controllers/OrganListController";
import OrganListHelper from "../../util/helpers/OrganListHelper";
import ProtocolHelper from "../../util/helpers/ProtocolHelper";
import CopyToClipboard from "react-copy-to-clipboard";

export default function ProtocolComponent({
  refresh,
  reload,
  processId,
  organListId,
  disabled,
  search,
}) {
  const userData = Storage.getUserData();
  const [data, setData] = useState([]);
  const [organList, setOrganList] = useState({});
  const [protocol, setProtocol] = useState(null);
  const [type, setType] = useState(1);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState(null);
  const [shortCuts, setShortCuts] = useState([]);

  const [results, setResults] = useState([]);

  const [finish, setFinish] = useState(0);
  const [internalReason, setInternalReason] = useState(false);
  const [department, setDepartment] = useState(null);
  const [observations, setObservations] = useState(null);
  const [disable, setDisable] = useState(false);
  const [updateName, setUpdateName] = useState("");
  const organListHelper = OrganListHelper();
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const protocolHelper = ProtocolHelper();

  useEffect(() => {
    loadProtocols();
    getShortcuts();
    if (disabled === undefined || disabled === null) {
      setDisable(false);
    } else {
      setDisable(disabled);
    }
  }, [processId, refresh, disabled]);

  async function loadOrganList(consuls) {
    await organCtrl.SelectById(organListId, (res) => {
      setOrganList(res);
      if (res.consulting === true) {
        reprocessStatus(res, consuls);
      }
    });
  }

  function loadProtocols() {
    if (!processId) return;
    ProtocolList(processId, (res) => {
      let consultList = [],
        protocolList = [];

      for (let s in res) {
        res[s].time = new Date(res[s].createdDate).getTime();
        if (res[s].type === 1) {
          consultList.push(res[s]);
        }
        if (res[s].type === 2) {
          protocolList.push(res[s]);
        }
      }
      consultList.sort(function (a, b) {
        return b.time - a.time;
      });
      setData(ProcessDates(consultList));
      if (organListId !== undefined && organListId !== null) {
        loadOrganList(res);
        let done = res.filter((x) => x.status === false);
        if (done.length === 0 && reload !== undefined) {
          reload();
        }
      }
    });
  }

  async function saveUpdate() {
    let proto = {
      ProtocolCode: protocol,
      type: type,
      url: url,
      status: false,
      name: name,
      processId: parseInt(processId),
      organListId,
    };

    InsertProtocol(proto, async (res) => {
      // await Timeline(`Operador iniciou uma consulta : ${name} com o Nº protocolo : ${protocol}`);
      await Historic(`Consulta Iniciada (${name} - Protocolo: ${protocol})`);
      if (organListId !== undefined || search === true) {
        let oldOrgan = organList;
        oldOrgan.consulting = true;

        await organCtrl.Update(oldOrgan, (res) => {
          setOrganList(res);
        });
      }

      setProtocol("");
      setUrl("");
      setName("");

      toast.info("Salvo com sucesso");
      loadProtocols();
      if (reload !== undefined) {
        reload();
      }
    });
  }

  async function deleteProtocols(protocol) {
    if (window.confirm(DeleteConfirm)) {
      ProtocolDelete(protocol.id, async (res) => {
        await Timeline(`Operador excluiu a consulta: ${protocol.name}`);
        toast.success("Removido com sucesso");
        loadProtocols();
      });
    }
  }

  async function Timeline(message) {
    await NotifyUpdate(
      processId,
      message,
      false,
      TimelineChannelSistema,
      organListId,
    );
  }

  async function Historic(message) {
    await NotifyUpdate(
      processId,
      message,
      true,
      TimelineChannelSistema,
      organListId,
    );
  }

  async function reprocessStatus(organ, consuls) {
    let open = consuls.filter(
      (x) => x.stage === protocolHelper.ProtocolStage.Pending,
    );
    if (open.length < 1) {
      await organCtrl.Update(
        {
          ...organ,
          consulting: false,
        },
        (res) => {
          setOrganList(res);
        },
      );
    }
  }

  async function consulting() {
    await organCtrl.Update(
      {
        ...organList,
        consulting: true,
      },
      (res) => {
        setOrganList(res);
      },
    );
  }

  function updateProtocolName(item) {
    if (updateName === "" || updateName === undefined) {
      toast.error("Preencha o nome para salvar");
      return;
    }
    ProtocolUpdate({ ...item, name: updateName }, (res) => {
      loadProtocols();
    });
  }

  async function updateStage(item, stage) {
    await Timeline(
      `O operador mudou o status da consulta "${item.name}" para "${protocolHelper.protocolStatusBase(stage)}"`,
    );
    if (
      stage === protocolHelper.ProtocolStage.Pending &&
      organList &&
      organList.consulting === false
    ) {
      await consulting();
    }
    if (stage === protocolHelper.ProtocolStage.Approved) {
      await Historic(`${item.name} Aprovado(a)`);
    }
    if (stage === protocolHelper.ProtocolStage.Disapproved) {
      await Historic(`Consulta Reprovada (Em contato com órgão responsável)`);
    }
    ProtocolUpdate({ ...item, stage: stage }, (res) => {
      loadProtocols();
    });
  }

  function consulted(item) {
    ProtocolUpdate({ ...item, consulted: DateTimeNow() }, (res) => {
      Timeline(`Fez a consulta ${item.name}`);
      loadProtocols();
    });
  }

  function getShortcuts() {
    ShortcutList((res) => {
      for (let s in res) {
        res[s].label = `${res[s].name} - ${res[s].url}`;
      }
      setShortCuts(res);
    });
  }

  function follow(item) {
    let url = item.url;
    if (url.includes("http") || url.includes("www")) {
      window.open(item.url, "_blank");
    } else {
      window.open(`https://${url}`, "_blank");
    }
  }

  function copy(item) {
    toast.info("Copiado para a área de transferência");
    navigator.clipboard.writeText(item);
  }

  function saveReturn() {
    if (window.confirm("Realmente deseja salvar essa informações?")) {
      const infos = {
        processId: parseInt(processId),
        wasDeferred: finish === 2 ? true : false,
        IsInternalReason: internalReason,
        department: parseInt(department),
        observations,
      };
      SaveProcessReturn(infos, (res) => {
        loadProtocols();
        toast.info("Salvo com sucesso");
        notifyEveryone(infos);
      });
    }
  }

  async function notifyEveryone(infos) {
    if (processId) {
      await processCtrl.Select(processId, (res) => {
        SendNotification({
          budgetId: 0,
          message:
            infos.wasDeferred === true
              ? `O processo ${res.processName} foi deferido`
              : `O processo ${res.processName} sofreu exigência no órgão`,
          processId: processId,
          status: 5,
          userId: res.responsibleId,
        });
      });
    }
  }

  function handleUrlChange(value) {
    setUrl(value);
    let people_to_show = [];

    if (value.length > 0) {
      setResults([]);
      people_to_show = Autocomplete(value, shortCuts, "label");
      setResults(people_to_show);
    } else {
      people_to_show = [];
      setResults([]);
    }
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-6">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> Consultas
            </h2>
          </div>
          <div className="col-6 text-right">
            {userData && userData.level === 4 ? (
              <Dropdown>
                <Dropdown.Toggle variant={"light"} disabled={disable}>
                  <button type="button" className="btn btn-sm btn-link">
                    <i className="far fa-check-circle"></i> Concluir protocolos
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ minWidth: 360 }}>
                  <div className="form-group">
                    <label className="d-block">
                      O processo voltou com exigência?
                    </label>
                    <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="example-radio-custom-inline1"
                        name="example-radio-custom-inline"
                        onChange={(evt) =>
                          setFinish(evt.target.checked === true ? 1 : 0)
                        }
                      />
                      <label
                        className="custom-control-label"
                        for="example-radio-custom-inline1"
                      >
                        Sim
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="example-radio-custom-inline2"
                        name="example-radio-custom-inline"
                        onChange={(evt) =>
                          setFinish(evt.target.checked === true ? 2 : 0)
                        }
                      />
                      <label
                        className="custom-control-label"
                        for="example-radio-custom-inline2"
                      >
                        Não
                      </label>
                    </div>
                  </div>
                  {finish && finish === 1 ? (
                    <div className="form-group">
                      <label className="d-block">Foi um erro interno?</label>
                      <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="example-radio-custom-inline4"
                          name="example-radio-custom-inline2"
                          onChange={(evt) =>
                            setInternalReason(
                              evt.target.checked === true ? true : false,
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          for="example-radio-custom-inline4"
                        >
                          Sim
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="example-radio-custom-inline3"
                          name="example-radio-custom-inline2"
                          defaultChecked={true}
                          onChange={(evt) =>
                            setInternalReason(
                              evt.target.checked === true ? false : true,
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          for="example-radio-custom-inline3"
                        >
                          Não
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {internalReason && internalReason === true ? (
                    <div>
                      <div className="form-group">
                        <span>Selecione a equipe responsável</span>
                        <select
                          className="form-control"
                          onChange={(evt) => setDepartment(evt.target.value)}
                        >
                          <option value={null}>Selecione</option>
                          {DepartmentsListHelper &&
                            DepartmentsListHelper.map((item) => (
                              <option value={item.value}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <span>Considerações</span>
                        <textarea
                          className="form-control"
                          onChange={(evt) => setObservations(evt.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    <button
                      className="btn btn-square btn-success"
                      onClick={() => saveReturn()}
                    >
                      Salvar
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
            <DropdownElement
              submit={() => saveUpdate()}
              disabled={disable}
              icon=""
              className="mt-3"
              style={{ minWidth: 240, maxWidth: "100%", height: 300 }}
              title={"Adicionar"}
            >
              <div className="form-group">
                <span>Nº de Protocolo</span>
                <input
                  type="text"
                  className="form-control"
                  value={protocol}
                  onChange={(evt) => setProtocol(evt.target.value)}
                />
              </div>
              <div className="form-group">
                <span>URL</span>
                <input
                  type="text"
                  className="form-control"
                  value={url}
                  onChange={(evt) =>
                    setUrl(evt.target.value) | handleUrlChange(evt.target.value)
                  }
                />
              </div>
              <ul
                style={{
                  borderRadius: "3px",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 0",
                  fontSize: "90%",
                  position: "fixed",
                  overflow: "auto",
                  marginTop: 20,
                  width: "90%",
                  maxHeight: "300px", // TODO: don't cheat, let it flow to the bottom
                }}
              >
                {results &&
                  results.map((item) => {
                    return (
                      <li
                        className="m-3"
                        onClick={() => setUrl(item.url) | setResults([])}
                      >
                        Nome : {item.name} - URL : {item.url}
                      </li>
                    );
                  })}
              </ul>
              <div className="form-group">
                <span>Nome / descrição</span>
                <textarea
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </div>
            </DropdownElement>
          </div>
        </div>
      </div>
      <div className="col-md-12 py-3">
        {data &&
          data.map((item) => (
            <div
              className={
                "block block-rounded border border-3x js-appear-enabled animated fadeIn"
              }
              data-toggle="appear"
            >
              <div className="block-header">
                <div className="block-title">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant={Storage.getTheme()}
                      disabled={disable}
                    >
                      {item && protocolHelper.protocolStatus(item.stage)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ zIndex: 99999999999 }}>
                      {protocolHelper.ProtocolStageList.map((sts) => (
                        <div>
                          <button
                            className={`btn btn-square btn-outline-${sts.color} mb-1 mt-1`}
                            style={{ width: "100%", zIndex: 99999999999 }}
                            onClick={() => updateStage(item, sts.value)}
                          >
                            {sts.name}
                          </button>
                          <br />
                        </div>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="block-tools">
                  <span></span>{" "}
                  <button
                    className={`btn btn-sm btn-square btn-outline-dark mb-1 mt-1`}
                    onClick={() => copy(item.url)}
                  >
                    Copiar URL <i className="si si-link ml-1"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-square btn-outline-danger"
                    title="Excluir"
                    onClick={() => deleteProtocols(item)}
                  >
                    <i className="fa fa-fw fa-close"></i>
                  </button>
                  <button
                    title="Abrir em uma nova guia"
                    className="btn btn-sm btn-square btn-outline-info"
                    onClick={() => follow(item)}
                  >
                    <i className="fa fa-link"></i>
                  </button>
                </div>
              </div>
              <div className="block-header">
                <div className="block-title">
                  <DropdownElement
                    submit={() => updateProtocolName(item)}
                    disabled={disable}
                    icon=""
                    style={{ minWidth: 240, maxWidth: "100%" }}
                    title={item.name}
                  >
                    <input
                      type="text"
                      placeholder="Nome"
                      defaultValue={item.name}
                      onChange={(evt) => setUpdateName(evt.target.value)}
                      className="form-control"
                    />
                  </DropdownElement>
                </div>
                <div className="block-tools">
                  <CopyToClipboard
                    text={item.protocolCode}
                    onCopy={() =>
                      toast.info("Copiado para a área de transferência")
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-dual"
                      title="copiar"
                    >
                      Nº Protocolo : {item.protocolCode}{" "}
                      <i className="far fa-copy" />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="block-content block-content-full block-content-sm bg-body-light row">
                <div className="col-8">
                  <span className="font-size-sm text-muted">
                    criado em : <strong>{item.createdDate}</strong>
                    <br />
                    ùltima atualização : <strong>{item.updatedDate}</strong>
                  </span>
                </div>
                {item && item.stage === protocolHelper.ProtocolStage.Pending ? (
                  <div className="col-4 text-right">
                    {IsTodayDate(item.consulted) === false ? (
                      <button
                        className={`btn mt-1 btn-sm btn-square btn-warning`}
                        onClick={() => consulted(item)}
                      >
                        Consultar
                      </button>
                    ) : (
                      <button className={`btn mt-1 btn-sm btn-square btn-info`}>
                        Consultado
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import RegisterForm from "../../register/main/RegisterForm";
import { toast } from "react-toastify";
import Modal from "@/app/core/layouts/main/components/CustomModal";

export default function CompleteAccountModal({
  customer,
  show,
  onHide,
  confirmSend,
}) {
  function handleSubmit() {
    if (confirmSend !== undefined) {
      confirmSend();
    }
  }

  return (
    <Modal
      title="Complete o cadastro para prosseguir"
      show={show}
      onHide={onHide}
      onSubmit={() => handleSubmit()}
    >
      <RegisterForm baseData={customer} returnRequest={() => handleSubmit()} />
    </Modal>
  );
}

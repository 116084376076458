import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import React, { useEffect, useState } from "react";
import CompanyModal from "./components/CompanyModal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import SituationOfCompanyModal from "./components/SituationOfCompanyModal";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import CompanyOrgansModal from "@/app/core/layouts/main-components/organs/CompanyOrgansModal";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function CompaniesComponent({ customerId }) {
  const userData = Storage.getUserData();
  const [customer, setCustomer] = useState(null);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [showCompany, setShowCompany] = useState(false);
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [showOrganModal, setShowOrganModal] = useState(false);
  const [company, setCompany] = useState({});
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const timestamp = new Date().getTime();

  const [report, setReport] = useState({
    total: 0,
    regular: 0,
    irregular: 0,
  });

  useEffect(() => {
    if (userData && userData.level != 5) {
      setCustomer(customerId);
      getCompanies(customerId);
    } else {
      setCustomer(userData.customerId);
      getCompanies(userData.customerId);
    }
  }, []);

  function notifyChange() {
    setChange(date.getTime());
  }

  function getCompanies(id) {
    CompanyController.List(id, (res) => {
      for (let s in res) {
        res[s].checked = false;
        if (res[s].organs && res[s].organs.length > 0) {
          const regular = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.Regular,
          );
          const irregular = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.Irregular,
          );
          const notApply = res[s].organs.filter(
            (x) => x.status == OrganHelper.CompanyOrganStatus.NotApply,
          );

          if (irregular.length == 0 && regular.length > 0) {
            res[s].isRegular = true;
          } else if (notApply.length == res[s].organs.length) {
            res[s].isRegular = undefined;
          } else {
            res[s].isRegular = false;
          }

          for (let y in res[s].organs) {
            res[s].organs[y].time = new Date(
              res[s].organs[y].updatedDate,
            ).getTime();
          }

          res[s].organs.sort(function (a, b) {
            return b.time - a.time;
          });

          res[s].lastChecked = DateTranslate(res[s].organs[0].updatedDate);
          res[s].lastCheckedTime = new Date(
            res[s].organs[0].updatedDate,
          ).getTime();
        }
      }

      res.sort(function (a, b) {
        return b.lastCheckedTime - a.lastCheckedTime;
      });

      setReport({
        total: res.length,
        regular: res.filter((x) => x.isRegular == true).length,
        irregular: res.filter((x) => x.isRegular != true).length,
      });
      setData(res);
    });
  }

  function deleteCompany(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      CompanyController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        getCompanies(customer);
      });
    }
  }

  function openCompany(item) {
    setCompany(item);
    if (userData && userData.level == 5) {
      setShowCompany(true);
    } else {
      setShowOrganModal(true);
    }
  }

  function checkOrUncheck(evt, index) {
    let base = data;
    base[index].checked = evt.target.checked;
    setData(base);
    notifyChange();
  }

  function newService(item) {
    let invalids = item.organs.filter(
      (x) => x.status == OrganHelper.CompanyOrganStatus.Irregular,
    );
    if (invalids.length > 0) {
      item.observation = "Regularizar: ";
      for (let s in invalids) {
        item.observation += `${invalids[s].name}; `;
      }
    }
    setSelected(item);
    setShowNewBudgetModal(true);
  }

  function requestConsultation() {
    const selecteds = data.filter((x) => x.checked == true);
    if (selecteds.length == 0) {
      toast.error(Message.PendingData);
    } else {
      let fifiteen = 86400 * 15;
      let Ok = false;
      for (let s in selecteds) {
        const dDay = selecteds[s].lastCheckedTime + fifiteen;
        if (
          dDay > timestamp &&
          selecteds[s].status != OrganHelper.CompanyOrganStatus.Pending &&
          selecteds[s].status != OrganHelper.CompanyOrganStatus.NotApply
        ) {
          toast.error(
            `Atenção! As consultas são realizadas a cada 15 dias. E a empresa ${selecteds[s].name} já foi consultada no período.`,
          );
        } else {
          CompanyController.Check(selecteds[s].id, (res) => {
            toast.success(
              "Solicitado a consulta de situação cadastral, em breve retornaremos",
            );
          });
        }
      }
      setData([]);
      notifyChange();
      getCompanies(customer);
    }
  }

  return (
    <div className="content mr-0 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-12 row mb-3">
          <h2 className="mb-0 ml-3 mt-2">
            <strong className="font-w700">Cadastro de empresas</strong>
            <br />
            <small className="text-muted">
              Consulte a regularidade cadastral das empresas dos seus clientes
            </small>
          </h2>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div class="block block-rounded block-link-pop block-bordered border-2x">
            <div class="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i class="far fa-2x fa-user-circle text-success"></i>
              </div>
              <div class="ml-3 text-right">
                <p class="font-size-h3 font-w300 mb-0">
                  {report && report.total}
                </p>
                <p class="text-muted mb-0">Empresas cadastrados</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div class="block block-rounded block-link-pop block-bordered border-2x">
            <div class="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i class="far fa-2x fa-check-circle text-info"></i>
              </div>
              <div class="ml-3 text-right">
                <p class="font-size-h3 font-w300 mb-0">
                  {report && report.regular}
                </p>
                <p class="text-muted mb-0">Empresas regulares</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 ml-0 mr-0">
          <div class="block block-rounded block-link-pop block-bordered border-2x">
            <div class="block-content block-content-full d-flex align-items-center justify-content-between">
              <div>
                <i class="si fa-2x si-user-unfollow text-danger"></i>
              </div>
              <div class="ml-3 text-right">
                <p class="font-size-h3 font-w300 mb-0">
                  {report && report.irregular}
                </p>
                <p class="text-muted mb-0">Empresas irregulares</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="block block-bordered border-2x">
            <div className="block-header">
              <div className="block-title">Empresas</div>
              <div className="block-tools">
                {/* <button className="btn btn-outline-success" onClick={() => requestConsultation()}>({change && data && data.filter(x => x.checked == true).length}) Solicitar consulta</button> */}
                {userData && userData.level == 5 ? (
                  <button
                    className="btn btn-outline-info"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="si si-plus" /> Adicionar empresa
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="block-content">
              <table className="table table-hover table-borderless">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>CNPJ</th>
                    <th>Ult. consulta</th>
                    <th>Situacao</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {change &&
                    data &&
                    data.map((item, index) => (
                      <tr>
                        <td title={item.id}>
                          {item.name}
                          {/* <div className="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-lg custom-control-dark mb-1">
                                                    <input type="checkbox" className="custom-control-input" id={`check-${item.id}`} name={`check-${item.id}`} onChange={evt => checkOrUncheck(evt, index)} />
                                                    <label className="custom-control-label" for={`check-${item.id}`}>{item.name}</label>
                                                </div> */}
                        </td>
                        <td>
                          {item.doc.length == 11
                            ? CpfMask(item.doc)
                            : CnpjMask(item.doc)}
                        </td>
                        <td>{item.lastChecked}</td>
                        <td>
                          {item.status ==
                          CustomerHelper().CompanyStatus.NotConsulted ? (
                            <span className="badger badge-seccondary">
                              Não consultado
                            </span>
                          ) : item.status ==
                            CustomerHelper().CompanyStatus.Consulting ? (
                            <span className="badge badge-warning">
                              Consultando
                            </span>
                          ) : item.isRegular == true ? (
                            <span className="badge badge-success">Regular</span>
                          ) : (
                            <span className="badge badge-danger">
                              Irregular
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-hero-sm btn-hero-success"
                            onClick={() => newService(item)}
                          >
                            Novo Pedido
                          </button>
                        </td>
                        <td>
                          <span
                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                            title="Editar"
                            onClick={() =>
                              setSelected(item) | setShowModal(true)
                            }
                          >
                            <i className="fa fa-fw fa-pencil-alt" />
                          </span>
                          {item && item.organs && item.organs.length > 0 ? (
                            <span
                              className="text-primary-lighter shaved-btn cursor-pointer ml-2 mr-2"
                              title="Visualizar"
                              onClick={() => openCompany(item)}
                            >
                              <i class="fas fa-eye" />
                            </span>
                          ) : (
                            ""
                          )}
                          <span
                            className="text-primary-lighter shaved-btn cursor-pointer  ml-2 mr-2"
                            title="Excluir"
                            onClick={() => deleteCompany(item)}
                          >
                            <i class="fas fa-times" />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <CompanyModal
        show={showModal}
        infos={selected}
        onHide={() =>
          setShowModal(false) | getCompanies(customer) | setSelected({})
        }
      />

      <SituationOfCompanyModal
        show={showCompany}
        infos={company}
        onHide={() =>
          setShowCompany(false) | getCompanies(customer) | setCompany({})
        }
      />

      <CustomerNewBudget
        show={showNewBudgetModal}
        infos={selected}
        customerId={userData && userData.customerId}
        onHide={() => setShowNewBudgetModal(false) | setSelected({})}
      />

      <CompanyOrgansModal
        show={showOrganModal}
        onHide={() =>
          setShowOrganModal(false) | getCompanies(customer) | setSelected({})
        }
        companyId={company && company.id}
      />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ContactSwitch from "../contact/ContactSwitch";
import DocumentsController from "@/app/core/base/controllers/DocumentsController";

export default function CustomerClosure({ processId }) {
  const [data, setData] = useState([]);
  const [redirectOut, setRedirectOut] = useState(false);
  const docsCtrl = DocumentsController();

  useEffect(() => {
    loadDocuments();
  }, []);

  function loadDocuments() {
    docsCtrl.ProcessList(processId, (res) => {
      let docs = res.filter((x) => x.status === 1);
      setData(docs);
    });
  }

  if (redirectOut) {
    return <Redirect to="/" />;
  }

  function redirectSystem() {
    setRedirectOut(true);
  }

  return (
    <>
      <div
        className="container"
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <ContactSwitch
              title="Atendimento"
              // organListId={organList && organList.id}
            />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #efefef",
            backgroundColor: "white",
            padding: "2em",
            width: "100%",
          }}
        >
          <table className="table table-vcenter">
            <thead>
              <tr>
                <div className="text-center">
                  <h4>Documentos</h4>
                </div>
              </tr>
            </thead>
          </table>
          <table className="table table-vcenter">
            <tbody>
              {data &&
                data.map((item) => {
                  return (
                    <tr>
                      <td>{item.filename}</td>
                      <td>
                        <a
                          className="btn btn-sm btn-dual"
                          href={item.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="si si-cloud-download" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-lg-12" align="center" style={{ margin: "1em" }}>
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                redirectSystem();
              }}
            >
              Voltar para o sistema
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

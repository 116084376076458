import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/services/DataService";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import UserAccountController from "@/app/core/base/controllers/UserAccountController";
import { SendWithdraw } from "@/app/core/base/controllers/ContactController";
import { MySupervisor } from "@/app/core/base/controllers/TeamsController";

export default function WithdrawModal({ show, onHide, user, valores }) {
  const [data, setData] = useState({});
  const [supervisor, setSupervisor] = useState({});

  useEffect(() => {
    if (show) {
      loadTeamSupervisor();
    }
  }, [show]);

  async function loadTeamSupervisor() {
    if (user.id !== undefined) {
      MySupervisor(user.teamId, (res) => {
        setSupervisor(res);
      });
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function save() {
    if (supervisor.length === 0 || supervisor.users.length === 0) {
      toast.error("O seu time está sem supervisor, fale com o administrador");
    } else if (data.value > valores.revenue) {
      toast.error("O valor está acima do seu saldo");
    } else {
      // UserAccountController().Insert({
      //   UserId: user.id,
      //   SenderId: user.id,
      //   Value: data.value,
      //   Type: FinancialHelper().OperationType.Outcome,
      //   Description: "Solicitação de saque",
      // }, res => {
      //   toast.success("O saque foi solicitado com sucesso, em breve o supervisor entrará em contato");
      // })
      SendWithdraw(user, data.value, supervisor.users[0]);
    }
    onHide();
  }

  return (
    <Modal
      title="Solicitar saque"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <span>Valor</span>
            <input
              className="form-control"
              type={"number"}
              name="value"
              value={data.value}
              onChange={(evt) => handleChange(evt, FieldTypeObj.Currency)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

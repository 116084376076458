import React, { useState, useEffect, useRef } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { toast } from "react-toastify";
import TimelineComponent from "@/app/core/layouts/main-components/TimelineComponent";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import ShortcutsComponent from "./ShortcutsComponent";
import { Dropdown } from "react-bootstrap";
import ProcessNotesComponent from "./ProcessNotes";
import {
  OnDataChange,
  RemoveBudgetData,
  SetBudgetData,
} from "../../util/services/DataService";
import ProcessController from "../../base/controllers/ProcessController";
import { SendEmailBudget } from "../../base/controllers/ContactController";
import {
  SendWhatsappBudget,
  PhoneTranslate,
} from "../../util/helpers/ContactHelper";
import UserContactCard from "./user/UserContactCard";
import {
  SelectedServiceUpdate,
  SelectedServices,
  ServiceList,
} from "../../base/controllers/ServicesController";
import { CustomerUsers } from "../../base/controllers/UsersController";
import ServiceCard from "./services/ServiceCard";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { DateTimeNow, ProcessDates } from "../../util/DateTranslater";
import CopyToClipboard from "react-copy-to-clipboard";
import GetBaseUrl from "../../base/BaseAddress";
import {
  TimelineChannelEmail,
  TimelineChannelWhatsapp,
  TimelineChannelSistema,
} from "../../util/helpers/TimelineHelper";
import { SendInsert } from "../../base/controllers/SendController";
import { BudgetStatus } from "../../util/helpers/BudgetHelper";
import { Message } from "../../util/messages/GeralMessages";
import ServiceDropdown from "./services/ServiceDropdown";
import CustomContact from "./contact/CustomContact";
import ServiceGroupsComponent from "./budget/ServiceGroupsComponent";
import ServiceGroupController from "../../base/controllers/ServiceGroupController";
import { ServiceGroupType } from "../../util/helpers/SelectedServiceHelper";
import ServiceGroupItem from "./budget/ServiceGroupItem";
import ConfirmProcessReview from "./process/ConfirmProcessReview";
import InputMask from "react-input-mask";
import { ValidaCNPJ } from "@/app/core/util/helpers/UserHelper";
import CustomerHelper from "../../util/helpers/CustomerHelper";
import FollowingProcessButton from "./process/FollowingProcessButton";
import { ComunicationController } from "../../base/controllers/ComunicationController";
import ProcessHelper from "../../util/helpers/ProcessHelpers";
import { FieldTypeObj } from "../../util/helpers/FieldTypeHelper";

export default function BudgetModal(props) {
  const [open, setOpen] = useState(true);
  const [responsibles, setResponsibles] = useState([]);
  const userData = Storage.getUserData();

  const [formInfos, setForm] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState(Storage.getServices());
  const [groups, setGroups] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const serviceGroup = ServiceGroupController();
  const processCtrl = ProcessController();
  const { show, onHide } = props;
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [busy, setBusy] = useState(false);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (show) {
      getInfo();

      if (userData && userData.level !== 1) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setForm({});
      setSelectedServices([]);
      setResponsibles([]);
      setLoading(false);
    }
  }, [props.info, show]);

  useEffect(() => {
    if (show && formInfos.id != undefined && busy == false) {
      updateData();
    }
  }, [formInfos]);

  function updateData() {
    let budget = formInfos;
    // budget.operator = null;
    // budget.customer = null;
    // budget.seller = null;
    // budget.budgetResponsible = null;
    // budget.responsible = null;
    setBusy(true);
    processCtrl.Update(
      budget,
      (res) => setBusy(false),
      (error) => setBusy(false),
    );
  }

  async function loadElements(selecteds) {
    if (props.data.id !== undefined) {
      await serviceGroup.Get(props.data.id, (res) => {
        let serviceList = [];
        for (let s in selecteds) {
          selecteds[s].isGroup = false;
          serviceList.push(selecteds[s]);
        }
        for (let s in res) {
          res[s].isGroup = true;
          serviceList.push(res[s]);
        }

        const itens = serviceList.sort(function (a, b) {
          return a.position - b.position;
        });
        setLoading(false);
        setSelectedServices(ProcessDates(itens));
        // setGroups(res);
      });
    }
  }

  async function getSelectedServices() {
    if (props.data !== undefined && props.data.id !== undefined) {
      setLoading(true);
      await SelectedServices(props.data.id, (res) => {
        loadElements(res);
      });
    }
  }

  function getResponsables(customerId) {
    CustomerUsers(customerId, (res) => {
      setResponsibles(res);
    });
  }

  async function getInfo() {
    if (!props.data || props.data.id === null || props.data.id === undefined) {
      setForm({});
      return;
    }
    setBusy(true);
    await processCtrl.Select(props.data.id, (res) => {
      setForm(res);
      if (userData.level === 1) {
        if (res.budgetStatus === 2) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }

      if (res.cnpj !== undefined && res.cnpj.length > 0) {
        setOpen(false);
      }
      getResponsables(res.customerId);
      getSelectedServices();
      getServices();
      setBusy(false);
    });
  }

  async function getServices() {
    if (!services || services.length == 0) {
      await ServiceList((res) => setServices(res));
    }
  }

  function handleChanges(evt, type) {
    let newInfos = OnDataChange(formInfos, evt, type);
    setForm(newInfos);
    SetBudgetData(newInfos);
  }

  function updateStatus(sts) {
    let infos = {
      ...formInfos,
      budgetStatus: sts,
    };

    update(infos);
  }

  async function update(infos) {
    setLoading(true);
    if (infos.operatorId === undefined) {
      infos.operatorId = userData.id;
    }
    await processCtrl.Update(infos, (res) => {
      toast.success("Salvo com sucesso");
      getInfo();
    });
    setLoading(false);
  }

  function updateInfos() {
    let infos = {
      ...formInfos,
      budgetStatus: formInfos.budgetStatus === 0 ? 1 : formInfos.budgetStatus,
    };

    update(infos);
  }

  function sendWhatsapp() {
    if (formInfos.budgetResponsable === null) {
      toast.error("Defina o responsável pelo Orçamento antes de enviar");
      return;
    }

    if (selectedServices.length === 0 && groups.length === 0) {
      toast.error("Selecione um serviço para o Orçamento antes de enviar");
      return;
    }

    if (
      formInfos.budgetResponsible === undefined ||
      formInfos.budgetResponsible.phone === undefined ||
      formInfos.budgetResponsible.phone === null
    ) {
      toast.error("O responsável pelo orçamento não tem um celular cadastrado");
      return;
    }

    updateStatus(5);

    addTimeline("Orçamento enviado (WhatsApp)", false, TimelineChannelWhatsapp);

    notifySend();

    setTimeout(() => {
      ComunicationController.BudgetWhats(formInfos.id, (res) => {
        onHide();
      });
    }, 1250);
  }

  function sendPreference() {
    if (formInfos.contact == ProcessHelper().ContactPreference.WhatsApp) {
      sendWhatsapp();
    } else if (formInfos.contact == ProcessHelper().ContactPreference.Email) {
      sendEmail();
    } else if (formInfos.contact == ProcessHelper().ContactPreference.Both) {
      sendEmail();
      sendWhatsapp();
    }
  }

  function notifySend() {
    SendInsert(
      {
        userId: userData.id,
        processId: formInfos.id,
        stage: 1,
      },
      (res) => {},
    );
  }

  function sendEmail() {
    if (selectedServices.length === 0 && groups.length === 0) {
      toast.error("Selecione um serviço para o Orçamento antes de enviar");
      return;
    }

    if (
      formInfos.budgetResponsible === undefined ||
      formInfos.budgetResponsible.firstName === undefined ||
      formInfos.budgetResponsible === null
    ) {
      toast.error(
        "O orçamento esta sem um responsável atribuído, adicione um usuário para enviar",
      );
      return;
    }

    updateStatus(5);

    notifySend();

    let listOfServices = [...selectedServices];
    for (let s in listOfServices) {
      listOfServices[s].selected = true;
    }
    for (let y in groups) {
      const base = groups[y];
      for (let s in base.optionalServices) {
        const item = base.optionalServices[s];
        listOfServices.push(item);
      }
    }
    setTimeout(() => {
      SendEmailBudget(
        formInfos,
        listOfServices,
        formInfos.budgetResponsible.firstName,
        formInfos.budgetResponsible.email,
        false,
      );
      addTimeline("Orçamento enviado (email)", false, TimelineChannelEmail);
      onHide();
    }, 1050);
  }

  function sendCustomEmail(mail) {
    if (selectedServices.length === 0 && groups.length === 0) {
      toast.error("Selecione um serviço para o Orçamento antes de enviar");
      return;
    }

    updateStatus(5);

    notifySend();

    let listOfServices = [...selectedServices];
    for (let s in listOfServices) {
      listOfServices[s].selected = true;
    }
    for (let y in groups) {
      const base = groups[y];
      for (let s in base.optionalServices) {
        const item = base.optionalServices[s];
        listOfServices.push(item);
      }
    }

    setTimeout(() => {
      SendEmailBudget(
        formInfos,
        listOfServices,
        formInfos.budgetResponsible !== undefined
          ? formInfos.budgetResponsible.firstName
          : "",
        mail,
      );
      addTimeline("Orçamento enviado (email)", false, TimelineChannelEmail);
      onHide();
    }, 1050);
  }

  function notifyCopy() {
    if (selectedServices.length === 0 && groups.length === 0) {
      toast.error("Selecione um serviço para o Orçamento antes de enviar");
      return;
    }

    toast.info("Copiado para a área de transferência");

    addTimeline(
      "O link do orçamento foi copiado para envio externo",
      false,
      TimelineChannelEmail,
    );
  }

  function addTimeline(message, isHistory, channel) {
    NotifyUpdate(formInfos.id, message, isHistory, channel);
  }

  function showDoc(item) {
    let url =
      "https://drive.google.com/viewerng/viewer?url=" +
      item +
      "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

    window.open(url, "_blank");
  }

  function save() {
    if (open === false && formInfos.cnpj && formInfos.cnpj.length > 0) {
      if (!ValidaCNPJ(formInfos.cnpj)) {
        toast.error("Número de CNPJ inválido");
        return;
      }
    }
    if (
      window.confirm(
        "Tem certeza de que deseja salvar sem enviar o orçamento ao cliente?",
      )
    ) {
      updateInfos();
      setTimeout(() => {
        RemoveBudgetData();
      }, 550);
    }
  }

  async function cancelBudget() {
    if (window.confirm("Deseja realmente remover esse orçamento?")) {
      let response = window.prompt("Descreva o motivo");
      if (response === "" || response === null) {
        toast.error("Você precisa descrever o motivo");
        return;
      }
      await processCtrl.Delete(formInfos.id, response, 0, (res) => {
        toast.success(Message.DeleteSuccess);
        onHide();
      });
    }
  }

  async function updateBudgetStatus(status) {
    if (
      window.confirm(
        `Deseja realmente  ${
          status === BudgetStatus.Acepted ? "aceitar" : "recusar"
        }  esse orçamento?`,
      )
    ) {
      if (status === BudgetStatus.Acepted) {
        let listOfServices = selectedServices;
        for (let y in groups) {
          const base = groups[y];
          for (let s in base.optionalServices) {
            const item = base.optionalServices[s];
            if (item.selected === true) {
              listOfServices.push(item);
            }
          }
        }
        if (listOfServices.length === 0) {
          toast.error(Message.ServiceDataPending);
          return;
        } else {
          await processCtrl.BudgetAccepted(props.data.id, false, (res) => {
            toast.success("Salvo com sucesso");
            onHide();
          });
        }
      } else if (status === BudgetStatus.Refused) {
        await processCtrl.BudgetDeclined(props.data.id, (res) => {
          toast.success("Salvo com sucesso");
          onHide();
        });
      }
    }
  }

  function CustomerStatus(i) {
    if (i === 0) {
      return "Indefinido";
    } else if (i === 1) {
      return "Pendente";
    } else if (i === 2) {
      return "Pendente Hasa";
    } else if (i === 3) {
      return "Ativo";
    } else if (i === 4) {
      return (
        <span>
          <i className="fa fa-exclamation-circle text-danger" /> Bloqueado
        </span>
      );
    }
  }

  function dragStart(e, index) {
    dragItem.current = index;
  }
  function dragEnter(e, index) {
    dragOverItem.current = index;
  }

  const drop = async (e) => {
    if (
      disabled == true ||
      (userData &&
        userData.level !== 1 &&
        userData.level !== 2 &&
        userData.level !== 10)
    ) {
      return;
    }
    let copyListItems = [...selectedServices];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    for (let s in copyListItems) {
      copyListItems[s].position = parseInt(s) + 1;
    }

    setSelectedServices(copyListItems);
    for (let s in copyListItems) {
      const item = copyListItems[s];
      if (item.isGroup === true) {
        await serviceGroup.Update(item, (res) => {});
      } else {
        await SelectedServiceUpdate(item, (res) => {});
      }
    }
  };

  return (
    <Modal
      title="Orçamento"
      show={show}
      onHide={onHide}
      onSubmit={() => save()}
    >
      <div className="row">
        <div className="col-md-12 text-center py-3">
          {formInfos && formInfos.id !== undefined && (
            <ConfirmProcessReview
              process={formInfos}
              reload={() => getInfo()}
            />
          )}
        </div>
        <Tabs>
          <div className="col-md-12">
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="fa fa-suitcase"></i> Orçamento
              </button>
            </Tab>
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="fa fa-tasks"></i> Timeline
              </button>
            </Tab>
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="far fa-file-alt"></i> Documentos
              </button>
            </Tab>
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="fa fa-link"></i> Links úteis
              </button>
            </Tab>
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="far fa-sticky-note"></i> Observações
              </button>
            </Tab>
          </div>
          <Panel>
            <div className="col-sm-12">
              <div className="block-header">
                <div className="block-title">
                  <h2 className="content-heading">
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    Orçamento
                  </h2>
                </div>
                <div className="block-tools d-flex align-items-center justify-content-between">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant={"light"}
                      disabled={disabled}
                      className="btn btn-sm btn-light"
                    >
                      <i className="fa fa-exclamation" /> Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        type="button"
                        className="btn btn-sm btn-light mr-1 mb-3"
                        onClick={() => updateBudgetStatus(BudgetStatus.Acepted)}
                      >
                        <i className="si si-check mr-1 text-success"></i>{" "}
                        Aprovar
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light mr-1 mb-3"
                        onClick={() => updateBudgetStatus(BudgetStatus.Refused)}
                      >
                        <i className="si si-close mr-1 text-danger"></i> Recusar
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light mr-1 mb-3"
                        onClick={() => cancelBudget()}
                      >
                        <i className="si si-trash mr-1 text-danger"></i> Excluir
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    className="btn btn-info btn-sm"
                    onClick={() => sendPreference()}
                  >
                    <i className="si si-paper-plane" /> Enviar
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant={"light"}
                      disabled={
                        (formInfos.budgetStatus === BudgetStatus.Acepted ||
                          formInfos.budgetStatus === BudgetStatus.Refused) &&
                        disabled
                      }
                      className="btn btn-sm btn-light"
                    >
                      <i className="si si-envelope-letter" /> Enviar por
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        type="button"
                        className="btn btn-sm btn-light mr-1 mb-3"
                        onClick={() => sendWhatsapp()}
                      >
                        <i className="fab fa-whatsapp mr-1"></i> Whatsapp
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-light mr-1 mb-3"
                        onClick={() => sendEmail()}
                      >
                        <i className="far fa-envelope mr-1"></i> Email
                      </button>
                      <CopyToClipboard
                        text={`${GetBaseUrl()}#/confirm/${formInfos.id}`}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-light mr-1 mb-3"
                          title="copiar"
                          onClick={() => notifyCopy()}
                        >
                          <i className="far fa-copy mr-1" /> Copiar URL
                        </button>
                      </CopyToClipboard>
                      <CustomContact send={(mail) => sendCustomEmail(mail)} />
                    </Dropdown.Menu>
                  </Dropdown>
                  {userData &&
                  userData.level !== 5 &&
                  (enviroment == "staging" || enviroment == "local") ? (
                    <FollowingProcessButton
                      processId={formInfos && formInfos.id}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="block-content">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="example-masked-time">Nome do processo</label>
                    <input
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      type="text"
                      name="processName"
                      disabled={disabled}
                      defaultValue={formInfos && formInfos.processName}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="example-masked-time">
                      Cliente - Status:{" "}
                      {formInfos.customer &&
                        CustomerStatus(formInfos.customer.status)}
                    </label>
                    {formInfos &&
                    formInfos.customer &&
                    formInfos.customer.level !=
                      CustomerHelper().CustomerLevel.Normal ? (
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          name="clientName"
                          disabled={true}
                          defaultValue={
                            formInfos.customer &&
                            `${formInfos.customer.customerCode} - ${formInfos.customer.name}`
                          }
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            {CustomerHelper().ShowLevel(
                              formInfos.customer.level,
                            )}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <input
                        className="form-control"
                        type="text"
                        name="clientName"
                        disabled={true}
                        defaultValue={
                          formInfos.customer &&
                          `${formInfos.customer.customerCode} - ${formInfos.customer.name}`
                        }
                      />
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label for="example-masked-time">
                      Responsável pelo orçamento
                    </label>
                    <select
                      name="budgetResponsibleId"
                      onChange={(evt) => handleChanges(evt)}
                      disabled={disabled}
                      className="form-control"
                    >
                      <option value={null}>Selecione</option>
                      {responsibles &&
                        responsibles.map((item) => (
                          <option
                            value={item.id}
                            selected={item.id === formInfos.budgetResponsibleId}
                          >
                            {item.firstName} {item.lastName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label for="responsibleId">Responsável pelo processo</label>
                    <select
                      name="responsibleId"
                      onChange={(evt) => handleChanges(evt)}
                      disabled={disabled}
                      className="form-control"
                    >
                      <option value={null}>Selecione</option>
                      {responsibles &&
                        responsibles.map((item) => (
                          <option
                            value={item.id}
                            selected={item.id === formInfos.responsibleId}
                          >
                            {item.firstName} {item.lastName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="contact">Preferencia de contato</label>
                    <select
                      onChange={(evt) =>
                        handleChanges(evt, FieldTypeObj.Number)
                      }
                      className="form-control"
                      name="contact"
                      disabled={disabled}
                      defaultValue={formInfos && formInfos.contact}
                    >
                      {ProcessHelper().ContactList.map((item) => (
                        <option
                          value={item.value}
                          selected={formInfos.contactPreference === item.value}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="d-block">Se trata de uma abertura?</label>
                    <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        onClick={() => setOpen(true)}
                        id="example-radio-custom-inline1"
                        checked={open === true ? true : false}
                        name="example-radio-custom-inline"
                      />
                      <label
                        className="custom-control-label"
                        for="example-radio-custom-inline1"
                      >
                        Sim
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="example-radio-custom-inline2"
                        checked={open === false ? true : false}
                        onClick={() => setOpen(false)}
                        name="example-radio-custom-inline"
                      />
                      <label
                        className="custom-control-label"
                        for="example-radio-custom-inline2"
                      >
                        Não
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="example-textarea-input">
                      Observações ao cliente
                    </label>
                    <textarea
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      name="observation"
                      disabled={disabled}
                      defaultValue={
                        formInfos && formInfos.observation !== "null"
                          ? formInfos.observation
                          : ""
                      }
                      rows="4"
                      placeholder="Escreva suas observações ao cliente"
                    />
                  </div>
                </div>

                {open === false ? (
                  <div className="row">
                    {/* <div className="form-group col-md-6">
                      <label for="example-masked-time">Nire</label>
                      <input
                        onChange={(evt) => handleChanges(evt)}
                        defaultValue={formInfos && formInfos.nire}
                        className="form-control"
                        type="text"
                        disabled={disabled}
                        name="nire"
                      />
                    </div> */}
                    <div className="form-group col-md-6">
                      <label for="example-masked-time">CNPJ</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        maskChar=""
                        type="text"
                        className="form-control"
                        id="advanced-filter-document"
                        name="cnpj"
                        placeholder="99.999.999/9999-99"
                        value={formInfos && formInfos.cnpj}
                        onChange={(event) => {
                          handleChanges({
                            target: {
                              name: "cnpj",
                              value: event.target.value.replace(/\D/g, ""),
                            },
                          });
                        }}
                      />
                    </div>
                    {/* <div className="form-group col-md-6">
                      <label for="example-masked-time">CCM (Opcional)</label>
                      <input
                        onChange={(evt) => handleChanges(evt)}
                        defaultValue={formInfos && formInfos.ccm}
                        className="form-control"
                        type="text"
                        disabled={disabled}
                        name="ccm"
                      />
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label for="example-masked-time">Cadesp (Opcional)</label>
                      <input
                        defaultValue={formInfos && formInfos.cadesp}
                        onChange={(evt) => handleChanges(evt)}
                        className="form-control"
                        type="text"
                        disabled={disabled}
                        name="cadesp"
                      />
                    </div> */}
                  </div>
                ) : (
                  ""
                )}

                {formInfos && formInfos.budgetResponsible !== null ? (
                  <UserContactCard
                    processId={formInfos.id}
                    user={formInfos.budgetResponsible}
                  />
                ) : (
                  ""
                )}
              </div>

              <div className="block-header">
                <div className="block-title">
                  <h3 className="content-heading">
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    Serviços
                  </h3>
                </div>
                <div className="block-tools">
                  <ServiceDropdown
                    selectedServices={selectedServices}
                    processId={props.data && props.data.id}
                    reload={() => getSelectedServices()}
                  />
                  <ServiceGroupsComponent
                    processId={formInfos && formInfos.id}
                    refresh={() => getInfo()}
                    selectedServices={selectedServices}
                    disabled={
                      (formInfos &&
                        formInfos.budgetStatus === BudgetStatus.Acepted) ||
                      (formInfos &&
                        formInfos.budgetStatus === BudgetStatus.Refused)
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {loading === true ? (
                <div className="block-content text-center">
                  <i className={"fa fa-circle-notch fa-spin"}></i>
                </div>
              ) : (
                <div className="">
                  {selectedServices &&
                    selectedServices.map((item, index) => {
                      if (item.isGroup == false) {
                        return (
                          <div
                            className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            key={`service-${item.id}-${index}`}
                            onDragEnd={drop}
                            draggable
                          >
                            <ServiceCard
                              service={item}
                              disable={disabled}
                              selectedServices={selectedServices}
                              reload={() => getSelectedServices()}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn"
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            key={`service-${item.id}-${index}`}
                            onDragEnd={drop}
                            draggable
                          >
                            <ServiceGroupItem
                              group={item}
                              services={services}
                              position={index + 1}
                              refresh={() => getInfo()}
                              reloadGroups={() => getInfo()}
                              selectedServices={selectedServices}
                              disabled={disabled}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              )}
              {/* <ServiceGroupsComponent
                processId={formInfos && formInfos.id}
                // refresh={() => getInfo()}
                selectedServices={selectedServices}
                disabled={
                  (formInfos &&
                    formInfos.budgetStatus === BudgetStatus.Acepted) ||
                  (formInfos && formInfos.budgetStatus === BudgetStatus.Refused)
                    ? true
                    : false
                }
              /> */}
            </div>
          </Panel>
          <Panel>
            <div
              className="col-sm-12"
              style={{ overflowY: "scroll", height: "950px" }}
            >
              <TimelineComponent processId={formInfos.id} />
            </div>
          </Panel>
          <Panel>
            <div
              className="col-sm-12"
              style={{ overflowY: "scroll", height: "950px" }}
            >
              <DocsComponent
                processId={formInfos && formInfos.id}
                setView={(data) => showDoc(data)}
              />
            </div>
          </Panel>
          <Panel>
            <div
              className="col-sm-12"
              style={{ overflowY: "scroll", height: "950px" }}
            >
              <ShortcutsComponent />
            </div>
          </Panel>
          <Panel>
            <div
              className="col-sm-12"
              style={{ overflowY: "scroll", height: "950px" }}
            >
              <ProcessNotesComponent
                budgetId={0}
                processId={formInfos && formInfos.id}
                open={(data) => showDoc(data)}
              />
            </div>
          </Panel>
        </Tabs>
      </div>
    </Modal>
  );
}

import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { OnDataChange } from "@/app/core/util/services/DataService";
import CustomModal from "@/app/core/layouts/main/components/Modal";
import { Message } from "@/app/core/util/messages/GeralMessages";
import DocsController from "@/app/core/base/controllers/DocumentsController";
import Storage from "@/app/core/util/Storage";
import { Dropdown } from "react-bootstrap";

function DeferredEmailModal({ docs, show, onHide }) {
  const userData = Storage.getUserData();
  const [data, setData] = useState({
    from: userData != null ? userData.email : "",
  });
  const [fileList, setFileList] = useState([]);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      setData({
        ...data,
        description: `Bom dia, tudo bem?
    
    Conforme orientado, segue anexo as documentações para |_motive_| na |_nome_orgao_|  da empresa supramencionada no assunto deste e-mail.
    
    Aguardo retorno. Qualquer dúvida estou à disposição.
    
    Atenciosamente ${userData && userData.firstName} ${userData && userData.lastName} - Hasa Documentos`,
      });
    }
    setFileList(docs);
  }, [docs]);

  function nameOrgan(name) {
    setData({
      ...data,
      name,
      description: `Bom dia, tudo bem?

Conforme orientado, segue anexo as documentações para |_motive_| na ${name}  da empresa supramencionada no assunto deste e-mail.

Aguardo retorno. Qualquer dúvida estou à disposição.

Atenciosamente ${userData && userData.firstName} ${userData && userData.lastName} - Hasa Documentos`,
    });
  }

  function handleName() {
    let old = data.description;
  }

  function handleEmailChange(evt) {
    setData(OnDataChange(data, evt));
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  async function sendEmail() {
    if (
      !data.from ||
      !data.targetEmail ||
      !data.subject ||
      !data.description ||
      !data.motive
    ) {
      console.log(
        `Erro ao preencher`,
        data.from,
        data.targetEmail,
        data.subject,
        data.description,
      );
      toast.error(Message.PendingData);
    } else {
      let body = data;
      body.attachments = [];
      let errors = [];
      const files = fileList.filter((x) => x.checked == true);

      if (data.description.includes("_nome_orgao_")) {
        toast.error("Esqueceu de mudar o nome do órgão no corpo do email");
        return;
      }

      data.description = data.description.replace("|_motive_|", data.motive);

      if (!data.from.includes("@hasa.com.br")) {
        toast.error("O email de remetente não é um endereço Hasa");
        return;
      }

      if (files.length == 0) {
        toast.error("Selecione ao menos 1 arquivo para prosseguir");
      } else {
        for (let s in files) {
          body.attachments.push(files[s].id);
          if (!files[s].uri.includes("pdf")) {
            errors.push(files[s]);
          }
        }

        if (errors.length > 0) {
          toast.error(
            `Atenção! Impossível enviar o email com esse tipo de arquivo, nessa ferramenta são aceitos apenas PDF's.`,
          );
        } else {
          if (window.confirm("Deseja continuar?")) {
            setLoading(true);
            await DocsController().SendOrgan(
              body,
              (res) => {
                setLoading(false);
                toast.success(Message.Saved);
                onHide();
              },
              (error) => {
                toast.error("Ocorreu um erro ao enviar o email");
                setLoading(false);
              },
            );
          }
        }
      }
    }
  }

  function handleCheckDoc(evt, index) {
    let old = fileList;
    old[index].checked = evt.target.checked;
    setFileList(old);
    notifyChange();
  }

  return (
    <CustomModal
      title="Enviar ao órgão"
      show={show}
      onHide={onHide}
      isLoading={loading}
      submitName="Enviar"
      onSubmit={() => sendEmail()}
      size="sm"
    >
      <div class="row">
        <div className="form-group col-md-6">
          <span>Remetente</span>
          <input
            type="email"
            className="form-control"
            name="from"
            value={data.from}
            onChange={(evt) => handleEmailChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Órgão a ser protocolado</span>
          <input
            type="email"
            className="form-control"
            name="name"
            value={data.name}
            onChange={(evt) => nameOrgan(evt.target.value)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Destinatário</span>
          <input
            type="email"
            className="form-control"
            name="targetEmail"
            value={data.targetEmail}
            onChange={(evt) => handleEmailChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Assunto</span>
          <input
            type="email"
            className="form-control"
            name="subject"
            value={data.subject}
            onChange={(evt) => handleEmailChange(evt)}
          />
        </div>
        <div className="form-group col-md-12">
          <span>Motivo do protocolo</span>
          <input
            type="text"
            className="form-control"
            name="motive"
            value={data.motive}
            onChange={(evt) => handleEmailChange(evt) | handleName()}
          />
        </div>
        <div className="form-group col-md-12">
          {/* <span className="font-w700">Selecionar arquivos</span>
                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-block btn-lg btn-white text-left border border-2x" variant="light">
                            Todos os arquivos
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "100%", maxHeight: 440, overflowY: "scroll" }}>
                            <label className="font-w700">Residencial</label>
                            {fileList && fileList.map((item, index) => (
                                <div class="form-group ml-2">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input type="checkbox" class="custom-control-input" id={item.id} name={item.id} onChange={(evt) => handleCheckDoc(evt, index)} />
                                        <label class="custom-control-label" for={item.id}>{item.name}</label>
                                    </div>
                                </div>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown> */}
          {change &&
          fileList &&
          fileList.filter((x) => x.checked == true).length > 0 ? (
            <div>
              <span>Anexos</span>
            </div>
          ) : (
            ""
          )}
          {change &&
            fileList &&
            fileList
              .filter((x) => x.checked == true)
              .map((item) => (
                <button className="btn btn-sm btn-dual">
                  <i className="far fa-file-pdf mr-2" />
                  {item.name}
                </button>
              ))}
        </div>
        <div className="form-group col-md-12">
          <span>Descrição</span>
          <textarea
            type="email"
            className="form-control"
            disabled={true}
            name="description"
            value={data.description}
            onChange={(evt) => handleEmailChange(evt)}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default DeferredEmailModal;

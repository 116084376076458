import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import DailyRatesForm from "./DailyRatesForm";
import {
  DailyActivityDelete,
  DailyActivityList,
} from "@/app/core/base/controllers/DailyActivitiesControlle";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import Functions from "@/app/core/util/Functions";

export default function DailyRatesComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});

  const cols = [
    { header: "Departamento", name: "department", isSortable: true },
    { header: "Tasks por dia", name: "tasks", isSortable: true },
    { header: "Atualizado em", name: "updatedDate", isSortable: true },
    { header: "Criado em", name: "createdDate", isSortable: true },
  ];

  useEffect(() => {
    getDailyRates();
  }, []);

  function getDailyRates() {
    setTableLoading(true);
    DailyActivityList((res) => {
      for (let s in res) {
        res[s].department = DepartmentTranslate(res[s].level);
        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
      }
      setData(res);
      setTableLoading(false);
    });
  }

  function disableDailyRate(data) {
    if (window.confirm(`Tem certeza que quer remover a tarefa?`)) {
      DailyActivityDelete(data.id, (res) => {
        props.toast.info("Tarefa removida");
      });
    }
  }

  function open(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  return (
    <>
      <Card
        title="Tarefas diárias pessoa/departamento"
        action={() => setShowModal(!showModal)}
        icon="settings"
      >
        <Table
          loading={tableLoading || false}
          data={data}
          columns={cols}
          insertAction={() => setShowModal(true)}
          editAction={(task) => open(task)}
          deleteAction={(task) => disableDailyRate(task.data)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          hideHeader
        />
      </Card>

      <DailyRatesForm
        values={selected}
        show={showModal}
        isLoading={modalLoading}
        onHide={() =>
          setShowModal(!showModal) | setSelected({}) | getDailyRates()
        }
      />
    </>
  );
}

import React, { useState } from "react";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import { toast } from "react-toastify";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function BudgetLine({ item, user, members, reload }) {
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [newUser, setNewUser] = useState("");
  const processCtrl = ProcessController();

  function openSelectedService() {
    setSelectedData({ processId: item.id, id: item.id });
    setShowProcessModal(true);
  }

  async function redirectProcess() {
    if (newUser.length === 0 || newUser === null) {
      toast.error("Selecione um usuario para salvar");
    } else {
      if (window.confirm("Deseja redirecionar esse processo?")) {
        let budget = item;
        budget.sellerId = newUser;
        if (budget.operatorId === undefined || budget.operatorId === null) {
          budget.operatorId = budget.budgetResponsibleId;
        }

        await processCtrl.Update(budget, (res) => {
          toast.success(Message.Saved);
          reload();
        });
      }
    }
  }

  function processStatus(item) {
    if (user.level === 1) {
      return BudgetStatusTranslate(item.budgetStatus);
    } else {
      return "S.I.";
    }
  }

  async function deleteBudget() {
    if (window.confirm("Deseja realmente excluir esse item?")) {
      let response = window.prompt("Descreva o motivo");
      if (response === "" || response === null) {
        toast.error("Você precisa descrever o motivo");
        return;
      }
      await processCtrl.Delete(item.id, response, (res) => {
        toast.success(Message.DeleteSuccess);
        reload();
      });
    }
  }

  return (
    <tr>
      <td>{item.id}</td>
      <td>{item && item.processName}</td>
      <td>{processStatus(item)}</td>
      <td></td>
      <td>{DateTranslate(item.updatedDate)}</td>
      <td>{DateTranslate(item.createdDate)}</td>
      <td>
        <div className="row">
          <div className="col-md-6">
            <button
              className="btn btn-dual"
              onClick={() => openSelectedService()}
            >
              <i className="fa fa-eye" />
            </button>
            <button className="btn btn-dual" onClick={() => deleteBudget()}>
              <i className="si si-close text-danger" />
            </button>
          </div>
          <div className="col-md-6">
            <DropdownElement
              icon={"fa fa-edit"}
              button={"Salvar"}
              orientation={"left"}
              submit={() => redirectProcess()}
            >
              <div className="form-group">
                <span>Atribuir para</span>
                <select
                  className="form-control"
                  onChange={(evt) => setNewUser(evt.target.value)}
                >
                  <option value={null}>Selecione</option>
                  {members &&
                    members.map((item) => (
                      <option value={item.id}>
                        {item.firstName} - {item.email}
                      </option>
                    ))}
                </select>
              </div>
            </DropdownElement>
          </div>
        </div>
      </td>
      <OperationProcessModal
        data={selectedData}
        show={showProcessModal}
        onHide={() => setShowProcessModal(false) | setSelectedData({})}
      />
    </tr>
  );
}

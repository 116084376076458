export const EmailHelper = {
  Status: {
    Processed: 0,
    Droped: 1,
    Delivered: 2,
    Bounce: 3,
    Open: 4,
  },
  Type: {
    Budget: 0,
    Exigency: 1,
    Delivery: 2,
  },
  TypeList: [
    { name: "Orçamento", value: 0 },
    { name: "Exigência", value: 1 },
    { name: "Deferido", value: 2 },
  ],
};

import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";

export default function DeferredCard({
  customerId,
  getCustomerSO,
  finishedData,
  returnedData,
}) {
  const userData = Storage.getUserData();
  const [query, setQuery] = useState("");
  const [finished, setFinished] = useState([]);
  const [returned, setReturned] = useState([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [showNewBudgetModal, setShowNewBudgetModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [operationData, setOperationData] = useState({});

  const colsProcess = [
    // { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    {
      header: "Status",
      isSortable: true,
      name: "status",
      switch: {
        0: <span class="badge badge-info">Não iniciado</span>,
        1: <span class="badge badge-primary">Em andamento</span>,
        2: <span class="badge badge-success">Concluído</span>,
        4: <span class="badge badge-danger">Devolvido</span>,
      },
    },
    // { header: "Criado em", isSortable: true, name: "createdDate" },
    { header: "Concluído em", isSortable: true, name: "updatedDate" },
  ];

  useEffect(() => {
    setFinished(finishedData);
    loadReturned();
  }, [finishedData]);

  useEffect(() => {
    loadReturned();
  }, [returnedData]);

  async function loadReturned() {
    await ProcessController().Returned(customerId, (res) => {
      setReturned(ProcessDates(res));
    });
  }

  function handleChange(value) {
    setQuery(value);
    let refusedbgts = [];

    if (value.length > 0) {
      refusedbgts = autocompleteProcess(value, finishedData);
      setFinished(refusedbgts);
    } else {
      setFinished(finishedData);
    }
  }

  function autocompleteProcess(value, data) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.processName.toLowerCase();
      if (name.includes(inputValue)) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  function openNewBudget(itens) {
    setSelected(itens);
    setShowNewBudgetModal(true);
  }

  return (
    <div>
      <div className="block block-rounded">
        <div className="block-content">
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                placeholder="Filtrar"
                className="form-control"
                id="search-filter"
                value={query}
                onChange={(evt) => handleChange(evt.target.value)}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  onClick={() => setQuery("") | setFinished(finishedData)}
                  title="Limpar campo"
                >
                  <i className="si si-close"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="block block-rounded" style={{ overflowX: "scroll" }}>
            <div className="block-content">
              <Table
                title="Serviços concluídos"
                key={"ServiçosConcluidos"}
                data={finished}
                columns={colsProcess}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, finished)
                }
                refreshData={() => getCustomerSO()}
                button={(item) => openNewBudget(item)}
                viewAction={(data) =>
                  setOperationData(data.data) | setShowOperationModal(true)
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block block-rounded">
        <div className="block-content">
          <div className="block block-rounded" style={{ overflowX: "scroll" }}>
            <div className="block-content">
              <Table
                title="Processos Devolvidos"
                key={"devolvidos"}
                data={returned}
                columns={colsProcess}
                setFilter={(filter) =>
                  Functions.ProcessFilter(filter, finished)
                }
                refreshData={() => getCustomerSO()}
                button={(item) => openNewBudget(item)}
                viewAction={(data) =>
                  setOperationData(data.data) | setShowOperationModal(true)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <OperationProcessModal
        data={operationData}
        show={showOperationModal}
        submit={(updateBudget) => null}
        onHide={() =>
          setOperationData(null) |
          getCustomerSO() |
          setShowOperationModal(false)
        }
      />

      <CustomerNewBudget
        show={showNewBudgetModal}
        infos={selected}
        customerId={customerId}
        onHide={() => setShowNewBudgetModal(false) | setSelected({})}
      />
    </div>
  );
}

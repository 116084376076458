import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import Functions from "@/app/core/util/Functions";
import OrganListHelper, {
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";

export default function OngoingCard({ getCustomerSO, data, deferredData }) {
  const [open, setOpen] = useState(false);
  const userData = Storage.getUserData();
  const [query, setQuery] = useState("");
  const [serviceOrders, setServiceOrders] = useState([]);
  const [deferred, setDeferred] = useState([]);
  const [header, setHeader] = useState({
    id: "DESC",
    processName: "ASC",
    serviceName: "DESC",
    statusName: "DESC",
    createdDate: "DESC",
    updatedDate: "DESC",
  });

  const [headerDone, setHeaderDone] = useState({
    id: "DESC",
    processName: "ASC",
    createdDate: "DESC",
    updatedDate: "DESC",
    serviceName: "DESC",
  });

  const [showOperationModal, setShowOperationModal] = useState(false);
  const [operationData, setOperationData] = useState({});
  const organHelper = OrganListHelper();

  useEffect(() => {
    setServiceOrders(
      Functions.ProcessFilter(
        {
          direction: "ASC",
          orderBy: "processName",
        },
        data.filter(
          (x) =>
            x.status != OrganStatus.Returned &&
            x.status != OrganStatus.Deferred &&
            x.status != OrganStatus.Done,
        ),
      ),
    );
    setDeferred(
      Functions.ProcessFilter(
        {
          direction: "ASC",
          orderBy: "processName",
        },
        deferredData.filter((x) => x.status != OrganStatus.Returned),
      ),
    );
  }, [data, deferredData]);

  function handleChange(value) {
    setQuery(value);
    let defs = [];
    let sv = [];

    if (value.length > 0) {
      setServiceOrders([]);
      sv = autocomplete(value, data);
      defs = autocomplete(value, deferredData);
      setDeferred(defs);

      setServiceOrders(sv);
    } else {
      sv = [];
      setServiceOrders(data);
      setDeferred(deferredData);
    }
  }

  function autocomplete(value, data) {
    const inputValue = value.trim().toLowerCase();
    let itens = [];
    for (let s in data) {
      itens.push(data[s]);
    }
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name = item.process.processName.toLowerCase();
      const serviceName = item.serviceName.toLowerCase();
      const updatedDate = item.updatedDate;
      if (
        name.includes(inputValue) ||
        serviceName.includes(inputValue) ||
        updatedDate.includes(inputValue)
      ) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  function toggleCard() {
    setOpen(!open);
  }

  return (
    <div>
      <div className="block block-rounded">
        <div className="block-header">
          <div className="block-title">Serviços em andamento</div>
          <div className="block-tools">
            <button
              className="btn btn-dual btn-sm"
              onClick={() => getCustomerSO()}
            >
              <i className="si si-refresh"></i>
            </button>
          </div>
        </div>
        <div className="block-content" style={{ overflowX: "scroll" }}>
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                placeholder="Filtrar"
                className="form-control"
                id="search-filter"
                value={query}
                onChange={(evt) => handleChange(evt.target.value)}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text"
                  onClick={() => setQuery("") | setServiceOrders(data)}
                  title="Limpar campo"
                >
                  <i className="si si-close"></i>
                </span>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
            <thead>
              <tr>
                <td>
                  Id
                  <span
                    onClick={() => {
                      setHeader({
                        ...header,
                        id: header.id === "DESC" ? "ASC" : "DESC",
                      });
                      setServiceOrders(
                        Functions.ProcessFilter(
                          {
                            direction: header.id === "DESC" ? "ASC" : "DESC",
                            orderBy: "id",
                          },
                          data,
                        ),
                      );
                    }}
                  >
                    {header && header.id === "ASC" ? (
                      <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                    ) : (
                      <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                    )}
                  </span>
                </td>
                <td>
                  Processo
                  <span
                    onClick={() => {
                      setHeader({
                        ...header,
                        processName:
                          header.processName === "DESC" ? "ASC" : "DESC",
                      });
                      setServiceOrders(
                        Functions.ProcessFilter(
                          {
                            direction:
                              header.processName === "DESC" ? "ASC" : "DESC",
                            orderBy: "processName",
                          },
                          data,
                        ),
                      );
                    }}
                  >
                    {header && header.processName === "ASC" ? (
                      <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                    ) : (
                      <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                    )}
                  </span>
                </td>
                <td>
                  Serviço
                  <span
                    onClick={() => {
                      setHeader({
                        ...header,
                        serviceName:
                          header.serviceName === "DESC" ? "ASC" : "DESC",
                      });
                      setServiceOrders(
                        Functions.ProcessFilter(
                          {
                            direction:
                              header.serviceName === "DESC" ? "ASC" : "DESC",
                            orderBy: "serviceName",
                          },
                          data,
                        ),
                      );
                    }}
                  >
                    {header && header.serviceName === "ASC" ? (
                      <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                    ) : (
                      <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                    )}
                  </span>
                </td>

                <td>
                  Status
                  <span
                    onClick={() => {
                      setHeader({
                        ...header,
                        statusName:
                          header.statusName === "DESC" ? "ASC" : "DESC",
                      });
                      setServiceOrders(
                        Functions.ProcessFilter(
                          {
                            direction:
                              header.statusName === "DESC" ? "ASC" : "DESC",
                            orderBy: "statusName",
                          },
                          data,
                        ),
                      );
                    }}
                  >
                    {header && header.statusName === "ASC" ? (
                      <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                    ) : (
                      <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                    )}
                  </span>
                </td>
                <td>
                  Últ. atualização
                  <span
                    onClick={() => {
                      setHeader({
                        ...header,
                        updatedDate:
                          header.updatedDate === "DESC" ? "ASC" : "DESC",
                      });
                      setServiceOrders(
                        Functions.ProcessFilter(
                          {
                            direction:
                              header.updatedDate === "DESC" ? "ASC" : "DESC",
                            orderBy: "updatedDate",
                          },
                          data,
                        ),
                      );
                    }}
                  >
                    {header && header.updatedDate === "ASC" ? (
                      <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                    ) : (
                      <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                    )}
                  </span>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {serviceOrders &&
                serviceOrders.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.process && item.process.processName}</td>
                    <td>
                      {item.selectedService &&
                        item.selectedService.service &&
                        item.selectedService.serviceName}
                    </td>
                    <td>{organHelper.GetCurrentStatus(item)}</td>
                    <td>{item && item.updatedDate}</td>
                    <td>
                      <button
                        className="btn btn-dual btn-sm"
                        onClick={() =>
                          setOperationData({
                            ...item,
                            organListId: item.id,
                            id: item.processId,
                            processId: item.processId,
                          }) | setShowOperationModal(true)
                        }
                      >
                        <i className="si si-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <OperationProcessModal
          data={operationData}
          show={showOperationModal}
          submit={(updateBudget) => null}
          onHide={() =>
            setOperationData(null) |
            getCustomerSO() |
            setShowOperationModal(false)
          }
        />
      </div>

      {/* <div className="block block-rounded">
        <div className="block-header">
          <div className="block-title">
            Deferidos – Aguardando outros órgãos
          </div>

          <div className="block-tools">
            <button
              className="btn btn-dual btn-sm"
              onClick={() => getCustomerSO()}
            >
              <i className="si si-refresh"></i>
            </button>
            <button
              className="btn btn-sm btn-hero-light js-click-ripple-enabled"
              onClick={() => toggleCard()}
            >
              <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
            </button>
          </div>
        </div>
        {open === true ? (
          <div className="block-content">
            <table className="table table-striped table-hover table-borderless table-vcenter font-size-sm">
              <thead>
                <tr>
                  <td className="d-none d-sm-table-cell font-w700">
                    Id
                    <span
                      onClick={() => {
                        setHeaderDone({
                          ...headerDone,
                          id: headerDone.id === "DESC" ? "ASC" : "DESC",
                        });
                        setDeferred(
                          Functions.ProcessFilter(
                            {
                              direction:
                                headerDone.id === "DESC" ? "ASC" : "DESC",
                              orderBy: "id",
                            },
                            deferred
                          )
                        );
                      }}
                    >
                      {headerDone && headerDone.id === "ASC" ? (
                        <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                      ) : (
                        <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                      )}
                    </span>
                  </td>
                  <td className="d-none d-sm-table-cell font-w700">
                    Processo
                    <span
                      onClick={() => {
                        setHeaderDone({
                          ...headerDone,
                          processName:
                            headerDone.processName === "DESC" ? "ASC" : "DESC",
                        });
                        setDeferred(
                          Functions.ProcessFilter(
                            {
                              direction:
                                headerDone.processName === "DESC"
                                  ? "ASC"
                                  : "DESC",
                              orderBy: "processName",
                            },
                            deferred
                          )
                        );
                      }}
                    >
                      {headerDone && headerDone.processName === "ASC" ? (
                        <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                      ) : (
                        <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                      )}
                    </span>
                  </td>
                  <td className="d-none d-sm-table-cell font-w700">
                    Órgão
                    <span
                      onClick={() => {
                        setHeaderDone({
                          ...headerDone,
                          organName:
                            headerDone.organName === "DESC" ? "ASC" : "DESC",
                        });
                        setDeferred(
                          Functions.ProcessFilter(
                            {
                              direction:
                                headerDone.organName === "DESC"
                                  ? "ASC"
                                  : "DESC",
                              orderBy: "organName",
                            },
                            deferred
                          )
                        );
                      }}
                    >
                      {headerDone && headerDone.organName === "ASC" ? (
                        <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                      ) : (
                        <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                      )}
                    </span>
                  </td>
                  <td className="d-none d-sm-table-cell font-w700">
                    Serviço
                    <span
                      onClick={() => {
                        setHeaderDone({
                          ...headerDone,
                          serviceName:
                            headerDone.serviceName === "DESC" ? "ASC" : "DESC",
                        });
                        setDeferred(
                          Functions.ProcessFilter(
                            {
                              direction:
                                headerDone.serviceName === "DESC"
                                  ? "ASC"
                                  : "DESC",
                              orderBy: "serviceName",
                            },
                            deferred
                          )
                        );
                      }}
                    >
                      {headerDone && headerDone.serviceName === "ASC" ? (
                        <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                      ) : (
                        <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                      )}
                    </span>
                  </td>

                  <td className="d-none d-sm-table-cell font-w700">Status</td>
                  <td className="d-none d-sm-table-cell font-w700">
                    Últ. atualização
                    <span
                      onClick={() => {
                        setHeaderDone({
                          ...headerDone,
                          updatedDate:
                            headerDone.updatedDate === "DESC" ? "ASC" : "DESC",
                        });
                        setDeferred(
                          Functions.ProcessFilter(
                            {
                              direction:
                                headerDone.updatedDate === "DESC"
                                  ? "ASC"
                                  : "DESC",
                              orderBy: "updatedDate",
                            },
                            deferred
                          )
                        );
                      }}
                    >
                      {headerDone && headerDone.updatedDate === "ASC" ? (
                        <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                      ) : (
                        <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                      )}
                    </span>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {deferred &&
                  deferred.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.process && item.process.processName}</td>
                      <td>{item.organ && item.organ.name}</td>
                      <td>
                        {item.selectedService &&
                          item.selectedService.service &&
                          item.selectedService.service.name}
                      </td>
                      <td>{organHelper.GetCurrentStatus(item)}</td>
                      <td>{item && item.updatedDate}</td>
                      <td>
                        <button
                          className="btn btn-dual btn-sm"
                          onClick={() =>
                            setOperationData({
                              ...item,
                              organListId: item.id,
                              id: item.processId,
                              processId: item.processId,
                            }) | setShowOperationModal(true)
                          }
                        >
                          <i className="si si-eye"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
}

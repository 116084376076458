import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function TeamInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: "team/insert",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function TeamUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: "team/update",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function TeamDelete(id, response) {
  BaseService(
    {
      method: "GET",
      url: `team/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function TeamList(response) {
  BaseService(
    {
      method: "GET",
      url: `team`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function TeamSelect(teamId, response) {
  BaseService(
    {
      method: "GET",
      url: `team/${teamId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function SupervisorTeamSelect(teamId, response) {
  await BaseService(
    {
      method: "GET",
      url: `team/supervisor/${teamId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function DevolutionSupervisorResume(teamId, response) {
  await BaseService(
    {
      method: "GET",
      url: `team/devolution/resume/${teamId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function SupervisorTeamTasks(teamId, response) {
  await BaseService(
    {
      method: "GET",
      url: `team/supervisor/process/${teamId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function MySupervisor(teamId, response) {
  BaseService(
    {
      method: "GET",
      url: `team/mysupervisor/${teamId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function AdministratorTeamTasks(response) {
  BaseService(
    {
      method: "GET",
      url: `team/administrator/process`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function AdministratorTeams(response) {
  await BaseService(
    {
      method: "GET",
      url: `team/administrator/teams`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

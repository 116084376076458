import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { toast } from "react-toastify";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import { Dropdown } from "react-bootstrap";
import {
  OnDataChange,
  RemoveBudgetData,
  SetBudgetData,
} from "@/app/core/util/services/DataService";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { SendEmailBudget } from "@/app/core/base/controllers/ContactController";
import { SelectedServices } from "@/app/core/base/controllers/ServicesController";
import { CustomerUsers } from "@/app/core/base/controllers/UsersController";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import CopyToClipboard from "react-copy-to-clipboard";
import ServiceGroupsComponent from "@/app/core/layouts/main-components/budget/ServiceGroupsComponent";
import ServiceGroupController from "@/app/core/base/controllers/ServiceGroupController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ServiceGroupItem from "./ServiceGroupItem";
import CustomContact from "../contact/CustomContact";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import ServiceItem from "@/app/modules/confirm-service/components/ServiceItem";
import { ProcessTotal } from "@/app/core/util/helpers/BudgetHelper";

export default function CustomerBusinessModal(props) {
  const userData = Storage.getUserData();

  const [formInfos, setForm] = useState({});
  const [serviceGroups, setGroups] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalAccountability, setTotalAccountability] = useState(0);
  const serviceGroup = ServiceGroupController();
  const processCtrl = ProcessController();
  const { show, onHide, processId } = props;
  const enviroment = process.env.REACT_APP_ENV;
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    getInfo();
  }, [processId, show]);

  useEffect(() => {
    processValues();
  }, [selectedServices, serviceGroups]);

  function notyChange() {
    setChange(date.getTime());
  }

  function processValues() {
    let hasa = 0;
    let account = 0;
    let serviceList = [];
    if (serviceGroups !== null && serviceGroups.length > 0) {
      for (let y in serviceGroups) {
        const base = serviceGroups[y];
        for (let s in base.optionalServices) {
          const item = base.optionalServices[s];
          if (item.selected === true) {
            hasa += item.price;
            account += item.priceEdited;
            serviceList.push(item);
          }
        }
      }
    }
    if (selectedServices !== null && selectedServices.length > 0) {
      for (let y in selectedServices) {
        let base = selectedServices[y];
        hasa += base.price;
        account += base.priceEdited;
        base.selected = true;
        serviceList.push(base);
      }
    }
    setTotal(`R$${MoneyMask(ProcessTotal(serviceList), 2)}`);
    setTotalAccountability(`R$${MoneyMask(account - hasa, 2)}`);
  }

  async function loadElements() {
    if (processId !== undefined) {
      await serviceGroup.Get(processId, (res) => {
        setGroups(res);
        notyChange();
      });

      SelectedServices(processId, (res) => setSelectedServices(res));
    }
  }

  function getSelectedServices() {
    if (processId !== undefined && processId !== undefined) {
      SelectedServices(processId, (res) => {
        const itens = res.sort(function (a, b) {
          return a.position - b.position;
        });
      });
    }
  }

  async function getInfo() {
    if (!processId || processId === null || processId === undefined) {
      setForm({});
      return;
    }

    await processCtrl.Select(processId, (res) => {
      setForm(res);
      if (userData.level === 1) {
        if (res.budgetStatus === 2) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
      getSelectedServices();
    });
    loadElements();
  }

  function handleChanges(evt) {
    let newInfos = OnDataChange(formInfos, evt);
    setForm(newInfos);
    SetBudgetData(newInfos);
  }

  async function update(infos) {
    if (infos.operatorId === undefined) {
      infos.operatorId = userData.id;
    }
    await processCtrl.Update(infos, (res) => {
      toast.success("Salvo com sucesso");
      getInfo();
    });
  }

  function updateInfos() {
    let infos = {
      ...formInfos,
      budgetStatus: formInfos.budgetStatus === 0 ? 1 : formInfos.budgetStatus,
    };

    update(infos);
  }

  function showDoc(item) {
    let url =
      "https://drive.google.com/viewerng/viewer?url=" +
      item +
      "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

    window.open(url, "_blank");
  }

  function save() {
    updateInfos();
  }

  function sendEmail() {
    let listOfServices = [...selectedServices];
    for (let y in serviceGroups) {
      const base = serviceGroups[y];
      for (let s in base.optionalServices) {
        const item = base.optionalServices[s];
        if (item.selected === true) {
          listOfServices.push(item);
        }
      }
    }

    SendEmailBudget(formInfos, listOfServices, "", formInfos.email, true);
  }

  function notifyCopy() {
    toast.info("Copiado para a área de transferência");
  }

  function sendWhats() {
    let address = `https%3A%2F%2Fapp.hasa.com.br%2F%23%2Fconfirm%2F${processId}?customer=true`;
    if (enviroment === "local") {
      address = `http%3A%2F%2Flocalhost:3000%2F%23%2Fconfirm%2F${processId}?customer=true`;
    } else if (enviroment === "staging") {
      address = `https%3A%2F%2Fnavigate.one%2Fhasa%2F%23%2Fconfirm%2F${processId}?customer=true`;
    }
    let message = `Olá! Segue o link com o orçamento da ${formInfos.customer.name}: ${address}`;
    window.open(`https://api.whatsapp.com/send?text=${message}`, "_blank");
  }

  function openBusinessMan() {
    let url = `${GetBaseUrl()}#/confirm/${formInfos.id}?customer=true`;

    window.open(url, "_blank");
  }

  return (
    <Modal
      title="Enviar ao meu cliente (Empresário)"
      show={show}
      onHide={onHide}
      onSubmit={() => save()}
    >
      <div className="row">
        <Tabs>
          <div className="col-md-12">
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="fa fa-suitcase"></i> Editar Orçamento
              </button>
            </Tab>
            <button
              type="button"
              className="btn btn-sm btn-outline-info"
              onClick={() => openBusinessMan()}
            >
              <i className="fa fa-eye"></i> Visão do empresário
            </button>
            <Tab>
              <button type="button" className="btn btn-sm btn-outline-info">
                <i className="fa fa-location-arrow"></i> Enviar
              </button>
            </Tab>
          </div>
          <Panel>
            <div className="col-sm-12">
              <div className="block-header">
                <div className="block-title">
                  <h2 className="content-heading">
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    Orçamento
                  </h2>
                </div>
              </div>
              <div className="block-content">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label for="example-masked-time">Nome do processo</label>
                    <input
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      type="text"
                      name="processName"
                      disabled={disabled}
                      defaultValue={formInfos && formInfos.processName}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="example-masked-time">Honorários Hasa</label>
                    <input
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      type="text"
                      disabled={true}
                      value={total}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label for="example-masked-time">
                      Margem contabilidade
                    </label>
                    <input
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      type="text"
                      disabled={true}
                      value={totalAccountability}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label for="example-textarea-input">
                      Observações ao empresário
                    </label>
                    <textarea
                      onChange={(evt) => handleChanges(evt)}
                      className="form-control"
                      name="observationEdited"
                      defaultValue={
                        formInfos && formInfos.observationEdited !== "null"
                          ? formInfos.observationEdited
                          : ""
                      }
                      rows="4"
                      placeholder="Escreva suas observações ao empresário"
                    />
                  </div>
                </div>
              </div>

              <div className="block-header">
                <div className="block-title">
                  <h3 className="content-heading">
                    <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
                    Serviços
                  </h3>
                </div>
              </div>
              {selectedServices &&
                selectedServices.map((item) => (
                  <ServiceItem
                    service={item}
                    refresh={() => getInfo()}
                    clientView={false}
                    isClient={false}
                  />
                ))}

              {serviceGroups &&
                serviceGroups.map((item, index) => (
                  <ServiceGroupItem
                    group={item}
                    refresh={() => getInfo()}
                    clientView={false}
                    preview={false}
                    services={[]}
                    position={index + 1}
                  />
                ))}

              {/* <div className="col-sm-12 py-3">
                <DocsComponent
                  processId={formInfos && formInfos.id}
                  setView={(data) => showDoc(data)}
                />
              </div> */}
            </div>
          </Panel>
          <Panel>
            <div className="content row">
              <div className="col-2"></div>
              <div className="col-8">
                <div className="form-group">
                  <span>Enviar por email</span>
                  <div className="input-group">
                    <input
                      className="form-control"
                      name="email"
                      placeholder="Email do empresário"
                      onChange={(evt) => handleChanges(evt)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-success"
                        onClick={() => sendEmail()}
                      >
                        <i className="fa fa-location-arrow"></i> Enviar
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <span>Outras opções de compartilhamento</span>
                  <br />
                  <br />
                  <button
                    className="btn btn-success mr-2"
                    onClick={() => sendWhats()}
                  >
                    <i className="fab fa-whatsapp"></i> WhatsApp
                  </button>
                  OU
                  <CopyToClipboard
                    text={`${GetBaseUrl()}#/confirm/${
                      formInfos.id
                    }?customer=true`}
                  >
                    <button
                      type="button"
                      className="btn btn-info ml-2"
                      title="copiar"
                      onClick={() => notifyCopy()}
                    >
                      <i className="far fa-copy mr-1" /> Copiar URL
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              <div className="col-2"></div>
            </div>
          </Panel>
        </Tabs>
      </div>
    </Modal>
  );
}

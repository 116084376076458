export const DepartmentsListHelper = [
  { name: "Orçamento", value: 1 },
  { name: "Análise", value: 2 },
  { name: "Preparação", value: 3 },
  { name: "Devolução Órgão", value: 4 },
  { name: "Cliente", value: 5 },
  { name: "Operação", value: 6 },
  { name: "Financeiro", value: 7 },
  { name: "Administrador", value: 10 },
];

export const OperationalListHelper = [
  { name: "Orçamento", value: 1 },
  { name: "Análise", value: 2 },
  { name: "Preparação", value: 3 },
  { name: "Devolução Órgão", value: 4 },
  { name: "Financeiro", value: 7 },
  { name: "Administrador", value: 10 },
];

export const OperationalBase = [
  { name: "Análise", value: 2 },
  { name: "Preparação", value: 3 },
  { name: "Devolução Órgão", value: 4 },
];

export function DepartmentTranslate(i) {
  const res = DepartmentsListHelper.filter((x) => x.value === i);

  if (res.length < 1) return "S.I.";
  else return res[0].name;
}

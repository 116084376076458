import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import Storage from "@/app/core/util/Storage";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import AccountController from "@/app/core/base/controllers/UsersController";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";

export default function MainComponent() {
  const date = new Date();
  const userData = Storage.getUserData();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [missing, setMissing] = useState([]);
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const account = AccountController();

  useEffect(() => {
    loadNarnia();
  }, []);

  async function loadNarnia() {
    await organCtrl.DistributeOperators((res) => {});
    await processCtrl.Every(
      (res) => {
        let itens = res.process;

        for (let s in itens) {
          itens[s].organList = res.organList.filter(
            (x) => x.processId === itens[s].id,
          );
        }
        let narnia = processNarnia(itens);
        setMissing(narnia);
      },
      (error) => {},
    );
  }

  function processNarnia(itens) {
    let narnia = [];

    for (let s in itens) {
      let item = itens[s];

      let ongoing = item.organList.filter(
        (x) =>
          s.statusAnalysis !== 0 &&
          s.statusPreparation !== 0 &&
          x.stage !== 10 &&
          x.status !== 6,
      );
      let waiting = item.organList.filter(
        (x) => x.status === 0 && x.stage === 1,
      );
      let done = item.organList.filter((x) => x.status === 6 && x.stage === 10);

      item.ongoing = ongoing;
      item.waiting = waiting;
      item.done = done;

      let clearlyNarnia = item.organList.filter(
        (x) =>
          x.statusAnalysis === organHelper.AnalysisStatus.Done &&
          x.stage === 2 &&
          x.docMasters.length === 0,
      );
      let possibleNarnia = item.organList.filter(
        (x) =>
          x.statusAnalysis === organHelper.AnalysisStatus.Done &&
          x.docMasters.length === 0 &&
          (x.devolutionNotStartedId === undefined ||
            x.devolutionFiledId === undefined ||
            x.devolutionDevolutionId === undefined ||
            x.devolutionExigencyId === undefined ||
            x.devolutionDeferredId === undefined ||
            x.analystId === undefined ||
            x.preparerId === undefined) &&
          x.stage != 10 &&
          x.status !== 6 &&
          x.stage !== 3,
      );

      for (let y in clearlyNarnia) {
        clearlyNarnia[y].motive = "Concluído a análise e preso no departamento";
      }

      for (let y in possibleNarnia) {
        possibleNarnia[y].motive = "Em andamento e sem uma das atribuições";
      }

      item.narnia = possibleNarnia;
      if (item.waiting.length === item.organList.length) {
        narnia.push(item);
      } else if (clearlyNarnia.length > 0 || possibleNarnia.length > 0) {
        narnia.push(item);
      }
    }

    console.log("Narnia", narnia);

    return narnia;
  }

  function open(item) {
    setSelectedData({
      processId: item.processId === undefined ? item.id : item.processId,
      id: item.processId === undefined ? item.id : item.processId,
    });
    setShowProcessModal(true);
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-12">
          <div className="block block-transparent">
            <div className="block-header">
              <div className="block-title">
                <h2 className="content-heading">
                  <i className="fa fa-angle-right text-muted mr-1"></i> Narnia
                </h2>
              </div>
            </div>
            <div>
              <div className="col-md-12">
                <table className="table table-striped table-hover table-vcenter font-size-sm">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Processo</td>
                      <td>Cliente</td>
                      <td>Atualizado em</td>
                      <td>Criado em</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {missing &&
                      missing.map((item) => (
                        <tr>
                          <td>{item.id}</td>
                          <td>{item.processName}</td>
                          <td>{item.customer && item.customer.name}</td>
                          <td>{DateTranslate(item.updatedDate)}</td>
                          <td>{DateTranslate(item.createdDate)}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-dual"
                              onClick={() => open(item)}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <OperationProcessModal
              data={selectedData}
              show={showProcessModal}
              onHide={() => setShowProcessModal(false) | setSelectedData({})}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";
import { ExigencyTemplateList } from "../../base/controllers/ExigencyTemplateController";
import { InsertExigency } from "../../base/controllers/ExigenciesController";
import DocumentsController from "../../base/controllers/DocumentsController";
import { ExigencyStatus } from "../../util/constants/ExigenciesConstants";
import DocInput from "./documents/DocInput";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { TimelineChannelSistema } from "../../util/helpers/TimelineHelper";
import { ExigencyType } from "../../util/helpers/ExigencyActionHelper";

export default function ExigenciesInLine({
  toggleClass,
  processId,
  refresh,
  organListId,
}) {
  const [exigencies, setExigencies] = useState(Storage.getTemplates());
  const userData = Storage.getUserData();
  const [type, setType] = useState(2);
  const [action, setAction] = useState(2);
  const [file, setFile] = useState(null);
  const [content, setContent] = useState(null);
  const [disable, setDisable] = useState(false);
  const [results, setResults] = useState([]);

  const docsCtrl = DocumentsController();

  useEffect(() => {
    getModels();
  }, [processId, refresh]);

  function getModels() {
    if (!exigencies || exigencies.length == 0) {
      ExigencyTemplateList((res) => {
        for (let s in res) {
          res[s].name = `${res[s].code} - ${res[s].description}`;
        }
        setExigencies(res);
      });
    }
  }

  async function exigencySendNotice(description) {
    await NotifyUpdate(
      processId,
      description,
      false,
      TimelineChannelSistema,
      organListId,
    );
  }

  async function onSubmit(e) {
    setDisable(true);
    e.preventDefault();
    if (action === 3 || action === "3") {
      if (file === null || file === undefined) {
        toast.error(
          `Você precisa adicionar o arquivo da taxa para poder salvar`,
        );
        return;
      }
    }

    let exigency = {
      processId: processId,
      userId: userData.id,
      description: content,
      action: action,
      status: ExigencyStatus.waiting,
      organListId,
    };

    if (file !== null && file !== undefined) {
      var formData = new FormData();
      formData.append("file", file);
      await docsCtrl.UploadDocs(
        exigency.processId,
        formData,
        userData.id,
        organListId,
        (res) => {
          exigency.sendedId = res[0].id;
          register(exigency);
        },
      );
    } else {
      register(exigency);
    }
  }

  function register(exigency) {
    InsertExigency(exigency, (res) => {
      exigencySendNotice(
        `${userData.firstName} adicionou uma exigência : ${res.description}`,
      );
      toast.info("Salvo com sucesso");
      close();
    });
  }

  function handleExigencieChange(value) {
    setContent(value);
    let people_to_show = [];

    if (value.length > 0) {
      setResults([]);
      people_to_show = Autocomplete(value, exigencies, "name");
      setResults(people_to_show);
    } else {
      people_to_show = [];
      setResults([]);
    }
  }

  function close() {
    toggleClass();
    setContent("");
    setDisable(false);
    setType(2);
    setResults([]);
  }

  function verifyKey(evt) {
    if (evt.keyCode === "27" || evt.keyCode === 27) {
      close();
    }
  }

  return (
    <div className="content-header">
      <form className="w-100" onSubmit={(e) => onSubmit(e)}>
        <div className="row">
          <div className="col-md-5">
            <div className="form-group">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="example-group1-input2"
                  placeholder="Exigência"
                  value={content}
                  onKeyDown={(evt) => verifyKey(evt)}
                  onChange={(evt) => handleExigencieChange(evt.target.value)}
                />
                <ul
                  style={{
                    borderRadius: "3px",
                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                    background: "rgba(255, 255, 255, 0.9)",
                    padding: "2px 0",
                    fontSize: "90%",
                    position: "fixed",
                    overflow: "auto",
                    marginTop: 50,
                    width: "90%",
                    maxHeight: "300px", // TODO: don't cheat, let it flow to the bottom
                  }}
                >
                  {results &&
                    results.map((item) => {
                      return (
                        <li
                          className="m-3"
                          onClick={() =>
                            setContent(item.description) | setResults([])
                          }
                        >
                          {item.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <select
              className="form-control"
              onChange={(evt) => setType(evt.target.value)}
            >
              {ExigencyType.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <DocInput
              multiple={false}
              disable={false}
              uploadFile={(itens) => setFile(itens[0])}
            />
          </div>
          <div className="col-md-2">
            <button type="submit" className="btn btn-sm btn-info">
              Salvar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={disable}
              onClick={() => close()}
            >
              <i className="fa fa-fw fa-times-circle" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

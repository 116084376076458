import React, { useState, useEffect } from "react";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import TeamModal from "./TeamModal";
import {
  TeamDelete,
  TeamList,
} from "@/app/core/base/controllers/TeamsController";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { ProcessDates } from "@/app/core/util/DateTranslater";

export default function TeamsCard() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});

  const cols = [
    { header: "Equipe", isSortable: true, name: "id" },
    { header: "Departamento", isSortable: true, name: "departmentName" },
    { header: "Membros", isSortable: true, name: "qtd" },
    { header: "Data de atualização", isSortable: true, name: "updatedDate" },
    { header: "Data de criação", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    getModels();
  }, []);

  function getModels() {
    TeamList((res) => {
      let results = [];
      for (let s in res) {
        res[s].departmentName = DepartmentTranslate(res[s].department);
        res[s].qtd = res[s].users !== undefined ? res[s].users.length : 0;
        results.push(res[s]);
      }
      setData(ProcessDates(results));
    });
  }

  function showEditItem(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  function deleteItem(item) {
    if (window.confirm("Deseja realmente deletar esse item?")) {
      TeamDelete(item.data.id, (res) => {
        getModels();
      });
    }
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header bg-xwork">
          <h3 className="block-title">Equipes</h3>
          <div className="block-options">
            <button
              type="button"
              className="btn-block-option"
              onClick={() => setShowModal(true)}
            >
              <i className={`si si-plus`}></i>
            </button>
          </div>
        </div>
        <div className="block-content">
          <SampleTable
            title="Times"
            cols={cols}
            data={data}
            remove={(task) => deleteItem(task)}
            refresh={() => getModels()}
            newItem={() => setShowModal(true)}
            open={(item) => showEditItem(item)}
          />
        </div>
      </div>
      <TeamModal
        show={showModal}
        infos={selected}
        onHide={() => setShowModal(!showModal) | getModels() | setSelected({})}
      />
    </>
  );
}

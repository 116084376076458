import DiscountController from "@/app/core/base/controllers/DiscountController";
import {
  DiscountType,
  DiscountTypeList,
} from "@/app/core/util/helpers/BudgetHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";

export default function DiscountComponent({
  processId,
  optionalServiceId,
  selectedServiceId,
  service,
  disabled,
  refresh,
}) {
  const [data, setData] = useState({ hasValidity: false });
  const discountCtrl = DiscountController();

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function save() {
    if (data.type == null || data.type == undefined) {
      toast.error("Selecione um tipo de valor");
    } else if (data.value == 0 || data.value == undefined) {
      toast.error("Defina um valor");
    } else if (
      data.hasValidity == true &&
      (data.dueDate == undefined || data.dueDate == "")
    ) {
      toast.error("Defina uma data de validade");
    } else if (data.type == DiscountType.Percentage && data.value >= 100) {
      toast.error("O desconto não pode ser maior ou igual a 100%");
    } else if (data.type == DiscountType.Value && data.value >= service.price) {
      toast.error(
        "O desconto não pode ser maior ou igual ao valor total do serviço",
      );
    } else {
      let infos = data;
      if (processId !== undefined) {
        infos.processId = parseInt(processId);
      }
      if (optionalServiceId !== undefined) {
        infos.optionalServiceId = parseInt(optionalServiceId);
      }
      if (selectedServiceId !== undefined) {
        infos.selectedServiceId = parseInt(selectedServiceId);
      }
      if (
        data.dueDate !== undefined &&
        data.dueDate !== "" &&
        data.dueDate !== null
      ) {
        infos.dueDate = `${data.dueDate}T23:59:59`;
      }
      await discountCtrl.Insert(infos, (res) => {
        toast.success(Message.Saved);
        refresh();
      });
    }
  }

  return (
    <DropdownElement
      submit={() => save()}
      button="Adicionar desconto"
      title={" Desconto"}
      className={"btn btn-sm btn-dual"}
      style={{ width: 340 }}
      icon="si si-plus"
      disabled={disabled}
    >
      <div className="form-group">
        <span>Tipo de valor</span>
        <select
          className="form-control"
          name="type"
          onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
        >
          <option value={null}>Selecione</option>
          {DiscountTypeList.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <span>Valor</span>
        <input
          className="form-control"
          type="number"
          name="value"
          onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
        />
      </div>
      <div className="form-group">
        <div className="custom-control custom-switch custom-control-inline custom-control-success">
          <input
            type="checkbox"
            className="custom-control-input mt-1"
            name={"hasValidity"}
            checked={data && data.hasValidity}
          />
          <label
            className="custom-control-label"
            onClick={(evt) =>
              setData({
                ...data,
                hasValidity: !data.hasValidity,
                dueDate: !data.hasValidity === false ? undefined : data.dueDate,
              })
            }
            for={data && `hasValidity`}
          >
            {data && data.hasValidity === true
              ? "Remover validade"
              : "Adicionar validade"}
          </label>
        </div>
      </div>
      {data && data.hasValidity === true ? (
        <div className="form-group">
          <span>Válido até</span>
          <input
            className="form-control"
            type="date"
            name="dueDate"
            onChange={(evt) => handleChange(evt, FieldTypeObj.Date)}
          />
        </div>
      ) : (
        ""
      )}
    </DropdownElement>
  );
}

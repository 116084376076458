import DocMasterController from "@/app/core/base/controllers/DocMasterController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { DeleteConfirm, Message } from "@/app/core/util/messages/GeralMessages";
import CheckMobile from "@/app/core/util/Screen";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import GetBaseUrl from "@/app/core/base/BaseAddress";

export default function DocMasterItem({ data, reload, organList, openSearch }) {
  const [edit, setEdit] = useState(false);
  const [master, setMaster] = useState({});
  const docMaster = DocMasterController();
  const userData = Storage.getUserData();

  useEffect(() => {
    if (data && data.id !== undefined) {
      setMaster(data);
    } else {
      setMaster({});
    }
  }, [data]);

  async function DeleteMaster() {
    if (window.confirm(DeleteConfirm)) {
      await docMaster.Delete(data.id, (res) => {
        toast.success("Removido com sucesso");
        reload();
      });
    }
  }

  async function save() {
    if (master.name === "") {
      toast.error("Preencha um nome válido");
    } else {
      await docMaster.Update(master, (res) => {
        toast.success("Salvo com sucesso");
        reload();
      });
    }
  }

  function downloadDoc() {
    if (userData === null || (userData && userData.level === 5)) {
      if (
        organList &&
        (organList.reviews == undefined || organList.reviews.length == 0)
      ) {
        openSearch();
        return;
      }
      notifyDownload();
    }
    if (CheckMobile()) {
      window.location = data.url;
    } else {
      window.open(data.url, "_blank");
    }
  }

  async function notifyDownload() {
    await NotifyUpdate(
      parseInt(organList.processId),
      `O cliente fez o download do arquivo mestre '${data.name}' do órgão '${organList.organ.name}'`,
      false,
      TimelineChannelSistema,
      organList.id,
    );
  }

  return (
    <div className="js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn block-content">
      <div className="block-header">
        <div className="block-title mt-0 pt-0">
          {edit === false ? (
            <a
              href={"javascript:void(0)"}
              className="text-dark font-small font-sm text-sm-left mt-0"
              onClick={() => downloadDoc()}
            >
              <i className="far fa-file-pdf mr-2" />
              <small>{data && data.name}</small>
            </a>
          ) : (
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                defaultValue={master && master.name}
                disabled={!edit}
                id={`docMaster-${data && data.id}`}
                onChange={(evt) =>
                  setMaster({ ...master, name: evt.target.value })
                }
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className={`btn btn-success`}
                  title="Editar"
                  onClick={() => save()}
                >
                  <i className={`far fa-check-circle`} />
                </button>
              </div>
            </div>
          )}
        </div>
        {userData &&
        (userData.level === 4 ||
          userData.level === 10 ||
          (userData.role > 0 && userData.level != 5)) ? (
          <div className="block-tools d-flex">
            <CopyToClipboard
              text={`${GetBaseUrl()}#/shared/${data.organListId}`}
            >
              <button
                type="button"
                className="btn btn-sm btn-dual mr-1 mb-t"
                title="copiar"
                onClick={() => toast.info(Message.Copied)}
              >
                <i className="far fa-copy mr-1" />
              </button>
            </CopyToClipboard>
            <button
              type="button"
              onClick={() => setEdit(!edit)}
              className="js-task-star btn btn-sm btn-link text-warning"
            >
              <i className="far fa-edit fa-fw"></i>
            </button>
            <button
              type="button"
              onClick={() => DeleteMaster()}
              className="js-task-remove btn btn-sm btn-link text-danger"
            >
              <i className="fa fa-times fa-fw"></i>
            </button>
          </div>
        ) : (
          <div className="block-tools">
            <CopyToClipboard
              text={`${GetBaseUrl()}#/shared/${data.organListId}`}
            >
              <button
                type="button"
                className="btn btn-sm btn-dual mr-1 mb-t"
                title="copiar"
                onClick={() => toast.info(Message.Copied)}
              >
                <i className="far fa-copy mr-1" />
              </button>
            </CopyToClipboard>
            <button
              type="button"
              onClick={() => downloadDoc()}
              className="js-task-star btn btn-sm btn-link text-primary"
            >
              <i className="si si-cloud-download fa-fw"></i>
            </button>
            <a
              className="btn btn-dual"
              href={`https://api.whatsapp.com/send?text= Prezado cliente, \n segue o link para baixar seu documento finalizado: \n \n ${data && data.url}`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-whatsapp mr-1" />
            </a>
            <a
              className="btn btn-dual"
              href={`mailto:?body=Prezado cliente %0D%0A segue o link para baixar seu documento finalizado: %0D%0A %0D%0A ${data && data.url} %0D%0A %0D%0A Obrigado pela confiança! &subject=Processo finalizado - Conte sempre conosco!`}
              target="_blank"
              rel="noreferrer"
            >
              <i className="si si-envelope mr-1" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

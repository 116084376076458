import React, { useState } from "react";
import RemunerationCard from "./RemunerationCard";

export default function ServiceOrgan({
  data,
  removeOrganService,
  lowerPosition,
  upperPosition,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
      <div className="block-header block-header-default">
        <small>
          {data && `${data.position}º `}
          {data.organ && data.organ.name}
          {data.organ && data.organ.invisible == true ? (
            <span className="badge badge-danger">Removido</span>
          ) : (
            ""
          )}
        </small>
        <div className="block-tools">
          <button
            className="btn btn-sm btn-white"
            onClick={() => removeOrganService(data)}
          >
            <i className="si si-close text-danger" />
          </button>
          <button
            className="btn btn-sm btn-white"
            onClick={() => lowerPosition(data)}
          >
            <i className="si si-arrow-up" />
          </button>
          <button
            className="btn btn-sm btn-white"
            onClick={() => upperPosition(data)}
          >
            <i className="si si-arrow-down" />
          </button>
          <button
            className="btn btn-sm btn-outline btn-info"
            onClick={() => setOpen(!open)}
          >
            <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
          </button>
        </div>
      </div>
      {data && open === true ? (
        <div className="block-content">
          <RemunerationCard serviceOrganId={data.id} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

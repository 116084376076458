import BudgetCounter from "@/app/core/layouts/main-components/reports/operational/BudgetCounter";
import React, { useEffect, useState } from "react";
import AnalysisCounter from "./AnalysisCounter";
import DeferredCounter from "./DeferredCounter";
import DevolutionCounter from "./DevolutionCounter";
import ExigencyCounter from "./ExigencyCounter";
import PreparationCounter from "./PreparationCounter";

export default function Counters({ startDate, endDate }) {
  useEffect(() => {}, [startDate, endDate]);

  return (
    <div className="row">
      <div className="col-md-6">
        <BudgetCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-6">
        <AnalysisCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-6">
        <PreparationCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-6">
        <ExigencyCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-12">
        <DevolutionCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-12">
        <DeferredCounter startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-12"></div>
    </div>
  );
}

import React from "react";
import { DateToPast } from "../DateTranslater";

export default function ProtocolHelper() {
  const ProtocolStage = {
    Pending: 0,
    Disapproved: 1,
    Approved: 2,
  };

  const ProtocolStageList = [
    { name: "Pendente", value: 0, color: "info" },
    { name: "Reprovado", value: 1, color: "danger" },
    { name: "Aprovado", value: 2, color: "success" },
  ];

  function protocolStatusBase(i) {
    const res = ProtocolStageList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function protocolStatus(i) {
    const res = ProtocolStageList.filter((x) => x.value === i);
    if (res.length > 0) {
      return (
        <button
          className={`btn btn-sm btn-square btn-outline-${res[0].color} mb-1 mt-1`}
          style={{ width: "100%", zIndex: 99999999999 }}
        >
          {res[0].name}
        </button>
      );
    } else {
      return (
        <button
          className={`btn btn-sm btn-square btn-outline-secondary mb-1 mt-1`}
          style={{ width: "100%", zIndex: 99999999999 }}
        >
          S.I.
        </button>
      );
    }
  }

  return {
    ProtocolStage,
    ProtocolStageList,
    protocolStatusBase,
    protocolStatus,
  };
}

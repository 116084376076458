import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import {
  DateTranslate,
  OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";

export default function CustomerReportModal({ showModal, onHide, infos }) {
  const [data, setData] = useState({ notStarted: [], ongoing: [], done: [] });
  const organHelper = OrganListHelper();
  const cols = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    {
      header: "Email",
      isSortable: true,
      name: "email",
    },
    { header: "Telefone", isSortable: true, name: "phone" },
    { header: "Celular", isSortable: true, name: "whatsapp" },
    { header: "Última compra", isSortable: true, name: "updatedDate" },
  ];

  useEffect(() => {
    if (infos !== null && infos !== undefined) {
      let itens = [];

      for (let s in infos) {
        let item = infos[s];
        item.condition = "";
        item.updatedDate = OnlyDateTranslate(item.updatedDate);
        itens.push(item);
      }
      console.log("Clientes", itens, infos.idleList);
      setData(itens);
    } else {
      setData([]);
    }

    // if (infos != undefined) {
    //   loadReport()
    // }
  }, [infos, showModal]);

  return (
    <Modal title="Clientes" show={showModal} onHide={onHide} size="lg">
      <div className="block-header">
        <div className="block-title">
          {data.total && data.total.length + " Clientes"}
        </div>
        <div className="block-tools"></div>
      </div>
      <div style={{ overflowX: "scroll" }}>
        <Table
          title="Clientes"
          loading={false}
          data={data}
          columns={cols}
          tableId="customers"
          hide={false}
          limit={1000}
          hideHeader={true}
          setFilter={(filtered) => Functions.ProcessFilter(filtered, data)}
          viewAction={(selected) =>
            window.open(`#/customer-profile/${selected.data.id}`, "_blank")
          }
        />
      </div>
    </Modal>
  );
}

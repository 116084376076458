import React, { useState, useEffect } from "react";
import CustomModalComponent from "@/app/core/layouts/main/components/CustomModal";
import ReactToPdf from "react-to-pdf";
import { GetDia, GetMes } from "@/app/core/util/DateTranslater";

export default function ContractModal({ show, onHide, customer, user }) {
  const [formValidation, setFormValidation] = useState({});
  const ref = React.createRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [9, 37],
  };

  useEffect(() => {}, [customer, user]);

  function end() {}

  return (
    <CustomModalComponent
      show={show}
      onHide={onHide}
      onSave={end()}
      onSubmit={() => end()}
      disableSave={!Object.values(formValidation).every((x) => x === true)}
      title="Contrato"
    >
      <div ref={ref} style={{ width: 850 }}>
        <div class="col-md-12">
          <hr />
          <div class="painel-contrato" style={{ textAlign: "justify" }}>
            <h2 class="text-center">CONTRATO DE PRESTAÇÃO DE SERVIÇOS</h2>
            <h3>IDENTIFICAÇÃO DAS PARTES CONTRATANTES</h3>
            <p>
              CONTRATADA
              <strong>
                :<u>HASA DOCUMENTOS,</u>
              </strong>{" "}
              inscrita no CNPJ 23.615.058/0001-45, com sede na Rua Sete de
              Abril, 345 - 8&ordm; andar - conjunto 801 ao 806b -
              Rep&uacute;blica - S&atilde;o Paulo &ndash; SP &ndash; CEP
              01043-908.
            </p>
            <p>
              CONTRATANTE: {customer.name}, {customer.address} - {customer.city}{" "}
              - {customer.city} CEP. {customer.zipode},{" "}
              {customer.type === 1 ? customer.cnpj : customer.cpf}
              {customer.type === 1
                ? `, representada pelo(a) sócio(a):
                    ${
                      user && user.firstName !== undefined ? (
                        <strong>{user.firstName}</strong>
                      ) : (
                        ""
                      )
                    } que, neste ato, se declara ciente e aceita as cláusulas a seguir.`
                : ", que neste ato, se declara ciente e aceita as cláusulas a seguir."}
            </p>
            <p>
              <strong>DO OBJETO</strong>
            </p>
            <p>
              1- Constitui objeto deste instrumento a presta&ccedil;&atilde;o de
              servi&ccedil;os em legaliza&ccedil;&atilde;o de empresas, sendo a
              prepara&ccedil;&atilde;o, protocolo e acompanhamento dos processos
              para escrit&oacute;rios de contabilidade e advocacia.
            </p>
            <p>
              <strong>DA VIG&Ecirc;NCIA</strong>
            </p>
            <p>
              {" "}
              2 - Este contrato entrar&aacute; em vigor ap&oacute;s o efetivo
              cadastro realizado pelo (a) CONTRATANTE no site www.hasa.com.br,
              momento em que ser&aacute; gerado um c&oacute;digo de cliente, que
              &eacute; intransfer&iacute;vel, devendo o presente contrato ser
              assinado digitalmente ou por escrito e, neste &uacute;ltimo caso,
              entregue na sede da CONTRATADA ou por via postal, com firma
              reconhecida.{" "}
            </p>
            <p>
              2.1 - Desde que n&atilde;o haja inadimpl&ecirc;ncia, este contrato
              vigorar&aacute; por prazo indeterminado, podendo o (a) CONTRATANTE
              solicitar todos os servi&ccedil;os oferecidos pela CONTRATADA e
              que constam na tabela de honor&aacute;rios, divulgada no site
              www.hasa.com.br.
            </p>
            <p>
              <strong>DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATADA</strong>
            </p>
            <p>
              3 - A presta&ccedil;&atilde;o de servi&ccedil;os da CONTRATADA
              &eacute; relacionada &agrave; legaliza&ccedil;&atilde;o perante
              reparti&ccedil;&otilde;es p&uacute;blicas
              (prepara&ccedil;&atilde;o, protocolo e acompanhamento.){" "}
            </p>
            <p>
              3.1 - Todos os dados ou documentos fornecidos pelo (a) CONTRATANTE
              ser&atilde;o guardados at&eacute; a finaliza&ccedil;&atilde;o do
              processo. Ap&oacute;s os que n&atilde;o ficaram de posse da
              reparti&ccedil;&atilde;o competente ser&atilde;o devolvidos, sendo
              a remessa feita conforme op&ccedil;&atilde;o constante no cadastro
              do(a) CONTRATANTE.
            </p>
            <p>
              3.2 - Todos os documentos deferidos ou com exig&ecirc;ncias
              ser&atilde;o avisados por e-mail.
              <br />
              <br />
              3.3 - A CONTRATADA dever&aacute; dispensar os melhores
              esfor&ccedil;os para assegurar a qualidade do servi&ccedil;o
              prestado, comprometendo-se a manter sigilo sobre as
              informa&ccedil;&otilde;es cadastrais do (a) CONTRATANTE, exceto em
              caso de determina&ccedil;&atilde;o judicial.
            </p>
            <p>
              3.4 - Todos os servi&ccedil;os prestados ser&atilde;o realizados
              dentro do prazo estipulado na tabela divulgada no site
              www.hasa.com.br, salvo os casos que sofrerem exig&ecirc;ncias,
              hip&oacute;tese que ser&aacute; dado reentrado para atendimento da
              solicita&ccedil;&atilde;o da reparti&ccedil;&atilde;o,
              reiniciando-se o prazo ap&oacute;s novo protocolo.
            </p>
            <p>
              3.5 - Os prazos estipulados s&atilde;o de acordo com o informado
              por cada reparti&ccedil;&atilde;o, quais, a qualquer momento,
              poder&atilde;o sofrer altera&ccedil;&otilde;es.
            </p>
            <p>
              <strong>DAS OBRIGA&Ccedil;&Otilde;ES DA CONTRATANTE</strong>
            </p>
            <p>
              4 - Cabe exclusivamente ao (a) CONTRATANTE o envio de todas as
              informa&ccedil;&otilde;es e documentos para que a
              presta&ccedil;&atilde;o de servi&ccedil;os desejada seja feita de
              forma eficaz obrigando-se, ainda, ao cumprimento de todas as
              formalidades legais exigidas pelas reparti&ccedil;&otilde;es
              p&uacute;blicas, inclusive entrega de documentos e pagamento de
              taxas.
            </p>
            <p>
              4.1 - O valor das taxas deve ser enviado pelo (a) CONTRATANTE em
              dinheiro, cheque ou via dep&oacute;sito banc&aacute;rio na conta
              corrente da CONTRATADA. Em caso de dep&oacute;sito, o (a)
              CONTRATANTE se compromete a enviar c&oacute;pia do comprovante
              para o e-mail hasa@hasa.com.br, informando o c&oacute;digo de
              cliente cadastrado e nome do processo que deseja dar andamento.
            </p>
            <p>
              4.2 - O (a) CONTRATANTE deve ficar atento (a) ao e-mail cadastrado
              no site da CONTRATADA, atendendo as solicita&ccedil;&otilde;es com
              brevidade.{" "}
            </p>
            <p>
              <strong>DOS VALORES E FORMA DE PAGAMENTO</strong>
            </p>
            <p>
              5 - Como pagamento aos servi&ccedil;os prestados, atrav&eacute;s
              de boleto banc&aacute;rio, o (a) CONTRATANTE dever&aacute; pagar
              &agrave; CONTRATADA o valor dos honor&aacute;rios do (s)
              servi&ccedil;o(s) solicitado(s), sempre no dia 08 (oito) de cada
              m&ecirc;s, estando o servi&ccedil;o finalizado ou em andamento.
            </p>
            <p>
              5.1 - O (a) CONTRATANTE tem o prazo de 05 (cinco) dias para
              eventual contesta&ccedil;&atilde;o relativa &agrave;
              cobran&ccedil;a efetuada.
              <br />
              5.2 - O n&atilde;o pagamento ou atraso do boleto importar&aacute;
              na incid&ecirc;ncia de multa de 2% (dois por cento) ao m&ecirc;s,
              juros de mora de 1% (um por cento) ao m&ecirc;s e
              corre&ccedil;&atilde;o monet&aacute;ria podendo, a crit&eacute;rio
              exclusivo da CONTRATADA, o acesso do (a) CONTRATANTE ser bloqueado
              e, desde que haja a regulariza&ccedil;&atilde;o do d&eacute;bito,
              a qualquer tempo reativado.
            </p>
            <p>
              <strong>
                DO PROTESTO, NEGATIVA&Ccedil;&Atilde;O E COBRAN&Ccedil;A
              </strong>
            </p>
            <p>
              6- Havendo necessidade de envio da pend&ecirc;ncia ao
              Cart&oacute;rio de Protestos ser&aacute; emitida nota fiscal
              eletr&ocirc;nica e duplicata de presta&ccedil;&atilde;o de
              servi&ccedil;os por indica&ccedil;&atilde;o relativo aos valores
              devidos, sendo o (a) CONTRATANTE notificado (a) pelo
              Cart&oacute;rio de Protestos estando, ainda, sujeito (a) a
              negativa&ccedil;&atilde;o perante o SCPC e SERASA, que igualmente
              far&atilde;o a comunica&ccedil;&atilde;o, al&eacute;m de
              cobran&ccedil;a judicial, hip&oacute;tese que ser&atilde;o
              acrescidas custas processuais e honor&aacute;rios
              advocat&iacute;cios fixados em 20% (vinte por cento).{" "}
            </p>
            <p>
              <strong>DISPOSI&Ccedil;&Otilde;ES GERAIS</strong>
            </p>
            <p>
              a) Atrav&eacute;s do seu efetivo cadastro e
              aquisi&ccedil;&atilde;o do servi&ccedil;o HASA, o (a) CONTRATANTE
              se compromete a respeitar e cumprir todas as
              disposi&ccedil;&otilde;es aqui inseridas.
            </p>
            <p>
              b) As partes reconhecem o servi&ccedil;o de correio
              eletr&ocirc;nico (e-mail) como forma v&aacute;lida, eficaz de
              comunica&ccedil;&atilde;o e aceitam a p&aacute;gina do site da
              HASA como meio v&aacute;lido.
            </p>
            <p>
              c) Poder&aacute; a CONTRATADA terceirizar os servi&ccedil;os de
              cobran&ccedil;a de seus cr&eacute;ditos a outras empresas
              legalmente constitu&iacute;das para este fim.
            </p>
            <p>
              d) A CONTRATADA poder&aacute; ceder este contrato ou os direitos
              oriundos deste contrato a qualquer uma das sociedades do grupo
              econ&ocirc;mico do qual faz parte, que possa vir a fazer ou
              terceiros, ficando obrigada a comunicar a CONTRATANTE sua
              inten&ccedil;&atilde;o, sendo reservado o direito do (a)
              CONTRATANTE rescindir o presente contrato de
              presta&ccedil;&atilde;o de servi&ccedil;os, sem qualquer
              &ocirc;nus.{" "}
            </p>
            <p>
              e) O (a) CONTRATANTE ter&aacute; a sua disposi&ccedil;&atilde;o a
              central de telefone (11)3333-6660 e (e-mail)
              administrativo@hasa.com.br ou em caso de sugest&atilde;o ou
              reclama&ccedil;&atilde;o sac@hasa.com.br
            </p>
            <p>
              f) Entre as partes contratantes inexiste v&iacute;nculo
              trabalhista ou qualquer tipo de rela&ccedil;&atilde;o de
              subordina&ccedil;&atilde;o, respondendo cada qual por suas
              obriga&ccedil;&otilde;es previdenci&aacute;rias e encargos
              sociais.
            </p>
            <p>
              g) Este instrumento particular, constitui t&iacute;tulo executivo
              extrajudicial, nos termos do artigo 784, III do C&oacute;digo de
              Processo Civil e obriga as partes e seus sucessores.{" "}
            </p>
            <p>
              <strong>DO FORO</strong>
            </p>
            <p>
              Para dirimir quaisquer controv&eacute;rsias oriundas do presente
              contrato, as partes elegem o foro da Comarca de S&atilde;o Paulo,
              excluindo qualquer outro por mais privilegiado que seja.
            </p>
            <p>
              Por estarem assim justos e contratados, firmam o presente
              instrumento, de acordo com as assinaturas abaixo.
            </p>
            <h3>
              São Paulo, {GetDia()} de {GetMes()} de {new Date().getFullYear()}.
            </h3>
          </div>
        </div>
        <div class="col-md-12">
          <h4>Contratada:</h4>
          <p>
            ___________________________________________________________________________________________
          </p>
          <p>
            <strong>HASA DOCUMENTOS</strong>
          </p>
        </div>
        <div class="col-md-12">
          <h4>Contratante:</h4>
          <p>
            ___________________________________________________________________________________________
          </p>
          {customer.type === 1 ? (
            <p>
              <strong>{customer.name}</strong>
            </p>
          ) : (
            <p>
              <strong>{user.firstName}</strong>
            </p>
          )}
        </div>
        <div class="clearfix"></div>
      </div>
      <ReactToPdf
        targetRef={ref}
        filename="Hasa_Contrato.pdf"
        options={options}
      >
        {({ toPdf }) => <button onClick={toPdf}>Baixar contrato</button>}
      </ReactToPdf>
    </CustomModalComponent>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function DailyActivityList(response) {
  await BaseService(
    {
      method: "GET",
      url: `dailyactivity`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function DailyActivityInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "dailyactivity/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function DailyActivityUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `dailyactivity/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err.response !== undefined) {
        toast.error(HandleErrors(err));
      }
    },
  );
}

export async function DailyActivityDelete(id, response) {
  await BaseService(
    {
      method: "DELETE",
      url: `dailyactivity/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
      return false;
    },
  );
}

import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import {
  FeedbackStructureList,
  FormCustomerList,
} from "@/app/core/base/controllers/FeedbackController";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import QuizController from "@/app/core/base/controllers/QuizController";

export default function CustomerFeedbackComponent({ customerId }) {
  const userData = Storage.getUserData();
  const [data, setData] = useState([]);
  const [responses, setResponses] = useState([]);
  const [structure, setStructure] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const quizCtrl = QuizController();

  useEffect(() => {
    // loadForm();
    loadCustomerFeedbacks();
    loadQuiz();
  }, []);

  async function loadQuiz() {
    await quizCtrl.List((res) => {
      let response = [];
      for (let s in res) {
        if (res[s].available === true) {
          response.push(res[s]);
        }
      }

      setQuiz(response);
    });
  }
  function loadForm() {
    FeedbackStructureList((res) => {
      setStructure(res);
    });
  }

  function loadCustomerFeedbacks() {
    FormCustomerList(customerId, (res) => {
      setResponses(res);
    });
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Feedback</h3>
        </div>
        <div className="block-content">
          {/* <DropdownElement
            style={{ width: 400 }}
            orientation={"left"}
            icon={"si si-question"}
            title={"Responder o feedback"}
            submit={() => saveFeedback()}
          >


            {structure &&
              structure.map((item) => (
                <DynamicField
                  item={item}
                  data={item.feedbackValues}
                  handleChange={(evt) => handleChange(evt, item)}
                />
              ))}
          </DropdownElement> */}
          <p className="py-3 text-center">
            Seja o mais sincero (a) possível na sua mensagem, nosso time
            agradece de verdade!
          </p>
          <div className="row">
            {quiz.map((item) => (
              <div className="col-md-6 offset-md-3 block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
                <div className="block-header">
                  <div className="block-title">{item.name}</div>
                  <div className="block-tools">
                    <a
                      className=""
                      href={`${GetBaseUrl()}#/feedback/${item.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="si si-question"></i> Responder
                    </a>
                  </div>
                </div>
              </div>
            ))}
            {/* {responses.map((item) => (
            <div className="col-md-6">
              <FeedbackResponse data={item} />
            </div>
          ))} */}
          </div>
        </div>
      </div>
    </>
  );
}

import { SendDeferredToCustomer } from "@/app/core/base/controllers/ContactController";
import TranslateDeliveryType from "@/app/core/util/constants/DeliveryType";
import {
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import { processObservations } from "@/app/core/util/helpers/TasksHelper";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function DeferredItem({ data, open, reload }) {
  function deferred() {
    open({ data: data.process });
  }

  async function ReSendEmail() {
    if (data.responsible === undefined) {
      toast.error("O processo esta sem um responsável");
    } else {
      await SendDeferredToCustomer(
        { email: data.responsible, firstName: data.responsibleName },
        data.processName,
        data.organName,
        data.id,
      );
    }
  }

  return (
    <tr key={data.id}>
      <td>{data && data.id}</td>
      <td>{data && data.processName}</td>
      <td>{data && data.serviceName}</td>
      <td>{data && data.organName}</td>
      <td>
        <div class="form-group">
          <div class="input-group">
            {data && data.protocol}
            <CopyToClipboard
              text={data.protocol}
              onCopy={() => toast.info("Copiado para a área de transferência")}
            >
              <button
                type="button"
                class="btn btn-dual btn-sm ml-2"
                title="copiar"
              >
                <i className="far fa-copy" />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </td>
      <td>{data && processObservations(data)}</td>
      <td>{data && DateTranslate(data.updatedDate)}</td>
      {/* <td>{data.process && DateTranslateSimple(data.process.updatedDate)}</td> */}
      <td>{DateTranslate(data.endPreparationDate)}</td>
      <td>{TranslateDeliveryType(data && data.deliveryType)}</td>
      <td>
        <button className="btn btn-sm btn-dual" onClick={() => deferred()}>
          <i className="si si-check text-success" /> Finalizar etapa
        </button>
        {data && data.deliveryType !== 0 ? (
          <button className="btn btn-sm btn-dual" onClick={() => ReSendEmail()}>
            <i className="si si-envelope-letter" /> Reenviar email
          </button>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}

import React, { useState, useEffect } from "react";
import GoogleDocsViewer from "react-google-docs-viewer";

export default function FileViewer({ file, height, openImage }) {
  const [doc, setDoc] = useState(null);

  useEffect(() => {
    if (file !== undefined && file !== null) {
      processFile();
    }
  }, [file]);

  function processFile() {
    setDoc(
      <div className="text-center">
        <i className={"py-5 mt-5 fa fa-circle-notch fa-spin text-info fa-2x"} />
      </div>,
    );
    setTimeout(() => {
      let item = file.split(".");
      let archive = item[item.length - 1];

      if (
        archive === "jpg" ||
        archive === "jpeg" ||
        archive === "jfif" ||
        archive === "png" ||
        archive === "gif"
      ) {
        const path = decodeURI(file);
        setDoc(
          <div className="block block-rounded" style={{ height: height }}>
            <div className="block-content p-0 overflow-hidden">
              <a
                className="img-link img-link-zoom-in img-thumb img-lightbox img-fluid-100 text-center"
                data-toggle="layout"
                data-action="side_overlay_open"
                target="_blank"
                onClick={() => openImage(file)}
              >
                <img
                  className="img-fluid rounded-top"
                  src={file}
                  alt=""
                  style={{ maxHeight: height }}
                />
              </a>
            </div>
          </div>,
        );
      } else if (archive === "pdf" || archive === "PDF") {
        setDoc(
          <object
            data={file + "#toolbar=0"}
            type="application/pdf"
            width="100%"
            height={height}
          >
            <div>No online PDF viewer installed</div>
          </object>,
        );
      } else if (file.length > 0) {
        setDoc(
          <GoogleDocsViewer width="100%" height={height} fileUrl={file} />,
        );
      } else {
        setDoc("");
      }
    }, 600);
  }

  // function bufferToStream(buffer) {
  //   let stream = new Duplex();
  //   stream.push(buffer);
  //   stream.push(null);
  //   return stream;
  // }

  return <div className="">{doc && doc}</div>;
}

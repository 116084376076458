import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { Dropdown } from "react-bootstrap";
import {
  OrgansServiceDelete,
  OrgansServiceInsert,
  OrgansServiceList,
  OrgansServiceUpdate,
} from "@/app/core/base/controllers/OrgansServicesController";
import {
  ServiceInsert,
  ServiceUpdate,
} from "@/app/core/base/controllers/ServicesController";
import CurrencyFormat from "react-currency-format";
import ServiceOrgan from "./ServiceOrgan";
import FractionalCard from "@/app/core/layouts/main-components/budget/FractionedCard";

function ServicesForm(props) {
  const { data, show, onHide, submit, isLoading } = props;

  const [name, setName] = useState(null);
  const [time, setTime] = useState(null);
  const [value, setValue] = useState(0);
  const [observation, setObservation] = useState(null);
  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [type, setType] = useState(false);
  const [id, setId] = useState(null);
  const [organs, setOrgans] = useState([]);
  const [serviceOrgans, setServiceOrgans] = useState([]);
  const enviroment = process.env.REACT_APP_ENV;
  const organCtrl = OrganListController();

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setName(data.name);
      setTime(data.time);
      setValue(data.value);
      setObservation(data.observation);
      setId(data.id);
      setVisible(data.visible);
      loadSelectedOrgans(data.id);
      setType(data.type);
      setEditable(data.editable);
    } else {
      setName(null);
      setTime(null);
      setValue(0);
      setObservation("");
      setId(null);
      setVisible(false);
      setType(false);
      setEditable(false);
      setServiceOrgans([]);
    }
    loadOrgans();
  }, [data]);

  function loadSelectedOrgans(serviceId) {
    OrgansServiceList(serviceId, (res) => {
      let results = res.filter((x) => x.serviceId === data.id);
      let list = results.sort(function (a, b) {
        return a.position - b.position;
      });
      setServiceOrgans(list);
    });
  }

  function loadOrgans() {
    organCtrl.OrgansSelect((res) => {
      setOrgans(res);
    });
  }

  function postService() {
    const infos = {
      name: name,
      value: parseFloat(value),
      observation: observation,
      duration: parseInt(time),
      type: parseInt(type),
      editable,
      visible: visible,
    };

    ServiceInsert(infos, (res) => {
      toast.success("Serviço Cadastrado com Sucesso");
      onHide();
    });
  }

  function updateService() {
    const infos = {
      id: id,
      name: name,
      value: parseFloat(value),
      observation: observation,
      duration: parseInt(time),
      visible: visible,
      editable,
      type: parseInt(type),
    };

    ServiceUpdate(infos, (res) => {
      toast.success("Serviço Cadastrado com Sucesso");
      onHide();
    });
  }

  function saveService() {
    if (time === null || name === null || value === null) {
      toast.error("Preencha os dados para poder salvar");
      return;
    } else {
      if (id === null) {
        postService();
      } else {
        updateService();
      }
    }
  }

  function addOrgan(item) {
    OrgansServiceInsert(
      { organId: item.id, serviceId: id, position: serviceOrgans.length + 1 },
      (res) => {
        toast.info("Salvo com sucesso");
        loadSelectedOrgans(id);
      },
    );
  }

  function removeOrganService(item) {
    if (window.confirm("Você tem certeza de que deseja prosseguir?")) {
      OrgansServiceDelete(item.id, (res) => {
        toast.info("Removido com sucesso");
        loadSelectedOrgans(id);
      });
    }
  }

  function upperPosition(item) {
    if (item.position < serviceOrgans.length) {
      OrgansServiceUpdate({ ...item, position: item.position + 1 }, (res) => {
        loadSelectedOrgans(id);
      });
    }
  }

  function lowerPosition(item) {
    if (item.position > 1) {
      OrgansServiceUpdate({ ...item, position: item.position - 1 }, (res) => {
        loadSelectedOrgans(id);
      });
    }
  }

  return (
    <Modal
      title="Serviço"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveService()}
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="example-masked-time">Nome do Serviço</label>
            <input
              type="text"
              class="form-control"
              name="name"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </div>
          <div class="form-group">
            <label for="example-masked-time">Valor R$</label>
            <CurrencyFormat
              className={`form-control`}
              thousandSeparator={"."}
              decimalSeparator={","}
              name="marketValue"
              value={value}
              prefix={"R$"}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setValue(value);
              }}
            />
          </div>
          <div class="form-group">
            <label for="example-masked-time">Observação</label>
            <textarea
              type="text"
              class="form-control"
              name="observation"
              value={observation}
              onChange={(evt) => setObservation(evt.target.value)}
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="example-masked-time">Prazo em dias uteis</label>
            <input
              type="number"
              class="form-control"
              name="observation"
              value={time}
              onChange={(evt) => setTime(evt.target.value)}
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="example-masked-time">Visível para clientes?</label>
            <select
              class="form-control"
              name="visible"
              value={visible}
              onChange={(evt) =>
                setVisible(evt.target.value === "true" ? true : false)
              }
            >
              <option value={null}>Selecione</option>
              <option value={true} selected={visible === true ? true : false}>
                Sim
              </option>
              <option value={false} selected={visible === false ? true : false}>
                Não
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="example-masked-time">Serviço fracionado?</label>
            <select
              class="form-control"
              name="type"
              value={type}
              onChange={(evt) => setType(evt.target.value)}
            >
              <option value={null}>Selecione</option>
              <option value={1} selected={type === 1 ? true : false}>
                Sim
              </option>
              <option value={0} selected={type === 0 ? true : false}>
                Não
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="example-masked-time">Serviço editável?</label>
            <select
              class="form-control"
              name="editable"
              onChange={(evt) =>
                setEditable(evt.target.value == "true" ? true : false)
              }
            >
              <option value={null}>Selecione</option>
              <option value={true} selected={editable == true ? true : false}>
                Sim
              </option>
              <option value={false} selected={editable == false ? true : false}>
                Não
              </option>
            </select>
          </div>

          {type == 1 && id != null ? (
            <FractionalCard serviceId={id && id} />
          ) : (
            ""
          )}
        </div>
        {id && id !== null ? (
          <div className="col-md-12">
            <div className="block block-fx-pop">
              <div className="block-header">
                <div className="block-title">Orgãos pré-selecionados</div>
                <div className="block-tools">
                  <Dropdown>
                    <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: 450 }}>
                      <div className="row">
                        {organs.map((item) => (
                          <div className="col-6">
                            <div className="block block-bordered block-content row">
                              <div className="col-6">{item.name}</div>
                              <div className="col-6 text-right">
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={() => addOrgan(item)}
                                >
                                  <i className="si si-plus" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="block-content">
                {serviceOrgans.map((item) => (
                  <ServiceOrgan
                    data={item}
                    removeOrganService={(item) => removeOrganService(item)}
                    lowerPosition={(item) => lowerPosition(item)}
                    upperPosition={(item) => upperPosition(item)}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}

export default ServicesForm;

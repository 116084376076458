import { MotivationalList } from "@/app/core/base/controllers/MotivationalController";
import React, { useEffect, useState } from "react";
// import { Carousel } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function CarouselCard() {
  const [motivational, setMotivational] = useState([]);

  useEffect(() => {
    loadMotivational();
  }, []);

  function loadMotivational() {
    MotivationalList((res) => {
      const response = res.filter((x) => x.level === 5);
      setMotivational(response);
    });
  }

  return (
    <div>
      {motivational && motivational.length > 0 ? (
        <Carousel
          showArrows={true}
          autoPlay={true}
          showThumbs={false}
          transitionTime={1000}
          interval={5000}
          useKeyboardArrows={true}
          infiniteLoop={true}
        >
          {motivational &&
            motivational.map((item) => (
              <div key={`image-${item.id}`}>
                <img
                  className="d-block w-100"
                  style={{ height: 430 }}
                  src={item.url}
                  alt="First slide"
                />
              </div>
            ))}
        </Carousel>
      ) : (
        ""
      )}
    </div>
  );
}

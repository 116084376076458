import OrganListController from "@/app/core/base/controllers/OrganListController";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { DateTranslateSimple } from "@/app/core/util/DateTranslater";
import { Message, WrongRole } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import React, { useState } from "react";
import {
  AnalysisStatusTranslate,
  AnalysisCustomerStatusTranslate,
} from "@/app/core/util/StatusTranslater";
import OrganListHelper, {
  GetCurrentButton,
  GetCurrentStatus,
  OrganStatus,
  OrganStatusTranslate,
} from "@/app/core/util/helpers/OrganListHelper";
import DocInput from "../documents/DocInput";
import DocMasterController from "@/app/core/base/controllers/DocMasterController";
import DocMasterItem from "../documents/DocMasterItem";
import { SendDeferredToCustomer } from "@/app/core/base/controllers/ContactController";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";
import EvaluationModal from "@/app/core/layouts/main-components/evaluation/EvaluationModal";
import { DeliveryType } from "@/app/core/util/constants/DeliveryType";
import DeferredService from "@/app/modules/deferred";
import AddExistingDoc from "./AddExistingDoc";

export default function OrganCard({
  data,
  refresh,
  disabled,
  process,
  organListId,
  selectedService,
}) {
  const userData = Storage.getUserData();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const docMaster = DocMasterController();

  async function remove() {
    if (userData.level > 2 && userData.role === 0) {
      toast.error(WrongRole);
      return;
    }

    if (
      data.status === OrganStatus.Deferred ||
      data.status === OrganStatus.Done
    ) {
      toast.error("Não é possível remover um órgão deferido");
      return;
    }

    let message = "Você não pode excluir o órgão que está trabalhando";
    if (
      (data.stage == 2 && userData.id == data.analystId) ||
      (data.stage == 3 && userData.id == data.preparerId)
    ) {
      toast.error(message);
    } else {
      if (window.confirm("Gostaria de remover o item?")) {
        await organCtrl.Delete(data.id, (res) => {
          refresh();
        });
      }
    }
  }

  async function updateStatus() {
    await organCtrl.StartOrStop(data.id, (res) => {
      toast.success(Message.Saved);
      refresh();
    });
  }

  function loadUserTasked() {
    let stsAnalysis =
      userData && userData.level !== 5
        ? AnalysisStatusTranslate(data.statusAnalysis)
        : AnalysisCustomerStatusTranslate(data.statusAnalysis);
    let stsPreparation =
      userData && userData.level !== 5
        ? AnalysisStatusTranslate(data.statusPreparation)
        : AnalysisCustomerStatusTranslate(data.statusPreparation);

    let stsDevolution =
      data.stage > 4 ? "Concluído" : OrganStatusTranslate(data.status);

    let limit = 4;

    data.analysisShow = true;
    data.preparationShow = true;
    data.devolutionShow = true;

    if (selectedService.steps && selectedService.steps.length > 0) {
      for (let s in selectedService.steps) {
        data.analysisShow = false;
        data.PreparationShow = false;
        data.devolutionShow = false;
        const bs = selectedService.steps[s];
        if (bs.department == 2) {
          data.analysisShow = true;
        } else if (bs.department == 3) {
          data.preparationShow = true;
        } else if (bs.department == 4) {
          data.devolutionShow = true;
        }
      }
    }

    return (
      <div>
        {data.stage >= 2 &&
          data.analysisShow == true &&
          operatorCard(data.analyst, stsAnalysis)}
        {data.stage == 3 &&
          data.preparationShow == true &&
          data.preparationStage == PreparationStep.Track &&
          operatorCard(data.preparer, stsPreparation)}
        {data.stage >= 3 &&
          data.preparationShow == true &&
          data.preparationStage > PreparationStep.Track &&
          operatorCard(
            data.preparer,
            <span className="badge badge-success">Concluído</span>,
          )}
        {data.stage > 3 &&
          data.preparationShow == true &&
          data.preparationStage == PreparationStep.Track &&
          operatorCard(
            data.preparer,
            <span className="badge badge-success">Concluído</span>,
          )}
        {data.stage >= 3 &&
          data.preparationShow == true &&
          data.preparationStage >= PreparationStep.Review &&
          operatorCard(data.preparerReview, stsPreparation, "Conferência")}

        {data.stage >= 4 &&
          data.devolutionShow == true &&
          (data.organType == OrganListHelper().OrganListType.Phisical ||
            (data.protocol != undefined &&
              data.protocol != "" &&
              data.protocol != null)) &&
          (data.status === OrganStatus.NotStarted ||
            data.status > OrganStatus.NotStarted) &&
          operatorCard(
            data.devolutionNotStarted,
            stsDevolution,
            "A Protocolar",
          )}
        {data.stage >= 4 &&
          data.devolutionShow == true &&
          (data.status === OrganStatus.Filed ||
            data.status > OrganStatus.Filed) &&
          operatorCard(data.devolutionFiled, stsDevolution, "Protocolado")}

        {data.stage >= 4 &&
          data.devolutionShow == true &&
          (data.status === OrganStatus.Devolution ||
            data.status > OrganStatus.Devolution) &&
          operatorCard(
            data.devolutionDevolution,
            stsDevolution,
            "Aguardando devolução",
          )}
        {data.stage >= 4 &&
          data.devolutionShow == true &&
          (data.status === OrganStatus.Exigency ||
            data.status > OrganStatus.Exigency) &&
          data.returned === true &&
          operatorCard(
            data.devolutionExigency,
            stsDevolution,
            "Retornado com exigência",
          )}
        {data.stage >= 4 &&
          data.devolutionShow == true &&
          (data.status === OrganStatus.Deferred ||
            data.status > OrganStatus.Deferred) &&
          operatorCard(data.devolutionDeferred, stsDevolution, "Deferido")}
      </div>
    );
  }

  function operatorCard(item, status, esteira) {
    if (item === undefined) {
      return "";
    }
    return (
      <div class="custom-control custom-block custom-control-primary">
        <label class="custom-control-label" for="dm-project-new-people-1">
          <span class="d-flex align-items-center">
            <img
              class="img-avatar img-avatar48"
              src={
                item === undefined ||
                item.avatarImages === "" ||
                item === undefined ||
                item.avatarImages === undefined ||
                item.avatarImages.length === 0
                  ? `assets/media/avatars/avatar8.jpg`
                  : item.avatarImages[0].url
              }
              alt=""
            />
            <span class="ml-2">
              <span class="font-w700">
                {item && item.firstName} - Equipe : {item && item.teamId}
              </span>
              <span class="d-block font-size-sm text-muted">
                {item && DepartmentTranslate(item.level)} - {status}{" "}
                {esteira !== undefined ? `| ${esteira}` : ""}
              </span>
            </span>
          </span>
        </label>
      </div>
    );
  }

  async function ReSendEmail() {
    if (process.responsible === undefined) {
      toast.error("O processo esta sem um responsável");
    } else {
      await SendDeferredToCustomer(
        process.responsible,
        process.processName,
        data.organ.name,
        data.id,
      );
    }
  }

  async function uploadFile(itens) {
    setLoading(true);
    var formData = new FormData();
    for (let s in itens) {
      formData.append("file", itens[s]);
    }

    await docMaster.Upload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      refresh();
    });
  }

  return (
    <div
      className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning"
      key={`organList-item-${data.id}`}
    >
      {data && data.id === organListId ? (
        <div class="ribbon-box" style={{ marginTop: 20, marginLeft: -50 }}>
          <small>Atual</small>
        </div>
      ) : (
        ""
      )}
      <div className="block-header block-header-default">
        <small className="d-flex">
          <span className="mt-2 mr-2">
            {userData && userData.level !== 5 && `${data.position}º`}
            {data && data.organ && data.organ.name}
            {" - "}
            {userData && userData.level !== 5
              ? GetCurrentStatus(data)
              : organHelper.GetCurrentStatus(data, false, selectedService)}
          </span>
          {userData &&
          (((userData.level === 4 ||
            userData.level === 10 ||
            (userData.role > 0 && userData.level != 5)) &&
            (data.status === OrganStatus.Deferred ||
              data.status === OrganStatus.Devolution ||
              data.status === OrganStatus.Done)) ||
            data.done == true) ? (
            <span className="">
              <DocInput
                className={"btn-sm ml-2"}
                multiple={true}
                organList={data}
                loading={loading}
                uploadFile={(itens) => uploadFile(itens)}
              />
            </span>
          ) : (
            ""
          )}
        </small>
        {data && userData.level !== 5 ? (
          <div className="block-tools">
            {selectedService &&
            selectedService.deliveryType !== 0 &&
            userData.level === 4 ? (
              <button
                className="btn btn-sm btn-dual"
                onClick={() => ReSendEmail()}
              >
                <i className="si si-envelope-letter" /> Reenviar email
              </button>
            ) : (
              ""
            )}
            {userData && userData.level === 2 && userData.role === 1 ? (
              <a
                className="btn btn-sm btn-light"
                href={data && `#/analysis/${data.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-eye" />
              </a>
            ) : (
              ""
            )}
            {userData && (userData.level <= 2 || userData.level === 10) ? (
              <button
                className="btn btn-sm btn-light"
                disabled={
                  disabled !== undefined && userData.level !== 10
                    ? disabled
                    : false
                }
                onClick={() => updateStatus()}
              >
                {GetCurrentButton(data)}
              </button>
            ) : (
              ""
            )}
            {(userData &&
              (userData.level === 1 ||
                userData.level === 2 ||
                userData.level === 10) &&
              data.stage <= 2) ||
            (userData.level < 3 && data.stage <= 2) ? (
              <button
                className="btn btn-sm btn-light"
                disabled={disabled !== undefined ? disabled : false}
                onClick={() => remove()}
              >
                <i className="si si-close text-danger" />
              </button>
            ) : (
              ""
            )}

            {userData && userData.level !== 5 && data.stage > 1 ? (
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => setOpen(!open)}
              >
                <i
                  className={`si si-arrow-${open === true ? "up" : "down"}`}
                ></i>
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {userData &&
      userData.level == 5 &&
      data &&
      data.stage == 4 &&
      data.deliveryType == DeliveryType.Questioned ? (
        <div className="ml-3 mr-3">
          <div className="mt-2 mb-2">
            Como quer que sejam devolvidos os documentos originais?
          </div>
          <DeferredService
            match={data && { params: { key: data.id } }}
            presentation={true}
          />
        </div>
      ) : (
        ""
      )}
      {userData &&
      (((userData.level === 4 ||
        userData.level === 10 ||
        (userData.role > 0 && userData.level != 5)) &&
        (data.status === OrganStatus.Deferred ||
          data.status === OrganStatus.Devolution ||
          data.status === OrganStatus.Done)) ||
        data.done == true) ? (
        <div className="pl-3 pr-3">
          <AddExistingDoc
            process={process}
            organListId={data.id}
            reload={() => refresh()}
          />
        </div>
      ) : (
        ""
      )}
      {data && data.docMasters !== undefined && data.docMasters.length > 0 ? (
        <div className="ml-3 mr-3">
          <div className="mt-2 mb-2">Arquivo mestre:</div>
          {data.docMasters.map((item) => (
            <div className="">
              <DocMasterItem
                reload={() => refresh()}
                data={item}
                organList={data}
                openSearch={() => setShowModal(true)}
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}

      {data && open === true && userData.level !== 5 ? (
        <div className="ml-1 mr-1 mb-1 mt-1">{loadUserTasked()}</div>
      ) : (
        ""
      )}
      {data && data.returns !== undefined && data.returns.length > 0 ? (
        <div className="block-content">
          <div className="block-title">Retornos com exigência</div>
          {data &&
            data.returns !== undefined &&
            data.returns.map((item) => (
              <div className="block block-rounded block-bordered">
                <table className="table table-hover table-borderless">
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{item.id}</td>
                    </tr>
                    <tr>
                      <td>Motivo</td>
                      <td>
                        {item.isInternalReason === true
                          ? "Fator interno"
                          : "Fator externo"}
                      </td>
                    </tr>
                    <tr>
                      <td>Departamento</td>
                      <td>
                        {item.department !== 0
                          ? DepartmentTranslate(item.department)
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Observação</td>
                      <td>{item && item.observation}</td>
                    </tr>
                    <tr>
                      <td>Data</td>
                      <td>{DateTranslateSimple(item.created)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
        </div>
      ) : (
        ""
      )}
      <EvaluationModal
        show={showModal}
        onHide={() => setShowModal(false) | refresh()}
        processId={data && data.processId}
        organListId={data && data.id}
      />
    </div>
  );
}

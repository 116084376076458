import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { DateTranslate, ProcessDates } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import {
  FeedbackStructureDelete,
  FeedbackStructureList,
} from "@/app/core/base/controllers/FeedbackController";
import QuizModal from "./QuizModal";
import QuizController from "@/app/core/base/controllers/QuizController";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function FeedbacksCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [service, setService] = useState(null);
  const [data, setData] = useState([]);
  const quizCtrl = QuizController();

  const cols = [
    { header: "Id", isSortable: true, name: "id" },
    { header: "Nome", isSortable: true, name: "name" },
    {
      header: "Disponibilidade",
      isSortable: true,
      name: "available",
      switch: {
        true: "No sistema",
        false: "Apenas pelo link",
      },
    },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    getService();
  }, []);

  async function getService() {
    await quizCtrl.List((res) => setData(ProcessDates(res)));
  }

  async function disableServices(data) {
    if (window.confirm(Message.DeleteConfirm)) {
      await quizCtrl.Delete(data.id, (res) => {
        toast.info(Message.DeleteSuccess);
        getService();
      });
    }
  }

  function openService(item) {
    setService(item.data);
    setShowModal(true);
  }

  return (
    <>
      <Card
        title="Formulário de feedback"
        action={() => setShowModal(!showModal)}
        icon="plus"
      >
        <SampleTable
          title="Formulário de feedback"
          cols={cols}
          data={data}
          remove={(task) => disableServices(task.data)}
          refresh={() => getService()}
          newItem={() => setShowModal(true)}
          open={(item) => openService(item)}
        />
      </Card>

      <QuizModal
        data={service}
        show={showModal}
        onHide={() =>
          setShowModal(!showModal) | getService() | setService(null)
        }
      />
    </>
  );
}

import React from "react";
import { toast } from "react-toastify";
import { ExigencyStatus } from "@/app/core/util/constants/ExigenciesConstants";

export function Analysis(status) {
  switch (status) {
    case 0:
      return "Não iniciado";
      break;
    case 1:
      return "Em andamento";
      break;
    case 2:
      return "Cumprimento de exigências";
      break;
    case 3:
      return "Atrasado";
      break;
    case 4:
      return "Concluídos";
      break;

    default:
      return "Concluídos";
      break;
  }
}

export function Budget(status) {
  switch (status) {
    case 0:
      return "Não iniciado";
      break;
    case 1:
      return "Aguardando orçamento";
      break;
    case 2:
      return "Aprovado";
      break;
    case 3:
      return "Recusado";
      break;
    case 4:
      return "Retornar ao cliente";
      break;
    case 5:
      return "Aguardando retorno do cliente";
      break;
    case 6:
      return "Aguardando conclusão de serviço";
      break;
    case 9:
      return "Removido";
      break;

    default:
      return "Concluídos";
      break;
  }
}

export function BudgetStatusTranslate(sts) {
  if (sts === 0) {
    return <span className="badge badge-info">Não iniciado</span>;
  }
  if (sts === 1) {
    return <span className="badge badge-primary">Aguardando orçamento</span>;
  }
  if (sts === 2) {
    return <span className="badge badge-success">Confirmado</span>;
  }
  if (sts === 3) {
    return <span className="badge badge-danger">Recusado</span>;
  }
  if (sts === 4) {
    return <span className="badge badge-warning">Retornar ao cliente</span>;
  }
  if (sts === 5) {
    return <span className="badge badge-info">Aguardando Aprovação</span>;
  }
  if (sts === 6) {
    return <span className="badge badge-danger">Reavaliação </span>;
  }
  if (sts === 9) {
    return <span className="badge badge-dark">Removido</span>;
  }
}

export function AnalysisStatusTranslate(sts) {
  if (sts === 0) {
    return <span className="badge badge-primary">Não iniciado</span>;
  }
  if (sts === 1) {
    return <span className="badge badge-primary">Em andamento</span>;
  }
  if (sts === 2) {
    return <span className="badge badge-info">Aguar. Cump. de exigências</span>;
  }
  if (sts === 3) {
    return <span className="badge badge-danger">Atrasado</span>;
  }
  if (sts === 4) {
    return <span className="badge badge-success">Concluído</span>;
  }
  if (sts === 5) {
    return <span className="badge badge-danger">Reavaliação</span>;
  }
  if (sts === 6) {
    return <span className="badge badge-danger">Exigência no órgão</span>;
  }
  if (sts === 7) {
    return (
      <span className="badge badge-warning">Aguar. Confirm. do Operador</span>
    );
  }
  if (sts === 8) {
    return <span className="badge badge-warning">Retornar ao cliente</span>;
  }
  if (sts === 9) {
    return <span className="badge badge-secondary">Consultando</span>;
  }
}

export function AnalysisCustomerStatusTranslate(sts) {
  // if () {
  //   return <span className="badge badge-info">Aguardando</span>;
  // }
  if (
    sts === 0 ||
    sts === 1 ||
    sts === 3 ||
    sts === 5 ||
    sts === 8 ||
    sts === 7
  ) {
    return <span className="badge badge-primary">Em andamento</span>;
  }
  if (sts === 2) {
    return (
      <span className="badge badge-warning">Aguar. Cump. de exigências</span>
    );
  }
  if (sts === 4) {
    return <span className="badge badge-success">Concluído</span>;
  }
}

export function ProcessStatusTranslate(sts) {
  if (sts === 0) {
    return <span className="badge badge-info">Não iniciado</span>;
  }
  if (sts === 1 || sts === 3) {
    return <span className="badge badge-primary">Em andamento</span>;
  }
  if (sts === 2) {
    return <span className="badge badge-success">Concluído</span>;
  } else {
  }
}

export function StatusExigency(status) {
  switch (status) {
    case 0:
      return "Aguard. o cumprimento";
      break;
    case 1:
      return "Aguard. o cumprimento";
      break;
    case 2:
      return "Aguard. confirmação do operador";
      break;
    case 4:
      return "Aguard. confirmação do operador";
      break;
    case 3:
      return "Exigência cumprida";
      break;
    default:
      return "Concluído";
      break;
  }
}

export function StatusExigencyButton(status) {
  switch (status) {
    case 0:
      return (
        <button
          className="btn btn-sm btn-dual text-warning"
          title="Aguard. o cumprimento"
          onClick={() => showNotification("Aguard. o cumprimento")}
        >
          <i className="si si-clock" />
        </button>
      );
      break;
    case 1:
      return (
        <button
          className="btn btn-sm btn-dual text-warning"
          title="Aguard. o cumprimento"
          onClick={() => showNotification("Aguard. o cumprimento")}
        >
          <i className="si si-clock" />
        </button>
      );
      break;
    case 2:
      return (
        <button
          className="btn btn-sm btn-dual text-danger"
          title="Aguard. confirmação do operador"
          onClick={() => showNotification("Aguard. confirmação do operador")}
        >
          <i className="fa fa-exclamation-circle" />
        </button>
      );
      break;
    case 4:
      return (
        <button
          className="btn btn-sm btn-dual text-danger"
          title="Aguard. confirmação do operador"
          onClick={() => showNotification("Aguard. confirmação do operador")}
        >
          <i className="fa fa-exclamation-circle" />
        </button>
      );
      break;
    case 3:
      return (
        <button
          className="btn btn-sm btn-dual text-success"
          title="Exigência cumprida"
          onClick={() => showNotification("Exigência cumprida")}
        >
          <i className="fa fa-check" />
        </button>
      );
      break;
    default:
      return (
        <button
          className="btn btn-sm btn-dual text-success"
          title="Concluído"
          onClick={() => showNotification("Concluído")}
        >
          <i className="fa fa-check" />
        </button>
      );
      break;
  }
}

function showNotification(text) {
  toast.info(text);
}

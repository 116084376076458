import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { OperationalListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import RemunerationController from "@/app/core/base/controllers/RemunerationController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OrganStatusList } from "@/app/core/util/helpers/OrganListHelper";
import CurrencyFormat from "react-currency-format";

export default function RemunerationModal({
  infos,
  show,
  onHide,
  serviceOrganId,
}) {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const remunerationCtrl = RemunerationController();

  useEffect(() => {
    setData(infos);
  }, [infos]);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function save() {
    console.log("dados", data);
    if (data.level === undefined) {
      toast.error(`Preencha o departamento para poder salvar`);
      return;
    }

    if (data.id !== undefined) {
      await remunerationCtrl.Update(
        { ...data, serviceOrganId: serviceOrganId },
        (res) => {
          toast.success(`Salvo com sucesso`);
          onHide();
        },
      );
    } else {
      await remunerationCtrl.Insert(
        { ...data, serviceOrganId: serviceOrganId },
        (res) => {
          toast.success(`Salvo com sucesso`);
          onHide();
        },
      );
    }
  }

  return (
    <Modal
      title="Equipe"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Departamento</span>
        <select
          className="form-control"
          name="level"
          onChange={(evt) =>
            handleChange(evt, FieldTypeObj.Number) |
            setOpen(
              evt.target.value === "4" || evt.target.value === 4 ? true : false,
            )
          }
        >
          <option>Selecione</option>
          {OperationalListHelper.map((item) => (
            <option
              value={item.value}
              selected={data && data.level === item.value ? true : false}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {open && open === true ? (
        <div className="form-group">
          <span>Passo da devolução</span>
          <select
            className="form-control"
            name="devolutionStatus"
            onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
          >
            <option>Selecione</option>
            {OrganStatusList.map((item) => (
              <option
                value={item.value}
                selected={
                  data && data.devolutionStatus === item.value ? true : false
                }
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}
      <div className="form-group">
        <span>Valor Base</span>
        <CurrencyFormat
          className={`form-control`}
          thousandSeparator={"."}
          decimalSeparator={","}
          name="basePrice"
          value={data && data.basePrice}
          prefix={"R$"}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange(
              { target: { name: "basePrice", value: value } },
              FieldTypeObj.Currency,
            );
          }}
        />
      </div>
      <div className="form-group">
        <span>Valor por assertividade</span>
        <CurrencyFormat
          className={`form-control`}
          thousandSeparator={"."}
          decimalSeparator={","}
          name="priceRight"
          value={data && data.priceRight}
          prefix={"R$"}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange(
              { target: { name: "priceRight", value: value } },
              FieldTypeObj.Currency,
            );
          }}
        />
      </div>
    </Modal>
  );
}

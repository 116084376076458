import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function UploadTerms(formData, name, response) {
  BaseService(
    {
      method: "POST",
      url: `terms/upload?name=${name}`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function SendTermToAll(termId, response) {
  BaseService(
    {
      method: "POST",
      url: `terms/send/term/${termId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function TermsDelete(termsId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `terms/delete/${termsId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function TermsList(response) {
  BaseService(
    {
      method: "GET",
      url: `terms/`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerTerms(customerId, response) {
  BaseService(
    {
      method: "GET",
      url: `terms/customer/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.log("Erro api", err);
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerResponseTerms(termId, response, result) {
  BaseService(
    {
      method: "POST",
      url: `terms/response/term/${termId}?response=${response}`,
    },
    (res) => {
      result(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

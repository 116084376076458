import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ServiceStepController from "@/app/core/base/controllers/ServiceStepController";
import {
  DepartmentTranslate,
  OperationalBase,
} from "@/app/core/util/helpers/DepartmentHelper";

export default function FractionalCard({ serviceId }) {
  const [department, setDepartment] = useState(0);
  const [data, setData] = useState([]);
  const serviceSteps = ServiceStepController();

  useEffect(() => {
    loadSteps();
  }, [serviceId]);

  async function loadSteps() {
    await serviceSteps.List(serviceId, (res) => {
      let result = res.sort(function (a, b) {
        return a.department - b.department;
      });
      setData(result);
    });
  }

  async function addDepartment() {
    await serviceSteps.Insert(
      {
        department: parseInt(department),
        serviceId: parseInt(serviceId),
      },
      (res) => {
        loadSteps();
      },
    );
  }

  async function deleteStep(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await serviceSteps.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadSteps();
      });
    }
  }

  return (
    <div className="block block-bordered block-transparent border-warning-light border-2x bg-white">
      <div className="block-header">
        <div className="block-title">Departamentos</div>
        <div className="block-tools">
          <DropdownElement
            submit={() => addDepartment()}
            style={{ width: 450 }}
            icon={"si si-plus"}
            title="Adicionar Departamento"
            button="Salvar"
          >
            <div className="form-group">
              <span>Departamento</span>
              <select
                className="form-control"
                name="serviceId"
                onChange={(evt) => setDepartment(parseInt(evt.target.value))}
              >
                <option value={null}>Selecione</option>
                {OperationalBase.map((item) => (
                  <option value={item.value}>{item.name}</option>
                ))}
              </select>
            </div>
          </DropdownElement>
        </div>
      </div>
      <div className="mr-1 ml-1 mb-3 row">
        {data &&
          data.map((item) => (
            <div className="col-12 js-task block block-rounded block-fx-pop block-fx-pop mb-2 animated fadeIn">
              <table className="table table-borderless table-vcenter mb-0">
                <tbody>
                  <tr>
                    <td className="js-task-content font-w600">
                      {DepartmentTranslate(item.department)}
                    </td>
                    <td className="text-right" style={{ width: 100 }}>
                      <button
                        onClick={() => deleteStep(item)}
                        className="js-task-remove btn btn-sm btn-link text-danger"
                      >
                        <i className="fa fa-times fa-fw"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import translate from "@/i18n";

export default function Pagination(props) {
  const [paginationList, setPaginationList] = useState([]);
  const firstPageOnLoop = props.page > 2 ? props.page - 2 : 1;

  useEffect(() => {
    const listOfPages = [];
    for (let i = firstPageOnLoop; i < props.page; i++) {
      listOfPages.push(i);
    }
    if (props.page !== props.totalPages) {
      for (let i = props.page; i <= props.page + 2; i++) {
        listOfPages.push(i);
      }
    } else {
      listOfPages.push(props.totalPages);
    }

    setPaginationList(listOfPages);
  }, [firstPageOnLoop, props.page, props.totalPages]);

  return (
    <div className="row">
      <div className="col-sm-8 col-md-8 col-lg-8">
        <div
          className="dataTables_info"
          id="DataTables_Table_0_info"
          role="status"
          aria-live="polite"
        >
          {translate("page")} <strong>{props.page}</strong> {translate("of")}{" "}
          <strong>{props.totalPages}</strong>
        </div>
      </div>
      <div className="col-sm-4 col-md-4">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="DataTables_Table_0_paginate"
          align="right"
          style={{ float: "right" }}
        >
          <ul className="pagination">
            <li
              className={`paginate_button page-item previous ${
                props.page <= 1 ? "disabled" : ""
              }`}
              id="DataTables_Table_0_previous"
            >
              <span
                onClick={() => props.paginate(props.page - 1)}
                aria-controls="DataTables_Table_0"
                data-dt-idx="0"
                tabIndex="0"
                className="page-link"
                id="clickPage1"
              >
                <i className="fa fa-angle-left" />
              </span>
            </li>
            {paginationList &&
              paginationList.map((page) => {
                return (
                  <li
                    key={"page" + page}
                    className={`paginate_button page-item ${
                      props.page === page ? "active" : ""
                    }`}
                  >
                    <span
                      key={page}
                      onClick={() => props.paginate(page)}
                      aria-controls="DataTables_Table_0"
                      data-dt-idx={page}
                      tabIndex="0"
                      className="page-link"
                      id="clickPage2"
                    >
                      {page}
                    </span>
                  </li>
                );
              })}
            <li
              className={`paginate_button page-item next ${
                props.page === props.totalPages ? "disabled" : ""
              }`}
              id="DataTables_Table_0_next"
            >
              <span
                onClick={() => props.paginate(props.page + 1)}
                aria-controls="DataTables_Table_0"
                data-dt-idx="5"
                tabIndex="0"
                className="page-link"
                id="clickPage3"
              >
                <i className="fa fa-angle-right" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function ExigencyTimelineInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: `exigencytimeline/insert`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTimelineUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: `exigencytimeline/update`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTimelineDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `exigencytimeline/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTimelineList(exigencyId, response) {
  BaseService(
    {
      method: "GET",
      url: `exigencytimeline/${exigencyId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

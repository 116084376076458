import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";

export default function OrgansModal({ infos, show, onHide }, props) {
  const [data, setData] = useState({});
  const organCtrl = OrganListController();

  const { submit, isLoading } = props;

  useEffect(() => {
    setData(infos);
  }, [infos]);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function save() {
    if (data.name === undefined) {
      toast.error(`Preencha o nome para poder salvar`);
      return;
    }

    if (data.id !== undefined) {
      organCtrl.OrganUpdate(data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    } else {
      organCtrl.OrganInsert(data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  return (
    <Modal
      title="Orgãos"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <form onSubmit={save}>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="example-masked-time">Nome</label>
              <input
                type="text"
                class="form-control"
                name="name"
                defaultValue={infos && infos.name}
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div class="form-group">
              <label for="example-masked-time">Descrição</label>
              <input
                type="text"
                class="form-control"
                name="description"
                defaultValue={infos && infos.description}
                onChange={(evt) => handleChange(evt)}
                placeholder="Opcional"
              />
            </div>
            <div class="form-group">
              <label for="example-masked-time">Checável</label>
              <select
                class="form-control"
                name="checked"
                defaultValue={infos && infos.checked}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Bool)}
              >
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

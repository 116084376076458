import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import ExigencyForm from "./ExigencyModal";
import SampleTable from "@/app/core/components/table/premade/SampleTable";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import {
  ExigencyTemplateDelete,
  ExigencyTemplateList,
} from "@/app/core/base/controllers/ExigencyTemplateController";
import { ExigencyActionTranlate } from "@/app/core/util/helpers/ExigencyActionHelper";
import { DeleteConfirm } from "@/app/core/util/messages/GeralMessages";

export default function ExigenciesComponent() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState({});

  const cols = [
    { header: "Codigo", isSortable: true, name: "code" },
    { header: "Descricao", isSortable: false, name: "description" },
    { header: "Ação", isSortable: true, name: "actionName" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
    { header: "Atualizado em", isSortable: true, name: "updatedDate" },
  ];

  useEffect(() => {
    getModels();
  }, []);

  function getModels() {
    ExigencyTemplateList((res) => {
      for (let s in res) {
        res[s].actionName = ExigencyActionTranlate(res[s].action);
      }
      setData(ProcessDates(res));
    });
  }

  function showEditItem(item) {
    setItemData(item.data);
    setShowModal(true);
  }

  function deleteItem(item) {
    if (window.confirm(DeleteConfirm)) {
      ExigencyTemplateDelete(item.data.id, (res) => {
        getModels();
      });
    }
  }

  return (
    <>
      <Card
        title="Modelos de exigências"
        action={() => setShowModal(!showModal)}
        icon="plus"
      >
        <SampleTable
          data={data}
          cols={cols}
          newItem={() => setShowModal(true)}
          open={(item) => showEditItem(item)}
          remove={(item) => deleteItem(item)}
          refresh={() => getModels()}
        />
      </Card>
      <ExigencyForm
        show={showModal}
        data={itemData}
        onHide={() => setShowModal(!showModal) | getModels()}
      />
    </>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import {
  FeedbackStructureInsert,
  FeedbackStructureUpdate,
  FeedbackValueByStructure,
  FeedbackValueDelete,
  FeedbackValueInsert,
} from "@/app/core/base/controllers/FeedbackController";
import { FieldType } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";

function FeedbackStructureModal(props) {
  const { data, show, onHide, submit, isLoading } = props;

  const [values, setValues] = useState({});
  const [responses, setResponses] = useState([]);
  const [preResponse, setPreResponse] = useState("");

  useEffect(() => {
    if (data !== null && data.id !== undefined) {
      setValues(data);
      loadValues();
    } else {
      setValues({});
      setResponses([]);
    }
  }, [data]);

  function postFeedbackStructure() {
    const infos = {
      ...values,
      quizId: props.quizId,
      fieldType: parseInt(values.fieldType),
    };

    FeedbackStructureInsert(infos, (res) => {
      if (responses.length > 0) {
        for (let s in responses) {
          let result = responses[s];

          AddValues(result.value, res.id, (resul) => {});
        }
        setTimeout(() => {
          toast.success("Campo cadastrado com Sucesso");
          onHide();
        }, 500);
      } else {
        toast.success("Campo cadastrado com Sucesso");
        onHide();
      }
    });
  }

  function updateFeedbackStructure() {
    const infos = {
      ...values,
      quizId: props.quizId,
      fieldType: parseInt(values.fieldType),
    };

    FeedbackStructureUpdate(infos, (res) => {
      toast.success("Campo Cadastrado com Sucesso");
      onHide();
    });
  }

  function saveService() {
    if (values.description === undefined) {
      toast.error("Preencha os dados para poder salvar");
      return;
    } else {
      if (values.id === undefined) {
        postFeedbackStructure();
      } else {
        updateFeedbackStructure();
      }
    }
  }

  function addResponses() {
    if (values.id !== undefined) {
      AddValues(preResponse, values.id, (res) => {
        setPreResponse("");
        loadValues();
      });
    } else {
      let oldResponses = responses;
      oldResponses.push({ value: preResponse });
      setResponses(oldResponses);
      setPreResponse("");
    }
  }

  function removeResponse(item) {
    if (values.id !== undefined) {
      FeedbackValueDelete(item.id, (res) => {
        loadValues();
      });
    } else {
      let oldResponses = responses.filter((x) => x.value !== item.value);
      setResponses(oldResponses);
    }
  }

  function loadValues() {
    FeedbackValueByStructure(
      values.id !== undefined ? values.id : data.id,
      (res) => {
        setResponses(res);
      },
    );
  }

  function AddValues(answer, structureId, res) {
    FeedbackValueInsert(
      { value: answer, feedbackStructureId: structureId },
      (response) => {
        res(response);
      },
    );
  }

  function handleChange(evt) {
    setValues(OnDataChange(values, evt));
  }

  return (
    <Modal
      title="Campo de feedback"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveService()}
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="example-masked-time">Pergunta</label>
            <input
              type="text"
              class="form-control"
              name="description"
              value={values && values.description}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          <div class="form-group">
            <label for="example-masked-time">Tipo de campo</label>
            <select
              type="text"
              class="form-control"
              name="fieldType"
              onChange={(evt) => handleChange(evt)}
            >
              <option value={null}>Selecione</option>
              {FieldType.map((item) => (
                <option
                  value={item.value}
                  selected={item.value === values.fieldType}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {values && values.id !== undefined ? (
          <div className="col-md-12">
            <div className="block block-fx-pop">
              <div className="block-header">
                <div className="block-title">Opções de resposta</div>
                <div className="block-tools">
                  <Dropdown>
                    <Dropdown.Toggle variant="light">Adicionar</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <table className="table table-hover">
                        <tbody>
                          <div className="form-group">
                            <span>Escreva</span>
                            <input
                              type="text"
                              value={preResponse}
                              className="form-control"
                              onChange={(evt) =>
                                setPreResponse(evt.target.value)
                              }
                            />
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => addResponses()}
                            >
                              <i className="si si-plus" />
                            </button>
                          </div>
                        </tbody>
                      </table>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="block-content">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <td>Opção</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {responses.map((item) => (
                      <tr>
                        <td>{item && item.value}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-white"
                            onClick={() => removeResponse(item)}
                          >
                            <i className="si si-close text-danger" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Modal>
  );
}

export default FeedbackStructureModal;

import React, { useRef, useState } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import DocumentsController from "@/app/core/base/controllers/DocumentsController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { toast } from "react-toastify";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { useEffect } from "react";
import { ServiceListInsert } from "@/app/core/base/controllers/ServicesController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import InputMask from "react-input-mask";
import { ValidaCNPJ } from "@/app/core/util/helpers/UserHelper";

export default function CustomerNewBudget({
  show,
  onHide,
  selectedService,
  customerId,
  infos,
}) {
  const userData = Storage.getUserData();
  const [files, setFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [data, setData] = useState({
    processName: "",
    observation: "",
  });
  const [progress, setProgress] = useState({});
  const [change, setChange] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  let filesList = [];
  let clicked = 0;
  const processCtrl = ProcessController();
  const docsCtrl = DocumentsController();
  const automations = AutomationsController();
  const inputFile = useRef(null);

  useEffect(() => {
    setFiles([]);
    let base = data;
    if (infos && infos.id) {
      base.processName = infos.name;
      if (infos.processName) {
        base.processName = infos.processName;
        loadProcessDocs();
        setOpen(false);
        if (infos.id) {
          base.processId = infos.id;
        }
      }
      if (infos.cnpj) {
        base.cnpj = infos.cnpj;
      }
      base.cnpj = infos.doc;
      if (infos.doc) {
        setOpen(false);
        setDisabled(true);
      }
    } else {
      setDisabled(false);
      base.processName = "";
      base.observation = "";
    }
    setData(base);
  }, [selectedService, show, infos]);

  function loadProcessDocs() {
    docsCtrl.Principals(infos.id, (res) => {
      setOldFiles(res);
      toggleChange();
    });
  }

  function onButtonClick() {
    inputFile.current.click();
  }
  function handleChanges(evt) {
    setData(OnDataChange(data, evt));
  }

  async function save() {
    if (data.processName.length === 0 && data.observation.length === 0)
      if (files.length > 15) {
        toast.error("O limite de arquivos por vez é de 15");
        return;
      }
    if (open === false && (data.cnpj === undefined || data.cnpj === "")) {
      toast.error("O campo CNPJ é obrigatório");
      return;
    }
    if (open === false && data.cnpj.length > 0) {
      if (!ValidaCNPJ(data.cnpj)) {
        toast.error("Número de CNPJ inválido");
        return;
      }
    }
    clicked++;

    if (clicked > 1) return;

    setLoading(true);
    await processCtrl.Insert(
      {
        customerId:
          userData.customerId == undefined
            ? parseInt(customerId)
            : userData.customerId,
        responsibleId: userData.level == 5 ? userData.id : null,
        budgetResponsibleId: userData.level == 5 ? userData.id : null,
        hasCertificate,
        ...data,
      },
      async (res) => {
        NotifyUpdate(res.id, `Orçamento solicitado`, false);
        if (selectedService && selectedService.id) {
          ServiceListInsert(
            {
              serviceId: selectedService.id,
              serviceName: selectedService.name,
              processId: res.id,
              deliveryType: 0,
              position: 1,
            },
            (result) => {},
          );
        }
        if (oldFiles.length > 0) {
          setLoading(false);
          processOldFiles(res);
        }
        if (files.length > 0) {
          setLoading(false);
          processFiles(res);
        } else {
          toast.success(
            "Salvo com sucesso, iremos analisar seu pedido e entrar em contato em breve",
          );

          automations.DistributeProcess((res) => {});
          setLoading(false);
          onHide();
        }
      },
      (error) => {
        setLoading(false);
      },
    );
  }

  function processProgress(res) {
    if (res === "" || res === undefined) {
      return <div>Pendente</div>;
    } else if (res === true) {
      return <div>Salvo com sucesso</div>;
    } else {
      return <div>Falha</div>;
    }
  }

  async function processOldFiles(process) {
    setLoading(true);
    let list = [];
    for (let s in oldFiles) {
      list.push(oldFiles[s].id);
    }

    docsCtrl.CopyToProcess(process.id, list, (res) => {});
  }

  async function processFiles(process) {
    let list = [];
    for (let s in files) {
      list[s] = { status: 0 };
    }
    setProgress(list);
    setLoading(true);
    await files.forEach(async (file, index) => {
      let formData = new FormData();
      formData.append("file", file);
      let old = progress[0] === undefined ? list : progress;
      old[index] = "";

      await docsCtrl.UploadInitialProcessDocs(
        process.id,
        formData,
        userData.id,
        null,
        (res) => {
          old[index] = true;
          setProgress(old);
          let entries = Object.keys(old);
          let filesOld = files;
          filesOld[index].status = true;
          setFiles(filesOld);
          toggleChange();

          let filteredProgress = old.filter((x) => x === true);
          if (files.length === filteredProgress.length) {
            let itsOK = true;

            for (let s in entries) {
              itsOK = old[s] === false ? false : true;
            }
            if (itsOK === true) {
              setLoading(false);
              clicked = 0;
              toast.success(
                "Salvo com sucesso, iremos analisar seu pedido e entrar em contato em breve",
              );
              automations.DistributeProcess((res) => {});
              onHide();
            }
          }
        },
        (fail) => {
          old[index] = false;
          setProgress(old);
          setLoading(false);
        },
      );
    });
  }

  function removeFile(archive) {
    let itens = files;
    let newItens = [];
    for (let s = 0; s < itens.length; s++) {
      if (archive.name === itens[s].name) {
      } else {
        newItens.push(itens[s]);
      }
    }

    toggleChange();
    setFiles(newItens);
  }

  function removeOldFile(archive) {
    let itens = oldFiles;
    let newItens = [];
    for (let s = 0; s < itens.length; s++) {
      if (archive.id === itens[s].id) {
      } else {
        newItens.push(itens[s]);
      }
    }

    toggleChange();
    setOldFiles(newItens);
  }

  function toggleChange() {
    let date = new Date();
    setChange(date.getTime());
  }

  return (
    <Modal
      title="Novo pedido"
      show={show}
      isLoading={loading}
      onHide={onHide}
      onSubmit={() => save()}
    >
      <div className="block-content">
        <div className="row">
          <div className="form-group col-md-12">
            <label for="example-masked-time">Nome do processo</label>
            <input
              onChange={(evt) => handleChanges(evt)}
              className="form-control"
              type="text"
              name="processName"
              value={data.processName}
            />
          </div>
          <div className="form-group col-md-12">
            <label for="example-textarea-input">Descreva sua necessidade</label>
            <textarea
              onChange={(evt) => handleChanges(evt)}
              className="form-control"
              name="observation"
              value={data.observation}
              placeholder="Descreva sua necessidade"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="d-block">Se trata de uma abertura?</label>
            <div className="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                className="custom-control-input"
                onClick={() => setOpen(true)}
                id="example-radio-custom-inline1"
                checked={open === true ? true : false}
                disabled={disabled}
                name="example-radio-custom-inline"
              />
              <label
                className="custom-control-label"
                for="example-radio-custom-inline1"
              >
                Sim
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                className="custom-control-input"
                id="example-radio-custom-inline2"
                checked={open === false ? true : false}
                disabled={disabled}
                onClick={() => setOpen(false)}
                name="example-radio-custom-inline"
              />
              <label
                className="custom-control-label"
                for="example-radio-custom-inline2"
              >
                Não
              </label>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="d-block">
              Seu cliente possui certificado digital?
            </label>
            <div className="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                className="custom-control-input"
                onClick={() => setHasCertificate(true)}
                id="example-radio-custom-certificate1"
                checked={hasCertificate == true ? true : false}
                name="example-radio-custom-certificate"
              />
              <label
                className="custom-control-label"
                for="example-radio-custom-certificate1"
              >
                Sim
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline custom-control-primary">
              <input
                type="radio"
                className="custom-control-input"
                id="example-radio-custom-certificate2"
                checked={hasCertificate === false ? true : false}
                onClick={() => setHasCertificate(false)}
                name="example-radio-custom-certificate"
              />
              <label
                className="custom-control-label"
                for="example-radio-custom-certificate2"
              >
                Não
              </label>
            </div>
          </div>
          {open === false ? (
            <div className="form-group col-md-12">
              <label for="example-masked-time">CNPJ</label>
              <InputMask
                mask="99.999.999/9999-99"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="cnpj"
                placeholder="99.999.999/9999-99"
                value={data && data.cnpj}
                onChange={(event) => {
                  handleChanges({
                    target: {
                      name: "cnpj",
                      value: event.target.value.replace(/\D/g, ""),
                    },
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}
          {selectedService && selectedService.id !== undefined ? (
            <div className="col-12">
              <div className="block-header">
                <div className="block-title">Serviço selecionado</div>
              </div>

              <div className="block block-rounded block-bordered ribbon ribbon-left ribbon-bookmark ribbon-warning">
                <div className="block-header">
                  <div className="block-title">
                    {selectedService.name} - R$
                    {MoneyMask(selectedService.value, 2)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <div className="block-header">
              <div className="title">Documentos</div>
              <div className="tools">
                <a
                  href="javascript:void(0)"
                  className="btn btn-sm btn-link text-primary"
                  onClick={() => onButtonClick()}
                >
                  <i class="si si-cloud-upload"></i> Fazer upload
                </a>
              </div>
            </div>
            <table className="table">
              {change &&
                files &&
                files.map((item, index) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item && processProgress(item.status)}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-link text-primary text-danger"
                        onClick={() => removeFile(item)}
                      >
                        <i class="si si-close"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              {change &&
                oldFiles &&
                oldFiles.map((item, index) => (
                  <tr>
                    <td>{item.name}</td>
                    <td></td>
                    <td className="text-right">
                      <button
                        className="btn btn-sm btn-link text-primary text-danger m-2"
                        onClick={() => window.open(item.url, "_blank")}
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-link text-primary text-danger m-2"
                        onClick={() => removeOldFile(item, index)}
                      >
                        <i class="si si-close"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFile}
        onChange={(event) => {
          const parent = event.target.files;
          Array.prototype.forEach.call(files, (file) => {
            filesList.push(file);
          });
          Array.prototype.forEach.call(parent, (file) => {
            filesList.push(file);
          });

          setFiles(filesList);
          toggleChange();
        }}
        style={{ visibility: "hidden" }}
      />
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import ReportController from "@/app/core/base/controllers/ReportController";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import BudgetReportModal from "@/app/modules/dashboard/components/panels/administratorElements/Modals/BudgetReportModal.1";
import TeamsModal from "../../teams/TeamsModal";
import Storage from "@/app/core/util/Storage";

export default function BudgetCounter({ startDate, endDate }) {
  const [all, setAll] = useState(false);
  const report = ReportController();

  const [data, setData] = useState({
    notStarted: 0,
    ongoing: 0,
    ongoingPercentage: 0,
    accepted: 0,
    acceptedPercentage: 0,
    refused: 0,
    refusedPercentage: 0,
    accuracity: 0,
    total: 0,
    open: 0,
    openPercentage: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);

  useEffect(() => {
    loadCounters();
  }, [startDate, endDate, showModal]);

  function loadCounters() {
    const oldResult = Storage.getBudgetCounter();
    if (
      oldResult == null ||
      oldResult.startDate != startDate ||
      oldResult.endDate != endDate
    ) {
      report.BudgetResume(startDate, endDate, (res) => {
        // const base = res.total;
        const base = res.notStarted + res.onGoing + res.accepted + res.refused;
        const basePeriod =
          res.notStartedPeriod +
          res.onGoingPeriod +
          res.acceptedPeriod +
          res.refusedPeriod;
        let result = data;
        result.notStarted = parseInt((res.notStarted / base) * 100);
        result.ongoingPercentage = parseInt((res.onGoing / base) * 100);
        result.acceptedPercentage = parseInt((res.accepted / base) * 100);
        result.refusedPercentage = parseInt((res.refused / base) * 100);

        result.notStartedPeriod = parseInt(
          (res.notStartedPeriod / basePeriod) * 100,
        );
        result.ongoingPercentagePeriod = parseInt(
          (res.onGoingPeriod / basePeriod) * 100,
        );
        result.acceptedPercentagePeriod = parseInt(
          (res.acceptedPeriod / basePeriod) * 100,
        );
        result.refusedPercentagePeriod = parseInt(
          (res.refusedPeriod / basePeriod) * 100,
        );

        result.ongoing = parseInt(res.onGoing);
        result.accepted = parseInt(res.accepted);
        result.refused = parseInt(res.refused);

        result.ongoingPeriod = parseInt(res.onGoingPeriod);
        result.acceptedPeriod = parseInt(res.acceptedPeriod);
        result.refusedPeriod = parseInt(res.refusedPeriod);
        result.totalPeriod = basePeriod;

        result.open = res.notStarted;
        result.openPercentage = result.notStarted;

        result.openPeriod = res.notStarted;
        result.openPercentagePeriod = result.notStarted;

        result.startDate = startDate;
        result.endDate = endDate;
        setData(result);

        Storage.setBudgetCounter(result);
      });
    } else {
      setData(oldResult);
    }
  }

  return (
    <div>
      <div className="block block-rounded bg-xinspire">
        <div className="block-header bg-xinspire-dark">
          <div className="blick-title text-white text-uppercase font-size-sm font-w700">
            Orçamentos
          </div>
          <div className="block-tools">
            <button
              className="btn bg-xinspire-dark btn-sm text-white"
              onClick={() => setAll(!all)}
            >
              <i className="far fa-clock" />{" "}
              {all ? "Periodo consultado" : "Todo o período"}
            </button>
            <button
              className="btn bg-xinspire-dark btn-sm text-white"
              onClick={() => setShowModal(true)}
            >
              <i className="far fa-eye" />
            </button>
            <button
              className="btn bg-xinspire-dark btn-sm text-white"
              onClick={() => setShowTeamModal(true)}
            >
              <i className="far fa-chart-bar" />
            </button>
          </div>
        </div>
        <div className="block-content block-content-full">
          <div className="row text-center">
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 mx-auto text-white">
                  {data && all == true ? data.open : data.openPeriod}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && all == true
                    ? data.openPercentage
                    : data.openPercentagePeriod}
                  %
                </p>
                <p className="text-white-75 mb-0">Aguardando orçamento</p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 mx-auto text-white">
                  {data && all == true ? data.ongoing : data.ongoingPeriod}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && all == true
                    ? data.ongoingPercentage
                    : data.ongoingPercentagePeriod}
                  %
                </p>
                <p className="text-white-75 mb-0">Aguardando aprovação</p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-right border-black-op">
                <div className="item item-circle bg-black-25 mx-auto text-white">
                  {data && all == true ? data.accepted : data.acceptedPeriod}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && all == true
                    ? data.acceptedPercentage
                    : data.acceptedPercentagePeriod}
                  %
                </p>
                <p className="text-white-75 mb-0">Aprovados</p>
              </div>
            </div>
            <div className="col-3">
              <div className="py-3 border-black-op">
                <div className="item item-circle bg-black-25 mx-auto text-white">
                  {data && all == true ? data.refused : data.refusedPeriod}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && all == true
                    ? data.refusedPercentage
                    : data.refusedPercentagePeriod}
                  %
                </p>
                <p className="text-white-75 mb-0">Recusados</p>
              </div>
            </div>
            {/* <div className="col-3">
              <div className="py-3">
                <div className="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.total}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  100%
                </p>
                <p className="text-white-75 mb-0">Total</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <BudgetReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        infos={showModal && data && data.list}
        startDate={startDate}
        endDate={endDate}
      />
      <TeamsModal
        show={showTeamModal}
        onHide={() => setShowTeamModal(false)}
        level={1}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import OrganListController from "../../base/controllers/OrganListController";
import DropdownElement from "./elements/DropdownElement";
import Storage from "@/app/core/util/Storage";

export default function FilterOrgans({
  rawData,
  data,
  filter,
  responseData,
  responseFilter,
  processId,
  organListId,
  preLoad,
}) {
  const date = new Date();

  const [organs, setOrgans] = useState([]);
  const [change, setChange] = useState(date.getTime());
  const userData = Storage.getUserData();
  const organCtrl = OrganListController();
  let process = false;

  useEffect(() => {
    if (preLoad != undefined) {
      organizeData(preLoad.organList);
    } else {
      loadOrgans();
    }
  }, [processId, filter, organListId, preLoad]);

  useEffect(() => {
    handleOrganFilter();
  }, [change]);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadOrgans() {
    if (processId === undefined || processId === null) {
      return;
    }
    process = false;
    await organCtrl.ListByProcess(processId, (res) => {
      organizeData(res);
    });
  }

  function organizeData(res) {
    if (res != undefined) {
      let organ = res.filter((x) => x.id === organListId);
      for (let s in res) {
        if (organ.length > 0 && userData && userData.level === 5) {
          if (organ[0].id === res[s].id) {
            res[s].checked = true;
            process = true;
          } else {
            res[s].checked = false;
          }
        } else {
          res[s].checked = true;
        }
      }
      setOrgans(res);
      if (process === true) {
        setTimeout(() => {
          handleOrganFilter();
        }, 500);
      }
    }
  }

  function handleOrganFilter() {
    let list = [];
    let response = [];
    let available = organs.filter((x) => x.checked === true);
    if (filter.length > 0 && filter !== undefined) {
      list = filter;
    } else if (data.length > 0) {
      list = data;
    } else {
      list = rawData;
    }

    for (let s in list) {
      const c = available.filter((x) => x.id === list[s].organListId);
      if (c.length > 0) {
        response.push(list[s]);
      }
    }

    if (available.length === organs.length) {
      if (filter && filter.length > 0) {
        responseFilter(rawData);
      } else {
        responseData(rawData);
      }
    } else {
      if (filter && filter.length > 0) {
        responseFilter(response);
      } else {
        responseData(response);
      }
    }
  }

  function handleSelectedOrgans(evt) {
    let olds = organs;
    let id = parseInt(evt.target.name);
    let checked = evt.target.checked;
    for (let s in olds) {
      if (olds[s].id === id) {
        olds[s].checked = checked === true || checked === "true" ? true : false;
      }
    }
    setOrgans(olds);
    notifyChange();
  }

  return (
    <DropdownElement submit={() => handleOrganFilter()} button={"Filtrar"}>
      {change &&
        organs &&
        organs.map((item) => (
          <div class="form-check mt-2 mb-2">
            <input
              class="form-check-input mr-1"
              type="checkbox"
              name={item.id}
              id={`checkbox-${item.id}`}
              onChange={(evt) => handleSelectedOrgans(evt)}
              checked={item.checked}
            />
            <label class="form-check-label">
              {item.organ && item.organ.name}
            </label>
          </div>
        ))}
    </DropdownElement>
  );
}

import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import { DeliveryDevolution } from "@/app/core/base/controllers/SendController";
import {
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import { ServiceList } from "@/app/core/base/controllers/ServicesController";
import { toast } from "react-toastify";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { DepartmentUsers } from "@/app/core/base/controllers/UsersController";
import { Toast } from "react-bootstrap";
import { CustomerList } from "@/app/core/base/controllers/CustomerController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import ProcessDeferredModal from "@/app/core/layouts/main-components/process/ProcessDeferredModal";
import { ExigencyDeliveryList } from "@/app/core/base/controllers/ExigenciesController";
import { DeliveryType } from "@/app/core/util/constants/DeliveryType";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";

export default function ProfileMainComponent(props) {
  const date = new Date();
  const userData = Storage.getUserData();
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectAllCustomer, setSelectAllCustomer] = useState(true);
  const [selectAllServices, setSelectAllServices] = useState(true);
  const [selectAllOrgans, setSelectAllOrgans] = useState(true);
  const [type, setType] = useState(0);
  const [from, setFrom] = useState(userData.level > 1 ? 2 : userData.level);
  const [users, setUsers] = useState([]);
  const [itens, setItens] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [organs, setOrgans] = useState([]);
  const [selected, setSelected] = useState({});
  const [change, setChange] = useState(date.getTime());
  const [proceasss, setProcess] = useState([]);
  const [organList, setOrganList] = useState([]);
  const [processRaw, setProcessRaw] = useState([]);
  const [queryBase, setQuery] = useState("");
  const [organListRaw, setOrganListRaw] = useState([]);
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const [delivery, setDelivery] = useState([]);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadSecond();
  }, [refresh, type, from]);

  useEffect(() => {
    loadUsers();
    loadExigencies();
  }, []);

  useEffect(() => {
    processFilter();
  }, [
    selectAllCustomer,
    selectAllServices,
    selectAllOrgans,
    customers,
    services,
    organs,
  ]);

  function loadExigencies() {
    ExigencyDeliveryList((res) => setDelivery(res));
  }

  function loadUsers() {
    DepartmentUsers(5, (res) => {
      setUsers(res);
      loadCustomers();
    });
  }

  function loadCustomers() {
    CustomerList((res) => {
      setCustomers(checkAll(res));
      loadServices();
    });
  }

  function loadServices() {
    ServiceList((res) => {
      setServices(checkAll(res));
      loadOrgans();
    });
  }

  async function loadOrgans() {
    await organCtrl.OrgansSelect((res) => {
      setOrgans(checkAll(res));
      loadSecond();
    });
  }

  function checkAll(itens) {
    for (let s in itens) {
      itens[s].checked = true;
    }

    return itens;
  }

  async function loadSecond() {
    setLoading(true);
    await DeliveryDevolution((res) => {
      let listOrgans = res;

      const currentDate = date.getTime();

      for (let s in listOrgans) {
        listOrgans[s].time = new Date(listOrgans[s].updatedDate).getTime();
        const result = currentDate - listOrgans[s].time;
        listOrgans[s].days = parseInt(result / (86400 * 1000));
      }
      listOrgans.sort(function (a, b) {
        return b.time - a.time;
      });
      setOrganList(listOrgans);
      setOrganListRaw(listOrgans);
      setProcess([]);
    });

    setLoading(false);
  }

  function openElement(item) {
    setShowModal(true);
    setSelected(item);
  }

  function handleSelectedItens(evt, index) {
    let newItens = itens;
    let organs = organList;
    if (evt.target.checked === true) {
      let contains = newItens.filter((x) => x === organs[index].id);
      if (contains.length < 1) {
        if (organs[index] !== undefined) {
          newItens.push(organs[index].id);
        }
      }
    } else {
      for (let s in newItens) {
        if (s === organs[index].id) {
          newItens.splice(s, 1);
        } else {
          newItens.splice(s, 1);
        }
      }
    }
    organs[index].checked = evt.target.checked;

    setOrganList(organs);

    setItens(newItens);
    notyChange();
  }

  function handleQueryChange(info) {
    let query = info.trim().toLowerCase();

    if (info.length === 0 || info === "") {
      setProcess(processRaw);
      notyChange();
    } else {
      let oldData = organListRaw;
      let data = [];

      for (let s in oldData) {
        const item = oldData[s];
        let enter = false;

        if (item.selectedService.serviceName.trim().toLowerCase() === query) {
          enter = true;
        }

        if (
          item.process.processName.trim().toLowerCase().includes(query) ||
          item.process.customer.name.trim().toLowerCase().includes(query) ||
          (item.process.customer.address !== undefined &&
            item.process.customer.address
              .trim()
              .toLowerCase()
              .includes(query)) ||
          (item.process.customer.state !== undefined &&
            item.process.customer.state.trim().toLowerCase().includes(query)) ||
          (item.process.customer.crc !== undefined &&
            item.process.customer.crc.trim().toLowerCase().includes(query)) ||
          (item.process.customer.city !== undefined &&
            item.process.customer.city.trim().toLowerCase().includes(query)) ||
          item.process.customer.customerCode.includes(query) ||
          (item.process.customer.cnpj !== undefined &&
            CnpjMask(item.process.customer.cnpj).includes(query)) ||
          (item.process.customer.cpf !== undefined &&
            CpfMask(item.process.customer.cpf).includes(query))
        ) {
          enter = true;
        }

        let checkOrgan = organs.filter((x) => x.id === item.organId);

        if (checkOrgan.length > 0) {
          if (checkOrgan[0].name.trim().toLowerCase().includes(query)) {
            enter = true;
          }
        }

        if (
          DateTranslate(item.updatedDate).trim().toLowerCase().includes(query)
        ) {
          enter = true;
        }

        if (enter === true) {
          data.push(item);
        }
      }

      setOrganList(data);
      notyChange();
    }
  }

  function processFilter() {
    let oldData = organListRaw;
    let data = [];

    for (let s in oldData) {
      const item = oldData[s];
      let service = false,
        customer = false,
        organ = false;

      let checkService = services.filter(
        (x) =>
          item.selectedService &&
          x.id === item.selectedService.serviceId &&
          x.checked === true,
      );

      if (checkService !== null && checkService.length > 0) {
        service = true;
      }

      let checkCustomer = customers.filter(
        (x) => x.id === item.process.customerId && x.checked === true,
      );

      if (checkCustomer !== null && checkCustomer.length > 0) {
        customer = true;
      }

      let checkOrgan = organs.filter(
        (x) => x.id === item.organId && x.checked === true,
      );

      if (checkOrgan !== null && checkOrgan.length > 0) {
        organ = true;
      }

      if (customer === true && service === true && organ === true) {
        data.push(item);
      }
    }

    setOrganList(data);
    notyChange();
  }

  function notyChange() {
    setChange(date.getTime());
  }

  function processSituation(item) {
    if (item.deliveryType == DeliveryType.Retirada) {
      return (
        <span className="badge badge-danger">
          Não retirou - Enviar carta simples
        </span>
      );
    } else if (item.days > 15) {
      return <span className="badge badge-danger">Enviar carta simples</span>;
    } else if (item.days >= 10 && item.days <= 15) {
      return <span className="badge badge-warning">Último aviso</span>;
    } else {
      return <span className="badge badge-secondary">Aguard. resposta</span>;
    }
  }

  return (
    <div className="content">
      <div className="block block-rounded">
        <div className="block-header">
          <div className="block-title">Reenviar</div>
          <div className="block-tools">
            <div className="row">
              {userData && userData.role > 0 ? (
                <div className="col-7">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={(evt) => setType(parseInt(evt.target.value))}
                    >
                      <option value={0}>Mostrar minhas tarefas</option>
                      <option value={1}>Mostrar minha equipe</option>
                      {userData && userData.role > 1 ? (
                        <option value={2}>Mostrar todos</option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-12">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Filtrar"
                      className="form-control"
                      id="search-filter"
                      value={queryBase}
                      onChange={(evt) =>
                        handleQueryChange(evt.target.value) |
                        setQuery(evt.target.value)
                      }
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={() => handleQueryChange("") | setQuery("")}
                        title="Limpar campo"
                      >
                        <i className="si si-close"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-content">
          {loading === true ? (
            <div className="block-content text-center">
              <i className={"fa fa-circle-notch fa-spin"}></i>
            </div>
          ) : (
            ""
          )}
          <table className="table table table-hover table-vcenter font-size-sm">
            <thead>
              <tr>
                <td style={{ width: "5%" }}></td>
                <td style={{ width: "5%" }}>Id</td>
                <td style={{ width: "12.5%" }}>Processo</td>
                <td style={{ width: "12.5%" }}>Órgão</td>
                <td style={{ width: "12.5%" }}>Status</td>
                <td style={{ width: "12.5%" }}>Enviada a pergunta</td>
                <td style={{ width: "9.75%" }}>Situação</td>
                <td style={{ width: "19.5%" }}></td>
              </tr>
            </thead>
            <tbody></tbody>
          </table>

          <div>
            {change &&
              organList &&
              organList.map((item, index) => (
                <div className="block block-rounded">
                  <table className="table table table-hover table-vcenter font-size-sm">
                    <tr>
                      <td style={{ width: "5%" }}>
                        <input
                          className="form-check-input mr-1"
                          type="checkbox"
                          name={item.id}
                          id={`checkbox-${item.id}`}
                          onChange={(evt) => handleSelectedItens(evt, index)}
                          checked={item.checked}
                        />
                      </td>
                      <td style={{ width: "5%" }}>{item.id}</td>
                      <td style={{ width: "12.5%" }}>
                        {item.process && item.process.processName}
                      </td>
                      <td style={{ width: "12.5%" }}>
                        {item.organ && item.organ.name}
                      </td>
                      <td style={{ width: "12.5%" }}>
                        {organHelper.GetCurrentStatus(item)}
                      </td>
                      <td style={{ width: "12.5%" }}>
                        {DateTranslateSimple(item.updatedDate)}
                      </td>
                      <td style={{ width: "9.75%" }}>
                        <span>{processSituation(item)}</span>
                      </td>
                      <td style={{ width: "19.5%" }}>
                        <button
                          className="btn btn-sm btn-dual"
                          onClick={() => openElement(item)}
                        >
                          <i className="far fa-eye" />
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              ))}
          </div>
        </div>
      </div>
      {userData && (userData.level == 4 || userData.level == 10) ? (
        <div className="block block-rounded">
          <div className="block-header">
            <div className="block-title">
              ({delivery && delivery.length}) Devolução por não cumprimento
            </div>
            <div className="block-tools">
              <button
                className="btn btn-sm btn-dual"
                onClick={() => loadExigencies()}
              >
                <i className="si si-refresh"></i>
              </button>
            </div>
          </div>
          <div className="block-content">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Processo</th>
                  <th>Órgão</th>
                  <th>Última atualização</th>
                  <th>Situação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {delivery &&
                  delivery.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.process && item.process.processName}</td>
                      <td>{item.organ && item.organ.name}</td>
                      <td>{DateTranslateSimple(item.updatedDate)}</td>
                      <td>
                        {item.process.status == ProcessStatus.Returned ? (
                          <span className="badge badge-warning">Devolvido</span>
                        ) : (
                          <span className="badge badge-warning">
                            Devolução por não Cump.
                          </span>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-dual"
                          onClick={() =>
                            openElement({
                              ...item,
                              notFullfil: true,
                              exigency: true,
                            })
                          }
                        >
                          <i className="far fa-eye" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
      <ProcessDeferredModal
        show={showModal}
        onHide={() =>
          setShowModal(false) |
          loadSecond() |
          loadExigencies() |
          setSelected({})
        }
        organList={selected}
      />
    </div>
  );
}

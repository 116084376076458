export const SelectedServiceStatus = {
  NotStarted: 0,
  Ongoing: 1,
  Done: 2,
  Waiting: 3,
};

export const SelectedServiceAnalysisStatus = {
  NotStarted: 0,
  Ongoing: 1,
  WaitingExigencyFulfillment: 2,
  Late: 3,
  Done: 4,
  Reavaliation: 5,
  OrganExigency: 6,
  WaitingOperatorConfirmation: 7,
  ReturnToCustomer: 8,
  WaitingProtocol: 9,
};

export const SelectedServiceAnalysisStatusList = [
  { name: "Não iniciado", value: 0 },
  { name: "Em andamento", value: 1 },
  { name: "Aguar. Cumpri. Exigências", value: 2 },
  { name: "Concluído", value: 4 },
  { name: "Reavaliação", value: 5 },
  { name: "Exigência no órgão", value: 6 },
  { name: "Aguar. Confirm. do operador", value: 7 },
  { name: "Retornar ao cliente", value: 8 },
  { name: "Consultando", value: 9 },
];

export const ProcessBudgetStatus = {
  NotStarted: 0,
  WaitingBudget: 1,
  Acepted: 2,
  Refused: 3,
  ReturnToCustomer: 4,
  WaitingApproval: 5,
  Reavaliation: 6,
  Removed: 9,
};

export const ProcessStatus = {
  NotStarted: 0,
  Ongoing: 1,
  Done: 2,
  Late: 3,
  Returned: 4,
  Deleted: 9,
};

export const ServiceGroupType = {
  SingleChoice: 0,
  MultipleChoise: 1,
};

export const ServiceGroupTypeList = [
  { name: "Escolha um", value: 0 },
  { name: "Múltipla escolha", value: 1 },
];

export function ServiceGroupTypeTranslate(i) {
  let res = ServiceGroupTypeList.filter((x) => x.value === i);
  if (res.length > 0) {
    return res[0].name;
  } else {
    return "S.I.";
  }
}

export function HasMoreSteps(level, steps) {
  let response = "Encerrar processo";

  const res = steps.filter((x) => x.department > level);
  if (res.length > 0) {
    if (level == 2) {
      if (res.length == 1 && res[0].department == 4) {
        response = "Encerrar/Enviar ao órgão";
      } else {
        response = "Enviar p/ preparação";
      }
    } else if (level == 3) {
      if (res.length == 1 && res[0].department == 4) {
        response = "Encerrar/Enviar ao órgão";
      }
    }
  } else {
    response = "Encerrar/Enviar ao órgão";
  }
  return response;
}

import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import { ShortcutList } from "../../base/controllers/ShortcutsController";
import { Autocomplete } from "../../util/helpers/AutocompleteHelper";

export default function ShortcutsComponent(props) {
  const [links, setLinks] = useState(null);
  const userData = Storage.getUserData();
  const [filter, setFilter] = useState([]);
  const [question, setQuestion] = useState("");

  useEffect(() => {
    loadLinks();
  }, []);

  function loadLinks() {
    ShortcutList((res) => {
      for (let s in res) {
        res[s].label = `${res[s].name} - ${res[s].url}`;
      }
      setLinks(res);
    });
  }

  function followLink(item) {
    window.open(item.url, "_blank");
  }

  function handleFilterChange(value) {
    setQuestion(value);
    let links_to_show = [];

    if (value.length > 0) {
      setFilter([]);
      links_to_show = Autocomplete(value, links, "label");
      setFilter(links_to_show);
    } else {
      links_to_show = [];
      setFilter([]);
    }
  }

  return (
    <div>
      <div className="content">
        <h2 className="content-heading">
          <i className="fa fa-angle-right text-muted mr-1"></i> Atalhos
        </h2>
        {/* <div className="form-group">
          <label for="department">Departamento</label>
          <select
            className="form-control"
            name="department"
            onChange={(evt) => loadLinks(evt.target.value)}
          >
            <option value={0}>Todos</option>
            <option value={1} selected={userData.level === 1}>
              Orçamento
            </option>
            <option value={2} selected={userData.level === 2}>
              Análise
            </option>
            <option value={3} selected={userData.level === 3}>
              Preparação
            </option>
            <option value={4} selected={userData.level === 4}>
              Devolução órgão
            </option>
            <option value={5} selected={userData.level === 5}>
              Cliente
            </option>
          </select>
        </div> */}
        <div className="form-group py-3">
          <div className="input-group">
            <input
              type="text"
              placeholder="Filtrar"
              className="form-control"
              id="search-filter"
              value={question}
              onChange={(evt) => handleFilterChange(evt.target.value)}
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={() => setQuestion("") | setFilter([])}
                title="Limpar campo"
              >
                <i className="si si-close"></i>
              </span>
            </div>
          </div>
        </div>
        <table className="table table-hover table-bordered">
          <thead>
            <tr>
              <td>Nome</td>
              <td>Link</td>
              <td>Ações</td>
            </tr>
          </thead>
          {filter.length > 0 ? (
            <tbody>
              {filter &&
                filter.map((item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.url && item.url.substr(0, 30)}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-link"
                        title="Abrir"
                        onClick={() => followLink(item)}
                      >
                        <i className="far fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              {links &&
                links.map((item) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.url && item.url.substr(0, 30)}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-link"
                        title="Abrir"
                        onClick={() => followLink(item)}
                      >
                        <i className="far fa-eye"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function UploadContract(customerId, formData, status, response) {
  BaseService(
    {
      method: "POST",
      url: `contract/upload?folder=${customerId}&status=${status}`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function UpdateContract(contractId, formData, status, response) {
  BaseService(
    {
      method: "POST",
      url: `contract/update/upload?fileId=${contractId}&status=${status}`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ContractInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: `contract/insert`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ContractDelete(contractId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `contract/delete/${contractId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function ContractList(customerId, response) {
  await BaseService(
    {
      method: "GET",
      url: `contract/customer/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.log("Erro api", err);
      toast.error(HandleErrors(err));
    },
  );
}

export async function SeeContract(contractKey, response) {
  await BaseService(
    {
      method: "GET",
      url: `contract/consult/${contractKey}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

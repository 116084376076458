import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function CashFlowController() {
  async function Insert(cashflow, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "cashflow/insert",
        data: cashflow,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(cashflow, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "cashflow/update",
        data: cashflow,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(cashFlowId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `cashflow/delete/${cashFlowId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `cashflow/dates/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ServiceReport(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `cashflow/report/services/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerList(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `cashflow/customer/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function InsertEntry(entry, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "cashflow/entries/insert",
        data: entry,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateEntry(entry, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "cashflow/update",
        data: entry,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DeleteEntry(entryId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `cashflow/delete/${entryId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomerEntries(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `cashflow/entries/customer/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    Delete,
    List,
    CustomerList,
    ServiceReport,
    InsertEntry,
    UpdateEntry,
    DeleteEntry,
    CustomerEntries,
  };
}

import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import BaseService from "@/app/core/base/BaseService";
import { cols } from "./ColumnHelper";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import CustomerEdit from "./CustomerEditModal";
import {
  CustomerDelete,
  CustomerList,
} from "@/app/core/base/controllers/CustomerController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { DeleteConfirm, Message } from "@/app/core/util/messages/GeralMessages";
import Functions from "@/app/core/util/Functions";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

function CustomerSearchComponent({ history, search }, props) {
  const [raw, setRaw] = useState([]);
  const [data, setData] = useState([]);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [queries, setQueries] = useState([]);
  const [pages, setPages] = useState(1);
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    limit: 100,
    totalRows: 100,
  });
  const [current, setCurrent] = useState(1);
  const userData = Storage.getUserData();
  const customerHelper = CustomerHelper();
  let page = 1;

  useEffect(() => {
    if (search !== null && search !== undefined) {
      searchCustomer(search);
    } else {
      loadAllCustomers();
    }
  }, [props.search, search]);

  function searchCustomer(info) {
    BaseService(
      {
        method: "GET",
        url: `customer-search?filter=${info}&`,
      },
      (res) => {
        setData(res);
      },
      (err) => {},
    );
  }

  function load() {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 450);
  }

  function loadAllCustomers() {
    CustomerList((res) => {
      for (let s in res) {
        if (res[s].type === 1) {
          res[s].doc = CpfMask(res[s].cpf);
        } else if (res[s].type === 2) {
          res[s].doc = CnpjMask(res[s].cnpj);
        } else {
          res[s].doc = "S.I.";
        }

        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
      }
      let listLength = res.length / 100;
      setPages(parseInt(listLength));
      setRaw(res);
      setFilter(res);
      setData(res);
      // if (res.length < 100) {
      //   setData(res);
      // } else {
      //   setData(res.slice(0, 99));
      // }
    });
  }

  function editCustomer(item) {
    setCustomer(item.data);
    setShowCustomerModal(true);
  }

  function deleteCustomer(item) {
    if (userData.level !== 10) {
      toast.info(
        "Seu nível de acesso não permite a exclusão de um cliente, solicite ao administrador",
      );
    } else {
      if (window.confirm(DeleteConfirm)) {
        CustomerDelete(item.data.id, (res) => {
          toast.success(Message.DeleteSuccess);
          loadAllCustomers();
        });
      }
    }
  }

  function handleChange(value) {
    setQuery(value);
    if (value.length > 0) {
      setFilter([]);
      setFilter(autocomplete(value));
    } else {
      setFilter(raw);
    }
  }

  function autocomplete(value) {
    const inputValue = value.trim().toLowerCase();
    let itens = raw;
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const name =
        item.name !== undefined ? item.name.trim().toLowerCase() : "";
      const customerCode =
        item.customerCode !== undefined ? item.customerCode : "";
      const cpf = item.cpf !== undefined ? item.cpf.toLowerCase() : "";
      const cnpj = item.cnpj !== undefined ? item.cnpj.toLowerCase() : "";
      const phone = item.phone !== undefined ? item.phone : "";
      const email = item.email !== undefined ? item.email : "";
      const status =
        item.status !== undefined
          ? customerHelper.StatusTranslate(item.status).toLowerCase()
          : "";
      const createdDate = item.createdDate;
      if (
        name.includes(inputValue) ||
        customerCode.includes(value) ||
        cpf.includes(inputValue) ||
        phone.includes(inputValue) ||
        email.includes(inputValue) ||
        status.includes(inputValue) ||
        createdDate.includes(inputValue) ||
        cnpj.includes(inputValue)
      ) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            {queries &&
              queries.map((item) => (
                <div>
                  {item}
                  <br />
                  <br />
                  <br />
                </div>
              ))}
          </div>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control form-control-alt"
              placeholder="Pesquisar..."
              onChange={(evt) => handleChange(evt.target.value)}
            />
          </div>
          <Table
            title={filter && filter.length + " Clientes"}
            loading={false}
            data={filter}
            columns={cols}
            limit={100}
            hide={false}
            refreshData={() => loadAllCustomers()}
            insertAction={() => setShowCustomerModal(true)}
            editAction={(info) => editCustomer(info)}
            deleteAction={(info) => deleteCustomer(info)}
            setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
            viewAction={(selected) =>
              window.open(`#/customer-profile/${selected.data.id}`, "_blank")
            }
          />
        </div>
      </div>
      <CustomerEdit
        data={customer}
        show={showCustomerModal}
        onHide={() =>
          setShowCustomerModal(false) | setCustomer({}) | loadAllCustomers()
        }
        submit={(form) => loadAllCustomers() | setCustomer({})}
      />
      <ToastContainer className="toastr" />
    </>
  );
}
export default withRouter(CustomerSearchComponent);

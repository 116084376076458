import { AdministratorTeams } from "@/app/core/base/controllers/TeamsController";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { DepartmentsListHelper } from "@/app/core/util/helpers/DepartmentHelper";
import React, { useEffect, useState } from "react";
import TeamLine from "./TeamLine";

export default function TeamsModal({ show, onHide, level }) {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [department, setDepartment] = useState(0);

  useEffect(() => {
    if (level) {
      setDepartment(level);
    }
  }, [level, show]);

  useEffect(() => {
    if (show) {
      loadTeams();
    }
  }, []);

  useEffect(() => {
    processFilter();
  }, [department, show]);

  function processFilter() {
    let teams = raw.filter((x) => x.department === department);
    setData(teams);
  }

  async function loadTeams() {
    await AdministratorTeams((res) => setRaw(res));
  }

  return (
    <Modal title="Equipes" show={show} onHide={onHide}>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <span>Departamento</span>
            <select
              className="form-control"
              onChange={(evt) => setDepartment(parseInt(evt.target.value))}
            >
              <option>Selecione</option>
              {DepartmentsListHelper.map((item) => (
                <option
                  value={item.value}
                  selected={item.value === department ? true : false}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12">
          {department && data && data.map((item) => <TeamLine team={item} />)}
        </div>
      </div>
    </Modal>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const SenderController = {
  Insert: async function (data, response) {
    await BaseService(
      {
        method: "POST",
        url: "email",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  List: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: "email",
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Delete: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `email/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
};

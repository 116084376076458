import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function ExigencyTemplateInsert(values, response) {
  BaseService(
    {
      method: "POST",
      url: `exigencytemplate/insert`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTemplateUpdate(values, response) {
  BaseService(
    {
      method: "POST",
      url: `exigencytemplate/update`,
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTemplateDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `exigencytemplate/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ExigencyTemplateList(response) {
  BaseService(
    {
      method: "GET",
      url: `exigencytemplate`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

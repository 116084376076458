import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { ToastContainer, toast } from "react-toastify";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CustomerListCard from "./CustomerListCard";
import ReportController from "@/app/core/base/controllers/ReportController";
import { UpdateCustomerStage } from "@/app/core/base/controllers/CustomerController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import OportunityController from "@/app/core/base/controllers/StageController";
import Storage from "@/app/core/util/Storage";

export default function MainComponent(props) {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [oportunitiesRessurgenceList, setOportunitiesRessurgenceList] =
    useState([]);
  const [billingList, setBillingList] = useState([]);
  const [oportunitiesList, setOportunitiesList] = useState([]);
  const [discountPoliticList, setDiscountPoliticList] = useState([]);
  const [reactivedList, setReactivedList] = useState([]);
  const date = new Date();

  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  const cols = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    {
      header: "Email",
      isSortable: true,
      name: "email",
    },
    { header: "Telefone", isSortable: true, name: "phone" },
    { header: "Celular", isSortable: true, name: "whatsapp" },
    { header: "Última compra", isSortable: true, name: "lastBuy" },
  ];

  const colsOportunity = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    { header: "Estágio", isSortable: true, name: "stageName" },
    { header: "Ult. Timeline", isSortable: true, name: "timeline" },
    { header: "Data Ult. Timeline", isSortable: true, name: "timelineDate" },
  ];

  const colsReactved = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    { header: "Ult. Compra", isSortable: true, name: "updatedDate" },
    { header: "Data da compra atual", isSortable: true, name: "lastBuy" },
    { header: "Qtd de compras", isSortable: true, name: "servicesCount" },
  ];

  const colsFaturamento = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    { header: "Faturamento", isSortable: true, name: "debit" },
    { header: "Última compra", isSortable: true, name: "lastBuy" },
  ];

  const colsDiscount = [
    { header: "Cód.", isSortable: true, name: "customerCode" },
    {
      header: "Nome",
      isSortable: true,
      name: "name",
      style: {
        maxWidth: "220px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    // { header: "Descontos", isSortable: true, name: "discount" },
    { header: "Qtd. na política", isSortable: true, name: "servicesCount" },
    { header: "Observações", isSortable: false, name: "observation" },
  ];

  useEffect(() => {
    loadEvaluation();
  }, []);

  async function reloadDiscount() {
    setLoading(true);
    await ReportController().Discount(startDate, endDate, (res) => {
      setDiscountPoliticList(res);
      setLoading(false);
    });
  }

  async function loadEvaluation() {
    OportunityController().Get(userData.id, async (result) => {
      setSelected(result);
      setLoading(true);
      if (
        result.filter((x) => x.stage == CustomerHelper().Stage.Reativado)
          .length > 0
      ) {
        ReportController().Reactivated((res) => {
          for (let s in res) {
            res[s].lastBuy = DateTranslate(res[s].lastBuy);
            // res[s].updatedDate = DateTranslate(res[s].updatedDate);
          }
          setReactivedList(res);
          setLoading(false);
        });
      }
      if (
        result.filter((x) => x.stage == CustomerHelper().Stage.Lead).length > 0
      ) {
        ReportController().Retention((res) => {
          for (let s in res) {
            res[s].lastBuy = DateTranslate(res[s].lastBuy);
          }
          setOportunitiesRessurgenceList(res);
          setLoading(false);
        });
      }
      if (
        result.filter((x) => x.stage == CustomerHelper().Stage.Convertido)
          .length > 0
      ) {
        ReportController().Income(startDate, endDate, (res) => {
          for (let s in res) {
            res[s].lastBuy = DateTranslate(res[s].lastBuy);
            res[s].debit = res[s].debit.toLocaleString();
          }
          setBillingList(res);
          setLoading(false);
        });
      }
      if (
        result.filter((x) => x.stage == CustomerHelper().Stage.Qualificado)
          .length > 0
      ) {
        ReportController().InitialBuy((res) => {
          for (let s in res) {
            res[s].contact = contactLine(res[s]);
            res[s].stageChange = DateTranslate(res[s].createdDate);
            res[s].stageItem = observationLine(res[s].id, res[s]);
            res[s].timeline = createTimeline(res[s]);
            res[s].stageName = CustomerHelper().StageNameTranslate(
              res[s].stage,
            );
            res[s].timelineDate = res[s].lastTimeline
              ? DateTranslate(res[s].lastTimeline.createdDate)
              : "";
            res[s].lastTimelineString = res[s].lastTimeline
              ? res[s].lastTimeline.description
              : "";
          }
          setOportunitiesList(res);
          setLoading(false);
        });
      }
      if (
        result.filter((x) => x.stage == CustomerHelper().Stage.Perdido).length >
        0
      ) {
        ReportController().Discount(startDate, endDate, (res) => {
          setDiscountPoliticList(res);
          setLoading(false);
        });
      }
    });
  }

  function legibleName(name) {
    if (name.length > 70) {
      let newName = name.substr(0, 70) + "...";
      return newName;
    } else {
      return name;
    }
  }

  function createTimeline(customer) {
    if (customer.lastTimeline) {
      return (
        <div>
          {legibleName(customer.lastTimeline.description)}
          <br />
          <small className="text-muted">
            {customer.lastTimeline?.user?.firstName}{" "}
            {customer.lastTimeline?.user?.lastName}
          </small>
        </div>
      );
    } else {
      return "";
    }
  }

  function observationLine(customerId, customer) {
    function updateStage(customerId, stage) {
      UpdateCustomerStage(customerId, stage, (res) => {
        toast.success(Message.Saved);
        loadEvaluation();
      });
    }

    return (
      <div className="form-group d-flex">
        <div
          class="btn-toolbar mb-2"
          role="toolbar"
          aria-label="Outline Primary Toolbar with button groups"
        >
          <div
            class="btn-group mr-2 mb-2"
            role="group"
            aria-label="Outline Primary First group"
          >
            <button
              type="button"
              onClick={() =>
                updateStage(customerId, CustomerHelper().Stage.Lead)
              }
              className={
                customer.stage == CustomerHelper().Stage.Lead
                  ? "btn btn-primary"
                  : "btn btn-outline-primary"
              }
            >
              Lead
            </button>
            <button
              type="button"
              onClick={() =>
                updateStage(customerId, CustomerHelper().Stage.Qualificado)
              }
              className={
                customer.stage == CustomerHelper().Stage.Qualificado
                  ? "btn btn-info"
                  : "btn btn-outline-primary"
              }
            >
              Qualificado
            </button>
            <button
              type="button"
              onClick={() =>
                updateStage(customerId, CustomerHelper().Stage.Convertido)
              }
              className={
                customer.stage == CustomerHelper().Stage.Convertido
                  ? "btn btn-success"
                  : "btn btn-outline-primary"
              }
            >
              Convertido
            </button>
            <button
              type="button"
              onClick={() =>
                updateStage(customerId, CustomerHelper().Stage.Perdido)
              }
              className={
                customer.stage == CustomerHelper().Stage.Perdido
                  ? "btn btn-danger"
                  : "btn btn-outline-primary"
              }
            >
              Perdido
            </button>
          </div>
        </div>
      </div>
    );
  }

  function contactLine(customer) {
    function updateStage() {
      UpdateCustomerStage(customer.id, customer.stage, (res) => {
        toast.success(Message.Saved);
        loadEvaluation();
      });
    }

    return (
      <div className="form-group d-flex">
        <div
          class="btn-toolbar mb-2"
          role="toolbar"
          aria-label="Outline Primary Toolbar with button groups"
        >
          <div
            class="btn-group mr-2 mb-2"
            role="group"
            aria-label="Outline Primary First group"
          >
            <button
              type="button"
              onClick={() => updateStage()}
              className={"btn btn-outline-primary"}
            >
              Contatado
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <Loading loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <div className="content-heading ml-3">
            <h2>Oportunidades</h2>
          </div>
        </div>
        <div className="form-group col-md-2">
          <span>Data de início</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={startDate}
            onChange={(evt) => setStartDate(evt.target.value)}
          />
        </div>
        <div className="form-group col-md-2">
          <span>Data fim</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={endDate}
            onChange={(evt) => setEndDate(evt.target.value)}
          />
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-info mt-3"
            onClick={() => loadEvaluation()}
          >
            Pesquisar
          </button>
        </div>
        <div className="col-md-12">
          {selected &&
            selected.map((item) => {
              console.log("Lista", item);
              if (item.stage == CustomerHelper().Stage.Lead) {
                return (
                  <CustomerListCard
                    title={"Retenção"}
                    infos={oportunitiesRessurgenceList}
                    cols={cols}
                  />
                );
              }
              if (item.stage == CustomerHelper().Stage.Qualificado) {
                return (
                  <CustomerListCard
                    title={"Compra inicial"}
                    infos={oportunitiesList}
                    cols={colsOportunity}
                    hasFilter={true}
                  />
                );
              }
              if (item.stage == CustomerHelper().Stage.Reativado) {
                return (
                  <CustomerListCard
                    title={"Reativados"}
                    infos={reactivedList}
                    cols={colsReactved}
                    hasFilter={true}
                  />
                );
              }
              if (item.stage == CustomerHelper().Stage.Convertido) {
                return (
                  <CustomerListCard
                    title={"Faturamento"}
                    infos={billingList}
                    cols={colsFaturamento}
                  />
                );
              }
              if (item.stage == CustomerHelper().Stage.Perdido) {
                return (
                  <CustomerListCard
                    title={"Política de desconto"}
                    infos={discountPoliticList}
                    cols={colsDiscount}
                    reload={() => reloadDiscount()}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper, {
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";
import { Message } from "@/app/core/util/messages/GeralMessages";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import TimelineItem from "../timeline/TimelineItem";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import HelpModal from "@/app/core/layouts/main-components/feedback/ReportIssueModal";
import HistoricController from "@/app/core/base/controllers/HistoricController";
import OrganListHistoric from "../organs/OrganListHistoric";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import FollowController from "@/app/core/base/controllers/FollowController";

export default function StatusTab({
  view,
  processId,
  disabled,
  organListId,
  reload,
}) {
  const user = Storage.getUserData();
  const [data, setData] = useState([]);
  const [deferreds, setDeferred] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [process, setProcess] = useState({});

  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const historicCtrl = HistoricController();

  useEffect(() => {
    if (processId) {
      loadProcess();
    }
  }, [processId, disabled, organListId]);

  async function loadProcess() {
    setLoading(true);
    await organCtrl.ListStatusTab(processId, async (list) => {
      let infos = [];
      let results = [];
      let showAll = false;
      let budget = [];
      let historic = [];
      setProcess(list[0].process);
      for (let s in list) {
        historic = [
          ...historic,
          ...list[s].history.filter((x) => x.level != 1),
        ];
        budget = list[s].history.filter((x) => x.level == 1);
      }

      historic = [...budget, ...historic];
      // await historicCtrl.List(processId, (historic) => {
      if (list.length > 0) {
        if (list[0].process.status == ProcessStatus.Done) {
          showAll = true;
        }
      }
      for (let y in historic) {
        historic[y].time = new Date(historic[y].createdDate).getTime();
      }

      const historicDone = historic.sort(function (a, b) {
        return a.time - b.time;
      });

      const res = list.filter((x) => x.stage != 1 && x.stage != 10);

      let oficial = [];
      for (let s in res) {
        let levels = [
          { name: "Orçamento", department: 1, data: [] },
          { name: "Análise", department: 2, data: [] },
          { name: "Preparação", department: 3, data: [] },
          { name: "Devolução", department: 4, data: [] },
        ];
        let organ = res[s];

        levels[0].data = historicDone.filter((x) => x.level == 1);
        levels[0].data = levels[0].data.filter(function (value, index, array) {
          return array.indexOf(value) === index;
        });
        levels[1].data = historicDone.filter(
          (x) => x.level == 2 && x.organListId == organ.id,
        );
        levels[2].data = historicDone.filter(
          (x) => x.level == 3 && x.organListId == organ.id,
        );
        levels[3].data = historicDone.filter(
          (x) => x.level == 4 && x.organListId == organ.id,
        );

        const historicOrgan = historicDone.filter(
          (x) => x.organListId == organ.id,
        );
        oficial.push(levels[0]);

        let go = {};
        let timeline = [];

        if (organ.stage != 1) {
          timeline = organ.timeline.filter((x) => x.bookmark == true);

          if (res[s].status != OrganStatus.Done && showAll == false) {
            results.push({ ...organ, timeline, historic: levels });
          } else if (showAll) {
            results.push({ ...organ, timeline, historic: levels });
          }
        }
      }

      setData(results.filter((x) => x.status != OrganStatus.Deferred));
      setDeferred(
        results.filter(
          (x) =>
            x.status == OrganStatus.Deferred ||
            x.status == OrganStatus.Done ||
            x.status == OrganStatus.Returned,
        ),
      );
      // })

      if (res[0]) {
        loadCustomerFollowers(res[0].process.customerId);
      }
      setLoading(false);
    });
  }

  function loadCustomerFollowers(customerId) {
    FollowController().FollowingCustomer(customerId, (res) =>
      setFollowers(res),
    );
  }

  return (
    <div className="block">
      {loading === true ? (
        <div className="block-content">
          <div className="text-center py-5">
            <i className="fa fa-2x fa-circle-notch fa-spin text-success" />
          </div>
        </div>
      ) : (
        <div>
          {data &&
            data.map((item) => (
              <div
                className={`block block-rounded block-bordered border-success ml-2 mr-2 border-2x ribbon ribbon-left js-appear-enabled animated fadeInDown ribbon-bookmark ribbon-${organHelper.GetCurrentStatus(item, false, null, true)}`}
              >
                <div className="block-content">
                  <div className="form-group">
                    <span>Registro atual:</span>
                    <input
                      type="text"
                      className="form-control"
                      value={item.organ && item.organ.name}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group">
                    <span>Status:</span>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        item &&
                        `${organHelper.GetCurrentStatus(item, true)} - ${DepartmentTranslate(item.stage)}`
                      }
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="block-content mt-0 mb-0">
                  {/* Timeline Notes */}
                  {item &&
                    item.timeline &&
                    item.timeline.map((timeline) => (
                      <TimelineItem
                        preview={true}
                        data={timeline}
                        view={(url) => view(url)}
                        refresh={() => loadProcess()}
                      />
                    ))}
                </div>
                <div className="block-content mt-0">
                  <div className="block-title text-center font-w700 mb-3">
                    Acompanhe conosco o passo a passo{" "}
                    <i className="ml-2 si si-emoticon-smile" />
                  </div>
                  {/* History */}
                  {item &&
                    item.historic &&
                    item.historic.map((history) => {
                      if (history.data && history.data.length > 0) {
                        return (
                          <OrganListHistoric data={history} organList={item} />
                        );
                      }
                    })}
                </div>
                {followers &&
                followers.length == 0 &&
                process &&
                process.status != ProcessStatus.Done ? (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-sm btn-success ml-2"
                      onClick={() => setShowHelpModal(true)}
                    >
                      <i className="si si-question"></i> Suporte
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {followers && followers.length > 0 ? (
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-sm btn-success ml-2"
                      onClick={() => setShowHelpModal(true)}
                    >
                      <i className="si si-user-following"></i> Falar com meu
                      gerente
                      {/* Falar com meu gerente */}
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {user && user.level === 5 ? (
                  <HelpModal
                    show={showHelpModal}
                    followers={followers}
                    onHide={() => setShowHelpModal(false)}
                    processId={item.processId}
                    organListId={item.id}
                    processName={item.process.processName}
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
          {deferreds && deferreds.length > 0 ? (
            <div className="block block-fx-pop mt-3">
              <div className="block-header">
                <div className="block-title">Histórico</div>
                <div className="block-tools">
                  <button
                    className="btn btn-dual"
                    onClick={() => setOpen(!open)}
                  >
                    <i
                      className={`si si-arrow-${open === true ? "up" : "down"} mr-1`}
                    ></i>
                  </button>
                </div>
              </div>
              {open == true ? (
                <div className="block-content">
                  {deferreds &&
                    deferreds.map((item) => (
                      <div
                        className={`block block-rounded block-bordered border-success ml-2 mr-2 border-2x ribbon ribbon-left ribbon-bookmark ribbon-${organHelper.GetCurrentStatus(item, false, null, true)}`}
                      >
                        <div className="block-content">
                          <div className="form-group">
                            <span>Registro:</span>
                            <input
                              type="text"
                              className="form-control"
                              value={item.organ && item.organ.name}
                              disabled={true}
                            />
                          </div>
                          <div className="form-group">
                            <span>Status:</span>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                item && organHelper.GetCurrentStatus(item, true)
                              }
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="block-content mt-0 mb-0">
                          {/* Timeline Notes */}
                          {item &&
                            item.timeline &&
                            item.timeline.map((timeline) => (
                              <TimelineItem
                                preview={true}
                                data={timeline}
                                view={(url) => view(url)}
                              />
                            ))}
                        </div>
                        <div className="block-content mt-0">
                          {/* History */}
                          {item &&
                            item.historic &&
                            item.historic.map((history) => {
                              if (history.data && history.data.length > 0) {
                                return (
                                  <OrganListHistoric
                                    data={history}
                                    organList={item}
                                  />
                                );
                              }
                            })}
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

import React from "react";
import { SelectedServiceAnalysisStatusList } from "./SelectedServiceHelper";
import Storage from "@/app/core/util/Storage";
import { PreparationStep } from "./UserHelper";

export const OrganStatus = {
  NotStarted: 0,
  Filed: 1,
  Devolution: 2,
  Exigency: 3,
  Deferred: 4,
  Ongoing: 5,
  Done: 6,
  Deleted: 7,
  Returned: 8,
  ToBeReturned: 9,
};

export const OrganStatusRaw = [
  { name: "Aguardando", value: 0 },
  { name: "Protocolado", value: 1 },
  { name: "Devolução do órgão", value: 2 },
  { name: "Retornado com Exigência", value: 3 },
  { name: "Deferido", value: 4 },
  { name: "Em andamento", value: 5 },
  { name: "Concluído", value: 6 },
  { name: "Removido", value: 7 },
  { name: "Devolvido", value: 8 },
  { name: "Devolver por não cumprimento", value: 9 },
];

export const OrganStatusList = [
  { name: "A Protocolar", value: 0, visible: true, color: "primary" },
  { name: "Protocolado", value: 1, visible: true, color: "info" },
  {
    name: "Aguardando Devolução do órgão",
    value: 2,
    visible: true,
    color: "secondary",
  },
  { name: "Retornado com Exigência", value: 3, visible: true, color: "danger" },
  {
    name: "Deferido",
    value: 4,
    visible: true,
    color: "success",
    icon: "si si-check",
  },
  {
    name: "Em andamento",
    value: 5,
    visible: true,
    color: "primary",
  },
  {
    name: "Concluído",
    value: 6,
    visible: false,
    color: "success",
    icon: "si si-check",
  },
];

export function OrganStatusTranslate(i) {
  let res = OrganStatusRaw.filter((x) => x.value === i);
  if (res.length > 0) {
    return res[0].name;
  } else {
    return "S.I.";
  }
}

export function OrganStatusTranslateCustomer(i, devolutionStatus) {
  if (
    devolutionStatus ===
    OrganListHelper().DevolutionStatus.WaitingExigencyFulfillment
  ) {
    return (
      <span className="badge badge-warning">Aguar. Cump. de exigências</span>
    );
  } else if (i < 4 || i === 5) {
    return <span className="badge badge-primary">Em andamento</span>;
  } else {
    return <span className="badge badge-info">Deferido</span>;
  }
}

export function OrganStatusUserTranslate(i) {
  let res = OrganStatusList.filter((x) => x.value === i);
  if (res.length > 0) {
    return res[0].name;
  } else {
    return "S.I.";
  }
}

export function GetCurrentOperatorName(organList) {
  let user = null;
  if (organList && organList.stage === 2) {
    if (organList.analyst !== undefined) {
      user = `${organList.analyst.firstName} ${organList.analyst.lastName}`;
    } else {
      user = "S.I.";
    }
  } else if (organList && organList.stage === 3) {
    if (organList.preparer !== undefined) {
      user = `${organList.preparer.firstName} ${organList.preparer.lastName}`;
    } else {
      user = "S.I.";
    }
  } else if (organList && organList.stage === 4) {
    if (organList.status === OrganStatus.NotStarted) {
      if (organList.devolutionNotStarted !== undefined) {
        user = `${organList.devolutionNotStarted.firstName} ${organList.devolutionNotStarted.lastName}`;
      } else {
        user = "S.I.";
      }
    }

    if (organList.status === OrganStatus.Filed) {
      if (organList.devolutionFiled !== undefined) {
        user = `${organList.devolutionFiled.firstName} ${organList.devolutionFiled.lastName}`;
      } else {
        user = "S.I.";
      }
    }

    if (organList.status === OrganStatus.Devolution) {
      if (organList.devolutionDevolution !== undefined) {
        user = `${organList.devolutionDevolution.firstName} ${organList.devolutionDevolution.lastName}`;
      } else {
        user = "S.I.";
      }
    }

    if (organList.status === OrganStatus.Exigency) {
      if (organList.devolutionExigency !== undefined) {
        user = `${organList.devolutionExigency.firstName} ${organList.devolutionExigency.lastName}`;
      } else {
        user = "S.I.";
      }
    }

    if (organList.status === OrganStatus.Deferred) {
      if (organList.devolutionDeferred !== undefined) {
        user = `${organList.devolutionDeferred.firstName} ${organList.devolutionDeferred.lastName}`;
      } else {
        user = "S.I.";
      }
    }
  }

  return user;
}

export function GetCurrentOperator(organList) {
  let user = null;
  if (organList && organList.stage === 2) {
    user = organList.analystId;
  } else if (organList && organList.stage === 3) {
    user = organList.preparerId;
  } else if (organList && organList.stage === 4) {
    if (organList.status === OrganStatus.NotStarted) {
      user = organList.devolutionNotStartedId;
    }

    if (organList.status === OrganStatus.Filed) {
      user = organList.devolutionFiledId;
    }

    if (organList.status === OrganStatus.Devolution) {
      user = organList.devolutionDevolutionId;
    }

    if (organList.status === OrganStatus.Exigency) {
      user = organList.devolutionExigencyId;
    }

    if (organList.status === OrganStatus.Deferred) {
      user = organList.devolutionDeferredId;
    }

    if (organList.status === OrganStatus.Ongoing) {
      user = organList.devolutionNotStartedId;
    }
  }

  return user;
}

export function GetCurrentOperatoData(organList) {
  let user = {};
  if (organList && organList.stage === 2) {
    user = organList.analyst;
  } else if (organList && organList.stage === 3) {
    if (organList.preparationStage == PreparationStep.Track) {
      user = organList.preparer;
    } else {
      user = organList.preparerReview;
    }
  } else if (organList && organList.stage === 4) {
    if (organList.status === OrganStatus.NotStarted) {
      user = organList.devolutionNotStarted;
    }

    if (organList.status === OrganStatus.Filed) {
      user = organList.devolutionFiled;
    }

    if (organList.status === OrganStatus.Devolution) {
      user = organList.devolutionDevolution;
    }

    if (organList.status === OrganStatus.Exigency) {
      user = organList.devolutionExigency;
    }

    if (organList.status === OrganStatus.Deferred) {
      user = organList.devolutionDeferred;
    }

    if (organList.status === OrganStatus.Ongoing) {
      user = organList.devolutionNotStarted;
    }
  }

  return user;
}

export function GetCurrentStatus(organList) {
  const userData = Storage.getUserData();
  let status = null;
  if (organList && organList.status === OrganStatus.ToBeReturned) {
    if (organList.docMasters && organList.docMasters.length > 0) {
      status = "Deferido";
    } else {
      status = "Devolver por não cump.";
    }
  } else if (organList && organList.stage === 2) {
    let res = SelectedServiceAnalysisStatusList.filter(
      (x) => x.value === organList.statusAnalysis,
    );
    status = res[0].name === "Não iniciado" ? "Aguardando" : res[0].name;
  } else if (organList && organList.stage === 3) {
    let res = SelectedServiceAnalysisStatusList.filter(
      (x) => x.value === organList.statusPreparation,
    );
    if (
      userData &&
      userData.level === 2 &&
      userData.id === organList.analystId
    ) {
      status = "Em andamento";
    } else {
      status = res[0].name === "Não iniciado" ? "Aguardando" : res[0].name;
    }
  } else if (organList && organList.stage === 4) {
    status = OrganStatusUserTranslate(organList.status);
  } else if (organList && organList.stage === 10) {
    if (organList && organList.status === OrganStatus.Returned) {
      if (organList.docMasters && organList.docMasters.length > 0) {
        status = "Deferido";
      } else {
        status = "Devolvido";
      }
    } else {
      status = "Deferido";
    }
  } else {
    status = "Não iniciado";
  }

  return status;
}

export function GetCustomerCurrentStatus(organList) {
  let status = "";
  if (
    organList &&
    (organList.stage === 2 || organList.stage === 3 || organList.stage === 1)
  ) {
    if (
      organList.statusAnalysis ===
      OrganListHelper().AnalysisStatus.WaitingExigencyFulfillment
    ) {
      status = "exigencias";
    } else if (
      organList.statusPreparation ===
      OrganListHelper().AnalysisStatus.WaitingExigencyFulfillment
    ) {
      status = "exigencias";
    } else {
      status = "andamento";
    }
  } else if (organList && organList.stage === 4) {
    if (
      organList.statusDevolution ===
      OrganListHelper().DevolutionStatus.WaitingExigencyFulfillment
    ) {
      status = "exigencias";
    } else {
      status = OrganStatusUserTranslate(organList.status);
    }
  } else if (
    organList &&
    (organList.stage === 10 || organList.stage === OrganStatus.Deferred)
  ) {
    status = "Deferido";
  } else {
    status = "andamento";
  }

  return status;
}

export function GetCurrentButton(organList) {
  const userData = Storage.getUserData();
  let status = null;
  if (organList && organList.stage === 2) {
    let res = SelectedServiceAnalysisStatusList.filter(
      (x) => x.value === organList.statusAnalysis,
    );
    return <i className="fa fa-stop text-danger" />;
  } else if (organList && organList.stage === 3) {
    let res = SelectedServiceAnalysisStatusList.filter(
      (x) => x.value === organList.statusPreparation,
    );
    if (
      userData &&
      userData.level === 2 &&
      userData.id === organList.analystId
    ) {
      return <i className="fa fa-stop text-danger" />;
    } else {
      return <i className="fa fa-stop text-danger" />;
    }
  } else if (
    (organList && organList.status === OrganStatus.Done) ||
    (organList && organList.status === OrganStatus.Deferred)
  ) {
    return <i className="far fa-check-circle text-success" />;
  } else {
    return <i className="fa fa-play text-success" />;
  }
}

export default function OrganListHelper() {
  const AnalysisStatus = {
    NotStarted: 0,
    Ongoing: 1,
    WaitingExigencyFulfillment: 2,
    Late: 3,
    Done: 4,
    Reavaliation: 5,
    OrganExigency: 6,
    WaitingOperatorConfirmation: 7,
    ReturnToCustomer: 8,
    WaitingProtocol: 9,
  };

  const AnalysisStatusList = [
    { name: "Não iniciado", value: 0, color: "primary" },
    { name: "Em andamento", value: 1, color: "primary" },
    { name: "Aguar. Cumpri. Exigências", value: 2, color: "info" },
    { name: "Concluído", value: 4, color: "success" },
    { name: "Reavaliação", value: 5, color: "danger" },
    { name: "Exigência no órgão", value: 6, color: "danger" },
    { name: "Aguar. Confirm. do operador", value: 7, color: "warning" },
    { name: "Retornar ao cliente", value: 8, color: "warning" },
    { name: "Consultando", value: 9, color: "secondary" },
  ];

  const DevolutionStatusList = [
    { name: "A Protocolar", value: 0, color: "primary" },
    { name: "Protocolado", value: 1, color: "info" },
    {
      name: "Aguardando Devolução do órgão",
      value: 2,
      color: "secondary",
    },
    { name: "Retornado com Exigência", value: 3, color: "danger" },
    {
      name: "Deferido",
      value: 4,
      color: "success",
      icon: "si si-check",
    },
    {
      name: "Concluído",
      value: 6,
      color: "success",
      icon: "si si-check",
    },
    { name: "Aguar. Cumpri. Exigências", value: 7, color: "info" },
    { name: "Aguar. Confirm. do operador", value: 8, color: "warning" },
    { name: "Retornar ao cliente", value: 9, color: "warning" },
  ];

  const DevolutionStatus = {
    NotStarted: 0,
    Filed: 1,
    Devolution: 2,
    Exigency: 3,
    Deferred: 4,
    Done: 6,
    WaitingExigencyFulfillment: 7,
    WaitingOperatorConfirmation: 8,
    ReturnToCustomer: 9,
  };

  /* Colunas */
  const AnalysisCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Serviço", isSortable: true, name: "serviceName" },
    { header: "Órgão", isSortable: true, name: "organName" },

    {
      header: "Status",
      isSortable: true,
      name: "statusAnalysis",
      switch: {
        0: <span class="badge badge-primary">Não iniciados</span>,
        1: <span class="badge badge-primary">Em andamento</span>,
        2: <span class="badge badge-info">Aguar. Cump. de exigências</span>,
        3: <span class="badge badge-danger">Atrasado</span>,
        4: <span class="badge badge-success">Concluído</span>,
        5: <span class="badge badge-danger">Reavaliação </span>,
        7: (
          <span class="badge badge-warning">
            Aguar. confirmação do operador
          </span>
        ),
        8: <span class="badge badge-warning">Retornar ao cliente</span>,
        9: <span class="badge badge-secondary">Consultando</span>,
      },
    },
    {
      header: "Obs",
      isSortable: false,
      name: "observations",
      // switch: {
      //   false: "",
      //   true: <span class="badge badge-danger">Retornado com exigência</span>,
      // },
    },
    { header: "Data de início", isSortable: true, name: "updatedDate" },
  ];

  const PreparationCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Serviço", isSortable: true, name: "serviceName" },
    { header: "Órgão", isSortable: true, name: "organName" },

    {
      header: "Status",
      isSortable: true,
      name: "statusPreparation",
      switch: {
        0: <span class="badge badge-primary">Não iniciado</span>,
        1: <span class="badge badge-primary">Em andamento</span>,
        2: <span class="badge badge-info">Aguar. Cump. de exigências</span>,
        3: <span class="badge badge-danger">Atrasado</span>,
        4: <span class="badge badge-success">Concluído</span>,
        5: <span class="badge badge-danger">Reavaliação </span>,
        7: (
          <span class="badge badge-warning">
            Aguar. confirmação do operador
          </span>
        ),
        8: <span class="badge badge-warning">Retornar ao cliente</span>,
        9: <span class="badge badge-secondary">Consultando</span>,
      },
    },
    {
      header: "Obs",
      isSortable: false,
      name: "observations",
    },
    { header: "Última atualização", isSortable: true, name: "updatedDate" },
  ];

  const DevolutionCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    { header: "Protocolo", isSortable: true, name: "protocol" },
    {
      header: "Obs",
      isSortable: false,
      name: "observations",
    },
    { header: "Data de início", isSortable: true, name: "updatedDate" },
  ];

  const ExigencyReturnCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Serviço", isSortable: true, name: "serviceName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    {
      header: "Departamento Responsável",
      isSortable: true,
      name: "department",
    },
    {
      header: "Erro interno",
      isSortable: true,
      name: "isInternalReason",
      switch: {
        true: "Sim",
        false: "Não",
      },
    },
    {
      header: "Obs",
      isSortable: false,
      name: "observations",
    },
    { header: "Data", isSortable: true, name: "updatedDate" },
  ];

  const DoneCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Serviço", isSortable: true, name: "serviceName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    { header: "Análise concluída em", isSortable: true, name: "createdDate" },
    { header: "Concluído em", isSortable: true, name: "endDevolutionDate" },
  ];

  const ReviewCols = [
    // { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    { header: "Nota", isSortable: true, name: "answer" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  const ReviewBudgetCols = [
    // { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Comentário", isSortable: true, name: "observation" },
    { header: "Solicitado em", isSortable: true, name: "createdDate" },
    { header: "Recusado em", isSortable: true, name: "endBudgetDate" },
  ];

  const DeferredReturnCols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Serviço", isSortable: true, name: "serviceName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    { header: "Data de início", isSortable: true, name: "analysisDate" },
    {
      header: "Data de deferimento",
      isSortable: true,
      name: "endDevolutionDate",
    },
    {
      header: "Investidas",
      isSortable: true,
      name: "sends",
    },
  ];
  /* Fim Colunas */

  const OrganCols = [
    { header: "Id", isSortable: true, name: "id" },
    { header: "Nome", isSortable: true, name: "name" },
    { header: "Descricao", isSortable: true, name: "description" },
    { header: "Data de criação", isSortable: true, name: "createdDate" },
  ];

  const OrganNpsCols = [
    { header: "Órgão", isSortable: true, name: "name" },
    { header: "Qtd de avaliações", isSortable: true, name: "qtd" },
    { header: "NPS média", isSortable: true, name: "value" },
  ];

  const ProcessWithoutReviewCols = [
    { header: "Processo", isSortable: true, name: "processName" },
    { header: "Órgão", isSortable: true, name: "organName" },
    {
      header: "Data de deferimento",
      isSortable: true,
      name: "endDevolutionDate",
    },
  ];

  const ReavaliationType = [
    { name: "Erro", value: 1 },
    { name: "Solicitação do cliente", value: 2 },
  ];

  function ReavaliationTypeTranslate(i) {
    const res = ReavaliationType.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function GetCurrentStatus(organList, justName, service, justColor) {
    const userData = Storage.getUserData();
    let status = { name: "S.I.", color: "secondary", icon: "si si-close" };
    if (organList && organList.status === OrganStatus.ToBeReturned) {
      if (organList.docMasters && organList.docMasters.length > 0) {
        status = { name: "Deferido", color: "success", icon: "si si-check" };
      } else {
        status = {
          name: "Devolver por não cump.",
          color: "warning",
          icon: "si si-clock",
        };
      }
    } else if (organList && organList.stage === 2) {
      let res = AnalysisStatusList.filter(
        (x) => x.value === organList.statusAnalysis,
      );
      status = res[0];
    } else if (organList && organList.stage === 3) {
      let res = AnalysisStatusList.filter(
        (x) => x.value === organList.statusPreparation,
      );
      status = res[0];
    } else if (
      organList &&
      organList.stage === 4 &&
      organList.status === OrganStatus.Exigency
    ) {
      let res = DevolutionStatusList.filter(
        (x) => x.value === organList.statusDevolution,
      );
      status = res[0];
    } else if (organList && organList.stage === 10) {
      status = { name: "Deferido", color: "success", icon: "si si-check" };
    } else if (
      organList &&
      organList.stage === 4 &&
      organList.status !== OrganStatus.Exigency
    ) {
      let res = OrganStatusList.filter((x) => x.value === organList.status);
      status = res[0];
    } else {
      status = { name: "S.I.", color: "secondary", icon: "si si-close" };
    }

    if (userData.level === 5) {
      if (
        organList.status === OrganStatus.Done &&
        service != undefined &&
        service.steps != undefined
      ) {
        status = { name: "Concluído", color: "success", icon: "si si-check" };
      } else if (
        organList.status === OrganStatus.Deferred ||
        organList.status === OrganStatus.Done
      ) {
        status = { name: "Deferido", color: "success", icon: "si si-check" };
      } else {
        if (
          (organList.statusAnalysis ===
            AnalysisStatus.WaitingExigencyFulfillment &&
            organList.stage == 2) ||
          (organList.statusPreparation ===
            AnalysisStatus.WaitingExigencyFulfillment &&
            organList.stage == 3) ||
          (organList.statusDevolution ===
            DevolutionStatus.WaitingExigencyFulfillment &&
            organList.status == OrganStatus.Exigency)
        ) {
          status = {
            name: "Aguar. Cump. de exigências",
            color: "warning",
            icon: "",
          };
        } else if (
          organList.status === OrganStatus.NotStarted &&
          organList.stage === 1
        ) {
          status = { name: "Aguardando", color: "info", icon: "" };
        } else {
          if (organList && organList.status === OrganStatus.Returned) {
            if (organList.docMasters && organList.docMasters.length > 0) {
              status = {
                name: "Deferido",
                color: "success",
                icon: "si si-check",
              };
            } else {
              status = { name: "Devolvido", color: "danger", icon: "" };
            }
          } else if (
            organList &&
            organList.status === OrganStatus.ToBeReturned
          ) {
            status = {
              name: "Devolver por não cump.",
              color: "warning",
              icon: "si si-clock",
            };
          } else {
            status = { name: "Em andamento", color: "primary", icon: "" };
          }
        }
      }
    }

    if (justName !== undefined && justName === true) {
      return status.name;
    } else if (justColor != undefined && justColor == true) {
      return status.color;
    } else {
      return (
        <span className={"badge badge-" + status.color}>
          <i
            className={
              status !== undefined && status.icon !== undefined
                ? status.icon
                : ""
            }
          />{" "}
          {status !== undefined && status.name}
        </span>
      );
    }
  }

  const OrganListType = {
    Both: 0,
    Phisical: 1,
    Digital: 2,
  };

  const OrganListTypeList = [
    { name: "Ambos", value: 0 },
    { name: "Físico", value: 1 },
    { name: "Digital", value: 2 },
  ];

  function OrganListTypeTranslate(i) {
    const res = OrganListTypeList.filter((x) => x.value == i);
    if (res.length > 0) return res[0].name;
    else return "S.I.";
  }

  return {
    AnalysisStatus,
    AnalysisStatusList,
    AnalysisCols,
    PreparationCols,
    OrganCols,
    GetCurrentStatus,
    DevolutionStatusList,
    DevolutionStatus,
    DevolutionCols,
    ExigencyReturnCols,
    DeferredReturnCols,
    OrganNpsCols,
    ReavaliationType,
    ReavaliationTypeTranslate,
    DoneCols,
    ReviewCols,
    ReviewBudgetCols,
    OrganListType,
    OrganListTypeList,
    OrganListTypeTranslate,
    ProcessWithoutReviewCols,
  };
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "../../util/services/DataService";
import { Message } from "../../util/messages/GeralMessages";
import { SendEmailSupport } from "../../base/controllers/ContactController";
import ProcessController from "../../base/controllers/ProcessController";
import OrganListController from "../../base/controllers/OrganListController";
import { CustomerSelect } from "../../base/controllers/CustomerController";

export default function HelpModal({
  show,
  onHide,
  submit,
  isLoading,
  report,
  processId,
  processName,
  organListId,
}) {
  const [data, setData] = useState({});
  const userData = Storage.getUserData();
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();

  useEffect(() => {
    if (show) {
      loadData();
    }
  }, [organListId, show]);

  async function loadData() {
    let organList = {};
    let customerId = "";
    if (organListId !== undefined && organListId !== null) {
      await organCtrl.SelectById(organListId, (res) => (organList = res));
    }
    // let base = {};
    // if(processId != undefined){
    //   processCtrl.Select(processId, res => {
    //     base = {...res};
    //   })
    // }

    if (organListId != undefined && organListId != null) {
      await organCtrl.SelectById(organListId, (res) => {
        organList = res;
        customerId = res.process.customer.customerCode;
      });
    } else if (userData !== null && userData.level === 5) {
      await CustomerSelect(
        userData.customerId,
        (res) => (customerId = res.customerCode),
      );
    }

    setData({
      name: `${userData !== null ? userData.firstName : ""} ${
        userData !== null ? userData.lastName : ""
      }`,
      email: userData !== null ? userData.email : "",
      phone: userData !== null ? userData.phone : "",
      customerId: customerId,
      organList,
    });
  }

  async function save() {
    if (
      data.name !== undefined &&
      data.name !== "" &&
      data.email !== undefined &&
      data.email !== "" &&
      data.phone !== undefined &&
      data.phone !== "" &&
      data.description !== undefined &&
      data.description !== ""
    ) {
      SendEmailSupport({
        ...data,
        processId: processId,
        processName: processName,
      });
      if (report !== undefined) {
        report(
          `Responsável : ${data.name} Email : ${data.email} Telefone : ${data.phone} Descrição da dúvida : ${data.description}`,
        );
      }
      setTimeout(() => {
        toast.success(
          "Sua solicitação de ajuda foi enviada com sucesso, em breve retornaremos",
        );
        onHide();
      }, 500);
    } else {
      toast.error(Message.PendingData);
    }
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  return (
    <Modal
      title="Ajuda"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Nome</span>
        <input
          className="form-control"
          name="name"
          value={data.name}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>E-mail</span>
        <input
          className="form-control"
          name="email"
          value={data.email}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Cód. de cliente</span>
        <input
          className="form-control"
          name="customerId"
          value={data.customerId}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Telefone para contato</span>
        <input
          className="form-control"
          name="phone"
          value={data.phone}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Descreva o motivo do contato</span>
        <textarea
          className="form-control"
          name="description"
          value={data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}

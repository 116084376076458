import React, { useState } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { EvaluationInsert } from "@/app/core/base/controllers/EvaluationController";

export default function EvaluationModal(props) {
  const { organListId, processId, show, onHide, submit, isLoading } = props;
  const [data, setData] = useState({});
  const [saved, setSaved] = useState(false);

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function save(i) {
    if (i == undefined || i == NaN) {
      toast.error(Message.PendingReview);
    } else {
      let infos = data;
      if (organListId != undefined) {
        infos.organListId = parseInt(organListId);
      }
      if (processId != undefined) {
        infos.processId = parseInt(processId);
      }
      EvaluationInsert({ ...infos, answer: i }, (res) => {
        setSaved(true);
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  function close() {
    if (saved != true) {
      toast.success("Responda a pesquisa para continuar");
    } else {
      onHide();
    }
  }

  function answer(response) {
    save(response);
  }

  return (
    <Modal
      title="Seu processo foi concluído!"
      show={show}
      isLoading={isLoading}
      onHide={close}
      onSave={submit}
      hideButton={true}
      onSubmit={() => save()}
    >
      <form onSubmit={() => save()}>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12 mb-3">
              <h3 className="text-muted">
                Sua avaliação é muito importante para nossa evolução
              </h3>
            </div>
            <div className="col-sm-12">
              <label>
                Por favor, de 0 a 10 o quanto a Hasa atendeu suas expectativas?
              </label>
              <div className="mt-3 mb-3">
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(1)}
                >
                  1
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(2)}
                >
                  2
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(3)}
                >
                  3
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(4)}
                >
                  4
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(5)}
                >
                  5
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-danger"
                  onClick={() => answer(6)}
                >
                  6
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-warning"
                  onClick={() => answer(7)}
                >
                  7
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-warning"
                  onClick={() => answer(8)}
                >
                  8
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-success"
                  onClick={() => answer(9)}
                >
                  9
                </button>
                <button
                  type="button"
                  className="btn ml-1 mr-1 btn-success"
                  onClick={() => answer(10)}
                >
                  10
                </button>
              </div>
              <br />
              <small>
                <i className="fa fa-exclamation-circle text-danger" /> Essa
                avaliação é necessária para abrir seu documento
              </small>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ResetPassword } from "@/app/core/base/controllers/UsersController";
import { PasswordIsStrong } from "@/app/core/util/helpers/UserHelper";

export default function ForgotComponent(props) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState(null);
  const [passwordValid, setPasswordValid] = useState("");

  useEffect(() => {
    checkUrlToken();
  }, []);

  useEffect(() => {
    checkPassword();
  }, [password]);

  function checkPassword() {
    if (password.length > 5) {
      if (!PasswordIsStrong(password)) {
        setPasswordValid(" is-invalid");
      } else {
        setPasswordValid(" is-valid");
      }
    }
  }

  function recover() {
    if (!email || !password || !confirmPassword) {
      toast.error("Preencha todas as informações para recuperar a senha");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("As senhas são diferentes");
      return;
    }
    if (!PasswordIsStrong(password)) {
      toast.error(
        "A senha precisa de ao menos 1 caracter especial, letra maiúscula, minúscula e número",
      );
      setPasswordValid(" is-invalid");
      return;
    }
    const infos = {
      email,
      confirmPassword,
      password,
      token,
    };

    ResetPassword(infos, (res) => {
      toast.info("Sua senha foi alterada com sucesso");
      setTimeout(() => {
        goAuth();
      }, 1500);
    });
  }

  function goAuth() {
    window.location = "#/";
  }

  function checkUrlToken() {
    let param = props.location.search;
    let tkn = [];
    let item = param.split("?")[1].split("&"),
      returnBool = false;

    for (var i = 0; i < item.length; i++) {
      let parr = item[i].split("=");
      if (parr[0] == "token") {
        let itens = decodeURIComponent(parr[1]);
        let tks = itens.split(",");

        for (let s in tks) {
          tkn.push(tks[s]);
        }
      }
    }
    console.log("mano", tkn[0]);
    setToken(tkn[0]);
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          style={{
            backgroundImage: "url('assets/media/photos/photo22@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <p className="display-4 font-w700 text-white mb-3 text-center">
                  <img src={Logo} style={{ height: 160 }} />
                </p>
                <p className="text-center text-uppercase font-w700 font-size-sm text-muted">
                  Resetar a senha
                </p>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    <div className="js-validation-signin">
                      <div className="py-3">
                        <div className="form-group">
                          <span>E-mail</span>
                          <input
                            type="email"
                            className="form-control form-control-lg form-control-alt"
                            id="email"
                            name="email"
                            onChange={(evt) => setEmail(evt.target.value)}
                            placeholder="E-mail"
                          />
                        </div>
                        <div className="form-group">
                          <span>Nova senha</span>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg form-control-alt " +
                              passwordValid
                            }
                            id="password"
                            name="password"
                            onChange={(evt) => setPassword(evt.target.value)}
                            placeholder="Senha"
                          />
                        </div>
                        <div className="form-group">
                          <span>Confirme a senha</span>
                          <input
                            type="password"
                            className={
                              "form-control form-control-lg form-control-alt " +
                              passwordValid
                            }
                            id="password"
                            name="password"
                            onChange={(evt) =>
                              setConfirmPassword(evt.target.value)
                            }
                            placeholder="Confirmar a senha"
                          />
                        </div>
                        <div className="col-sm-12 mb-3 py-3">
                          <small className="">
                            * A senha precisa de ao menos 1 caracter especial,
                            letra maiúscula, minúscula e número
                          </small>
                          <br />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={() => recover()}
                          className="btn btn-block btn-hero-lg btn-hero-primary"
                        >
                          <i className="fa fa-fw fa-arrow-right mr-1" />{" "}
                          Redefinir
                        </button>
                        <p className="mt-3 mb-0 text-center">
                          <a
                            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                            onClick={() => goAuth()}
                          >
                            <i className="fa fa-arrow-left text-muted mr-1" />{" "}
                            Voltar para login
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Sua experiência está sendo atualizada!
                  <br />
                  Para sempre ;)
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Hasa - Documentos &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toastr" />
    </div>
  );
}

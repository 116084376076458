import React, { useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Grid from "./Grid";
import BigSearch from "./BigSearch";
import { ToastContainer, toast } from "react-toastify";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [search, setSearch] = useState(null);
  const [text, setText] = useState(null);

  function mySubmitHandler() {
    if (text === null || text === "") {
      toast.error("Preencha o campo para pesquisar");
      return;
    }
    setSearch(text);
  }

  return (
    <>
      <Loading loading={loading} />

      <div className="bg-white">
        <div className="content">
          <div className="push"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-md-12">
          <Grid {...props} search={search} />
        </div>
      </div>
    </>
  );
}

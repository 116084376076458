import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  DateTimeNow,
  DateTranslate,
  DateTranslateSimple,
  IsTodayDate,
} from "@/app/core/util/DateTranslater";
import { processObservations } from "@/app/core/util/helpers/TasksHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import JobsController from "@/app/core/base/controllers/JobsController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { useState } from "react";

export default function OrganDevolutionItem({ data, reload, open }) {
  const [edit, setEdit] = useState(false);
  const [protocol, setProtocol] = useState("");
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    if (data && data.protocol) {
      setProtocol(data.protocol);
    }
  }, [data]);

  async function exigency() {
    if (window.confirm("Deseja prosseguir?")) {
      await registerJob();
      await organCtrl.ReturnExigency(data.id, (res) => {
        toast.success(Message.Saved);
        reload();
      });
    }
  }

  async function deferred() {
    if (window.confirm("Deseja prosseguir?")) {
      await registerJob();
      await organCtrl.EndOrgan(data.id, (res) => {
        toast.success(Message.Saved);
        reload();
      });
    }
  }

  async function registerJob() {
    await jobsDone.Insert(data.processId, data.id, (res) => {});
    return true;
  }

  function consulted(data) {
    organCtrl.Update({ ...data, consulted: DateTimeNow() }, (res) => {
      NotifyUpdate(
        data.processId,
        `Operador consultou o órgão ${data && data.organName}`,
        false,
        TimelineChannelSistema,
        data.id,
      );
      reload();
    });
  }

  function saveOrEdit() {
    if (edit === false) {
      setEdit(true);
    } else {
      saveProtocol();
      setEdit(false);
    }
  }

  async function saveProtocol() {
    if (protocol.length > 0) {
      await organCtrl.Update({ ...data, protocol: protocol }, async (res) => {
        await NotifyUpdate(
          data.processId,
          `Alterou o Protocolo de ${data.protocol} para $${protocol}`,
          false,
          TimelineChannelSistema,
          data.id,
        );
        await NotifyUpdate(
          data.processId,
          `Protocolo: ${protocol}`,
          true,
          TimelineChannelSistema,
          data.id,
        );
        // reload();
        toast.success(Message.Saved);
      });
    } else {
      toast.error("Você deve preencher o Nº de Protocolo");
    }
  }

  return (
    <tr key={data.id}>
      {/* <td>{data && data.id}</td> */}
      <td>{data && data.processName}</td>
      <td>{data && data.serviceName}</td>
      <td>{data && data.organName}</td>
      <td>
        <div class="form-group">
          <div class="input-group">
            <textarea
              className="form-control"
              defaultValue={data && data.protocol}
              disabled={!edit}
              style={{ width: 120 }}
              id={`protocol-field-${data && data.id}`}
              name={`protocol-field-${data && data.id}`}
              onChange={(evt) => setProtocol(evt.target.value)}
            />
            {/* {data && data.protocol} */}
            <div className="input-group-append">
              <button
                type="button"
                className={`btn btn-${edit === false ? "dark" : "success"}`}
                title="Editar"
                onClick={() => saveOrEdit()}
              >
                <i
                  className={`far fa-${
                    edit === false ? "edit" : "check-circle"
                  }`}
                />
              </button>
              <CopyToClipboard
                text={data.protocol}
                onCopy={() =>
                  toast.info("Copiado para a área de transferência")
                }
              >
                <button
                  type="button"
                  class="btn btn-dual btn-sm ml-2"
                  title="copiar"
                >
                  <i className="far fa-copy" />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </td>
      <td>{data && processObservations(data)}</td>
      <td>{data && DateTranslate(data.updatedDate)}</td>
      {/* <td>{data.process && DateTranslateSimple(data.process.updatedDate)}</td> */}
      <td>{DateTranslate(data.endPreparationDate)}</td>
      <td>
        {IsTodayDate(data.consulted) === false ? (
          <button
            className={`btn mt-1 btn-sm btn-warning`}
            onClick={() => consulted(data)}
          >
            <i className="si si-clock mr-1" />
            Consultar
          </button>
        ) : (
          <button className={`btn mt-1 btn-sm btn-secondary`}>
            <i className="si si-check mr-1" />
            Consultado
          </button>
        )}
        <button className="btn btn-sm btn-dual" onClick={() => exigency()}>
          <i className="si si-close text-danger" /> Exigência
        </button>
        <button className="btn btn-sm btn-dual" onClick={() => deferred()}>
          <i className="si si-check text-success" /> Deferido
        </button>
        <button className="btn btn-sm btn-dual" onClick={() => open()}>
          <i className="fa fa-eye" />
        </button>
      </td>
    </tr>
  );
}

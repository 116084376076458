import React, { useState, useEffect, useRef } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { Typeahead } from "react-bootstrap-typeahead";
import { CustomerList } from "../../base/controllers/CustomerController";
import DocumentsController from "../../base/controllers/DocumentsController";
import ProcessController from "../../base/controllers/ProcessController";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { TimelineChannelSistema } from "../../util/helpers/TimelineHelper";
import AutomationsController from "../../base/controllers/AutomationsController";
import { Message } from "../../util/messages/GeralMessages";
import { CpfMask, CnpjMask } from "../../util/helpers/ContactHelper";

function OperationNewBudget(props) {
  const userData = Storage.getUserData();
  const [files, setFiles] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [query, setQuery] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [progress, setProgress] = useState({});
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputFile = useRef(null);

  const automations = AutomationsController();
  const processCtrl = ProcessController();
  const docsCtrl = DocumentsController();

  let filesList = [];
  const { show, onHide, isLoading } = props;
  let clicked = 0;

  function onButtonClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    setFiles([]);
    if (show === true) {
      loadCustomers();
    }

    setLoading(false);
  }, [props.data, show]);

  useEffect(() => {
    toggleChange();
  }, [files]);

  function loadCustomers() {
    CustomerList((res) => {
      setCustomerList(res);
    });
  }

  function save() {
    if (customer === null || customer === "") {
      if (files === null || files === "") {
        toast.error(
          "Você precisa preencher o cliente ou apenas subir os arquivos para poder salvar",
        );
      } else if (files === null || files === "") {
        toast.error("Faça o upload de algum arquivo para poder salvar");
      } else {
        saveModel();
      }
    } else {
      saveModel();
    }
  }

  async function saveModel() {
    if (files.length > 15) {
      toast.error("O limite de arquivos por vez é de 15");
      return;
    }
    clicked++;

    if (clicked > 1) return;

    await processCtrl.Insert(
      { customerId: customer.id, processName: "S.I.", operatorId: userData.id },
      async (res) => {
        if (files.length > 0) {
          setLoading(false);
          processFiles(res);
        } else {
          toast.success(Message.Saved);
          setLoading(false);
          onHide();
        }
      },
      (error) => {
        setLoading(false);
      },
    );
  }

  async function processFiles(process) {
    let list = [];
    for (let s in files) {
      list[s] = { status: 0 };
    }
    setProgress(list);
    setLoading(true);
    await files.forEach(async (file, index) => {
      let formData = new FormData();
      formData.append("file", file);
      let old = progress[0] === undefined ? list : progress;
      old[index] = "";

      await docsCtrl.UploadInitialProcessDocs(
        process.id,
        formData,
        userData.id,
        null,
        (res) => {
          old[index] = true;
          setProgress(old);
          let entries = Object.keys(old);
          let filesOld = files;
          filesOld[index].status = true;
          setFiles(filesOld);
          toggleChange();

          let filteredProgress = old.filter((x) => x === true);
          if (files.length === filteredProgress.length) {
            let itsOK = true;

            for (let s in entries) {
              itsOK = old[s] === false ? false : true;
            }
            if (itsOK === true) {
              setLoading(false);
              clicked = 0;
              toast.success(Message.Saved);
              automations.DistributeProcess((res) => {});
              onHide();
            }
          }
        },
        (fail) => {
          old[index] = false;
          setProgress(old);
          setLoading(false);
        },
      );
    });
  }

  function removeFile(archive) {
    let itens = files;
    let newItens = [];
    for (let s = 0; s < itens.length; s++) {
      if (archive.name === itens[s].name) {
      } else {
        newItens.push(itens[s]);
      }
    }

    toggleChange();
    setFiles(newItens);
  }

  function toggleChange() {
    let date = new Date();
    setChange(date.getTime());
  }

  function CustomerStatus() {
    let z = customerList.filter((x) => x.id === customer.id);

    if (z.length > 0) {
      const i = z[0].status;
      if (i === 0) {
        return "Indefinido";
      } else if (i === 1) {
        return "Pendente";
      } else if (i === 2) {
        return "Pendente Hasa";
      } else if (i === 3) {
        return "Ativo";
      } else if (i === 4) {
        return (
          <span>
            <i className="fa fa-exclamation-circle text-danger" /> Bloqueado
          </span>
        );
      }
    }
  }

  function processProgress(res) {
    // let res = progress[index];
    if (res === "" || res === undefined) {
      return <div>Pendente</div>;
    } else if (res === true) {
      return <div>Salvo com sucesso</div>;
    } else {
      return <div>Falha</div>;
    }
  }

  return (
    <Modal
      title="Adicionar O.O."
      show={show}
      isLoading={loading}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="example-masked-time">Cliente</label>
              <Typeahead
                options={customerList}
                onSearch={(q) => setQuery(q)}
                id="advanced-filter-state"
                name="clientName"
                placeholder="Selecionar Cliente"
                labelKey={(option) =>
                  `Cod. ${option.customerCode} - Nome: ${option.name} ${
                    option.cnpj !== undefined && option.cnpj !== ""
                      ? ` - CNPJ: ${CnpjMask(option.cnpj)}`
                      : ""
                  }${
                    option.cpf !== undefined && option.cpf !== ""
                      ? ` - CPF: ${CpfMask(option.cpf)}`
                      : ""
                  } - Email: ${option.email}`
                }
                value={customer && customer.name}
                onChange={(selected) => {
                  setCustomer(selected[0]);
                }}
              />
            </div>
            {customer && customer !== null ? (
              <div className="form-group text-center">
                Status do cliente: {CustomerStatus()}
              </div>
            ) : (
              ""
            )}
            <input
              type="file"
              id="file"
              multiple
              ref={inputFile}
              onChange={(event) => {
                const parent = event.target.files;
                Array.prototype.forEach.call(files, (file) => {
                  filesList.push(file);
                });
                Array.prototype.forEach.call(parent, (file) => {
                  filesList.push(file);
                });

                setFiles(filesList);
              }}
              style={{ visibility: "hidden" }}
            />
            <div className="block-header">
              <div className="title">Documentos</div>
              <div className="tools">
                <a
                  href="javascript:void(0)"
                  className="btn btn-sm btn-link text-primary"
                  onClick={() => onButtonClick()}
                >
                  <i class="fa fa-fw fa-file-upload"></i>
                </a>
              </div>
            </div>
            <table className="table">
              {change &&
                files &&
                files.map((item, index) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item && processProgress(item.status)}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-link text-primary text-danger"
                        onClick={() => removeFile(item)}
                      >
                        <i class="si si-close"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default OperationNewBudget;

import React from "react";
import { ChannelTranslate } from "@/app/core/util/StatusStates";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { ProcessLinkText } from "@/app/core/util/helpers/ExigencyActionHelper";
import FileViewer from "../FileViewer";
import Storage from "@/app/core/util/Storage";
import { useState } from "react";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";

export default function TimelineItem({
  refresh,
  data,
  addObservation,
  view,
  organListId,
  preview,
  remove,
}) {
  const timelineCtrl = TimelineController();

  const user = Storage.getUserData();
  const [open, setOpen] = useState(true);
  const enviroment = process.env.REACT_APP_ENV;

  async function showOff(item) {
    let infos = {
      ...data,
      important: !item.important,
      status: 1,
    };

    await timelineCtrl.Update(infos, (res) => {
      if (infos.important === true) {
        addObservation(item);
      }
      refresh();
    });
  }

  async function bookmarked(item) {
    let infos = {
      ...data,
      bookmark: !item.bookmark,
      status: 1,
    };
    if (infos.organListId === undefined && organListId !== undefined) {
      infos.organListId = parseInt(organListId);
    }
    if (infos.bookmark == true) {
      await timelineCtrl.Bookmark(infos, (res) => {
        if (infos.important === true) {
          addObservation(item);
        }
        refresh();
      });
    } else {
      await timelineCtrl.Update(infos, (res) => {
        if (infos.important === true) {
          addObservation(item);
        }
        refresh();
      });
    }
  }

  function verifyState(item) {
    if (item.important === true) {
      return "border-success";
    } else {
      if (item.channel === 0) {
        return "border-info";
      }

      return "border-secondary";
    }
  }

  function processAttachments() {
    return (
      <p>
        <div className="row gutters-tiny js-gallery img-fluid-100 js-gallery-enabled">
          {data.attachments.map((item) => {
            const url = item.url.split(".");
            let archive = url[url.length - 1];

            if (
              archive == "jpg" ||
              archive == "jpeg" ||
              archive == "jfif" ||
              archive == "png" ||
              archive == "gif"
            ) {
              return (
                <span>
                  <FileViewer
                    file={item.url}
                    height={120}
                    openImage={(img) => view(img)}
                  />
                  {/* {user && user.level != 5 && preview != true?
                    <button className="btn btn-sm btn-dual" onClick={() => remove(item)} title={"Excluir"}><i className="si si-close text-danger"></i> Remover</button>
                  :""} */}
                </span>
              );
              // <div className="col-md-4 animated fadeIn push">
              // </div>
            } else {
              return (
                <span>
                  <button
                    className="btn btn-sm btn-dual"
                    onClick={() => view(item.url)}
                    title={item.name}
                  >
                    <i className="fa fa-paperclip text-success mr-1"></i>{" "}
                    {item.name}
                  </button>
                  {/* {user && user.level != 5 && preview != true?
                    <button className="btn btn-sm btn-dual" onClick={() => remove(item)} title={"Excluir"}><i className="si si-close text-danger"></i></button>
                  :""} */}
                </span>
              );
            }
          })}
        </div>
      </p>
    );
  }

  function toggleCard() {
    setOpen(!open);
  }

  return (
    <a
      className={
        "block block-rounded block-link-shadow block-bordered " +
        verifyState(data) +
        " border-3x js-appear-enabled animated fadeIn"
      }
      data-toggle="appear"
      href="javascript:void(0)"
    >
      <div className="block-header">
        {preview && preview == true ? (
          <div className="block-title inline-block font-small">
            Comentário da equipe Hasa:
          </div>
        ) : (
          <div className="block-title">
            {" "}
            {data.user !== null && data.user !== undefined
              ? `${data.user.firstName} | ${DepartmentTranslate(
                  data.user.level,
                )} - `
              : ""}
            {(data.user === null || data.user === undefined) &&
            data.channel != TimelineChannelSistema
              ? "Cliente - "
              : ""}
            {ChannelTranslate(data.channel)}
          </div>
        )}
        {preview && preview == true ? (
          <div className="block-tools d-flex">
            <span
              className="block block-rounded block-fx-pop block-bordered mr-2 text-warning p-2 d-flex"
              style={{ height: 35 }}
            >
              <i className="fa fa-exclamation-triangle mr-2" />
              <small>Atenção</small>
              {/* <i className="far fa-bookmark"/> */}
            </span>

            {user && user.level != 5 ? (
              <button
                className={
                  data.bookmark === true
                    ? "btn btn-sm btn-square btn-warning"
                    : "btn btn-sm btn-square btn-outline-danger"
                }
                onClick={() => bookmarked(data)}
                style={{ height: 35 }}
              >
                {data.bookmark === true
                  ? "Informado cliente"
                  : "Informar cliente"}
              </button>
            ) : (
              ""
            )}
            {data.attachments && data.attachments.length > 0 ? (
              <button
                className="btn btn-sm btn-square btn-outline-dark js-click-ripple-enabled"
                onClick={() => toggleCard()}
              >
                <i
                  className={`si si-arrow-${open === true ? "up" : "down"}`}
                ></i>
              </button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="block-tools">
            <button
              className={
                data.important === true
                  ? "btn btn-sm btn-square btn-success"
                  : "btn btn-sm btn-square btn-outline-success"
              }
              onClick={() => showOff(data)}
            >
              {data.important === true ? "Observações" : "Observações"}
            </button>
            {user && user.level != 5 ? (
              <button
                className={
                  data.bookmark === true
                    ? "btn btn-sm btn-square btn-warning"
                    : "btn btn-sm btn-square btn-outline-danger"
                }
                onClick={() => bookmarked(data)}
              >
                {data.bookmark === true
                  ? "Informado cliente"
                  : "Informar cliente"}
              </button>
            ) : (
              ""
            )}
            {data && data.attachments && data.attachments.length > 0 ? (
              <button
                className="btn btn-sm btn-square btn-outline-dark js-click-ripple-enabled"
                onClick={() => toggleCard()}
              >
                <i
                  className={`si si-arrow-${open === true ? "up" : "down"}`}
                ></i>
              </button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
        <p className="text-muted mb-0">{ProcessLinkText(data.observation)}</p>
      </div>
      {open === true &&
      data &&
      data.attachments &&
      data.attachments.length > 0 ? (
        <div className="ml-3 mr-3 mt-1 mb-1 d-flex align-items-center justify-content-between">
          {data && data.attachments && processAttachments()}
        </div>
      ) : (
        ""
      )}
      <div className="block-content block-content-full block-content-sm bg-body-light">
        <span className="font-size-sm text-muted">
          <strong>{DateTranslate(data.createdDate)}</strong>
        </span>
      </div>
    </a>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import OrganListController from "../../base/controllers/OrganListController";
import { ExigencyList } from "../../base/controllers/ExigenciesController";
import { ExigencyStatus } from "../../util/constants/ExigenciesConstants";
import { toast } from "react-toastify";
import JobsController from "@/app/core/base/controllers/JobsController";
import OrganListHelper from "../../util/helpers/OrganListHelper";

export default function EndPreparationModal({
  data,
  preLoad,
  show,
  onHide,
  submit,
  isLoading,
}) {
  const [organList, setOrganList] = useState({});
  const [info, setInfo] = useState({
    protocol: "",
  });
  const [isDigital, setIsDigital] = useState(false);
  const [hasProtocol, setHasProtocol] = useState(false);
  const [loading, setLoading] = useState(false);
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    if (data !== undefined && data.id !== undefined && show) {
      setOrganList(data);
      loadExigencies(data);
    }
  }, [data, show]);

  function loadExigencies(res) {
    if (preLoad && preLoad.id != undefined) {
      let res = [];
      if (preLoad.exigencies) {
        res = preLoad.exigencies;
      }
      let names = "Com as seguintes exigências em aberto : ";
      let exigencies = res.filter((x) => x.status !== ExigencyStatus.done);

      if (exigencies.length > 0) {
        for (let s in exigencies) {
          names += `${s}) - ${exigencies[s].description}; `;
        }
      }
    }
    // ExigencyList(res.processId, (res) => {
    // });
  }

  async function save() {
    if (
      isDigital === true &&
      hasProtocol == true &&
      (info.protocol === "" || info.protocol === undefined)
    ) {
      toast.error("O número de protocolo é obrigatório");
    } else {
      setLoading(true);
      let organ = organList;
      if (isDigital == true) {
        organ.organType = OrganListHelper().OrganListType.Digital;
      } else {
        organ.organType = OrganListHelper().OrganListType.Phisical;
      }
      await organCtrl.SendDevolution(
        {
          ...organ,
          protocol: info.protocol,
          deliveryType: isDigital === true ? 5 : 0,
        },
        async (res) => {
          await jobsDone.Insert(organList.processId, organList.id, (res) => {});
          setLoading(false);
          submit();
          onHide();
        },
      );
    }
  }

  function changeStatus(evt) {
    if (evt === "true" || evt === true) {
      setIsDigital(true);
    } else {
      setIsDigital(false);
    }
  }

  function handleChange(evt) {
    let old = info;
    let nw = { [evt.target.name]: evt.target.value };

    setInfo({ ...old, ...nw });
  }

  return (
    <Modal
      title="Encerrar preparação"
      show={show}
      isLoading={loading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Esse processo é digital ou físico?</span>
        <select
          className="form-control"
          onChange={(evt) => changeStatus(evt.target.value)}
        >
          <option>Selecione</option>
          <option value={true}>Digital</option>
          <option value={false}>Físico</option>
        </select>
      </div>

      {isDigital === true ? (
        <div className="form-group">
          <label className="d-block">Tem protocolo?</label>
          <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input
              type="radio"
              className="custom-control-input"
              id="yes"
              name="hasProtocol"
              onChange={(evt) => setHasProtocol(true)}
              checked={hasProtocol == true ? true : false}
            />
            <label className="custom-control-label" for="yes">
              Sim
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline custom-control-lg">
            <input
              type="radio"
              className="custom-control-input"
              id="no"
              name="hasProtocol"
              onChange={(evt) => setHasProtocol(false)}
              checked={hasProtocol == false ? true : false}
            />
            <label className="custom-control-label" for="no">
              Não
            </label>
          </div>
        </div>
      ) : (
        ""
      )}

      {hasProtocol === true ? (
        <div className="form-group">
          <span>Nº de protocolo</span>
          <input
            className="form-control"
            name="protocol"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
}

import React from "react";
import { BudgetStatusTranslate } from "@/app/core/util/StatusTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import OrganListHelper, {
  GetCurrentOperatoData,
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import { ProcessStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import {
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import Storage from "@/app/core/util/Storage";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function ProcessFollowItem({ data, open, rows }) {
  const organHelper = OrganListHelper();
  const organCtrl = OrganListController();
  const userData = Storage.getUserData();
  const timelineCtrl = TimelineController();

  function goTo(organList) {
    let infos = data;
    if (organList !== undefined) {
      infos.organListId = organList.id;
    }
    open(infos);
  }

  function processBody() {
    if (
      (data.budgetStatus !== BudgetStatus.Acepted ||
        data.budgetStatus === BudgetStatus.Refused) &&
      data.status !== ProcessStatus.Deleted
    ) {
      return (
        <div onClick={() => goTo()} style={{ cursor: "pointer" }}>
          <table className="table table-hover">
            <tbody>
              <tr>
                <td>Orçamento - {BudgetStatusTranslate(data.budgetStatus)}</td>
                <td>
                  <i className="si si-clock mr-3" />{" "}
                  {DateTranslateSimple(data.updatedDate)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else if (
      data.budgetStatus === BudgetStatus.Acepted &&
      data.status !== ProcessStatus.Deleted
    ) {
      let organList = [];

      if (data.organList) {
        organList = data.organList.filter(
          (x) =>
            x.status === OrganStatus.Done || x.status === OrganStatus.Deferred,
        );
      }

      let ongoing = [];

      if (data.organList) {
        ongoing = data.organList.filter(
          (x) =>
            x.stage !== 1 &&
            x.stage !== 10 &&
            x.status !== OrganStatus.Deferred,
        );
      }

      let notStarted = [];

      if (data.organList) {
        notStarted = data.organList.filter((x) => x.stage === 1);
      }

      if (
        data.organList != undefined &&
        organList.length !== data.organList.length
      ) {
        return (
          <div>
            {data &&
              data.organList &&
              data.organList.map((item) => {
                if (
                  item.stage > 1 &&
                  item.status !== OrganStatus.Done &&
                  item.status != OrganStatus.Deferred
                ) {
                  let operator = GetCurrentOperatoData(item);

                  // if (operator !== undefined) {
                  return (
                    <div className="ribbon ribbon-right ribbon-bookmark ribbon-warning">
                      <table
                        className="table table-hover"
                        onClick={() => goTo(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <tbody className="">
                          <tr>
                            <td>
                              <small>
                                {item.selectedService &&
                                  item.selectedService.serviceName}
                                <br /> {item.organ && item.organ.name}{" "}
                              </small>
                            </td>
                            <td>
                              <small>
                                {DepartmentTranslate(item.stage)} <br />
                                {organHelper.GetCurrentStatus(item)}
                              </small>
                            </td>
                            <td>
                              <small>
                                <i className="si si-clock mr-3" />
                                {DateTranslateSimple(item.updatedDate)}
                              </small>
                            </td>
                            <td>
                              <button
                                className="btn btn-dual"
                                onClick={() => goTo(item)}
                              >
                                <i class="far fa-eye"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                  // }
                }
              })}
          </div>
        );
      } else {
        return (
          <div
            className="block block-bordered block-rounded"
            onClick={() => goTo()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>
                    <span className={"badge badge-success"}>
                      <i className={"si si-check"} /> Processo Concluído
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    } else {
      return (
        <div
          className="block block-bordered block-rounded"
          onClick={() => goTo()}
          style={{ cursor: "pointer" }}
        >
          <table className="table table-hover">
            <tbody className="">
              <tr>
                <td>Status</td>
                <td>
                  <span className={"badge badge-danger"}>
                    <i className={"si si-close"} /> Processo excluído
                  </span>
                </td>
              </tr>
              <tr>
                <td>Última atualização</td>
                <td>{DateTranslate(data.updatedDate)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }

  return (
    <div class={`col-md-${rows != undefined ? rows : "6"}`}>
      <div class="block block-bordered block-rounded block-fx-pop">
        <div className="block-header">
          <div className="block-title">
            Processo : {data && data.processName}
            <div class="font-size-sm text-success mb-1">
              Cliente : {data.customer && data.customer.name} | Cód :{" "}
              {data.customer && data.customer.customerCode}
            </div>
          </div>
          <div className="block-tools">
            {data &&
            (data.budgetStatus !== BudgetStatus.Acepted ||
              data.status == ProcessStatus.Done ||
              data.status == ProcessStatus.Deleted) ? (
              <button className="btn btn-dual" onClick={() => goTo()}>
                <i class="far fa-eye"></i>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mt-1 mr- ml-1 mb-1">{processBody()}</div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  BlobProvider,
  PDFDownloadLink,
  Svg,
  Font,
  Line,
} from "@react-pdf/renderer";

export default function DevolutionDoc({ process }) {
  const [myDoc, setMyDoc] = useState(null);

  const styles = StyleSheet.create({
    body: {
      paddingTop: 5,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: {
      marginVertical: 50,
    },
    section2: {
      marginVertical: 70,
    },
    title: {
      fontSize: 24,
      marginVertical: 5,
      textAlign: "center",
      // fontFamily: "Times-Roman",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginTop: 10,
    },
    subtitle: {
      fontSize: 18,
      margin: 6,
      // fontFamily: "Times-Roman",
    },
    text: {
      margin: 8,
      fontSize: 12,
      textAlign: "justify",
      // fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 5,
      marginHorizontal: 230,
      width: 80,
    },
    image2: {
      marginVertical: 10,
      marginHorizontal: 230,
      width: 80,
    },
    spacer: {
      marginVertical: 60,
    },
    header: {
      fontSize: 12,
      marginBottom: 10,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  useEffect(() => {
    if (process && process.id) {
      createContract();
    }
  }, [process]);

  function createContract() {
    setMyDoc(
      <Document>
        <Page style={styles.body} orientation="portrait">
          <view style={styles.section}>
            <Text style={styles.title}>
              {process.customer.customerCode} -{" "}
              {process.customer && process.customer.name}
            </Text>
            <Text style={styles.title}>
              {process &&
                process.customer &&
                `${process.customer.street}, ${process.customer.number} ${process.customer.complement != undefined ? process.customer.complement : ""} - ${process.customer.neighborhood} - ${process.customer.city} - ${process.customer.uf}, ${process.customer.zipcode}`}
            </Text>
            <view style={styles.section}></view>
          </view>
          <Svg height="10" width="530" style={styles.section2}>
            <Line
              x1="0"
              y1="0"
              x2="850"
              y2="0"
              strokeWidth={4}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <view style={styles.section}>
            <Image style={styles.image2} src={Logo} />
            <Text style={styles.title}>Hasa Documentos</Text>
            <Text style={styles.title}>
              Rua Coelho Lisboa, 442 - Cj 84, 8º Andar - Tatuapé, São Paulo -
              SP, 03323-040
            </Text>
            <Text style={styles.title}>Edifício Los Angeles</Text>
          </view>
        </Page>
      </Document>,
    );
  }

  return (
    <span>
      {process && myDoc && (
        <BlobProvider document={myDoc}>
          {({ blob, url, loading, error }) => (
            <a
              className="btn btn-sm btn-white"
              href={url}
              target="_blank"
              title="Documento de devolução"
              rel="noreferrer"
            >
              <i className="far fa-envelope"></i>
            </a>
          )}
        </BlobProvider>
      )}
    </span>
  );
}

import CustomerExigencies from "@/app/core/layouts/main-components/exigencies/CustomerExigencies";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import React, { useState } from "react";

export default function ExigencyShortCutModal({ show, onHide, process }) {
  const [selected, setSelected] = useState(1);

  return (
    <Modal
      title="Exigências"
      show={show}
      // isLoading={loading}
      onHide={onHide}
    >
      {process && process.length == 0 ? (
        <div className="text-center">
          <i className="si si-check fa-2x text-success" />
          <br />
          <h3 className="text-muted">Você não tem exigências pendentes</h3>
        </div>
      ) : (
        <Tabs>
          <ul class="nav nav-tabs nav-tabs-alt border-bottom-0 justify-content-center border-warning justify-content-md-start">
            {process &&
              process.map((item, index) => (
                <Tab>
                  <li class="nav-item">
                    <a
                      class={
                        selected &&
                        `btn ${selected == index + 1 ? "btn-warning" : "btn-dual"}`
                      }
                      href="javascript:void(0)"
                      onClick={() => setSelected(index + 1)}
                    >
                      <span class="d-none d-md-inline ml-1">
                        ({item.waitingExigencies}) {item.processName}
                      </span>
                    </a>
                  </li>
                </Tab>
              ))}
          </ul>
          {process &&
            process.map((item) => (
              <Panel>
                <CustomerExigencies
                  processId={item.id}
                  organListId={item.organListId}
                  isInternal={true}
                />
              </Panel>
            ))}
        </Tabs>
      )}
    </Modal>
  );
}

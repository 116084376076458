import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { ResendInsert } from "@/app/core/base/controllers/SendController";

export default function ResendModal({ values, show, onHide, isLoading }) {
  const [data, setData] = useState({});

  useEffect(() => {
    if (values) {
      setData(values);
    } else {
      setData({});
    }
  }, [values]);

  function handleSubmit() {
    ResendInsert(data, (res) => {
      toast.success("Salvo com sucesso");
      onHide();
    });
  }

  function handleChange(evt) {
    let old = data;
    let newData = { [evt.target.name]: parseInt(evt.target.value) };

    setData({ ...old, ...newData });
  }

  return (
    <Modal
      title="Envio automático"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={handleSubmit}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label for="example-masked-time">Tipo de regra</label>
              <select
                className="form-control"
                onChange={(evt) => handleChange(evt)}
                name="type"
              >
                <option value={null}>Selecionar</option>
                <option value={1}>Orçamento</option>
                <option value={2}>Exigência</option>
                <option value={3}>Devolução</option>
              </select>
            </div>
            <div className="form-group">
              <label for="example-masked-time">
                Prazo limite para permanecer na esteira
              </label>
              <input
                type="number"
                className="form-control"
                name="expiration"
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div className="form-group">
              <label for="example-masked-time">
                Repetir a cada quantos dias?
              </label>
              <input
                type="number"
                className="form-control"
                name="every"
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div className="form-group">
              <label for="example-masked-time">
                Repetir o reenvio quantas vezes?
              </label>
              <input
                type="number"
                className="form-control"
                name="howMany"
                onChange={(evt) => handleChange(evt)}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

import React from "react";
import { Dropdown } from "react-bootstrap";
import translate from "@/i18n";

export default function UserOptions() {
  return (
    <>
      <Dropdown.Item
        eventKey="0"
        className="bg-primary-darker rounded-top font-w600 text-white text-center p-3"
      >
        User Options
      </Dropdown.Item>
      <Dropdown.Item className="p-2" />
      <Dropdown.Item eventKey="1" className="dropdown-item">
        <i className="far fa-fw fa-user mr-1" /> {translate("profile")}
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="dropdown-item d-flex align-items-center justify-content-between"
      >
        <i className="far fa-fw fa-envelope mr-1" /> {translate("inbox")}
        <span className="badge badge-primary">3</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey="3" className="dropdown-item">
        <i className="far fa-fw fa-file-alt mr-1" /> {translate("invoice")}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="4"
        className="dropdown-item"
        data-toggle="layout"
        data-action="side_overlay_toggle"
      >
        <i className="far fa-fw fa-building mr-1" /> {translate("settings")}
      </Dropdown.Item>

      <Dropdown.Divider />
      <Dropdown.Item eventKey="5" className="dropdown-item">
        <i className="far fa-fw fa-arrow-alt-circle-left mr-1" />{" "}
        {translate("sign out")}
      </Dropdown.Item>
    </>
  );
}

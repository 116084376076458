import UserTile from "@/app/core/layouts/main/elements/UserTile";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";
import Storage from "@/app/core/util/Storage";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper, {
  OrganStatus,
  OrganStatusList,
} from "@/app/core/util/helpers/OrganListHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { PreparationStep } from "@/app/core/util/helpers/UserHelper";

export default function ReavaliationCard({
  process,
  show,
  preLoad,
  organListId,
}) {
  const [userPreview, setUserPreview] = useState(null);
  const [organList, setOrganList] = useState({});
  const [description, setDescription] = useState("");
  const userData = Storage.getUserData();
  const [department, setDepartment] = useState(userData.level - 1);
  const [status, setStatus] = useState(0);
  const [preparationStage, setPreparationStage] = useState(0);
  const [type, setType] = useState(0);
  const organCtrl = OrganListController();
  const organListHelper = OrganListHelper();

  useEffect(() => {
    if (process.id !== undefined || organListId !== undefined) {
      loadData();
    }
    console.log("Processo e Orgao", process, organListId);
  }, [process, organListId]);

  useEffect(() => {
    loadResponsible();
  }, [department]);

  function loadResponsible(res, place, status) {
    let organ = res;
    console.log("O que acountexcer", res, status);
    if (res === undefined) {
      organ = organList;
    }
    if (organ !== undefined && organ.id !== undefined) {
      // setUserPreview(null);
      if (place == 1) {
        setUserPreview(process.seller);
      } else if (place == 2) {
        setUserPreview(organ.analyst);
      } else if (place == 3) {
        if (status == 0) {
          setUserPreview(organ.preparer);
        } else if (status == 1) {
          setUserPreview(organ.preparerReview);
        }
      } else if (place == 4) {
        if (OrganStatus.NotStarted === status) {
          setUserPreview(organ.devolutionNotStarted);
        } else if (OrganStatus.Filed === status) {
          setUserPreview(organ.devolutionFiled);
        } else if (OrganStatus.Devolution === status) {
          setUserPreview(organ.devolutionDevolution);
        } else if (
          OrganStatus.Deferred === status ||
          OrganStatus.Done === status
        ) {
          setUserPreview(organ.devolutionDeferred);
        } else if (OrganStatus.Exigency === status) {
          setUserPreview(organ.devolutionExigency);
        }
      }
    }
  }

  async function loadData() {
    if (organListId !== undefined) {
      if (preLoad != undefined && preLoad.id != undefined) {
        for (let s in preLoad.selectedServices) {
          const service = preLoad.selectedServices[s];

          const organ = service.organList.filter(
            (x) => x.id == parseInt(organListId),
          );
          if (organ.length > 0) {
            setOrganList(organ[0]);
            loadResponsible(organ[0], department);
          }
        }
      } else {
        await organCtrl.SelectById(organListId, (organ) => {
          loadResponsible(organ, department);
          setOrganList(organ);
        });
      }
    }
  }

  async function sendDepartment() {
    if (description === "") {
      toast.error("Preencha para poder salvar");
      return;
    }
    if (userPreview === null) {
      toast.error(
        "O usuário do departamento anterior esta inativo ou foi removido",
      );
      return;
    }

    await organCtrl.Reavaliation(
      organList.id,
      description,
      department,
      status,
      type,
      preparationStage,
      (res) => {
        toast.success(Message.Saved);
        setTimeout(() => {
          window.location = "#/";
        }, 500);
      },
    );
  }

  return (
    <DropdownElement
      title="Solicitar Reavaliação"
      icon="fa fa-code-branch"
      button="Enviar"
      // disabled={disable}
      submit={() => sendDepartment()}
      style={{ width: 380 }}
    >
      <div className="form-group">
        <span>Selecione o departamento</span>
        <select
          className="form-control"
          value={department}
          onChange={(evt) =>
            setDepartment(evt.target.value) |
            loadResponsible(organList, parseInt(evt.target.value))
          }
        >
          <option value={0}>Selecione</option>
          {userData.level === 2 || userData.level > 2 ? (
            <option value={1}>Orçamento</option>
          ) : (
            ""
          )}
          {userData.level === 3 || userData.level > 3 ? (
            <option value={2}>Análise</option>
          ) : (
            ""
          )}
          {userData.level >= 3 ? <option value={3}>Preparação</option> : ""}
          {userData.level === 4 || userData.level > 4 ? (
            <option value={4}>Devolução</option>
          ) : (
            ""
          )}
        </select>
      </div>
      {(userData.level === 3 && department == 3) || department == 3 ? (
        <div className="form-group">
          <span>Selecione o estágio da preparação</span>
          <select
            className="form-control"
            onChange={(evt) =>
              setPreparationStage(evt.target.value) |
              loadResponsible(organList, 3, parseInt(evt.target.value))
            }
          >
            <option value={0}>Selecione</option>
            <option value={0}>Esteira padrão</option>
            {userData &&
            ((userData.preparationSteps !== undefined &&
              userData.preparationSteps.filter(
                (x) => x.step == PreparationStep.Review,
              ).length > 0) ||
              userData.level >= 3) ? (
              <option value={1}>Conferência</option>
            ) : (
              ""
            )}
          </select>
        </div>
      ) : (
        ""
      )}
      {department == 4 ? (
        <div className="form-group">
          <span>Selecione o estágio</span>
          <select
            className="form-control"
            onChange={(evt) =>
              setStatus(evt.target.value) |
              loadResponsible(organList, 4, parseInt(evt.target.value))
            }
          >
            <option value={0}>Selecione</option>
            {userData.level === 4 &&
              OrganStatusList.map((item) => {
                if (item.visible) {
                  return <option value={item.value}>{item.name}</option>;
                }
              })}
          </select>
        </div>
      ) : (
        ""
      )}
      <div className="form-group">
        <span>Tipo de reavaliação</span>
        <select
          className="form-control"
          onChange={(evt) => setType(evt.target.value)}
        >
          <option value={0}>Selecione</option>
          {organListHelper.ReavaliationType.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <span>Descreva</span>
        <textarea
          className="form-control"
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </div>

      {userPreview && userPreview ? (
        <UserTile user={userPreview} show={() => show(userPreview)} />
      ) : (
        ""
      )}
    </DropdownElement>
  );
}

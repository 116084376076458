import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import DailyRatesForm from "./DailyRatesForm";
import {
  ResendDelete,
  ResendList,
} from "@/app/core/base/controllers/SendController";
import ResendModal from "./ResendModal";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import Functions from "@/app/core/util/Functions";

export default function ResendComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});

  const cols = [
    {
      header: "Tipo",
      name: "type",
      isSortable: true,
      switch: { 1: "Orçamento", 2: "Exigência", 3: "Devolução" },
    },
    { header: "Retirar", name: "expiration", isSortable: true },
    { header: "Reenviar a cada", name: "every", isSortable: true },
    { header: "Repetir quantas vezes", name: "howMany", isSortable: true },
    { header: "Atualizado em", name: "updatedDate", isSortable: true },
    { header: "Criado em", name: "createdDate", isSortable: true },
  ];

  useEffect(() => {
    getDailyRates();
  }, []);

  function getDailyRates() {
    setTableLoading(true);
    ResendList((res) => {
      setData(ProcessDates(res));
      setTableLoading(false);
    });
  }

  function disableDailyRate(data) {
    if (window.confirm(`Tem certeza que quer remover a tarefa?`)) {
      ResendDelete(data.id, (res) => {
        toast.info("Tarefa removida");
        getDailyRates();
      });
    }
  }

  return (
    <>
      <Card
        title="Envio automático"
        action={() => setShowModal(!showModal)}
        icon="settings"
      >
        <Table
          loading={tableLoading || false}
          data={data}
          columns={cols}
          insertAction={() => setShowModal(true)}
          deleteAction={(task) => disableDailyRate(task.data)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          hideHeader={true}
        />
      </Card>

      <ResendModal
        values={selected}
        show={showModal}
        isLoading={modalLoading}
        onHide={() =>
          setShowModal(!showModal) | setSelected({}) | getDailyRates()
        }
      />
    </>
  );
}

import {
  DateTranslate,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { processObservations } from "@/app/core/util/helpers/TasksHelper";
import React, { useEffect, useState } from "react";

export default function OrganExigencyItem({ data, open, reload }) {
  const [protocol, setProtocol] = useState("");
  const organHelper = OrganListHelper();

  useEffect(() => {}, [data]);

  function openExigency() {
    window.location = `#/analysis/${data.id}`;
  }

  return (
    <tr key={data.id}>
      <td>{data && data.id}</td>
      <td>{data && data.processName}</td>
      <td>{data && data.serviceName}</td>
      <td>{data && data.organName}</td>
      <td>{data && organHelper.GetCurrentStatus(data)}</td>
      <td>{data && processObservations(data)}</td>
      <td>{data && DateTranslate(data.updatedDate)}</td>
      <td>{DateTranslate(data.endPreparationDate)}</td>
      <td>
        <button className="btn btn-sm btn-dual" onClick={() => openExigency()}>
          <i className="fa fa-eye" />
        </button>
      </td>
    </tr>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";
// import ReactToExcel from "react-html-table-to-excel";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function DevolutionReportModal({
  showModal,
  onHide,
  startDate,
  endDate,
}) {
  const [data, setData] = useState({
    notStarted: [],
    filed: [],
    devolution: [],
    exigency: [],
    deferred: [],
    done: [],
  });
  const organHelper = OrganListHelper();
  const report = ReportController();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (showModal) {
      loadInfos();
    }
  }, [showModal]);

  async function loadInfos() {
    setLoading(true);
    await report.DevolutionCounters(startDate, endDate, (res) => {
      let infos = res.serviceOrders;

      if (infos !== null && infos !== undefined && infos.length > 0) {
        for (let s in infos) {
          infos[s].serviceName = `${
            infos[s].selectedService !== undefined
              ? infos[s].selectedService.serviceName
              : "S.I."
          }`;
          infos[s].organName = `${
            infos[s].organ !== undefined ? infos[s].organ.name : "S.I."
          }`;
          infos[s].processName = `${
            infos[s].process !== undefined
              ? infos[s].process.processName
              : "S.I."
          }`;
          infos[s].createdDate = DateTranslate(infos[s].createdDate);
          infos[s].updatedDate = DateTranslate(infos[s].updatedDate);
        }

        let notStarted = infos.filter(
          (x) =>
            x.status === organHelper.DevolutionStatus.NotStarted &&
            x.stage === 4,
        );

        let filed = infos.filter(
          (x) =>
            x.status === organHelper.DevolutionStatus.Filed && x.stage === 4,
        );

        let devolution = infos.filter(
          (x) =>
            x.status === organHelper.DevolutionStatus.Devolution &&
            x.stage === 4,
        );

        let exigency = infos.filter(
          (x) =>
            x.status === organHelper.DevolutionStatus.Exigency && x.stage === 4,
        );

        let deferred = infos.filter(
          (x) =>
            x.status === organHelper.DevolutionStatus.Deferred && x.stage === 4,
        );

        let done = infos.filter(
          (x) => x.status === organHelper.DevolutionStatus.Done,
        );

        setData({
          notStarted,
          filed,
          devolution,
          exigency,
          deferred,
          done,
        });
      } else {
        setData({
          notStarted: [],
          filed: [],
          devolution: [],
          exigency: [],
          deferred: [],
          done: [],
        });
      }
      setLoading(false);
    });
  }

  return (
    <Modal
      title="Devolução"
      show={showModal}
      onHide={onHide}
      isLoading={loading}
    >
      <div className="block-header">
        <div className="block-title">
          {data.notStarted && data.notStarted.length + " A protocolar"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="notStarted"
            filename={`Relatorio - Devolução 'A protocolar' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.notStarted}
        columns={organHelper.DevolutionCols}
        tableId="notStarted"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.notStarted)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.filed && data.filed.length + " Protocolado"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="filed"
            filename={`Relatorio - Devolução 'Protocolado' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.filed}
        columns={organHelper.DevolutionCols}
        tableId="filed"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.filed)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.devolution && data.devolution.length + " Devolução"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="devolution"
            filename={`Relatorio - Devolução 'Devolução' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.devolution}
        columns={organHelper.DevolutionCols}
        tableId="devolution"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.devolution)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.exigency && data.exigency.length + " Exigência"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="exigency"
            filename={`Relatorio - Devolução 'Retornado com exigência' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.exigency}
        columns={organHelper.DevolutionCols}
        tableId="exigency"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.exigency)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.deferred && data.deferred.length + " Deferidos"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="deferred"
            filename={`Relatorio - Devolução 'Deferidos' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.deferred}
        columns={organHelper.DevolutionCols}
        tableId="deferred"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.deferred)}
      />

      <div className="block-header">
        <div className="block-title">
          {data.done && data.done.length + " Concluídos"}
        </div>
        <div className="block-tools">
          {/* <ReactToExcel
            className="btn btn-info mt-3"
            table="done"
            filename={`Relatorio - Devolução 'Concluídos' periodo: ${startDate} - ${endDate}`}
            sheet="sheet 1"
            buttonText="Exportar"
          /> */}
        </div>
      </div>

      <Table
        title={" Não iniciados"}
        data={data.done}
        columns={organHelper.DevolutionCols}
        tableId="done"
        hide={false}
        hideHeader={true}
        refreshData={() => null}
        setFilter={(filter) => Functions.ProcessFilter(filter, data.done)}
      />
    </Modal>
  );
}

import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function QuizController() {
  async function Insert(quiz, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "quiz/insert",
        data: quiz,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(quiz, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "quiz/update",
        data: quiz,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Send(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "quiz/send",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `quiz/list`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Get(quizId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `quiz/${quizId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(quizId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `quiz/delete/${quizId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Update,
    List,
    Send,
    Get,
    Delete,
  };
}

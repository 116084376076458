import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function OptionalOrganListController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalorganlist/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalorganlist/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function UpdateList(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `optionalorganlist/update/list`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Get(optionalServiceId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `optionalorganlist/service/${optionalServiceId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Delete(organListId, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `optionalorganlist/delete/${organListId}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    Get,
    Delete,
    Update,
    UpdateList,
  };
}

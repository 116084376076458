import React, { useEffect, useRef, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import Axios from "axios";
import Storage from "@/app/core/util/Storage";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
  BlobProvider,
  Font,
  renderToStream,
} from "@react-pdf/renderer";

import TutorialModal from "./TutorialModal";
import DocInput from "../documents/DocInput";
import {
  ContractInsert,
  UploadContract,
} from "@/app/core/base/controllers/ContractController";
import { toast } from "react-toastify";
import {
  CepMask,
  CnpjMask,
  CpfMask,
} from "@/app/core/util/helpers/ContactHelper";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function GenerateContract({ customer, user }) {
  const [myDoc, setMyDoc] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);
  const [customerType, setCustomerType] = useState(0);
  const date = new Date();
  const inputFile = useRef(null);
  const userData = Storage.getUserData();
  const automations = AutomationsController();
  const customerHelper = CustomerHelper();
  function onUploadClick() {
    inputFile.current.click();
  }

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  let day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear();
  // Font.register({
  //   family: "Oswald",
  //   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  // });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 17,
      textAlign: "center",
      // fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    secondtitle: {
      fontSize: 14,
      margin: 10,
      // fontFamily: "Oswald",
    },
    subtitle: {
      fontSize: 18,
      margin: 20,
      // fontFamily: "Oswald",
    },
    text: {
      fontSize: 14,
      textAlign: "justify",
      // fontFamily: "Times-Roman",
      margin: 10,
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 200,
      width: 80,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  useEffect(() => {
    createContract();
  }, [customer]);

  function createContract() {
    setMyDoc(
      <Document>
        <Page style={styles.body}>
          <Image style={styles.image} src={Logo} />
          <Text style={styles.title}>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</Text>
          <Text style={styles.subtitle}>
            IDENTIFICAÇÃO DAS PARTES CONTRATANTES
          </Text>
          <Text style={styles.text}>
            CONTRATADA: HASA DOCUMENTOS, inscrita no CNPJ 23.615.058/0001-45,
            com sede na Rua Coelho Lisboa, 442 - Cj 84, 8º Andar - Tatuapé, São
            Paulo - SP, 03323-040.
          </Text>
          <Text style={styles.text}>
            CONTRATANTE: {customer.name}, inscrita no{" "}
            {customer && customer.type === 1
              ? `CPF ${CpfMask(customer.cpf)}`
              : `CNPJ ${CnpjMask(customer.cnpj)}`}
            , com sede na {customer.street}{" "}
            {customer.number !== undefined ? `nº ${customer.number}` : ""} -{" "}
            {customer.neighborhood} - {customer.complement} - {customer.city}{" "}
            {customer.uf} - CEP {CepMask(customer.zipcode)}.
          </Text>
          <Text style={styles.secondtitle}>DO OBJETO</Text>
          <Text style={styles.text}>
            1- Constitui objeto deste instrumento a prestação de serviços em
            legalização de empresas, sendo a preparação, protocolo e
            acompanhamento dos processos para escritórios de contabilidade e
            advocacia.
          </Text>

          <Text style={styles.secondtitle}>DA VIGÊNCIA</Text>
          <Text style={styles.text}>
            2 - Este contrato entrará em vigor após o efetivo cadastro realizado
            pelo (a) CONTRATANTE no site www.hasa.com.br, momento em que será
            gerado um código de cliente, que é intransferível, devendo o
            presente contrato ser assinado digitalmente ou por escrito e, neste
            último caso, entregue na sede da CONTRATADA ou por via postal, com
            firma reconhecida.
          </Text>
          <Text style={styles.text}>
            2.1 - Desde que não haja inadimplência, este contrato vigorará por
            prazo indeterminado, podendo o (a) CONTRATANTE solicitar todos os
            serviços oferecidos pela CONTRATADA e que constam na tabela de
            honorários, divulgada no site www.hasa.com.br.
          </Text>

          <Text style={styles.secondtitle}>DAS OBRIGAÇÕES DA CONTRATADA</Text>
          <Text style={styles.text}>
            3 - A prestação de serviços da CONTRATADA é relacionada à
            legalização perante repartições públicas (preparação, protocolo e
            acompanhamento.)
          </Text>
          <Text style={styles.text}>
            3.1 - Todos os dados ou documentos fornecidos pelo (a) CONTRATANTE
            serão guardados até a finalização do processo. Após os que não
            ficaram de posse da repartição competente serão devolvidos, sendo a
            remessa feita conforme opção constante no cadastro do(a)
            CONTRATANTE.
          </Text>
          <Text style={styles.text}>
            3.2 - Todos os documentos deferidos ou com exigências serão avisados
            por e-mail.
          </Text>
          <Text style={styles.text}>
            3.3 - A CONTRATADA deverá dispensar os melhores esforços para
            assegurar a qualidade do serviço prestado, comprometendo-se a manter
            sigilo sobre as informações cadastrais do (a) CONTRATANTE, exceto em
            caso de determinação judicial.
          </Text>
          <Text style={styles.text}>
            3.4 - Todos os serviços prestados serão realizados dentro do prazo
            estipulado na tabela divulgada no site www.hasa.com.br, salvo os
            casos que sofrerem exigências, hipótese que será dada reentrada para
            atendimento da solicitação da repartição, reiniciando-se o prazo
            após novo protocolo.
          </Text>
          <Text style={styles.text}>
            3.5 - Os prazos estipulados são de acordo com o informado por cada
            repartição, quais, a qualquer momento, poderão sofrer alterações.
          </Text>

          <Text style={styles.secondtitle}>DAS OBRIGAÇÕES DA CONTRATANTE</Text>
          <Text style={styles.text}>
            4 - Cabe exclusivamente ao (a) CONTRATANTE o envio de todas as
            informações e documentos para que a prestação de serviços desejada
            seja feita de forma eficaz obrigando-se, ainda, ao cumprimento de
            todas as formalidades legais exigidas pelas repartições públicas,
            inclusive entrega de documentos e pagamento de taxas.
          </Text>
          <Text style={styles.text}>
            4.1 - O (a) CONTRATANTE deve ficar atento (a) ao e-mail cadastrado
            no site da CONTRATADA, atendendo as solicitações com brevidade.
          </Text>

          <Text style={styles.secondtitle}>
            DOS VALORES E FORMA DE PAGAMENTO
          </Text>
          <Text style={styles.text}>
            5 - Como pagamento aos serviços prestados, através de boleto
            bancário, o (a) CONTRATANTE deverá pagar à CONTRATADA o valor dos
            honorários do (s) serviço(s) solicitado(s), sempre no dia 08 (oito)
            de cada mês, estando o serviço finalizado ou em andamento.
          </Text>
          <Text style={styles.text}>
            5.1 - O (a) CONTRATANTE tem o prazo de 05 (cinco) dias para eventual
            contestação relativa à cobrança efetuada.
          </Text>
          <Text style={styles.text}>
            5.2 - O não pagamento ou atraso do boleto importará na incidência de
            multa de 2% (dois por cento) ao mês, juros de mora de 1% (um por
            cento) ao mês e correção monetária podendo, a critério exclusivo da
            CONTRATADA, o acesso do (a) CONTRATANTE ser bloqueado e, desde que
            haja a regularização do débito, a qualquer tempo reativado.
          </Text>

          <Text style={styles.secondtitle}>CANCELAMENTO E ESTORNO</Text>
          <Text style={styles.text}>
            6 - O cancelamento de serviços já aprovados e/ou em andamento deve
            ser solicitado por e-mail ou diretamente na plataforma da
            CONTRATADA, com uma solicitação clara e explícita para o
            cancelamento imediato do serviço.
          </Text>
          <Text style={styles.text}>
            6.1 - Se o cancelamento for solicitado em até 24 horas após a
            confirmação do pedido, será realizado o estorno integral do valor
            pago, exceto nos casos em que o serviço já tenha sido analisado e/ou
            preparado.
          </Text>
          <Text style={styles.text}>
            6.2 - Se o cancelamento for solicitado após 24 horas da confirmação
            do pedido, será realizado o estorno de 30% do valor pago, exceto nos
            casos em que o serviço já tenha sido analisado e/ou preparado.
          </Text>
          <Text style={styles.text}>
            6.3 - Se o serviço já tiver sido analisado e/ou preparado, será
            cobrado o valor total do serviço contratado, sem direito a estorno.
          </Text>

          <Text style={styles.secondtitle}>
            DEVOLUÇÃO POR NÃO CUMPRIMENTO DE EXIGÊNCIAS
          </Text>
          <Text style={styles.text}>
            7 - Se o processo permanecer sem interação e aguardando o
            cumprimento de exigências pelo(a) CONTRATANTE por mais de 90
            (noventa) dias corridos, o serviço será automaticamente finalizado e
            devolvido.
          </Text>
          <Text style={styles.text}>
            7.1 - Para que um processo devolvido possa ser retomado, deverá
            passar por um novo orçamento e aprovação de novo honorário pelo(a)
            CONTRATANTE para garantir a atualização do serviço em questão.
          </Text>
          <Text style={styles.text}>
            7.2 – A CONTRATANTE poderá solicitar a renovação do prazo de 90 dias
            para o cumprimento das exigências a qualquer momento, desde que o
            pedido de prorrogação seja feito antes da devolução efetiva do
            processo.
          </Text>

          <Text style={styles.secondtitle}>
            DO PROTESTO, NEGATIVAÇÃO E COBRANÇA
          </Text>
          <Text style={styles.text}>
            8- Havendo necessidade de envio da pendência ao Cartório de
            Protestos será emitida nota fiscal eletrônica e duplicata de
            prestação de serviços por indicação relativo aos valores devidos,
            sendo o (a) CONTRATANTE notificado (a) pelo Cartório de Protestos
            estando, ainda, sujeito (a) a negativação perante o SCPC e SERASA,
            que igualmente farão a comunicação, além de cobrança judicial,
            hipótese que serão acrescidas custas processuais e honorários
            advocatícios fixados em 20% (vinte por cento).
          </Text>

          <Text style={styles.secondtitle}>DISPOSIÇÕES GERAIS</Text>

          <Text style={styles.text}>
            a) Através do seu efetivo cadastro e aquisição do serviço HASA, o
            (a) CONTRATANTE se compromete a respeitar e cumprir todas as
            disposições aqui inseridas.
          </Text>
          <Text style={styles.text}>
            b) As partes reconhecem o serviço de correio eletrônico (e-mail)
            como forma válida, eficaz de comunicação e aceitam a página do site
            da HASA como meio válido.
          </Text>
          <Text style={styles.text}>
            c) Poderá a CONTRATADA terceirizar os serviços de cobrança de seus
            créditos a outras empresas legalmente constituídas para este fim.
          </Text>
          <Text style={styles.text}>
            d) A CONTRATADA poderá ceder este contrato ou os direitos oriundos
            deste contrato a qualquer uma das sociedades do grupo econômico do
            qual faz parte, que possa vir a fazer ou terceiros, ficando obrigada
            a comunicar a CONTRATANTE sua intenção, sendo reservado o direito do
            (a) CONTRATANTE rescindir o presente contrato de prestação de
            serviços, sem qualquer ônus.
          </Text>
          <Text style={styles.text}>
            e) O (a) CONTRATANTE terá a sua disposição a central de telefone
            (11)3333-6660 e (e-mail) administrativo@hasa.com.br ou em caso de
            sugestão ou reclamação sac@hasa.com.br
          </Text>
          <Text style={styles.text}>
            f) Entre as partes contratantes inexiste vínculo trabalhista ou
            qualquer tipo de relação de subordinação, respondendo cada qual por
            suas obrigações previdenciárias e encargos sociais.
          </Text>
          <Text style={styles.text}>
            g) Este instrumento particular, constitui título executivo
            extrajudicial, nos termos do artigo 784, III do Código de Processo
            Civil e obriga as partes e seus sucessores.
          </Text>
          <Text style={styles.text}>
            h) O presente instrumento, incluindo todas as páginas de assinaturas
            e eventuais anexos, podem ser feitos de forma digital ou eletrônica,
            com o que as partes declaram expressamente concordar, e representam
            a integralidade dos termos entre elas acordados, reconhecendo como
            válida qualquer forma de comprovação de anuência aos termos ora
            acordados em formato eletrônico, ainda que não utilizem de
            certificado digital emitido no padrão ICP-Brasil, incluindo
            assinaturas eletrônicas na plataforma DOCUSIGN, ZAPSING ou qualquer
            outra que for utilizada entre as partes para manifestação da
            vontade, nos termos do art. 10, § 2º, da Medida Provisória nº
            2.200-2 e demais alterações posteriores, sendo o suficiente para
            formalização, validade e vinculação das partes ao presente
            instrumento lembrando que, caso você pertença à determinada pessoa
            jurídica, somente poderá assinar o documento eletronicamente ou
            digitalmente, se possuir poder outorgado à si para representar a
            instituição.
          </Text>

          <Text style={styles.secondtitle}>DO FORO</Text>
          <Text style={styles.text}>
            Para dirimir quaisquer controvérsias oriundas do presente contrato,
            as partes elegem o foro da Comarca de São Paulo, excluindo qualquer
            outro por mais privilegiado que seja. Por estarem assim justos e
            contratados, firmam o presente instrumento, de acordo com as
            assinaturas abaixo.
          </Text>

          <Text style={styles.subtitle}>
            São Paulo, {day} de {monthNames[month - 1]} de {year}
          </Text>
          <Text style={styles.text}></Text>
          <Text style={styles.text}></Text>

          <Text style={styles.text}>
            ______________________________________________________________
          </Text>
          <Text style={styles.text}>CONTRATADA: HASA DOCUMENTOS</Text>
          <Text style={styles.text}></Text>
          <Text style={styles.text}></Text>
          <Text style={styles.text}></Text>
          <Text style={styles.text}>
            ______________________________________________________________
          </Text>
          <Text style={{ ...styles.text, textTransform: "uppercase" }}>
            CONTRATANTE: {customer.name}
          </Text>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>,
    );
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);
    UploadContract(userData.customerId, formData, 1, (res) => {
      toast.success("Salvo com sucesso, bem vindo!");
      setTimeout(() => {
        window.location = `#/customer-profile/${userData.customerId}`;
      }, 500);
    });
  }

  function signature(blob, url, loading, error) {
    if (loading === true && blob === null) {
      toast.error(
        "Ocorreu um erro ao gerar o contrato. Por favor entre em contato com o suporte da Hasa",
      );
    } else if (blob !== null) {
      var reader = new FileReader();
      if (hasCertificate === true) {
        if (customerType === customerHelper.Type.Undefined) {
          toast.error("Selecione o tipo de certificado para prosseguir");
          return;
        } else {
          toast.info("Preparando contrato...");
        }
      }
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        uploadSigned(base64data);
      };
    } else {
      toast.info("Gerando o contrato");
    }
  }

  async function uploadSigned(base) {
    const body = {
      document: {
        path: `/Contrato-de-prestacao.pdf`,
        content_base64: base,
        customerType: customerType,
      },
    };

    ContractInsert(body.document, (res) => {
      toast.info("Siga o processo de assinatura do contrato");
      // window.open(res.signatureUrl, "_blank");
      window.location = res.signatureUrl;
    });
  }

  return (
    <div>
      {myDoc && (
        <BlobProvider document={myDoc}>
          {({ blob, url, loading, error }) => {
            // Do whatever you need with blob here
            return (
              <div>
                <div className="text-center">
                  Assine o contrato digitalmente com o seu certificado digital e
                  logo após acesse seu e-mail para conhecer nossa plataforma
                  <br />
                  <br />
                  <div class="form-group col-md-12">
                    <label class="d-block">
                      Você tem um certificado digital?
                    </label>
                    <div class="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        class="custom-control-input"
                        checked={hasCertificate === false ? true : false}
                        onClick={() =>
                          setHasCertificate(false) |
                          setCustomerType(customerHelper.Type.LegalPerson)
                        }
                        id="example-radio-custom-inline1"
                        name="example-radio-custom-inline"
                      />
                      <label
                        class="custom-control-label"
                        for="example-radio-custom-inline1"
                      >
                        Não
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline custom-control-primary">
                      <input
                        type="radio"
                        class="custom-control-input"
                        id="example-radio-custom-inline2"
                        checked={hasCertificate === true ? true : false}
                        onClick={() => setHasCertificate(true)}
                        name="example-radio-custom-inline"
                      />
                      <label
                        class="custom-control-label"
                        for="example-radio-custom-inline2"
                      >
                        Sim
                      </label>
                    </div>
                  </div>
                  {hasCertificate === true ? (
                    <div class="form-group col-md-12">
                      <label class="d-block">
                        Seu certificado digital é de:
                      </label>
                      <div class="custom-control custom-radio custom-control-inline custom-control-primary">
                        <input
                          type="radio"
                          class="custom-control-input"
                          checked={
                            customerType === customerHelper.Type.LegalPerson
                              ? true
                              : false
                          }
                          onClick={() =>
                            setCustomerType(customerHelper.Type.LegalPerson)
                          }
                          id="example-radio-custom-inline3"
                          name="example-radio-custom-inlineTwo"
                        />
                        <label
                          class="custom-control-label"
                          for="example-radio-custom-inline3"
                        >
                          Pessoa Jurídica
                        </label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline custom-control-primary">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="example-radio-custom-inline4"
                          checked={
                            customerType === customerHelper.Type.Physical
                              ? true
                              : false
                          }
                          onClick={() =>
                            setCustomerType(customerHelper.Type.Physical)
                          }
                          name="example-radio-custom-inlineTwo"
                        />
                        <label
                          class="custom-control-label"
                          for="example-radio-custom-inline4"
                        >
                          Pessoa Física
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />
                  <button
                    className="btn btn-white"
                    id="sign-contract"
                    onClick={() => signature(blob, url, loading, error)}
                  >
                    <i className="fa fa-file-signature"></i> Assinar contrato
                  </button>
                </div>
                <div className="block block-rounded">
                  <div className="block-header">
                    <div className="block-title"></div>
                    <div className="block-tools"></div>
                  </div>
                  <div className="block-content text-center"></div>
                </div>
                <input
                  type="file"
                  ref={inputFile}
                  onChange={(event) => {
                    uploadFile(event.currentTarget.files);
                  }}
                  style={{ visibility: "hidden", width: 10 }}
                />
              </div>
            );
          }}
        </BlobProvider>
      )}
      <TutorialModal show={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
}

import React from "react";

export default function CardModelComponent(props) {
  return (
    <div className="block block-themed  block-mode-loading-refresh">
      <div className="block-header bg-xwork">
        <h3 className="block-title">{props.title}</h3>
        <div className="block-options">
          <button
            type="button"
            className="btn-block-option"
            onClick={props.action}
          >
            <i className={`si si-${props.icon}`}></i>
          </button>
        </div>
      </div>
      <div className="block-content">{props.children}</div>
    </div>
  );
}

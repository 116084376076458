import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export async function MotivationalInsert(formData, level, response) {
  await BaseService(
    {
      method: "POST",
      url: `motivational/insert?level=${level}`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      return response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function MotivationalList(response) {
  await BaseService(
    {
      method: "GET",
      url: `motivational`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export async function MotivationalDelete(motivationalId, response) {
  await BaseService(
    {
      method: "DELETE",
      url: `motivational/delete/${motivationalId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

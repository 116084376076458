import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import { DateTranslate, ProcessDates } from "../../util/DateTranslater";
import { StatusExigency } from "../../util/StatusTranslater";
import { DepartmentTranslate } from "../../util/helpers/DepartmentHelper";
import { DeleteConfirm } from "../../util/messages/GeralMessages";
import { ExigencyUpdate } from "../../base/controllers/ExigenciesController";
import { ExigencyStatus } from "../../util/constants/ExigenciesConstants";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import Storage from "@/app/core/util/Storage";
import DocumentsController from "../../base/controllers/DocumentsController";
import {
  ExigencyTimelineInsert,
  ExigencyTimelineList,
} from "../../base/controllers/ExigencyTimelineController";
import ExigencyTimelineItem from "./exigencies/ExigencyTimelineItem";
import { NotifyUpdate } from "../../base/controllers/TimelineController";
import { TimelineChannelSistema } from "../../util/helpers/TimelineHelper";
import DocInput from "./documents/DocInput";

function ExigencyFulfillmentComponent(props) {
  const {
    handleSubmit,
    show,
    onHide,
    submit,
    isLoading,
    data,
    disable,
    reportNotDone,
  } = props;
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [response, setResponse] = useState(0);
  const [notes, setNotes] = useState("");
  const [sendedId, setSendedId] = useState(null);
  const [user, setUser] = useState({});
  const userData = Storage.getUserData();

  const [timeline, setTimeline] = useState([]);
  const [files, setFiles] = useState([]);
  const docsCtrl = DocumentsController();

  useEffect(() => {
    if (data !== undefined && data !== null) {
      if (data.notes !== null && data.notes !== undefined) {
        setResponse(1);
        setNotes(data.notes);
      }
      if (
        data.processFileId !== undefined &&
        data.processFileId !== null &&
        data.processFileId !== 0
      ) {
        setFile({
          ...data.processFile,
          urlSee: data.processFile.url,
        });
      }
      if (
        data.sendedId !== undefined &&
        data.sendedId !== null &&
        data.sendedId !== 0
      ) {
        setSendedId(data.sendedId);
      }

      if (data.userId) {
        setUser(data.user);
      }

      loadExigencyTimeline();
    } else {
      setResponse(0);
      setNotes("");
      setFile(null);
      setSendedId(null);
      setUser({});
    }

    if (userData && userData.level === 6) {
      setDisabled(true);
    }

    if (disable !== undefined) {
      setDisabled(disable);
    }

    loadProcessFiles();
  }, [props.data]);

  async function loadProcessFiles() {
    if (data !== null && data.processId != undefined) {
      await docsCtrl.ProcessList(data.processId, (res) => {
        let results = [];
        let list = [];

        for (let s in res) {
          res[s].time = new Date(res[s].created).getTime();
          list.push(res[s]);
        }

        let master = list.filter((x) => x.status === 1);
        let complement = list.filter((x) => x.status === 2);
        let consults = list.filter((x) => x.status === 3);

        for (let s in master) {
          results.push(master[s]);
        }
        for (let s in complement) {
          results.push(complement[s]);
        }
        for (let s in consults) {
          results.push(consults[s]);
        }

        setFiles(results);
      });
    }
  }

  function loadExigencyTimeline() {
    if (data.id != undefined) {
      ExigencyTimelineList(data.id, (res) => {
        for (let s in res) {
          res[s].time = new Date(res[s].createdDate).getTime();
        }

        res.sort(function (a, b) {
          return b.time - a.time;
        });

        setTimeline(ProcessDates(res));
      });
    }
  }

  async function saveModel() {
    if (response === null) {
      toast.error("Você precisa informar se foi cumprida");
      return;
    }

    let update = {
      ...data,
      sendedId,
      notes: notes,
      status: parseInt(response),
    };

    const old = update;

    if (update.status === ExigencyStatus.waiting) {
      update.processFileId = null;
      update.response = null;
    }

    ExigencyUpdate(update, (res) => {
      const infos = {
        ...old,
        exigencyId: update.id,
        description:
          response === 1 || response === "1"
            ? `Exigência reprovada: ${notes}`
            : `Exigência cumprida com sucesso.`,
        userId: userData.id,
      };

      NotifyUpdate(
        old.processId,
        response === 1 || response === "1"
          ? `Reprovou a Exigencia : ${notes}`
          : `Aprovou a resposta do cliente : ${data.response}`,
        false,
        TimelineChannelSistema,
        old.organListId,
      );

      reportNotDone();

      ExigencyTimelineInsert(infos, (res) => {
        onHide();
      });
    });
  }

  function removeDocument() {
    if (window.confirm(DeleteConfirm)) {
      setResponse(false);
      ExigencyUpdate({ ...data, fileId: 0 }, (res) => {});
    }
  }

  function verifyResponse() {
    if (data.response === undefined && data.processFile === undefined) {
      return <div className="text-center">O cliente ainda não respondeu</div>;
    } else {
      return (
        <div class="block block-fx-pop">
          <div class="block-content block-content-sm block-content-full bg-body-light">
            <div class="row">
              <div class="col-sm-7">
                <a
                  class="font-w600 link-fx"
                  href="be_pages_generic_profile.html"
                >
                  Resposta do cliente
                </a>
              </div>
              <div class="col-sm-5 d-sm-flex align-items-sm-center">
                <div class="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                  <p class="mb-0">
                    Respondido em : {data && DateTranslate(data.updatedDate)}
                    {/* <button
                      className="btn btn-sm btn-dual"
                      title="Excluir"
                      onClick={() => removeDocument()}
                    >
                      <i className="fa fa-times fa-fw text-danger" />
                    </button> */}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 py-3">
                <p>
                  {data && data.response}
                  <br />
                  {file && file !== null ? (
                    <a
                      class="font-size-sm text-muted pt-2 link"
                      href={file && file.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fa fa-paperclip"></i> {file && file.name}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <Modal
      title="Exigencia"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => saveModel()}
    >
      <Tabs>
        {userData != null && userData.level != 5 ? (
          <Tab>
            <button className="btn btn-dual btn-square">
              <i className="fa fa-fw fa-tasks"></i> Exigência
            </button>
          </Tab>
        ) : (
          ""
        )}
        <Tab>
          <button className="btn btn-dual btn-square">
            <i className="si si-fw si-clock"></i> Timeline
          </button>
        </Tab>
        {userData != null && userData.level != 5 ? (
          <Panel>
            <form onSubmit={handleSubmit}>
              <div class="row">
                <div class="col-sm-12">
                  {/* Enviado para o cliente */}

                  <div class="block block-fx-pop">
                    <div class="block-content block-content-sm block-content-full bg-body-light">
                      <div class="media py-3">
                        <div class="mr-3 ml-2 overlay-container overlay-right">
                          <img
                            class="img-avatar img-avatar48"
                            src={
                              user && user.picture !== null
                                ? user.picture
                                : "assets/media/avatars/avatar4.jpg"
                            }
                            alt=""
                          />
                          <i class="far fa-circle text-white overlay-item font-size-sm bg-success rounded-circle"></i>
                        </div>
                        <div class="media-body">
                          <div class="row">
                            {data && data.user ? (
                              <div class="col-sm-7">
                                <a
                                  class="font-w600 link-fx"
                                  href="be_pages_generic_profile.html"
                                >
                                  {data && data.user.firstName} -{" "}
                                  {data.user &&
                                    DepartmentTranslate(data.user.level)}
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                            <div class="col-sm-5 d-sm-flex align-items-sm-center">
                              <div class="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                                <p class="mb-0">
                                  Criado em :{" "}
                                  {data && DateTranslate(data.createdDate)}
                                </p>
                                <p class="mb-0">
                                  Status : {data && StatusExigency(data.status)}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-12 py-3">
                              <span>Exigência</span>
                              <br />
                              <br />
                              <p>
                                {data && data.description}
                                <br />
                                <br />
                                {data &&
                                data.sendedId !== 0 &&
                                data.sendedId !== undefined ? (
                                  <a
                                    class="font-size-sm text-muted pt-2 link"
                                    href={data.sended && data.sended.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i class="fa fa-paperclip"></i> anexo -{" "}
                                    {data.sended && data.sended.name}
                                    <br />
                                    Remetente :{" "}
                                    {data.user && data.user.firstName}
                                    <br />
                                    Departamento :{" "}
                                    {data.user &&
                                      DepartmentTranslate(data.user.level)}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data && verifyResponse()}

                  {/* <div class="form-group">
                    <label for="example-masked-time">
                      Selecionar arquivo
                    </label>
                    <select
                      className="form-control"
                      disabled={disabled}
                      onChange={(evt) => setSendedId(parseInt(evt.target.value))}
                    >
                      <option value={null}>Selecione uma opção</option>
                      {files && files.map(item => (
                        <option value={item.id} selected={data && item.id === sendedId ? true : false}>{item.name}</option>
                      ))}
                    </select>
                  </div> */}
                  <div class="form-group">
                    <label for="example-masked-time">
                      Essa exigencia foi cumprida?
                    </label>
                    <select
                      className="form-control"
                      disabled={disabled}
                      onChange={(evt) => setResponse(evt.target.value)}
                    >
                      <option value={null}>Selecione uma opção</option>
                      <option
                        value={1}
                        selected={
                          (data &&
                            data.notes !== undefined &&
                            data.notes.length > 0) ||
                          response === true
                            ? true
                            : false
                        }
                      >
                        Nao
                      </option>
                      <option value={3} selected={data && data.status === 3}>
                        Sim
                      </option>
                    </select>
                  </div>
                  {response === 1 || response === "1" ? (
                    <div class="form-group">
                      <label for="example-masked-time">
                        Escreva uma nota para o cliente :
                      </label>
                      <textarea
                        className="form-control"
                        disabled={disabled}
                        value={notes}
                        onChange={(evt) => setNotes(evt.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </Panel>
        ) : (
          ""
        )}

        <Panel>
          <div className="row">
            <div className="col-md-12">
              <h2 className="content-heading">
                <i className="fa fa-angle-right text-muted mr-1"></i> Timeline
              </h2>
            </div>
            <div className="col-md-12">
              {timeline &&
                timeline.map((item) => <ExigencyTimelineItem data={item} />)}
            </div>
          </div>
        </Panel>
      </Tabs>
    </Modal>
  );
}
export default ExigencyFulfillmentComponent;

import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import RecoverComponent from "./main";
import Storage from "@/app/core/util/Storage";

const EmptyRouter = (props) =>
  Storage.isAuthenticated() ? (
    <Redirect to="/app" />
  ) : (
    <div {...props}>{renderRoutes(props.route.routes, props)}</div>
  );

const ResetRouter = () => ({
  path: "/forgot",
  exact: true,
  component: EmptyRouter,
  routes: [
    {
      path: "/forgot",
      component: RecoverComponent,
    },
  ],
});

export default ResetRouter();

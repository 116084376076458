import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import {
  NoteDelete,
  NoteUpdate,
} from "@/app/core/base/controllers/ProcessNotesController";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";

export default function NotesItem({ refresh, data, open }, props) {
  const [observation, setObservation] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setObservation(data.observation);
    }
  }, [data]);

  function saveNote() {
    setDisabled(true);
    NoteUpdate(
      {
        ...data,
        observation: observation,
      },
      (res) => {
        toast.info(Message.Saved);
        refresh();
      },
    );
  }

  function verifyState(item) {
    if (item.important === true) {
      return "border-success";
    } else {
      if (item.channel === 0) {
        return "border-info";
      }

      return "border-secondary";
    }
  }

  function deleteNote() {
    if (window.confirm(Message.DeleteConfirm)) {
      NoteDelete(data.id, (res) => {
        toast.success(Message.DeleteSuccess);
        refresh();
      });
    }
  }

  function fileDownload() {
    window.open(data.file.url, "_blank");
  }

  function fileSee() {
    open(data.file.url);
  }

  return (
    <a
      className={
        "block block-rounded border " +
        verifyState(data) +
        " border-3x js-appear-enabled animated fadeIn"
      }
      data-toggle="appear"
    >
      <div className="block-header">
        <div className="block-title">
          {data && data.user !== undefined ? (
            <span title="Remetente">
              <i className="far fa-user-circle mr-1" />
              {data.user.firstName}
              {" - "}
              {DepartmentTranslate(data.user.level)}
            </span>
          ) : (
            ""
          )}
          {disabled === true ? (
            ""
          ) : (
            <div>
              <textarea
                className="form-control"
                value={observation}
                onChange={(evt) => setObservation(evt.target.value)}
                disabled={disabled}
              />
              <button
                className="btn btn-sm btn-square btn-success ml-1 mt-3"
                onClick={() => saveNote()}
              >
                <i className="si si-check"></i> Salvar
              </button>
            </div>
          )}
        </div>
        <button
          className="btn btn-sm btn-link"
          onClick={() => setDisabled(!disabled)}
        >
          <i className="far fa-edit"></i>
        </button>
        <div className="block-tools">
          <button
            className="btn btn-sm btn-link text-danger"
            onClick={() => deleteNote()}
          >
            <i className="fa fa-times fa-fw"></i>
          </button>
        </div>
      </div>
      <div className="block-content block-content-full block-content-sm bg-body-light">
        <div className="row">
          <div className="col-md-12">{observation}</div>
          <div className="col-md-6">
            <span className="font-size-sm text-muted">
              <strong>{data.createdDate}</strong>
            </span>
          </div>
          <div className="col-md-6">
            <div className="text-right">
              {data && data.file !== undefined ? (
                <span className="font-size-sm text-right">
                  <button
                    className="btn btn-sm btn-dual btn-square"
                    onClick={() => fileDownload()}
                  >
                    <i className="si si-cloud-download text-dark"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-dual btn-square"
                    onClick={() => fileSee()}
                  >
                    <i className="fa fa-eye text-dark"></i>
                  </button>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

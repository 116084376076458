import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ReminderController = {
  Insert: async function (data, response) {
    await BaseService(
      {
        method: "POST",
        url: "reminder/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Update: async function (data, response) {
    await BaseService(
      {
        method: "POST",
        url: "reminder/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  List: async function (customerId, response) {
    await BaseService(
      {
        method: "GET",
        url: `reminder/customer/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Delete: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `reminder/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Get: async function (id, response) {
    await BaseService(
      {
        method: "GET",
        url: `reminder/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Response: async function (id, response) {
    await BaseService(
      {
        method: "POST",
        url: `reminder/response/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
};

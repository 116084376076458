import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import InputMask from "react-input-mask";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import AccountController from "@/app/core/base/controllers/UsersController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";

function EditUserComponent(props) {
  const { handleSubmit, show, onHide, isLoading } = props;

  const userData = Storage.getUserData();
  const token = Storage.getUserToken();

  const [data, setData] = useState(userData);
  const account = AccountController();

  useEffect(() => {}, []);

  async function saveMe() {
    if (data.firstName === null || data.email === null || data.phone === null) {
      toast.error("Preencha todas as informacoes para poder salvar");
      return;
    }

    let user = data;

    await account.Update(user, (res) => {
      Storage.setUserData({
        ...token,
        user: { ...userData, ...user },
      });
      toast.success(Message.Saved);
      onHide();
    });
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  return (
    <Modal
      title="Editar meu usuario"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={() => saveMe()}
      onSubmit={() => saveMe()}
    >
      <form onSubmit={handleSubmit}>
        <div class="row">
          <div class="col-sm-12">
            <div className="col-sm-12">
              <label for="name">Nome</label>
              <input
                type="text"
                className="form-control"
                name="firstName"
                value={data && data.firstName}
                placeholder="Nome"
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div className="col-sm-12">
              <label for="name">Sobrenome</label>
              <input
                type="text"
                className="form-control"
                name="lastName"
                value={data && data.lastName}
                placeholder="Sobrenome"
                onChange={(evt) => handleChange(evt)}
              />
            </div>
            <div className="col-sm-12">
              <label for="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={data && data.email}
                onChange={(evt) => handleChange(evt)}
                placeholder="E-mail do usuário"
              />
            </div>
            <div className="col-sm-12">
              <label for="phone">Celular</label>
              <InputMask
                mask="(99) 999999999"
                maskChar=""
                type="text"
                className="form-control"
                id="advanced-filter-document"
                name="phone"
                placeholder="11 999999999"
                defaultValue={data && data.phone}
                onChange={(event) => {
                  handleChange({
                    target: {
                      name: "phone",
                      value: event.target.value.replace(/\D/g, ""),
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default EditUserComponent;

import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import {
  DateTranslate,
  DateTranslateSimple,
  DateTranslateToInput,
} from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EntryModal from "./EntryModal";
import PaymentModal from "./PaymentModal";

export default function FinancialEntries({ customerId }) {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);

  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    loadCustomerPayments();
  }, []);

  async function loadCustomerPayments() {
    await cashFlow.CustomerEntries(customerId, (res) => {
      for (let s in res) {
        res[s].dueDateString = DateTranslateSimple(res[s].dueDate);
        res[s].dueDate = DateTranslateToInput(res[s].dueDate);
      }
      setData(res);
    });
  }

  async function deleteCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await cashFlow.DeleteEntry(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadCustomerPayments();
      });
    }
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Conta corrente</h3>
          <div className="block-tools">
            <button
              className="btn btn-sm btn-white"
              onClick={() => setShowModal(true)}
            >
              <i className="si si-credit-card"></i> Registrar operação
            </button>
          </div>
        </div>
        <div className="block-content">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <td>Id</td>
                <td>Status</td>
                <td>Tipo</td>
                <td>Valor</td>
                <td>Data de correspondente</td>
                <td>Data de lançamento</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr>
                  <td>{item.id}</td>
                  <td>
                    {financialHelper.PaymentStatusTranslateBadge(item.status)}
                  </td>
                  <td>
                    {financialHelper.OperationTypeTranslateBadge(item.type)}
                  </td>
                  <td>
                    {item.value > 0 &&
                    item.type === financialHelper.OperationType.Income ? (
                      <span className="text-success">
                        R${MoneyMask(item.value, 2)}
                      </span>
                    ) : (
                      <span className="text-danger">
                        R${MoneyMask(item.value, 2)}
                      </span>
                    )}
                  </td>
                  <td>{item.dueDateString}</td>
                  <td>{DateTranslateSimple(item.createdDate)}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-square btn-white m-1"
                      onClick={() => setShowModal(true) | setSelected(item)}
                    >
                      <i className="far fa-edit" /> Editar cobrança
                    </button>
                    <button
                      className="btn btn-sm btn-square btn-white m-1"
                      onClick={() => deleteCharge(item)}
                    >
                      <i className="si si-close text-danger" /> Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <EntryModal
          selected={selected}
          show={showModal}
          onHide={() =>
            setShowModal(false) | loadCustomerPayments() | setSelected({})
          }
        />
      </div>
    </>
  );
}

import React from "react";

export default function UserTile({ user, show }, props) {
  if (user) {
    user.picture = "assets/media/avatars/avatar7.jpg";
  }

  function showModal() {
    show();
  }

  return (
    <div className="block block-bordered">
      <div className="block-content block-content-sm block-content-full bg-body-light">
        <div className="media py-3">
          <div className="mr-3 ml-2 overlay-container overlay-right">
            <img
              className="img-avatar img-avatar48"
              src={"assets/media/avatars/avatar4.jpg"}
              alt=""
            />
          </div>
          <div className="media-body">
            <div className="row">
              <div className="col-sm-7">
                <a className="font-w600 link-fx" href="#">
                  {user && user.firstName} {user && user.lastName}
                </a>
                <div className="font-size-sm text-muted">
                  <a
                    href={`https://wa.me/${user && user.phone}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {user && user.phone}
                  </a>
                  <br />
                  <a
                    href={`mailto:${user && user.email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {user && user.email}
                  </a>
                </div>
              </div>
              <div className="col-sm-5 d-sm-flex align-items-sm-center">
                <div className="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                  <p className="mb-0">
                    {user && user.department}
                    <button
                      type="button"
                      className="btn btn-sm btn-link"
                      onClick={() => {
                        showModal();
                      }}
                    >
                      <i className="far fa-eye"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "@/app/core/components/table/";
import Functions from "@/app/core/util/Functions";
import CashFlowReport from "@/app/core/layouts/main-components/reports/CashFlowReports";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import {
  DateTranslateToInput,
  ProcessDates,
} from "@/app/core/util/DateTranslater";
import PaymentModal from "@/app/core/layouts/main-components/financial/PaymentModal";

export default function FinancialPanel() {
  const date = new Date();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [charges, setCharges] = useState([]);
  const [startDate, setStartDate] = useState(
    DateTranslateToInput(date.getDate()),
  );
  const [endDate, setEndDate] = useState(DateTranslateToInput(date.getDate()));
  const [refreshData, setRefreshData] = useState(false);
  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    loadCashFlow();
  }, [refreshData, startDate, endDate]);

  async function loadCashFlow() {
    await cashFlow.List(startDate, endDate, (res) => {
      for (let s in res) {
        res[s].customerName = `${res[s].customer.name}`;
      }
      setCharges(ProcessDates(res));
    });
  }

  function viewAction(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="block-title">Dashboard</div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <span>Data de início</span>
          <input
            type={"date"}
            className="form-control"
            onChange={(evt) => setStartDate(evt.target.value)}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group">
          <span>Data de início</span>
          <input
            type={"date"}
            className="form-control"
            onChange={(evt) => setEndDate(evt.target.value)}
          />
        </div>
      </div>
      <div className="col-md-12 mt-3"></div>
      <div className="col-md-6">
        <CashFlowReport startDate={startDate} endDate={endDate} />
      </div>
      <div className="col-md-6">
        <Table
          title="Cobranças enviadas"
          loading={false}
          data={charges}
          columns={financialHelper.ChargeResumeCols}
          refreshData={() => setRefreshData(!refreshData)}
          setFilter={(filter) => Functions.ProcessFilter(filter, charges)}
          viewAction={(data) => viewAction(data)}
        />
      </div>

      <PaymentModal
        selected={selected}
        show={showModal}
        onHide={() =>
          setShowModal(false) | setSelected({}) | setRefreshData(!refreshData)
        }
      />
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import { toast, ToastType } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import Storage from "@/app/core/util/Storage";
import SearchItem from "./elements/SearchItem";
import BudgetModal from "./BudgetModal";
import OperationProcessModal from "./OperationProcessModal";
import { Search } from "../../base/controllers/GlobalController";
import SearchCustomerItem from "./elements/SearchCustomerItem";

export default function SearchModal({ show, onHide, information }, props) {
  const userData = Storage.getUserData();
  const [question, setQuestion] = useState(null);
  const [data, setData] = useState({ process: [], customers: [] });
  const [process, setProcess] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [noResults, setNoResults] = useState(false);

  const [processOpen, setProcessOpen] = useState(true);
  const [customerOpen, setCustomerOpen] = useState(true);

  useEffect(() => {
    setData({});
    setNoResults(false);
  }, [show]);

  useEffect(() => {
    if (
      information !== undefined &&
      information !== null &&
      information !== ""
    ) {
      setQuestion(information);
      setTimeout(() => {
        search();
      }, 1000);
    }
  }, [information]);

  function search() {
    if (question === null || question === "") {
      toast.error("O campo de pesquisa esta vazio!");
      return;
    }
    setLoading(true);
    setNotFound(false);
    Search(question, userData.id, (res) => {
      setData(res);
      setProcess(res.process);
      setCustomers(res.customers);

      if (res.process.length === 0 && res.customers.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }

      setLoading(false);
    });
  }

  function verifyKey(evt) {
    if (evt.keyCode === `13` || evt.keyCode === 13) {
      search();
    }
  }

  function openBudget(item) {
    setSelectedData(item);
    setShowBudgetModal(!showBudgetModal);
  }

  function openProcess(item) {
    setShowProcessModal(true);
    setSelectedData(item);
  }

  return (
    <CustomModal
      title="Pesquisa"
      show={show}
      isLoading={loading}
      onHide={onHide}
    >
      <div className="form-group">
        <div className="input-group">
          <input
            type="text"
            placeholder={`Pesquisar...`}
            className="form-control form-control-alt"
            defaultValue={information}
            onChange={(evt) => setQuestion(evt.target.value)}
            onKeyDown={(evt) => verifyKey(evt)}
          />
          <div className="input-group-append">
            <span className="input-group-text input-group-text-alt">
              <i className="fa fa-search" onClick={() => search()}></i>
            </span>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12 text-center">
            {data && data.length === 0 ? <h3>0 Resultados encontrados</h3> : ""}
          </div>
          <div className="block col-12">
            {userData && userData.level != 5 ? (
              <div className="block-header">
                <div className="block-title">Processos</div>
                <div className="block-tools">
                  <button
                    className="btn btn-sm btn-light js-click-ripple-enabled"
                    onClick={() => setProcessOpen(!processOpen)}
                  >
                    <i
                      className={`si si-arrow-${processOpen === true ? "up" : "down"}`}
                    ></i>
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {processOpen === true ? (
              <div className="block-content row">
                {process && process && process.length !== 0
                  ? process.map((item) => (
                      <SearchItem
                        data={item}
                        reload={() => search()}
                        open={(info) => openProcess(info)}
                        budget={(info) => openBudget(info)}
                      />
                    ))
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
          {userData && userData.level != 5 ? (
            <div className="block col-12">
              <div className="block-header">
                <div className="block-title">Clientes</div>
                <div className="block-tools">
                  <button
                    className="btn btn-sm btn-light js-click-ripple-enabled"
                    onClick={() => setCustomerOpen(!customerOpen)}
                  >
                    <i
                      className={`si si-arrow-${customerOpen === true ? "up" : "down"}`}
                    ></i>
                  </button>
                </div>
              </div>
              {customerOpen === true ? (
                <div className="block-content row">
                  {customers &&
                    customers &&
                    customers.length !== 0 &&
                    customers.map((item) => (
                      <SearchCustomerItem
                        data={item}
                        reload={() => search()}
                        open={(info) => openProcess(info)}
                        budget={(info) => openBudget(info)}
                      />
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {notFound == true ? (
            <div className="col-12 text-center">
              <h4 className="h3 font-w400 text-muted mb-5 js-appear-enabled animated fadeInUp">
                Nenhum resultado foi encontrado
              </h4>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <BudgetModal
        data={selectedData}
        info={selectedData}
        show={showBudgetModal}
        onHide={() => setShowBudgetModal(false) | setSelectedData({})}
      />

      <OperationProcessModal
        data={selectedData}
        show={showProcessModal}
        onHide={() => setShowProcessModal(false) | setSelectedData({})}
      />
    </CustomModal>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleUploadErrors } from "../../util/helpers/DocsHelper";

export default function DocsController() {
  async function UploadDocs(
    processId,
    formData,
    senderId,
    organListId,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `file/upload?folder=${processId}&senderId=${senderId}${
          organListId !== null && organListId !== undefined
            ? `&organListId=${organListId}`
            : ""
        }`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function UploadInitialProcessDocs(
    processId,
    formData,
    senderId,
    organListId,
    response,
    fail,
  ) {
    await BaseService(
      {
        method: "POST",
        url: `file/upload?folder=${processId}&senderId=${senderId}${
          organListId !== null && organListId !== undefined
            ? `&organListId=${organListId}`
            : ""
        }`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        fail === undefined ? toast.error(HandleUploadErrors(err)) : fail(err),
    );
  }

  async function ChangeDoc(fileId, formData, senderId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `file/update/upload?fileId=${fileId}&senderId=${senderId}`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function Update(infos, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "file/update",
        data: infos,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function HideAll(processId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `file/hide/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function VisibilityOfSelected(fileIds, visible, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `file/visibility/selecteds/?fileIds=${fileIds}&visible=${visible}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function DeleteList(fileIds, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `file/delete/list/?fileIds=${fileIds}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function CopyToProcess(processId, listfileIds, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `file/copy-files?processId=${processId}`,
        data: listfileIds,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function AddAsMaster(organListId, listfileIds, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `file/add-as-master?organListId=${organListId}`,
        data: listfileIds,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function ProcessList(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `file/process/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function Principals(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `file/principals/${processId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  async function SendOrgan(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `email/send/organ`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleUploadErrors(err)) : error(err),
    );
  }

  return {
    UploadDocs,
    UploadInitialProcessDocs,
    ChangeDoc,
    Update,
    ProcessList,
    HideAll,
    CopyToProcess,
    Principals,
    AddAsMaster,
    VisibilityOfSelected,
    DeleteList,
    SendOrgan,
  };
}

import React from "react";

export default function CardItem({ value, name, icon, aparence, text, click }) {
  return (
    <div
      className={aparence}
      onClick={() => (click !== undefined ? click() : null)}
    >
      <div className="block-content block-content-full d-flex align-items-center justify-content-between">
        <div className="mr-3">
          <p className={"font-size-h3 font-w300 mb-0 " + text}>{value}</p>
          <p className={"mb-0 text-muted " + text}>{name}</p>
        </div>
        <div>
          <i className={icon}></i>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import first from "@/assets/img/tutorial/first.png";
import second from "@/assets/img/tutorial/second.png";
import third from "@/assets/img/tutorial/third.png";
import fifith from "@/assets/img/tutorial/fifith.png";
export default function TutorialModal({ show, onHide, submit }) {
  return (
    <Modal
      title="Como assinar com o certificado digital?"
      show={show}
      isLoading={false}
      onHide={onHide}
      onSave={submit}
    >
      <table class="table table-striped table-borderless table-vcenter">
        <tbody>
          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                01
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Abra o contrato com o Adobe Acrobat
                </a>
                <p class="text-muted mb-0">
                  Se você não tiver o programa instalado em seu computador,
                  basta clicar nesse link :
                  <a
                    href="https://get.adobe.com/br/reader/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    https://get.adobe.com/br/reader/
                  </a>
                  <br />
                  Obs : Não precisa fazer nenhuma assinatura, a versão gratuita
                  atende o uso do certificado
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                02
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Em ferramentas
                </a>
                <p class="text-muted mb-0">
                  Com o contrato aberto vá para a sessão de "Ferramentas"
                </p>
                <img src={first} style={{ width: 400 }} />
              </div>
            </td>
          </tr>

          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                03
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Selecione a opção Certificados
                </a>
                <br />
                <img src={second} style={{ width: 400 }} />
              </div>
            </td>
          </tr>

          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                04
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Agora clique em assinar digitalmente e depois arraste para
                  desenhar a área na qual deve exibir a assinatura
                </a>
                <img src={third} style={{ width: 400 }} />
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                05
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Procure a sua assinatura digital no computador, <br />
                  selecione e preencha a sua senha.
                </a>
                <img src={fifith} style={{ width: 400 }} />
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-center w-25 d-none d-md-table-cell">
              <a
                class="item item-circle bg-primary text-white font-size-h2 mx-auto"
                href="javascript:void(0)"
              >
                06
              </a>
            </td>
            <td>
              <div class="py-4">
                <a
                  class="link-fx h4 mb-2 d-inline-block text-dark"
                  href="javascript:void(0)"
                >
                  Salve o arquivo e faça o upload na plataforma. Pronto!
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function NoteInsert(notes, response) {
  BaseService(
    {
      method: "POST",
      url: `note/insert`,
      data: notes,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function NoteUpdate(notes, response) {
  BaseService(
    {
      method: "POST",
      url: `note/update`,
      data: notes,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProcessNotes(processId, response) {
  BaseService(
    {
      method: "GET",
      url: `note/process/${processId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function NoteDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `note/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import DailyRatesForm from "./DailyRatesForm";
import {
  ResendDelete,
  ResendList,
} from "@/app/core/base/controllers/SendController";
import ResendModal from "./ResendModal";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import Functions from "@/app/core/util/Functions";
import ObjectiveController from "@/app/core/base/controllers/ObjectiveController";
import ObjectiveModal from "./ObjectiveModal";

export default function ObjectiveComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const objectiveCtrl = ObjectiveController();

  const cols = [
    { header: "Vendas", name: "sales", isSortable: true },
    { header: "Atendimento", name: "service", isSortable: true },
    // { header: "Recorrencia", name: "recurrence", isSortable: true },
    { header: "BHAG", name: "bhag", isSortable: true },
    { header: "Atualizado em", name: "updatedDate", isSortable: true },
    { header: "Criado em", name: "createdDate", isSortable: true },
  ];

  useEffect(() => {
    getDailyRates();
  }, []);

  function getDailyRates() {
    setTableLoading(true);
    objectiveCtrl.Get((res) => {
      setData(ProcessDates(res));
      setTableLoading(false);
    });
  }

  function disableDailyRate(data) {
    if (window.confirm(`Tem certeza que quer remover a tarefa?`)) {
      objectiveCtrl.Delete(data.id, (res) => {
        toast.info("Tarefa removida");
        getDailyRates();
      });
    }
  }

  return (
    <>
      <Card
        title="Objetivos"
        action={() => setShowModal(!showModal)}
        icon="settings"
      >
        <Table
          loading={tableLoading || false}
          data={data}
          columns={cols}
          insertAction={() => setShowModal(true)}
          deleteAction={(task) => disableDailyRate(task.data)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          hideHeader={true}
        />
      </Card>

      <ObjectiveModal
        values={selected}
        show={showModal}
        isLoading={modalLoading}
        onHide={() =>
          setShowModal(!showModal) | setSelected({}) | getDailyRates()
        }
      />
    </>
  );
}

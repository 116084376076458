import React, { useEffect, useState, useRef } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import {
  CustomerPicture,
  CustomerSelect,
} from "@/app/core/base/controllers/CustomerController";
import CustomerFeedbackComponent from "@/app/core/layouts/main-components/feedback/CustomerFeedbackComponent";
import ReportIssueModal from "@/app/core/layouts/main-components/feedback/ReportIssueModal";
import CriticModal from "@/app/core/layouts/main-components/feedback/CriticModal";
import ComplimentModal from "@/app/core/layouts/main-components/feedback/ComplimentModal";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [data, setData] = useState({});
  const userData = Storage.getUserData();
  const [showModal, setShowModal] = useState(false);
  const [showComplimentModal, setShowComplimentModal] = useState(false);
  const inputFile = useRef(null);

  useEffect(() => {
    getCustomerData();
  }, []);

  function getCustomerData() {
    CustomerSelect(userData.customerId, (res) => {
      setData(res);
    });
  }

  return (
    <>
      <div className={Storage.getTheme() + ""}>
        <Loading loading={loading} />
        <Tabs>
          <div className="bg-white">
            <div className="ml-1 mr-1 mt-5 mb-1">
              <div className="push">
                <Tab>
                  <button
                    style={{ margin: 10 }}
                    className="btn btn-sm btn-light"
                  >
                    <i className="si si-speech mr-1" /> Feedback
                  </button>
                </Tab>
                <button
                  style={{ margin: 10 }}
                  className="btn btn-sm btn-light"
                  onClick={() => setShowModal(true)}
                >
                  <i className="far fa-sticky-note mr-1" /> Registrar crítica
                </button>
                <button
                  style={{ margin: 10 }}
                  className="btn btn-sm btn-light"
                  onClick={() => setShowComplimentModal(true)}
                >
                  <i className="far fa-check-square mr-1" /> Registrar elogio
                </button>
              </div>
            </div>
            <div className="content row">
              <div className="col-lg-12 col-md-12 col-md-12">
                <Panel>
                  <CustomerFeedbackComponent
                    customerId={userData && userData.customerId}
                  />
                </Panel>
              </div>
            </div>
          </div>
        </Tabs>
        <CriticModal show={showModal} onHide={() => setShowModal(false)} />
        <ComplimentModal
          show={showComplimentModal}
          onHide={() => setShowComplimentModal(false)}
        />
      </div>
    </>
  );
}

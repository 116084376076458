import React from "react";

export default function UserContactCard({ user }) {
  function sendWhatsapp() {
    const url = `https://api.whatsapp.com/send?phone=${user.phone}`;
    window.open(url, "_blank");
  }

  function openUserEmail() {
    const url = `mailto:${user.email}`;
    window.open(url, "_blank");
  }

  return (
    <div className="block block-fx-pop">
      <div className="block-content block-content-sm block-content-full bg-body-light">
        <div className="media py-3">
          <div className="mr-3 ml-2 overlay-container overlay-right">
            <img
              className="img-avatar img-avatar48"
              src={"assets/media/avatars/avatar4.jpg"}
              alt=""
            />
            <i className="far fa-circle text-white overlay-item font-size-sm bg-success rounded-circle"></i>
          </div>
          <div className="media-body">
            <div className="row">
              <div className="col-sm-7">
                <a className="font-w600 link-fx" href="#">
                  {user && user.firstName} {user && user.lasttName}
                </a>
                <div className="font-size-sm text-muted">
                  {user && user.phone}
                  <br />
                  {user && user.email}
                </div>
              </div>
              <div className="col-sm-5 d-sm-flex align-items-sm-center">
                <div className="font-size-sm font-italic text-muted text-sm-right w-100 mt-2 mt-sm-0">
                  <p className="mb-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-link"
                      disabled={false}
                      onClick={() => {
                        sendWhatsapp();
                      }}
                    >
                      <i className="fab fa-whatsapp"></i> Whatsapp
                    </button>
                  </p>
                  <p className="mb-0">
                    <button
                      type="button"
                      className="btn btn-sm btn-link"
                      onClick={() => {
                        openUserEmail();
                      }}
                    >
                      <i className="si si-envelope-letter"></i> Email
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

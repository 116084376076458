import React, { useState, useEffect } from "react";
import Storage from "@/app/core/util/Storage";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { StateList } from "@/app/core/base/controllers/StateController";
import { Redirect } from "react-router-dom";
import {
  CustomerConsult,
  CustomerInsert,
  CustomerUpdate,
  SendWelcome,
} from "@/app/core/base/controllers/CustomerController";
import {
  LoginAccount,
  UserConsult,
  UserInsert,
} from "@/app/core/base/controllers/UsersController";
import { SendWelcomeCustomer } from "@/app/core/base/controllers/ContactController";
import {
  ConversionNotify,
  GetAddress,
} from "@/app/core/base/controllers/GlobalController";
import ContractModal from "./ContractModal";
import {
  CPFValidator,
  PasswordIsStrong,
  ValidaCNPJ,
} from "@/app/core/util/helpers/UserHelper";
import { HandleAuthErrors } from "@/app/core/util/helpers/ErrorHelper";
import rdstationNodeClient from "rdstation-node-client";

export default function RegisterForm({ returnRequest, baseData }) {
  const [stateList, setStateList] = useState([]);
  const [customer, setCustomer] = useState({ type: 1 });
  const [user, setUser] = useState({});
  const [authenticate, setAuthenticate] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [passwordValid, setPasswordValid] = useState("");
  // var conversions = new rdstationNodeClient.Conversions(
  //   "c25214a3f48d7b7570f7b261916eb332"
  // );

  useEffect(() => {
    loadStates();
    if (baseData !== undefined) {
      setCustomer({ ...customer, ...baseData });
    }
  }, [baseData]);

  function loadStates() {
    StateList((res) => {
      setStateList(res);
    });
  }

  function loadStates() {
    StateList((res) => {
      setStateList(res);
    });
  }

  function handleChange(evt) {
    let old = customer;
    let newData = { [evt.target.name]: evt.target.value };

    setCustomer({ ...old, ...newData });

    if (evt.target.name === "cpf") {
      if (evt.target.value.length === 11 || evt.target.value.length === 14) {
        if (!CPFValidator(evt.target.value)) {
          toast.error("Número de CPF inválido");
        }
      }
    }

    if (evt.target.name === "cnpj") {
      if (evt.target.value.length === 14) {
        if (!ValidaCNPJ(evt.target.value)) {
          toast.error("Número de CNPJ inválido");
        }
      }
    }

    if (evt.target.name === "zipcode" && evt.target.value.length >= 8) {
      GetAddress(evt.target.value, (res) => {
        setCustomer({ ...customer, ...res });
      });
    }
  }

  function handleUserChange(evt) {
    let old = user;
    let newData = { [evt.target.name]: evt.target.value };

    if (evt.target.name === "password") {
      if (evt.target.value.length > 5) {
        if (!PasswordIsStrong(evt.target.value)) {
          setPasswordValid(" is-invalid");
        } else {
          setPasswordValid(" is-valid");
        }
      }
    }

    setUser({ ...old, ...newData });
  }

  function registerCustomer() {
    if (user.password === user.passwordConfirmation) {
      if (!CPFValidator(customer.cpf)) {
        toast.error("Número de CPF inválido");
        return;
      }
      if (!PasswordIsStrong(user.password)) {
        toast.error(
          "A senha é muito fraca, ela precisa ter ao menos 1 caracter especial e letras maiúsculas",
        );
        return;
      }
      if (
        !customer.name ||
        !customer.street ||
        !customer.neighborhood ||
        !customer.city ||
        !customer.uf ||
        !customer.zipcode ||
        !customer.phone
      ) {
        toast.error("Preencha todos os dados para poder salvar");
      } else {
        const infos = customer;

        CustomerConsult(infos.cnpj, infos.cpf, infos.type, (response) => {
          continueTask(response);
        });
      }
    } else {
      toast.error("As senhas são diferentes");
    }
  }

  function continueTask(response) {
    if (response === true) {
      toast.error("Já existe um cliente cadastrado com esse documento");
    } else {
      const us = user;
      UserConsult(us.email, (responseUser) => {
        if (responseUser === true) {
          toast.error(
            "Já existe uma conta de usuário cadastrado com esse email",
          );
        } else {
          let customerBody = customer;
          customerBody.email = us.email;
          if (returnRequest === undefined) {
            CustomerInsert(customerBody, (res) => {
              createUser(res);
            });
          } else {
            CustomerUpdate(customerBody, (res) => {
              createUser(res);
            });
          }
        }
      });
    }
  }

  async function createUser(company) {
    let identifier = "cadastro_sistema";

    if (returnRequest !== undefined) {
      identifier = "orcamento_sem_cadastro";
    }

    UserInsert(
      {
        ...user,
        username: user.email,
        customerId: company.id,
        level: 5,
        cpf: company.cpf,
        phone: company.whatsapp,
      },
      async (res) => {
        toast.info("Cadastrado com sucesso!");
        await SendWelcome(company.id, (res) => {});
        LoginAccount(user, (res) => {
          toast.success("Bem vindo!");
          Storage.setUserData(res);
          // SendWelcomeCustomer(res, company.id);
          if (returnRequest === undefined) {
            setAuthenticate(true);
          } else {
            returnRequest();
          }
        });
      },
      (error) => {
        toast.error(HandleAuthErrors(error));
      },
    );
  }

  if (authenticate) return <Redirect to="/verification" />;

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="form-group">
          <label className="d-block">Tipo de cliente</label>
          <div className="custom-control custom-radio custom-control-inline custom-control-primary">
            <input
              type="radio"
              className="custom-control-input"
              onClick={() =>
                handleChange({ target: { value: 1, name: "type" } })
              }
              disabled={disable}
              id="example-radio-custom-inline1"
              checked={customer.type === 1 ? true : false}
              name="example-radio-custom-inline"
            />
            <label
              className="custom-control-label"
              for="example-radio-custom-inline1"
            >
              Pessoa física
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline custom-control-primary">
            <input
              type="radio"
              className="custom-control-input"
              id="example-radio-custom-inline2"
              checked={customer.type === 2 ? true : false}
              onClick={() =>
                handleChange({ target: { value: 2, name: "type" } })
              }
              disabled={disable}
              name="example-radio-custom-inline"
            />
            <label
              className="custom-control-label"
              for="example-radio-custom-inline2"
            >
              Pessoa Jurídica
            </label>
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="name">Nome</label>
          <input
            type="text"
            className="form-control"
            placeholder="Nome"
            name="name"
            disabled={disable}
            value={customer && customer.name}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      {/* <div className="col-sm-6">
        <div className="form-group">
          <label for="email">E-mail</label>
          <input
            placeholder="E-mail"
            className="form-control"
            type="email"
            name="email"
            disabled={disable}
            value={customer && customer.email}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div> */}

      <div className="col-sm-6">
        <div className="form-group">
          <label for="zipcode">Cep</label>
          <input
            placeholder="CEP"
            className="form-control"
            type="text"
            name="zipcode"
            disabled={disable}
            value={customer && customer.zipcode}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="address">Logradouro</label>
          <input
            placeholder="Logradouro"
            className="form-control"
            type="text"
            name="street"
            disabled={disable}
            value={customer && customer.street}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="address">Número</label>
          <input
            placeholder="Número"
            className="form-control"
            type="text"
            name="number"
            disabled={disable}
            value={customer && customer.number}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="address">Bairro</label>
          <input
            placeholder="Bairro"
            className="form-control"
            type="text"
            name="neighborhood"
            disabled={disable}
            value={customer && customer.neighborhood}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="address">Complemento</label>
          <input
            placeholder="Complemento"
            className="form-control"
            type="text"
            name="complement"
            disabled={disable}
            value={customer && customer.complement}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="city">Cidade</label>
          <input
            placeholder="Cidade"
            className="form-control"
            type="text"
            name="city"
            disabled={disable}
            value={customer && customer.city}
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="uf">Estado</label>
          <select
            name="uf"
            onChange={(evt) => handleChange(evt)}
            disabled={disable}
            value={customer.uf}
            className="form-control"
          >
            <option value={null}>Selecionar</option>
            {stateList &&
              stateList.map((state) => (
                <option
                  value={state.shortName}
                  selected={customer.uf === state.shortName}
                >
                  {state.longName} ({state.shortName})
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="col-sm-12 py-2"></div>
      {customer && customer.type === 2 ? (
        <div className="col-sm-6">
          <div className="form-group">
            <label for="document">CNPJ</label>
            <InputMask
              mask="99.999.999/9999-99"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              disabled={disable}
              name="cnpj"
              placeholder="99.999.999/9999-99"
              value={customer && customer.cnpj}
              onChange={(event) => {
                handleChange({
                  target: {
                    name: "cnpj",
                    value: event.target.value.replace(/\D/g, ""),
                  },
                });
              }}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col-sm-6">
        <div className="form-group">
          <label for="document">
            {customer && customer.type === 2 ? "CPF do Responsável" : "CPF"}
          </label>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            disabled={disable}
            name="cpf"
            placeholder="999.999.999-99"
            value={customer && customer.cpf}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "cpf",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="document">CRC / OAB</label>
          <input
            placeholder="CRC"
            className="form-control"
            disabled={disable}
            type="text"
            value={customer && customer.crc}
            name="crc"
            onChange={(evt) => handleChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="phone">Telefone comercial</label>
          <InputMask
            mask="(99) 9999-9999"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            disabled={disable}
            name="phone"
            placeholder="(99) 99999-9999"
            value={customer && customer.phone}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "phone",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="phone">Whatsapp</label>
          <InputMask
            mask="(99) 99999-9999"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            disabled={disable}
            name="phone"
            placeholder="11 99999-9999"
            value={customer && customer.whatsapp}
            onChange={(event) => {
              handleChange({
                target: {
                  name: "whatsapp",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-sm-12 py-2">Login de administrador</div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="firstName">Nome</label>
          <input
            placeholder="Nome"
            className="form-control"
            type="text"
            disabled={disable}
            name="firstName"
            value={user && user.firstName}
            onChange={(evt) => handleUserChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="lastName">Sobrenome</label>
          <input
            placeholder="Sobrenome"
            className="form-control"
            type="text"
            name="lastName"
            disabled={disable}
            value={user && user.lastName}
            onChange={(evt) => handleUserChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-12">
        <div className="form-group">
          <label for="city">E-mail</label>
          <input
            placeholder="E-mail"
            className="form-control"
            disabled={disable}
            type="text"
            name="email"
            value={user && user.email}
            onChange={(evt) => handleUserChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="city">Senha</label>
          <input
            placeholder="*********"
            className={"form-control" + passwordValid}
            disabled={disable}
            type="password"
            name="password"
            value={user && user.password}
            onChange={(evt) => handleUserChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label for="city">Confirme a Senha</label>
          <input
            placeholder="*********"
            className={"form-control" + passwordValid}
            type="password"
            disabled={disable}
            name="passwordConfirmation"
            value={user && user.passwordConfirmation}
            onChange={(evt) => handleUserChange(evt)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-3 py-3">
        <small className="">
          * A senha precisa de ao menos 1 caracter especial, letra maiúscula,
          minúscula e número
        </small>
        <br />
      </div>
      <div className="col-sm-12 text-center">
        {disable === false ? (
          <button
            type="submit"
            className="btn btn-block btn-hero-lg btn-hero-primary"
            onClick={() => registerCustomer()}
            // onClick={() => setShowModal(true)}
          >
            <i className="fa fa-fw fa-sign-in-alt mr-1" /> Cadastrar
          </button>
        ) : (
          <button
            type="submit"
            id="account-register"
            className="btn btn-block btn-hero-lg btn-hero-success"
            onClick={() => registerCustomer()}
          >
            <i className="si si-check mr-1" />
            Cadastrado com sucesso! Entrar no sistema
          </button>
        )}
        {returnRequest === undefined ? (
          <p className="mt-3 mb-0 text-center">
            <a
              className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
              onClick={() => (window.location = "/")}
            >
              <i className="fa fa-arrow-left text-muted mr-1" /> Voltar para
              login
            </a>
          </p>
        ) : (
          ""
        )}
      </div>
      <ContractModal
        show={showModal}
        onHide={() => setShowModal(false)}
        customer={customer}
        user={user}
      />
    </div>
  );
}

import ProcessController from "@/app/core/base/controllers/ProcessController";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { CustomerUsers } from "@/app/core/base/controllers/UsersController";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserTile from "../../main/elements/UserTile";
import DropdownElement from "../elements/DropdownElement";

export default function ResponsibleCard({ data, refresh, toggleUserModal }) {
  const [customerUsers, setCustomerUsers] = useState([]);
  const [budgetResponsible, setBudgetResponsible] = useState("");
  const [responsible, setResponsible] = useState("");
  const [open, setOpen] = useState(false);
  const processCtrl = ProcessController();

  useEffect(() => {
    if (open) {
      loadCustomerUsers();
    }
  }, [data, open]);

  function loadCustomerUsers() {
    if (data === null || data === undefined || data.customerId === undefined) {
      return;
    }
    CustomerUsers(data.customerId, (res) => {
      setCustomerUsers(res);
    });
  }

  async function saveUpdate() {
    if (window.confirm("Deseja continuar?")) {
      let message = "Adicionou ";
      let process = data;
      if (budgetResponsible.length === 0 && responsible.length === 0) {
        toast.error("Selecione ao menos 1 responsável");
        return;
      }
      if (budgetResponsible.length > 0) {
        process.budgetResponsibleId = budgetResponsible;
        const budget = customerUsers.filter((x) => x.id === budgetResponsible);
        message += ` responsável do orçamento: '${budget[0].firstName}' '${budget[0].email}'`;
      }
      if (budgetResponsible.length > 0 && responsible.length > 0) {
        message += " e ";
      }
      if (responsible.length > 0) {
        process.responsibleId = responsible;
        const responsi = customerUsers.filter((x) => x.id === responsible);
        message += ` responsável do processo: '${responsi[0].firstName}' '${responsi[0].email}'`;
      }

      if (data.operatorId === undefined && responsible.length > 0) {
        process.operatorId = responsible;
      }

      NotifyUpdate(process.id, message, false);

      await processCtrl.Update(process, (res) => {
        toast.success("Salvo com sucesso");
        refresh();
      });
    }
  }

  return (
    <div className="block block-fx-shadow">
      <div className="block-header">
        <div className="block-title">Contatos do cliente</div>
        <div className="block-tools d-flex align-items-center justify-content-between">
          <DropdownElement
            submit={() => saveUpdate()}
            button="Salvar"
            className="btn btn-square btn-sm btn-outline-dark"
          >
            <div className="form-group">
              <span>Responsável pelo processo</span>
              <select
                className="form-control"
                onChange={(evt) => setResponsible(evt.target.value)}
              >
                <option value={null}>Selecione</option>
                {customerUsers.map((item) => (
                  <option value={item.id}>
                    {item.firstName} - {item.email}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <span>Responsável pelo orçamento</span>
              <select
                className="form-control"
                onChange={(evt) => setBudgetResponsible(evt.target.value)}
              >
                <option value={null}>Selecione</option>
                {customerUsers.map((item) => (
                  <option value={item.id}>
                    {item.firstName} - {item.email}
                  </option>
                ))}
              </select>
            </div>
          </DropdownElement>
          <button
            className="btn btn-sm btn-square btn-outline-dark js-click-ripple-enabled"
            onClick={() => setOpen(!open)}
          >
            <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
          </button>
        </div>
      </div>
      {open === true ? (
        <div className="block-content">
          Responsável pelo processo
          <br />
          {data &&
          data.responsibleId !== null &&
          data.responsibleId !== undefined ? (
            <UserTile
              user={data.responsible}
              show={() => {
                toggleUserModal(data.responsible);
              }}
            />
          ) : (
            <small>Não cadastrado / removido</small>
          )}
          <br />
          Responsável pelo orçamento
          <br />
          {data &&
          data.budgetResponsibleId !== null &&
          data.budgetResponsibleId !== undefined ? (
            <UserTile
              user={data.budgetResponsible}
              show={() => {
                toggleUserModal(data.budgetResponsible);
              }}
            />
          ) : (
            <small>Não cadastrado / removido</small>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function HistoricController() {
  async function Insert(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `history/insert`,
        data: values,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function List(processId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `history/process/${processId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `history/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Clear(organListId, department, destiny, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `history/return-exigency/${organListId}?department=${department}&destiny=${destiny}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    Insert,
    List,
    Update,
    Clear,
  };
}

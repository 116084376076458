import OptionalServiceController from "@/app/core/base/controllers/OptionalServiceController";
import ServiceGroupController from "@/app/core/base/controllers/ServiceGroupController";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import {
  ServiceGroupType,
  ServiceGroupTypeList,
  ServiceGroupTypeTranslate,
} from "@/app/core/util/helpers/SelectedServiceHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropdownElement from "../elements/DropdownElement";
import OptionalServiceItem from "./OptionalServiceItem";
import Storage from "@/app/core/util/Storage";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { Typeahead } from "react-bootstrap-typeahead";

export default function ServiceGroupItem({
  group,
  refresh,
  services,
  position,
  clientView,
  preview,
  reloadGroups,
  selectedServices,
  disabled,
}) {
  const [data, setData] = useState({});
  const [newService, setNewService] = useState({});
  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());

  const serviceGroup = ServiceGroupController();
  const optionalService = OptionalServiceController();

  useEffect(() => {
    if (group && group.id !== undefined) {
      setData(group);
    } else {
      setData({});
    }
  }, [group]);

  async function loadGroup() {
    await serviceGroup.GetById(data.id, (res) => setData(res));
  }

  async function addNewService() {
    if (newService.serviceId === undefined) {
      toast.error("Selecione ao menos o serviço");
    } else {
      let isSelected = false;
      await optionalService.Insert(
        {
          ...newService,
          serviceGroupId: data.id,
          processId: data.processId,
          serviceId: newService.serviceId,
          description: newService.description,
          position: parseInt(position),
          selected: isSelected,
          editable: newService.editable,
        },
        (res) => {
          setNewService({ description: "" });
          if (userData && userData.level !== 5) {
            loadGroup();
          } else {
            reload();
          }
        },
      );
    }
  }

  async function saveOrUpdate() {
    if (edit === true) {
      save();
    }
    setEdit(!edit);
  }

  async function save() {
    await serviceGroup.Update(data, (res) => {
      toast.success("Salvo com sucesso");
      setNewService({ description: "" });
      refresh();
    });
  }

  function handleChange(evt, type) {
    setNewService(OnDataChange(newService, evt, type));
  }

  function handleGroupChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function handleSingleOption(evt, index) {
    let sv = data.optionalServices;

    for (let s in sv) {
      if (sv[s].id !== evt.id) {
        sv[s].selected = false;
      } else {
        sv[s].selected = true;
      }

      await optionalService.Update(sv[s], (res) => {});
    }

    if (userData === null || userData.level === 5) {
      refresh();
    } else {
      loadGroup();
    }
  }

  async function removeGroup() {
    if (window.confirm(Message.DeleteConfirm)) {
      await serviceGroup.Delete(data.id, (res) => {
        toast.success(Message.DeleteSuccess);
        reloadGroups();
      });
    }
  }

  function reload() {
    refresh();
  }

  function upperPosition() {
    if (data.stage > 2) {
      toast.error("Esse Serviço não pode mais ser movido");
      return;
    }

    if (data.position < selectedServices.length) {
      let oldData = data;
      oldData.position = data.position + 1;
      setData(oldData);
      setTimeout(() => {
        save();
      }, 400);
    }
  }

  function lowerPosition() {
    if (data.stage > 2) {
      toast.error("Esse Serviço não pode mais ser movido");
      return;
    }
    if (data.position > 1) {
      let oldData = data;
      oldData.position = data.position - 1;
      setData(oldData);
      setTimeout(() => {
        save();
      }, 400);
    }
  }

  return (
    <div className="block block-bordered border-warning-light border-2x">
      <div className="block-header">
        {userData && userData.level !== 5 ? (
          <div className="block-title content-heading">
            {data && data.position}º Grupo de serviços{" "}
            <button
              className="btn btn-sm btn-square btn-dual ml-1"
              disabled={disabled}
              onClick={() => saveOrUpdate()}
            >
              {edit === true ? (
                <i className="si si-check text-success" />
              ) : (
                <i className="fa fa-edit text-info" />
              )}
            </button>
            <button
              className="btn btn-sm btn-square btn-dual ml-1"
              disabled={disabled}
              onClick={() => removeGroup()}
            >
              <i className="si si-close text-danger" />
            </button>
          </div>
        ) : (
          <div className="block-title content-heading">
            {data && data.type === ServiceGroupType.SingleChoice
              ? `${data && data.position}º Selecione uma opção de serviço`
              : "Também recomendamos esses serviços complementares"}
            {userData && userData.level === 5 && clientView !== true ? (
              <button
                className="btn btn-sm btn-square btn-dual ml-1"
                disabled={disabled}
                onClick={() => saveOrUpdate()}
              >
                {edit === true ? (
                  <i className="si si-check text-success" />
                ) : (
                  <i className="fa fa-edit text-info" />
                )}
              </button>
            ) : (
              ""
            )}
          </div>
        )}

        {userData && userData.level !== 5 ? (
          <div className="block-tools">
            <DropdownElement
              submit={() => addNewService()}
              style={{ width: 450 }}
              icon={"si si-plus"}
              title="Adicionar serviço"
              disabled={disabled}
            >
              <div className="form-group">
                <span>Serviço</span>
                <Typeahead
                  options={services}
                  id="advanced-filter-state"
                  name="name"
                  clearButton={true}
                  placeholder="Selecionar serviço"
                  labelKey={(option) =>
                    `${option.name} - R$${MoneyMask(option.value, 2)}`
                  }
                  onChange={(selected) => {
                    if (selected[0] !== undefined) {
                      setNewService({
                        ...newService,
                        serviceId: selected[0].id,
                      });
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <span>Descrição</span>
                <textarea
                  className="form-control"
                  name="description"
                  value={newService && newService.description}
                  onChange={(evt) => handleChange(evt)}
                />
              </div>
            </DropdownElement>
            {userData && userData.level !== 5 ? (
              <div className="block-tools text-right">
                {userData && (userData.level === 1 || userData.level === 2) ? (
                  <button
                    className="btn btn-sm btn-square btn-outline-white"
                    onClick={() => lowerPosition(data)}
                    disabled={disabled !== undefined ? disabled : false}
                  >
                    <i className="si si-arrow-up" />
                  </button>
                ) : (
                  ""
                )}
                {userData && (userData.level === 1 || userData.level === 2) ? (
                  <button
                    className="btn btn-sm btn-square btn-outline-white"
                    onClick={() => upperPosition(data)}
                    disabled={disabled !== undefined ? disabled : false}
                  >
                    <i className="si si-arrow-down" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mr-1 ml-1 mb-3 row">
        <div className={userData && userData.level !== 5 ? "col-6" : "col-12"}>
          <div className="form-group">
            {/* <span>Descrição : </span> */}
            <br />
            {edit === false ? (
              <p>{data && data.description}</p>
            ) : (
              <textarea
                className="form-control"
                value={data && data.description}
                name="description"
                onChange={(evt) => handleGroupChange(evt)}
              />
            )}
          </div>
        </div>
        {userData && userData.level !== 5 ? (
          <div className="col-6">
            <div className="form-group">
              <span>Tipo : </span>
              <br />
              {edit === false ? (
                <p>{data && ServiceGroupTypeTranslate(data.type)}</p>
              ) : (
                <select
                  className="form-control"
                  name="type"
                  onChange={(evt) =>
                    handleGroupChange(evt, FieldTypeObj.Number)
                  }
                >
                  <option value={null}>Selecione</option>
                  {ServiceGroupTypeList.map((item) => (
                    <option
                      value={item.value}
                      selected={data && data.type === item.value ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {data &&
          data.optionalServices &&
          data.optionalServices.map((item, index) => (
            <div className="col-md-6">
              <OptionalServiceItem
                service={item}
                radialChange={(evt) => handleSingleOption(evt, index)}
                group={data}
                refresh={() => reload()}
                clientView={clientView}
                isClient={preview}
                disabled={disabled}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

import React from "react";

export default function BigSearchComponent() {
  return (
    <div className="bg-white">
      <div className="content">
        <form
          className="push"
          action="be_pages_generic_search.html"
          method="post"
        >
          <div className="input-group input-group-lg">
            <input
              type="text"
              className="form-control form-control-alt"
              placeholder="Pesquisar..."
            />
            <div className="input-group-append">
              <span className="input-group-text border-0 bg-body">
                <i className="fa fa-fw fa-search"></i>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CashFlowReport({ startDate, endDate }) {
  const date = new Date();
  const [data, setData] = useState([
    {
      name: "10/05",
      Receitas: 780.58,
    },
    {
      name: "12/05",
      Receitas: 982,
    },
    {
      name: "13/05",
      Receitas: 651,
    },
    {
      name: "14/05",
      Receitas: 9200,
    },
    {
      name: "15/05",
      Receitas: 3780,
    },
    {
      name: "16/05",
      Receitas: 3817,
    },
    {
      name: "17/05",
      Receitas: 4000,
    },
  ]);
  const [param, setParam] = useState(30);
  const [services, setServices] = useState([]);
  const cashFlow = CashFlowController();

  useEffect(() => {
    loadData();
  }, [startDate, endDate]);

  function loadData() {
    cashFlow.List(startDate, endDate, (res) => {});

    cashFlow.ServiceReport(startDate, endDate, (res) => {
      let total = 0;
      for (let s in res) {
        total += res[s].value;
      }

      for (let s in res) {
        res[s].percentage = total / res[s].value;
      }

      setServices(res);
    });
  }

  function getTotalValue() {
    let total = 0;
    for (let s in data) {
      total += data[s].Receitas;
    }

    return total;
  }

  return (
    <div>
      <div className="block block-fx-pop">
        <div className="block-header">
          <div className="block-title">
            <div class="py-1">
              <p class="h6 text-muted">Balanço total</p>
            </div>
          </div>
          <div className="block-tools">
            <p class="h3 font-w700 mb-2 text-success">
              R${MoneyMask(getTotalValue(), 2)}
            </p>
          </div>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-md-12" style={{ height: 300 }}>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ResponsiveContainer>
                  <BarChart
                    width={"100%"}
                    height={700}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* <Bar dataKey="Balanço" fill="#197BBD" /> */}
                    <Bar dataKey="Receitas" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block block-tranparent">
        <div className="block-header">
          <div className="block-title">Receita por serviço</div>
          <div className="block-tools"></div>
        </div>
        <div className="block-content">
          <div className="row">
            <div className="col-md-12">
              {services &&
                services.map((item) => (
                  <div class="mb-0">
                    <div class="progress mb-1" style={{ height: 6 }}>
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                        role="progressbar"
                        style={{ width: `${parseInt(item.percentage)}%` }}
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p class="font-size-sm font-w600 mb-3 row">
                      <span class="font-w700 col-md-6">
                        {item.name} - {parseInt(item.percentage)}%
                      </span>
                      <span class="font-w700  col-md-6 text-right">
                        R${MoneyMask(item.value, 2)}
                      </span>
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { ToastContainer, toast } from "react-toastify";
import { EvaluationListByDate } from "@/app/core/base/controllers/EvaluationController";
import { DateIsNear, DateTranslate } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import BudgetModal from "@/app/core/layouts/main-components/BudgetModal";

export default function MainComponent(props) {
  const [loading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    loadEvaluation();
  }, []);

  function loadEvaluation() {
    EvaluationListByDate(startDate, endDate, (res) => {
      setData(res.filter((x) => x.observation && x.observation.length > 0));
    });
  }

  function reactivate(item) {
    if (window.confirm(Message.Confirm)) {
      item.process.budgetStatus = BudgetStatus.WaitingBudget;
      ProcessController().ReactivateBudget(item.processId, (res) => {
        setShowModal(true);
        setSelected(res);
      });
    }
  }

  return (
    <div className="content">
      <Loading loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <div className="content-heading">
            <h2>Recusados</h2>
          </div>
        </div>
        <div className="form-group col-md-2">
          <span>Data de início</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={startDate}
            onChange={(evt) => setStartDate(evt.target.value)}
          />
        </div>
        <div className="form-group col-md-2">
          <span>Data fim</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={endDate}
            onChange={(evt) => setEndDate(evt.target.value)}
          />
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-info mt-3"
            onClick={() => loadEvaluation()}
          >
            Pesquisar
          </button>
        </div>
        <div className="col-md-12">
          <table className="table table hover">
            <thead>
              <tr>
                <th>Processo</th>
                <th>Cliente</th>
                <th>Motivo</th>
                <th>Data da recusa</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr>
                    <td>{item.process && item.process.processName}</td>
                    <td>
                      {item.process &&
                        item.process.customer &&
                        item.process.customer.customerCode}{" "}
                      -{" "}
                      {item.process &&
                        item.process.customer &&
                        item.process.customer.name}
                    </td>
                    <td>{item.observation}</td>
                    <td>{DateTranslate(item.createdDate)}</td>
                    <td>
                      {DateIsNear(item.createdDate) < 30 ? (
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => reactivate(item)}
                        >
                          Reativar
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <BudgetModal
        data={selected}
        info={selected}
        show={showModal}
        onHide={() => setShowModal(false) | loadEvaluation() | setSelected({})}
      />
    </div>
  );
}

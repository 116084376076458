import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";
// import ReactToExcel from "react-html-table-to-excel";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";

export default function ExigencyReportModal({
  showModal,
  onHide,
  infos,
  startDate,
  endDate,
}) {
  const [data, setData] = useState([]);
  const organHelper = OrganListHelper();

  useEffect(() => {
    if (
      infos !== null &&
      infos !== undefined &&
      infos.length > 0 &&
      showModal == true
    ) {
      for (let s in infos) {
        infos[s].serviceName = `${
          infos[s].selectedService !== undefined
            ? infos[s].selectedService.serviceName
            : "S.I."
        }`;
        infos[s].organName = `${
          infos[s].organList !== undefined
            ? infos[s].organList.organ.name
            : "S.I."
        }`;
        infos[s].processName = `${
          infos[s].process !== undefined ? infos[s].process.processName : "S.I."
        }`;
        infos[s].createdDate = DateTranslate(infos[s].createdDate);
        infos[s].updatedDate = DateTranslate(infos[s].updatedDate);
        infos[s].department = DepartmentTranslate(infos[s].department);
      }

      setData(infos);
    } else {
      setData([]);
    }
  }, [infos, showModal]);

  return (
    <Modal size="lg" title="Exigências" show={showModal} onHide={onHide}>
      <div className="block-header">
        <div className="block-title">{data && data.length + " Exigências"}</div>
        <div className="block-tools"></div>
      </div>
      <div className="" style={{ maxWidth: 1200, overflowX: "scroll" }}>
        <Table
          title={" Não iniciados"}
          data={data}
          columns={organHelper.ExigencyReturnCols}
          tableId="exigencyReturns"
          hide={false}
          hideHeader={true}
          refreshData={() => null}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
        />
      </div>
    </Modal>
  );
}

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import TimelineController from "@/app/core/base/controllers/TimelineController";
import { SelectedServiceAnalysisStatus } from "@/app/core/util/helpers/SelectedServiceHelper";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import HelpModal from "../HelpModal";

export default function ContactSwitch({
  title,
  processName,
  processId,
  organListId,
}) {
  const timelineCtrl = TimelineController();
  const processCtrl = ProcessController();
  const notificationCtrl = NotificationController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();
  const [showHelpModal, setShowHelpModal] = useState(false);

  async function requestContact(message) {
    await processCtrl.Select(processId, async (response) => {
      await organCtrl.SelectById(organListId, async (res) => {
        let question = message;

        let body = {
          message: "O cliente solicitou contato. " + question,
          processId: parseInt(processId),
          selectedServiceId: res !== undefined ? res.selectedServiceId : null,
          organListId: res !== undefined ? res.id : null,
          status: 4,
          userId: "",
        };

        if (res.stage === 1) {
          body.userId = "";
        }
        if (res.stage === 2) {
          res.statusAnalysis = SelectedServiceAnalysisStatus.ReturnToCustomer;
          body.userId = res.analystId;
        }
        if (res.stage === 3) {
          res.statusPreparation =
            SelectedServiceAnalysisStatus.ReturnToCustomer;
          body.userId = res.preparerId;
        }
        if (res.stage === 4) {
          res.statusDevolution = organHelper.DevolutionStatus.ReturnToCustomer;
          body.userId = res.devolutionExigencyId;
        }

        await organCtrl.Update(res, (newResponse) => {});

        await notificationCtrl.Insert(body, async (res) => {
          body.userId = null;
          await timelineCtrl.Insert(
            {
              ...body,
              observation: body.message,
              channel: 6,
            },
            (res) => {
              toast.info(
                "Salvo com sucesso. Um representante entrará em contato",
              );
              // setShowHelpModal(true);
            },
          );
        });
      });
    });
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant={"white"}
        className="form-control"
        style={{ width: "80%" }}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="form-control"
        style={{ width: "80%", height: 240 }}
      >
        <div className="form-group">
          <span>Entrar em contato por :</span>
          <br />
        </div>
        <a
          type="button"
          className="btn btn-hero-sm btn-outline-secondary"
          style={{ width: "100%" }}
          href={
            "mailto:hasa@hasa.com.br?subject=Duvidas no cumprimento de exigências Processo: " +
            processName
          }
        >
          Email
        </a>
        <br />
        <a
          type="button"
          className="btn btn-hero-sm btn-outline-success"
          style={{ width: "100%" }}
          href={
            "https://api.whatsapp.com/send?phone=551133336660&text=Estou com dúvidas no cumprimento de exigências Processo: " +
            processName
          }
          target="_blank"
          rel="noreferrer"
        >
          Whatsapp
        </a>
        <button
          className="btn btn-hero-sm btn-outline-warning"
          style={{ width: "100%" }}
          onClick={() => setShowHelpModal(true)}
        >
          Solicitar contato
        </button>
        <br />
        <br />
        <div className="form-group">
          <span>
            Por telefone no numero :
            <br /> (11) 2095-7753
          </span>
        </div>

        <HelpModal
          show={showHelpModal}
          processId={processId}
          organListId={organListId}
          onHide={() => setShowHelpModal(false)}
          report={(message) => requestContact(message)}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import { customerBudgetCols, customerProcessCols } from "./ColumnHelper";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CustomerGrid({ setShowOperationModal }) {
  const userData = Storage.getUserData();
  const [refreshData, setRefreshData] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [processes, setProcesses] = useState([]);

  useEffect(() => {
    processData();
  }, [refreshData]);

  function processData() {
    if (userData && userData.level === 5 && userData.customerId !== undefined) {
      window.location = `#/customer-profile/${userData.customerId}`;
    }
    // GetCustomerProcess(userData.customerId, (res) => {
    //   let budgetList = [];
    //   let processList = [];
    //   for (let s in res) {
    //     const item = res[s];
    //     item.updatedDate = DateTranslate(item.updatedDate);
    //     item.createdDate = DateTranslate(item.createdDate);
    //     if (item.budgetStatus === 5) {
    //       budgetList.push(item);
    //     }
    //     if (item.budgetStatus === 2 && item.status === 1) {
    //       processList.push(item);
    //     }
    //   }

    //   setBudgets(budgetList);
    //   setProcesses(processList);
    // });
  }

  function viewAction(recv) {
    setShowOperationModal(recv);
  }

  function viewBudgetAction(recv) {
    if (recv.data.budgetStatus === 5) {
      window.open(`#/confirm/${recv.data.id}`, "_blank");
    } else {
      toast.info("Esse orçamento esta sendo elaborado");
    }
  }

  return (
    <div>
      <div className="">
        <div className="row">
          <Table
            title={"Orçamentos"}
            loading={false}
            data={budgets}
            columns={customerBudgetCols}
            refreshData={() => setRefreshData(!refreshData)}
            viewAction={(data) => viewBudgetAction(data)}
          />
          <Table
            title="Processos"
            loading={false}
            data={processes}
            columns={customerProcessCols}
            refreshData={() => setRefreshData(!refreshData)}
            viewAction={(data) => viewAction(data)}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CityDelete,
  CityList,
  CitySearch,
  CityUpdate,
} from "@/app/core/base/controllers/AvailableCityController";
import { ProcessDates } from "@/app/core/util/DateTranslater";

export default function ProfileMainComponent(props) {
  const userData = Storage.getUserData();
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [city, setCity] = useState("");

  async function toggleModal() {}

  useEffect(() => {
    loadAvailableCities();
    toggleModal();
  }, [refresh]);

  function loadAvailableCities() {
    CityList((res) => {
      console.log("Cities", res);
      setData(ProcessDates(res));
    });
  }

  function updateCity(item) {
    CityUpdate(
      {
        ...item,
        available: !item.available,
      },
      (res) => {
        setRefresh(!refresh);
      },
    );
  }

  function removeCity(item) {
    if (window.confirm("Você tem certeza de que deseja excluir esse item?")) {
      CityDelete(item.id, (res) => {
        setRefresh(!refresh);
      });
    }
  }

  function saveCity() {
    if (city === "" || city === null) {
      alert("Preencha para poder salvar");
    }

    CitySearch(city, (res) => {
      setRefresh(!refresh);
      setCity("");
    });
  }

  return (
    <div>
      <div className="content">
        <div className="row">
          <div className="col-md-6">
            <div
              className="py-4 py-md-0 text-center text-md-left js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              <h1 className="font-size-h2 mb-2">Cidades disponíveis</h1>
              <h3 className="font-size-lg font-w400 text-muted mb-0">
                Sessão de pesquisa do site
              </h3>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span>Adicionar cidade</span>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cidade"
                  value={city}
                  onChange={(evt) => setCity(evt.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => saveCity()}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="block block-content">
              <table className="table table-hover table-bordered">
                <thead>
                  <tr>
                    <td>Município</td>
                    <td>Pesquisas</td>
                    <td>Disponibilidade</td>
                    <td>Ações</td>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item) => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.searchs}</td>
                        <td>
                          {item.available === true
                            ? "Disponível"
                            : "Indisponível"}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-square btn-info"
                            onClick={() => updateCity(item)}
                          >
                            {item.available === true
                              ? "Tornar Indisponível"
                              : "Tornar Disponível"}
                          </button>
                          <button
                            className="btn btn-sm btn-square btn-danger"
                            onClick={() => removeCity(item)}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className="toastr" />
    </div>
  );
}

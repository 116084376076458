import React, { useState, useRef } from "react";
import Storage from "@/app/core/util/Storage";
import {
  ContractDelete,
  SeeContract,
  UpdateContract,
  UploadContract,
} from "@/app/core/base/controllers/ContractController";
import DocButton from "../DocButton";
import { DeleteConfirm, Message } from "@/app/core/util/messages/GeralMessages";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import FileViewer from "../../FileViewer";
import { toast } from "react-toastify";

export default function ContractItem({ item, refresh }) {
  const userData = Storage.getUserData();
  const [open, setOpen] = useState(false);
  const inputFile = useRef(null);

  function onUploadClick() {
    inputFile.current.click();
  }

  async function downloadDoc(item) {
    if (item.documentKey === undefined || item.documentKey === "") {
      window.open(item.url, "_blank");
    } else {
      await SeeContract(item.documentKey, (res) => {
        if (res.document !== undefined) {
          if (res.document.downloads.signed_file_url !== undefined) {
            window.open(res.document.downloads.signed_file_url, "_blank");
          } else if (res.document.downloads.original_file_url !== undefined) {
            window.open(res.document.downloads.original_file_url, "_blank");
          } else {
            toast.error(
              "Não foi possível visualizar o documento. Confira o status no painel da ClickSign",
            );
          }
        }
      });
    }
  }

  function removeContract(item) {
    if (window.confirm(DeleteConfirm))
      ContractDelete(item.id, (res) => {
        refresh();
      });
  }

  function uploadFile(files) {
    var formData = new FormData();
    for (let s in files) {
      formData.append("file", files[s]);
    }

    UpdateContract(item.id, formData, 2, (res) => {
      toast.success(Message.Saved);
      refresh();
    });
  }

  function translateStatus(sts) {
    if (sts === 0) {
      return <span className={"badge badge-danger"}>Não assinado</span>;
    } else if (sts === 1) {
      return <span className={"badge badge-info"}>Assinado</span>;
    } else if (sts === 2) {
      return <span className={"badge badge-success"}>Assinado por ambos</span>;
    }
  }

  return (
    <div
      className={`block block-rounded border-left border-5x block-fx-pop mb-2`}
      key={item.id}
    >
      <div className="block-header">
        <div className="block-title">{item.name}</div>
        <div className="block-tools">
          {userData && userData.level !== 5 ? (
            <DocButton
              data={item}
              title="Atualizar"
              style={"text-dark mb-1"}
              action={() => onUploadClick()}
              icon={"fa fa-fw fa-file-upload"}
            />
          ) : (
            ""
          )}
          <DocButton
            data={item}
            title="Download"
            style={"text-dark mb-1"}
            action={() => downloadDoc(item)}
            icon={"fa fa-fw fa-file-download"}
          />
          {/* <DocButton
            data={item}
            title="Visualizar"
            style={"text-dark mb-1"}
            action={() => setOpen(!open)}
            icon={"fa fa-fw fa-eye"}
          /> */}
          {userData && userData.level !== 5 ? (
            <DocButton
              data={item}
              title="Remover"
              style={"text-dark mb-1"}
              action={() => removeContract(item)}
              icon={"si si-close"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <table className="table table-borderless table-vcenter mb-0">
        <tbody>
          <tr>
            <td className="text-left">{translateStatus(item.status)}</td>
            <td className="text-right">{DateTranslate(item.updatedDate)}</td>
          </tr>
        </tbody>
      </table>
      {open === true ? (
        <div className="block-content text-center">
          <FileViewer file={item.url} height={450} openImage={(data) => null} />
        </div>
      ) : (
        ""
      )}
      <input
        type="file"
        ref={inputFile}
        onChange={(event) => {
          uploadFile(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
    </div>
  );
}

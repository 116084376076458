import React from "react";
import Layout from "@/app/core/layouts/main";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import CompanyComponent from "./main";
import Storage from "@/app/core/util/Storage";

const ProjectShell = (props) =>
  Storage.isAuthenticated() ? (
    <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
  ) : (
    <Redirect to="/" />
  );

const Redirecter = () => <Redirect to="/error/404" />;

const CompanyRouter = {
  path: "/company",
  component: ProjectShell,
  routes: [
    {
      path: "/company",
      exact: true,
      component: CompanyComponent,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};

export default CompanyRouter;

import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import Table from "@/app/core/components/table/";
import { ProcessDates } from "@/app/core/util/DateTranslater";
import { toast } from "react-toastify";
import Functions from "@/app/core/util/Functions";
import { SenderController } from "@/app/core/base/controllers/SenderController";
import SenderModal from "./SenderModal";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function SenderCard(props) {
  const [showModal, setShowModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});

  const cols = [
    { header: "Id", name: "id", isSortable: true },
    { header: "Email", name: "email", isSortable: true },
    {
      header: "Tipo",
      name: "type",
      isSortable: true,
      switch: { 0: "Orçamento", 1: "Exigência", 2: "Deferido" },
    },
    { header: "Atualizado em", name: "updatedDate", isSortable: true },
    { header: "Criado em", name: "createdDate", isSortable: true },
  ];

  useEffect(() => {
    getDailyRates();
  }, []);

  function getDailyRates() {
    setTableLoading(true);
    SenderController.List((res) => {
      setData(ProcessDates(res));
      setTableLoading(false);
    });
  }

  function disableDailyRate(data) {
    if (window.confirm(Message.DeleteConfirm)) {
      SenderController.Delete(data.id, (res) => {
        toast.info(Message.DeleteSuccess);
        getDailyRates();
      });
    }
  }

  return (
    <>
      <Card
        title="Remetentes"
        action={() => setShowModal(!showModal)}
        icon="settings"
      >
        <Table
          loading={tableLoading || false}
          data={data}
          columns={cols}
          insertAction={() => setShowModal(true)}
          deleteAction={(task) => disableDailyRate(task.data)}
          setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          hideHeader={true}
        />
      </Card>

      <SenderModal
        values={selected}
        show={showModal}
        isLoading={modalLoading}
        onHide={() =>
          setShowModal(!showModal) | setSelected({}) | getDailyRates()
        }
      />
    </>
  );
}

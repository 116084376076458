import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export function CityList(response) {
  BaseService(
    {
      method: "GET",
      url: `city`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CitySearch(query, response) {
  BaseService(
    {
      method: "GET",
      url: `city/search?query=${query}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.success(HandleErrors(err));
    },
  );
}

export function CityDelete(id, response) {
  BaseService(
    {
      method: "DELETE",
      url: `city/delete/${id}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CityInsert(data, response) {
  BaseService(
    {
      method: "POST",
      url: "city/insert",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CityUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: `city/update`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err.response !== undefined) {
        toast.error(HandleErrors(err));
      }
    },
  );
}

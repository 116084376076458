import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import ConfirmService from "@/app/modules/confirm-service";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import { toast } from "react-toastify";

export default function CustomerBudgetModal(
  { show, onHide, process, processId },
  props,
) {
  const userData = Storage.getUserData();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(1);

  useEffect(() => {}, [show, process]);

  function preEnd() {
    toast.success(
      "O serviço será iniciado imediatamente! Agradecemos sua parceria",
    );
    onHide();
  }

  return (
    <CustomModal
      title="Orçamento"
      show={show}
      isLoading={loading}
      onHide={onHide}
    >
      {processId && processId != undefined ? (
        <ConfirmService match={processId && { params: { key: processId } }} />
      ) : (
        ""
      )}
      {process && process.length == 0 ? (
        <div className="text-center">
          <i className="si si-check fa-2x text-success" />
          <br />
          <h3 className="text-muted">Você não tem orçamentos pendentes</h3>
        </div>
      ) : (
        <Tabs>
          <ul class="nav nav-tabs nav-tabs-alt border-bottom-0 justify-content-center border-warning justify-content-md-start">
            {process &&
              process.map((item, index) => (
                <Tab>
                  <li class="nav-item">
                    <a
                      class={
                        selected &&
                        `btn ${selected == index + 1 ? "btn-warning" : "btn-dual"}`
                      }
                      href="javascript:void(0)"
                      onClick={() => setSelected(index + 1)}
                    >
                      <span class="d-none d-md-inline ml-1">
                        {item.processName}
                      </span>
                    </a>
                  </li>
                </Tab>
              ))}
          </ul>
          {process &&
            process.map((item) => (
              <Panel>
                <ConfirmService
                  match={item && { params: { key: item.id } }}
                  end={() => preEnd()}
                />
              </Panel>
            ))}
        </Tabs>
      )}
    </CustomModal>
  );
}

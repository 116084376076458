import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import {
  DateToPast,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";

export default function NotificationsModal({ processId, open, show, onHide }) {
  const [days, setDays] = useState(7);
  const [notifications, setNotifications] = useState([]);
  const userData = Storage.getUserData();
  const notificationCtrl = NotificationController();

  useEffect(() => {
    loadNotifications();
  }, []);

  async function loadProcessNotifications() {
    await notificationCtrl.GetAllProcessNotifications(
      processId,
      days,
      (res) => {
        for (let s in res) {
          res[s].time = new Date(res[s].createdDate).getTime();
        }

        res.sort(function (a, b) {
          return b.time - a.time;
        });

        setNotifications(res);
      },
    );
  }

  async function loadUserNotifications() {
    await notificationCtrl.AllUserNotifications(userData.id, days, (res) => {
      for (let s in res) {
        res[s].time = new Date(res[s].date).getTime();
      }

      res.sort(function (a, b) {
        return b.time - a.time;
      });

      setNotifications(res);
    });
  }

  function loadNotifications() {
    if (processId !== null && processId !== undefined) {
      loadProcessNotifications();
    } else {
      loadUserNotifications();
    }
  }

  return (
    <Modal
      title="Central de notificações"
      show={show}
      onHide={onHide}
      onSubmit={() => onHide()}
    >
      <div className="block-header">
        <div className="block-title">
          <div className="form-group">
            <span>Carregar :</span>
            <select
              className="form-control"
              value={days}
              onChange={(evt) => setDays(parseInt(evt.target.value))}
            >
              <option value={7}>Últimos 7 dias</option>
              <option value={30}>Últimos 30 dias</option>
              <option value={300}>Todas as notificações</option>
            </select>
          </div>
          <div className="form-group py-3">
            <button
              className="btn btn-sm btn-info"
              onClick={() => loadNotifications()}
            >
              Consultar
            </button>
          </div>
        </div>
      </div>
      <div className="block-content">
        {notifications &&
          notifications.map((item) => (
            <div className="block block-fx-pop">
              <div className="block-header">
                <div className="block-title">
                  {!processId &&
                    item.process &&
                    `Processo : ${item.process.processName}`}{" "}
                  - {item.user && item.user.firstName} -{" "}
                  {item.user && DepartmentTranslate(item.user.level)}
                </div>
                <div className="block-tools">
                  {DateTranslateSimple(item.createdDate)} - (
                  {DateToPast(item.createdDate)})
                </div>
              </div>
              <div className="block-content">
                <div className="row">
                  <div className="col-md-8">{item.message}</div>
                  <div className="col-md-4 text-right">
                    {item && item.view === true ? (
                      <button
                        className="btn btn-sm btn-dual"
                        onClick={() => toast.info("Visualizado")}
                      >
                        <i className="fa fa-check text-success" />
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-sm btn-dual"
                      onClick={() => open(item)}
                    >
                      <i className="fa fa-eye" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
}

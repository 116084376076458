import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { SendSugestionEmail } from "@/app/core/base/controllers/ContactController";

export default function SugestionModal({
  show,
  onHide,
  submit,
  isLoading,
  customer,
}) {
  const [data, setData] = useState({});
  const userData = Storage.getUserData();

  useEffect(() => {
    loadData();
  }, [show, customer]);

  async function loadData() {
    setData({
      name: `${userData !== null ? userData.firstName : ""} ${
        userData !== null ? userData.lastName : ""
      }`,
      customerId: userData !== null ? userData.customerId : "",
      email: userData !== null ? userData.email : "",
      customerCode: customer && customer.customerCode,
    });
  }

  async function save() {
    if (
      data.name !== undefined &&
      data.name !== "" &&
      data.description !== undefined &&
      data.description !== ""
    ) {
      SendSugestionEmail(data);
      setTimeout(() => {
        toast.success("Sua sugestão foi enviada com sucesso, Obrigado!");
        onHide();
      }, 500);
    } else {
      toast.error(Message.PendingData);
    }
  }

  function handleChange(evt) {
    setData(OnDataChange(data, evt));
  }

  return (
    <Modal
      title="Sugestões"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <div className="form-group">
        <span>Nome</span>
        <input
          className="form-control"
          name="name"
          value={data.name}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      <div className="form-group">
        <span>Sugestão</span>
        <textarea
          className="form-control"
          name="description"
          value={data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}

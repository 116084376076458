import ProcessController from "@/app/core/base/controllers/ProcessController";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import {
  DateTranslate,
  DateTranslateToInput,
} from "@/app/core/util/DateTranslater";
import React, { useEffect, useState } from "react";
// import ReactToExcel from "react-html-table-to-excel";

export default function ConsultComponent() {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const process = ProcessController();

  useEffect(() => {
    loadProcesses();
  }, [startDate, endDate]);

  async function loadProcesses() {
    await process.ByDate(startDate, endDate, (res) => {
      console.log(`Distintos`, res);
      setData(res);
    });
  }

  function translation(i) {
    if (i === 0) {
      return <span class="badge badge-secondary">Não lançado</span>;
    }
    if (i === 1) {
      return <span class="badge badge-info">Lançado</span>;
    }
    if (i === 2) {
      return <span class="badge badge-success">Confirmado</span>;
    }
  }

  function openProcess(item) {
    setSelected({ id: item.processId, processId: item.processId });
    setShowModal(true);
  }

  return (
    <div className="content">
      <div className="block block-transparent">
        <div className="block-header">
          <div className="block-title">
            {data && data.length} Serviços totais
            <br />
            <small>
              {data && data.filter((x) => x.price > 60 && x.price != 0).length}{" "}
              Serviços contabilizados
            </small>
          </div>
          <div className="block-tools row">
            <div className="col-4">
              <div className="form-group">
                <span>Data de início</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={startDate}
                  onChange={(evt) => setStartDate(evt.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <span>Data de fim</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={endDate}
                  onChange={(evt) => setEndDate(evt.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              {/* <ReactToExcel
                className="btn btn-link mt-4"
                table="table-element"
                filename={`relatorio ${startDate} - ${endDate}`}
                sheet="sheet 1"
                buttonText="Exportar"
              /> */}
            </div>
          </div>
        </div>

        <div className="block-content">
          <div className="row">
            <div className="col-md-12">
              <table
                className="table-hover table table bordered"
                id="table-element"
              >
                <thead>
                  <tr>
                    <td>Id</td>
                    <td>Cód. cliente</td>
                    <td>Nome</td>
                    <td>Serviço</td>
                    <td>Lançamento do processo</td>
                    <td>Atualizado em</td>
                    <td>Aceito o orçamento em</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>
                          {item.process.customer &&
                            item.process.customer.customerCode}
                        </td>
                        <td>{item.process.processName}</td>
                        <td>{item.serviceName}</td>
                        <td>{translation(item.process.synchronized)}</td>
                        <td>{DateTranslate(item.process.updatedDate)}</td>
                        <td>{DateTranslate(item.acceptedDate)}</td>
                        <td>
                          <button
                            className="btn btn-white btn-sm"
                            onClick={() => openProcess(item)}
                          >
                            <i className="far fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <OperationProcessModal
          data={selected}
          show={showModal}
          onHide={() => setShowModal(false) | setSelected({})}
          reservation={selected}
        />
      </div>
    </div>
  );
}
